import React from 'react'
import "./footer.scss";
export default function Footer() {
  return (
    <>
      <div className='footer p-0'>
        <div className='footer_bg'>
          <div className='footer_content'>
            <p className='futuraNBold-30 text-white'>Join Our<br></br> Community</p>
            <div className='footer_btn futuraN700-16'>
              <button className='btn-secondry text-white m-2'> Find your home</button>
              <a href="/place-an-ad">
                  <button className='btn-primary text-lightBlack m-2'>Host with Us </button>
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
