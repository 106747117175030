import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { AutoComplete } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { getUser } from "../../utils/functions";
import { AppContext } from "../AppContext";
import PageHeader from "../_newComponents/pageHeader";
import FilterButtons from "../_newComponents/FilterButtons";
import MobileNav from "../_newComponents/MobileNav";
import NoParkingImage from "../images/no-parking-img.svg";
import SearchIcon from "../icons/search.svg";
import StarIcon from "../icons/star_active.svg";
import GarageIcon from "../icons/garage.svg";
import StairIcon from "../icons/stairs.svg";
import PinIcon from "../icons/pinMarker.svg";
import BedIcon from "../icons/bedAm.svg";
import CalendarIcon from "../icons/calendar.svg";
import ManIcon from "../icons/man.svg";
import WomanIcon from "../icons/woman.svg";
import CoupleIcon from "../icons/couple.svg";
import ListIcon from "../icons/list.svg";
import "./style/hot-leads.scss";
import { Link } from "react-router-dom";

const HotLeadPage = () => {

    const user = getUser();
    const { userInfo } = useContext(AppContext);
  const [leads, setLeads] = useState([]);
  const [options, setOptions] = useState([]);
  const [val, setVal] = useState("");
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/get-hot-lead`)
      .then((res) => {
        const data = res.data.data;

        data.forEach((item) => {
          const isAfter = moment(item.move_in_date).isAfter(
            moment().format("YYYY-MM-DD"),
            "day"
          );

          item.date = isAfter && moment(item.move_in_date).format("MMMM D");
        });

        setLeads(data);
      })
      .catch((err) => console.log(err));
  }, [userInfo]);

 

  const onSelect = () => {};
  const onSearch = () => {};

  const reset = () => {};
  const applyFilters = () => {};
  const change = () => {};

  const separateNumbers = (num) =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;

  console.log(leads);

  return (
    <div className="hotlead">
      <PageHeader homeLink="/" isCustom title="Parking" />

      {/* <div className="search-container">
        <div className="search-listing d-flex align-items-center justify-content-between">
          <form
            className="search-listing__container mr-2"
            style={{ flex: 1 }}
            onSubmit={(e) => {
              e.preventDefault();
              applyFilters();
            }}
          >
            <img src={SearchIcon} alt="Search" />

            <AutoComplete
              style={{
                background: "inherit",
                borderRadius: "999px",
                boxShadow: "none",
              }}
              id="search-autocomplete"
              className="form-control search"
              placeholder="Find a place to stay"
              allowClear={true}
              options={options}
              onSelect={onSelect}
              onSearch={(value) => {
                onSearch("data", value);
                setVal(value);
              }}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </form>

          <div>
            <Button
              onClick={applyFilters}
              className="bookN-s"
              style={{ fontWeight: "bold" }}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      <div className="parking__filters">
        <FilterButtons
          filters={filters}
          onReset={reset}
          onSearch={applyFilters}
          change={change}
        />
      </div> */}

      <div className="hotlead__list">
        {leads.map((prk, i) => (
          <div className="lead-list" key={i}>
            <div className="row ml-0 mr-0">
              <div className="lead-list__info col-12 pl-0 pr-0">
                {/* <Link to={`/parking/${btoa(prk.prk_id)}/${prk.prk_title}`}> */}
                  <h2>{prk.name}
                  <img src={`https://roomdaddy.com/public/flags/32x32/${prk.sortname.toLowerCase()}.png`} alt="" srcset=""></img>
                  {prk.gender === "male" ? 
                    (
                         <img
                      src={ManIcon}
                      alt="Parking Garage Icon - RoomDaddy"
                    />
                    )
                 : 
                    prk.gender === "female" ? (
                     <img
                      src={WomanIcon}
                      alt="Parking Garage Icon - RoomDaddy"
                    />
                    ) :
                    (
                     <img
                      src={CoupleIcon}
                      alt="Parking Garage Icon - RoomDaddy"
                    />
                    )
            }
            
                  </h2>

                  
                {/* </Link> */}

                {/* <p>
                  <img src={StarIcon} alt="Parking Rating - RoomDaddy" />

                  <span style={{ color: "#1c1c1c" }}>
                    <b>4.6</b> <u>All Reviews</u>
                  </span>
                </p> */}
                    
                {/* <h6 className="lead-loc">{prk.location},{prk.city}</h6> */}
                <p>
                    <img
                      src={PinIcon}
                      alt="Parking Garage Icon - RoomDaddy"
                    />
                    <span>{prk.location},{prk.city}</span>
                  </p>

                  <p>
                    <img
                      src={BedIcon}
                      alt="Parking Garage Icon - RoomDaddy"
                    />
                    <span>Finding a {prk.looking_for} for {prk.length_of_stay} months</span>
                    
                  </p>

                <div className="lead-list__features">
                  
                  

                  {/* <p>
                    <img src={StairIcon} alt="Parking Stair Icon - RoomDaddy" />
                    <span>{prk.gender}</span>
                  </p> */}
                </div>

                <p>
                    <img src={CalendarIcon} alt="Calendar Icon - RoomDaddy" />
                    <span>{prk.move_in_date}</span>
                  </p>
                  {(userInfo.id === 191 || userInfo.id === 8760) ?
                  (
                    prk.comments ? 
                        (
                          <p>
                          <img src={ListIcon} alt="Calendar Icon - RoomDaddy" />
                          <span>{prk.comments}</span>
                        </p>
                        ) : (null)
                  ) : (null)
                  
                  }
                {/* <p>Available {prk.move_in_date ? `from ${prk.move_in_date}` : "Now"}</p> */}
              </div>

              {/* <div className="prk-list__img col-5 pl-0 pr-0 text-right">
                <img src={NoParkingImage} alt="No Parking Img - RoomDaddy" />
              </div> */}
            </div>

            <div className="row ml-0 mr-0 pt-3 align-items-center">
              {/* <div className="col-6 pl-0 pr-0">
                <a href={`tel:${prk.number}`}>
                  <Button className="prk-button bookN-s">Contact</Button>
                </a>
              </div> */}

{(userInfo.id === 191 || userInfo.id === 8760) ?
                  (
            <div className="lead-price col-6 pl-0 pr-0 text-left">
                <p className="mb-0"> <span>{prk.dial_code}{prk.number}</span></p>
              </div>
                  ) : (null)
}
              <div className="lead-price col-6 pl-0 pr-0 text-right">
                <p className="mb-0"> <span>Budget: </span>AED {separateNumbers(prk.budget)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <MobileNav />
    </div>
  );
};

export default HotLeadPage;
