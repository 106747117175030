import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Steps, Button, message } from "antd";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { AppContext } from "../../AppContext";
import Header from "../../components/pageHeader/index";
import StepOne from "../../_newPages/EntirePostnAddPages/entireStepOne"; 
import StepTwo from "../../_newPages/EntirePostnAddPages/entireStepTwo";
import StepThree from "../../_newPages/EntirePostnAddPages/entireStepThree";
import StepFour from "../../_newPages/EntirePostnAddPages/entireStepFour"; 
import StepFive from "./stepFive";
import Back from "../../icons/back.svg";
import Cross from "../../icons/cross.svg";
import GoogleMap1 from "../../_newComponents/GoogleMap";
import axios from "axios";
import app from '../../utils/appConfig'
// import "./index.scss";

const { Step } = Steps;

export default function RentPlace() {
  const history = useHistory();
  const { userInfo, setUserInfo ,editHomeDetail} = useContext(AppContext);
  // const { userInfo } = useContext(AppContext);
  const [current, setCurrent] = React.useState(0);
  const [placeAdInfo, setPlaceAdInfo] = useState({
    lat: 25.2048493,
    lng: 55.2707828, 
    city: "",
    propertyType: "",
  });
  const [stepOneInfo, setStepOneInfo] = useState({
    hotel_type: "", hotel_city: "", hotel_country: 229, hotel_address_line: "",
    hotel_street_address: "", hotel_pin_location: '',hotel_currency:"AED",
    hotel_latitude: userInfo.lat, hotel_longitude: userInfo.lng,
});
const [secondStepInfo, setSecondStepInfo] = useState({
  hotel_facilities: [],amenities:[], house_rules: [], number_guests: 1,
  ad_type: "entire-accomodation", bathroom_quantity: 1, property_id: userInfo.propertyId,hotel_allow_pets:"no",
  smoking:"non-smoking",hotel_parking:0
});
const [thirdStepInfo, setThirdStepInfo] = useState({
  hotel_checkin_from: new Date(), hotel_checkout_to: null,hotel_cancel_free: 1,
  bills_included: "no",hotel_amenities_outdoor:[],
  minimum_stay: 0, hotel_accommodate_children:"yes",hotel_live_booking: "no",
  hotel_allow_pets: "no",
});
const [fourStepInfo, setFourStepInfo] = useState({
  hotel_name: "", hotel_desc: "", room_pics: [],
  name:userInfo.name,email:userInfo.email,phone:userInfo.phone,user_id:userInfo.id
});
  const steps = [
    <StepOne nextStep={setCurrent} to="entire_step_one" oneInfo={setStepOneInfo} />,
    <StepTwo nextStep={setCurrent} to="entire_step_two" stepTwoInfo={setSecondStepInfo}/>,
    <StepThree nextStep={setCurrent} to="entire_step_three" stepThreeInfo={setThirdStepInfo} />,
    <StepFour nextStep={setCurrent} to="entire_step_four" step1={stepOneInfo} step2={secondStepInfo} step3={thirdStepInfo} user={userInfo} stepFourInfo={setFourStepInfo}/>,
    <StepFive  nextStep={setCurrent} user={userInfo} />,
  ];

  useEffect(() => {
    
  }, [placeAdInfo,current]);

  function updateCords(e) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      },
      (results, status) => {
        let result = results[0];
        if (result) {
          let adrs = result.formatted_address;
          let location = result.geometry.location;
          setUserInfo({
            ...userInfo,
            address: adrs,
            lat: location.lat(),
            lng: location.lng(),
          });
        }
      }
    );
  }

  return (
    <div className="place-an-ad">
      <div className="header screen-header pt-3 pb-3" style={{ zIndex: "999" }}>
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const confirmation = window.confirm(
                  "Your changes might not be saved, are you sure you want to go back?"
                );

                if (confirmation) {
                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost) history.push("/");
                  else history.goBack();
                }
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Entire Accomodation</div>
          </div>

          <div className="col-auto">
            {current > 0 && (
              <img
                className=""
                src={Cross}
                alt="cencel_Arrow"
                onClick={() => setCurrent(current - 1)}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "55px" }}>
      {current === 0 ? (
          <GoogleMap1 lat={userInfo.lat} lng={userInfo.lng} />
        ) : null}
      <div className="steps">
        <Steps current={current} direction="horizontal">
          {steps.map((item, idx) => (
            <Step key={"step" + idx} />
          ))}
        </Steps>
        <div className="steps-content">
          <Card style={{ display: `${current === 0 ? "block" : "none"}` }}>
            {steps[0]}
          </Card>
          <Card style={{ display: `${current === 1 ? "block" : "none"}` }}>
            {steps[1]}
          </Card>
          <Card style={{ display: `${current === 2 ? "block" : "none"}` }}>
            {steps[2]}
          </Card>
          <Card style={{ display: `${current === 3 ? "block" : "none"}` }}>
            {steps[3]}
          </Card>
          <Card style={{ display: `${current === 4 ? "block" : "none"}` }}>
            {steps[4]}
          </Card>
        </div>
      </div>
      <div className="steps-action">
        
        {current > 0 && (
          <Button
            style={{ marginBottom: 16 }}
            onClick={() => setCurrent(current - 1)}
          >
            Previous
          </Button>
        )}
      </div>
    </div>
  </div>
  );
}
