import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Tabs } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import { getUser } from "../../utils/functions";
import HotelBooking from "../_newComponents/HotelBooking";
import CoLivingBooking from "../_newComponents/CoLivingBooking";
import PageHeader from "../_newComponents/pageHeader";
import MobileNav from "../_newComponents/MobileNav";
import Calender from "../icons/calendar.svg";
import "./style/mybooking.css";

const { TabPane } = Tabs;

export default function MyBooking() {
  let user = getUser();
  let userId = user.id;

  const { userInfo } = useContext(AppContext);

  const [allBooking, setAllBooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookingData, setBookingData] = useState({});
  const [homeBookingData, setHomeBookingData] = useState({});
  const [otherBookingData, setOtherBookingData] = useState({});

  // const [data, setData] = useState(otherBookingData);
  // const [Booking, setBooking] = useState(CoLivingBooking);

  const callback = (key) => {
    // console.log("key -->", key);
    // if (key == 1) {
    //   setBooking(CoLivingBooking);
    //   setData(otherBookingData);
    // } else if (key == 2) {
    //   setBooking(HotelBooking);
    //   setData(bookingData);
    // }
  };

  useEffect(() => {
    if (userInfo.id)
      axios
        .get(`${app.hotelBaseUrl}/api/get_booking_data/${userInfo.id}`)
        .then((res) => {
          let booking = { current: [], past: [], future: [] };

          const data =
            Object.getPrototypeOf(res.data) === Object.prototype
              ? Object.values(res.data)
              : res.data;

          data.forEach((e) => {
            switch (e.booking) {
              case "future":
                booking.future.push(e);
                break;
              case "past":
                booking.past.push(e);
                break;
              case "current":
                booking.current.push(e);
                break;
              default:
                break;
            }
          });

          setAllBooking(data);
          setBookingData(booking);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
  }, [userInfo]);

  function onCancelBooking(id) {
    axios
      .post(`${app.hotelBaseUrl}/api/cancel_booking`, { data: id })
      .then((res) => {
        console.log("cancel", res);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/my-bookings/${userId}`)
      .then((res) => {
        let otherBooking = { current: [], past: [], future: [] };

        console.log(res.data);

        res.data.data.map((e) => {
          if (e.fld_isActive_booking != 0 && e.fld_is_current_tanent == 1) {
            otherBooking.current.push(e);
          } else if (e.fld_isActive_booking == 0) {
            otherBooking.past.push(e);
          } else if (
            e.fld_isActive_booking == 1 &&
            e.fld_is_current_tanent == 0
          ) {
            otherBooking.future.push(e);
          }
        });

        setOtherBookingData(otherBooking);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <PageHeader isHome homeLink="/" />

      <div
        className="layoutBody h-100 booking-page"
        style={{ padding: "75px 0" }}
      >
        <div className="container-fluid">
          <div className="row px-3">
            <div class="montserratN900-22">
              <p>My Bookings</p>
            </div>
          </div>
          <div className="row">
            <Tabs
              className="futuraN-16 text-gray w-100 h-100"
              style={{ paddingBottom: "80px" }}
              defaultActiveKey="1"
              onChange={callback}
              centered
            >
              <TabPane className="custom-subtab" tab="CoLivings" key="1">
                <Tabs
                  className="futuraN-16 text-gray w-100 h-100"
                  defaultActiveKey="1"
                  centered
                >
                  <TabPane tab="Current" key="1">
                    {otherBookingData?.current?.map((item, index) => (
                      <CoLivingBooking item={item} key={index} />
                    ))}

                    {!loading && !otherBookingData?.current?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no active bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/co-living">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Past" key="2">
                    {otherBookingData?.past?.map((item, index) => (
                      <CoLivingBooking item={item} key={index} />
                    ))}

                    {!loading && !otherBookingData?.past?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no past bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/co-living">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Upcoming" key="3">
                    {otherBookingData?.future?.map((item, index) => (
                      <CoLivingBooking item={item} key={index} />
                    ))}

                    {!loading && !otherBookingData?.future?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no upcoming bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/co-living">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                </Tabs>
              </TabPane>

              <TabPane className="custom-subtab" tab="Hotels" key="2">
                <Tabs
                  className="futuraN-16 text-gray w-100 h-100"
                  defaultActiveKey="1"
                  centered
                >
                  <TabPane tab="Current" key="1">
                    {bookingData?.current?.map((item, index) => (
                      <HotelBooking item={item} key={index} />
                    ))}

                    {!loading && !bookingData?.current?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no active bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/hotel-rooms">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Past" key="2">
                    {bookingData?.past?.map((item, index) => (
                      <HotelBooking item={item} key={index} />
                    ))}

                    {!loading && !bookingData?.past?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no past bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/hotel-rooms">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Upcoming" key="3">
                    {bookingData?.future?.map((item, index) => (
                      <HotelBooking item={item} key={index} />
                    ))}

                    {!loading && !bookingData?.future?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no upcoming bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/hotel-rooms">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                </Tabs>
              </TabPane>
              {/* entire home */}
              <TabPane className="custom-subtab" tab="Entire Home" key="3">
                <Tabs
                  className="futuraN-16 text-gray w-100 h-100"
                  defaultActiveKey="1"
                  centered
                >
                  <TabPane tab="Current" key="1">
                    {bookingData?.current?.map((item, index) => (
                      <HotelBooking item={item} key={index} />
                    ))}

                    {!loading && !bookingData?.current?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no active bookings</h3>

                        <p>
                          Plan a new trip and explore places to stay close to
                          all the places you love.{" "}
                          <Link to="/hotel-rooms">Browse Places</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                </Tabs>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <MobileNav />
    </>
  );
}

/*
  <TabPane tab="Others" key="3">
    {MyBookingData.map((item, index) => (
      <div key={index} className="pt-2 pb-2 mx-3">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-5">
                    <img
                      className="d-block w-100 bookingCard-img"
                      style={{ objectFit: "cover" }}
                      src={item.room_image}
                      alt={item.fld_room_name}
                    />
                  </div>
                  <div className="col-7 p-0 pt-2 pb-2 pl-2">
                    <div className="row">
                      <div
                        class="futuraBold14"
                        style={{ color: "#1C2C4A" }}
                      >
                        <p className="mb-0">{item.fld_room_name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="futuraBkBtN-12">
                        <p className="mb-1">{item.fld_building} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="futuraBkBtN-12">
                        <ul
                          className="p-0 m-0"
                          style={{ columns: 2 }}
                        >
                          <li>
                            <span>Monthly rent </span>
                          </li>
                          <li>
                            <span>&nbsp;Deposit</span>
                          </li>
                        </ul>
                      </div>
                      <div className="montserratNB-12">
                        <ul
                          className="p-0 mb-2"
                          style={{
                            columns: 2,
                            fontFamily: "futuraMdBt",
                            color: "#1C2C4A",
                          }}
                        >
                          <li>
                            <span>{item.fld_rent}</span>
                          </li>
                          <li>
                            <span>
                              &nbsp;&nbsp;&nbsp;
                              {item.fld_rent_paid}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 pl-0 pr-0">
                        <p className="mb-0 futuraBkBtN-12">
                          <img
                            className="small_icon"
                            src={Calender}
                            alt="calender_icon"
                          />
                          <span>
                            &nbsp; {item.fld_move_in_date}
                          </span>
                        </p>
                        <p className="mb-0 futuraBkBtN-12">
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {item.fld_move_out_date}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <div>
                          <Button className="bookN-s futuraN700-14">
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </TabPane>
*/
