import React, { useContext, useState, useEffect } from "react";
import { DatePicker, Space, message,Select} from "antd";
import moment from "moment";
import axios from "axios";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Button } from "@material-ui/core";
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
export default function StepThree({ nextStep, to ,stepThreeInfo }) {
    const { userInfo, editHomeDetail,editHomeStepsDetail, setEditHomeStepsDetail } = useContext(AppContext);
    const [thirdStepInfo, setThirdStepInfo] = useState({
        hotel_checkin_from: userInfo.checkIn, hotel_checkout_to: null,hotel_cancel_free: 1,
        bills_included: "no",hotel_amenities_outdoor:[],
        minimum_stay: 0, hotel_accommodate_children:"yes",hotel_live_booking: "no",
        hotel_allow_pets: "no",hotel_cancel_paid:"20%",
    });

    function sentFormData() {
    
        let data = new FormData();    
        for(let i in thirdStepInfo ) {
            data.append(i, thirdStepInfo[i]);
        }
        data.append("fld_tanent", userInfo.id);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        setEditHomeStepsDetail({...editHomeStepsDetail,thirdStepInfo});
        nextStep(3);
        
        // axios.post(`${app.baseUrl}/api/${to ? to : "step-three"}`, data, config).then(res=> {
        //     message.success("Step Three completed successfully");
        //     nextStep(3);
        // }).catch( err=> console.log(err));
    }
    // useEffect(()=> {
    //     console.log(thirdStepInfo);
    // }, [thirdStepInfo]);

    function updateThirdForm(property, value) {
        if([property] == "hotel_amenities_outdoor"  ) {
            if(thirdStepInfo[property].includes(value)) {
                const index = thirdStepInfo[property].indexOf(value);
                thirdStepInfo[property].splice(index, 1);
           
            } else {
                thirdStepInfo[property].push(value);
                
            }
        }
        else{
            setThirdStepInfo({...thirdStepInfo, [property]: value});
        }
    }

    function disabledDate(current) {
        return current && current < moment(thirdStepInfo.hotel_checkin_from, dateFormat);
    }
    function getSetDate(dateObj, dateStr) {
        if(dateStr.length === 2 ){
            setThirdStepInfo({...thirdStepInfo, "hotel_checkin_from": dateStr[0], "hotel_checkout_to": dateStr[1]});
        }
    }

    useEffect(() => {
        if(editHomeDetail.id) {
            setThirdStepInfo({
                hotel_amenities_outdoor: editHomeDetail.services.hotel_amenities_outdoor ,
                hotel_cancel_paid: editHomeDetail.policies.pay_full ? editHomeDetail.policies.pay_full : null,
                bills_included: editHomeDetail.services.bills === "yes" ? "yes":"no",
                hotel_checkin_from: editHomeDetail.policies.check_in_from ,
                hotel_checkout_to: editHomeDetail.avalability_end ? editHomeDetail.avalability_end : "",
                hotel_cancel_free: editHomeDetail.policies.advance_cancel ,
                hotel_live_booking: editHomeDetail.policies.live_booking === "yes" ? "yes":"no"
            });
        }
    }, [editHomeDetail]);
    return (
    <div className="py-3 px-2">
      <div className="container pb-4 px-3">
        <div className="row">
          <div className="col pb-4">
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Choose a bed type
              </label>

              <Checkbox
                name="hotel_amenities_outdoor"
                checked={thirdStepInfo.hotel_amenities_outdoor?.includes("seaview") ? "checked" : false} onChange={()=> updateThirdForm("hotel_amenities_outdoor", "seaview")}
              >
                Sea view
              </Checkbox>

              <Checkbox
                checked={thirdStepInfo.hotel_amenities_outdoor?.includes("lake_view") ? "checked" : false} onChange={()=> updateThirdForm("hotel_amenities_outdoor", "lake_view")}
                name="hotel_amenities_outdoor"
               
              >
                Lake view
              </Checkbox>

              <Checkbox
                className="ml-0"
                name="hotel_amenities_outdoor"
                checked={thirdStepInfo.hotel_amenities_outdoor?.includes("no_view") ? "checked" : false} onChange={()=> updateThirdForm("hotel_amenities_outdoor", "no_view")}
              >
               Neighbourhood view
              </Checkbox>
            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Set the room’s availability
              </label>

              <div className="calender">
                <Space direction="horizontal">
                  <div className="d-flex flex-column">
                    <p className="mb-1">From</p>

                    <DatePicker
                      size="large"
                      placeholder={"Start date"}
                      style={{ width: "100%" }}
                      defaultValue={moment(thirdStepInfo.hotel_checkin_from, dateFormat)}
                      disabledDate={current => current && current < moment(thirdStepInfo.hotel_checkin_from, dateFormat)}
                      onChange={(e, str) =>
                        setThirdStepInfo({
                          ...thirdStepInfo, 
                          from_date: str,
                          to_date: moment(str, dateFormat)
                            .add(30, "days")
                            .format(dateFormat),
                        })
                      }
                    />
                  </div>

                  <div className="d-flex flex-column">
                  <p className="mb-1">To </p>
                    <DatePicker 
                        size="large"
                        placeholder={"End date"}
                        style={{ width: "100%" }}
                        defaultValue={thirdStepInfo.hotel_checkout_to && moment(thirdStepInfo.hotel_checkout_to, dateFormat)}
                        disabledDate={current => current && current < moment(thirdStepInfo.hotel_checkin_from, dateFormat)}
                        onChange={(e,str)=> setThirdStepInfo({...thirdStepInfo, hotel_checkout_to: str})}
                    />
                  </div>
                </Space>
              </div>
            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Bills included
              </label>

              <Checkbox
                name="bills_included"
                checked={thirdStepInfo.bills_included === "yes" ? "checked" : false} onChange={()=> updateThirdForm("bills_included","yes")}
              >
                Yes
              </Checkbox>

              <Checkbox
                name="bills_included"
                checked={thirdStepInfo.bills_included === "no" ? "checked" : false} onChange={()=> updateThirdForm("bills_included", "no")}
              >
                No
              </Checkbox>

            </div>
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              Do you want to Enable Live Booking?
              </label>

              <Checkbox
                name="hotel_live_booking"
                checked={thirdStepInfo.hotel_live_booking === "yes" ? "checked" : false} onChange={()=> updateThirdForm("hotel_live_booking", "yes")}
              >
                Yes
              </Checkbox>

              <Checkbox
                name="hotel_live_booking"
                checked={thirdStepInfo.hotel_live_booking === "no" ? "checked" : false} onChange={()=> updateThirdForm("hotel_live_booking", "no")}
              >
                No
              </Checkbox>

            </div>

            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              How many days in advance can guests cancel free of charge?
              </label>
              <input type="number"
                    onChange={ e=> updateThirdForm("hotel_cancel_free", e.target.value)}
                    className="form-control inputBorder"
                    value={thirdStepInfo.hotel_cancel_free}
                />
            </div>
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              Or guest will pay: *
              </label>
              <div className="form-control inputBorder">
                    <Select
                        // showSearch
                        style={{ width: "100%" }}
                        value={thirdStepInfo.hotel_cancel_paid}
                        onChange={ value => updateThirdForm("hotel_cancel_paid", value)}
                    >
                        <Option value="0">10%</Option>
                        <Option value="1">20%</Option>
                        <Option value="2">50%</Option>
                        <Option value="3">100%</Option>
                    </Select>
                </div>
            </div>
            
          </div>
        </div>
      </div>
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Continue
      </Button>
      {/* </form> */}
    </div>
    );
}