import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { WingBlank } from "antd-mobile";
import {
  AmenitiesFilters,
  HouseRulesFilters,
  ListingFilters,
  SortFilters,
} from "./filtersData";
import { getLsValue } from "../../../utils/functions";
import data from "./colivingFiltersBtns";
import Modal from "../Modal";
import ConfirmPopup from "../ConfirmPopup";
import Cross from "../../icons/cross.svg";
import "./index.scss";

const FilterButtons = ({ onReset, onSearch, filters, setFilters, change }) => {
  const [popup, setPopup] = useState(false);
  const [min, setMin] = useState(filters?.["min_search_price"] || "");
  const [max, setMax] = useState(filters?.["max_search_price"] || "");
  const [budgetPopUp, setBudgetPopUp] = useState(false);
  const [amenitiesPopUp, setAmenitiesPopUp] = useState(false);
  const [housePopUp, setHousePopUp] = useState(false);
  const [listingPopUp, setListingPopUp] = useState(false);
  const [sortPopUp, setSortPopUp] = useState(false);

  useEffect(() => {
    if (popup) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [popup]);

  const onOpen = (key) => (e) => {
    e.preventDefault();

    if (key === "Budget") setBudgetPopUp(true);
    if (key === "Amenities") setAmenitiesPopUp(true);
    if (key === "House") setHousePopUp(true);
    if (key === "Listing") setListingPopUp(true);
    if (key === "Sort") setSortPopUp(true);
  };

  const onClose = (key) => (e) => {
    if (key === "Budget") setBudgetPopUp(false);
    if (key === "Amenities") setAmenitiesPopUp(false);
    if (key === "House") setHousePopUp(false);
    if (key === "Listing") setListingPopUp(false);
    if (key === "Sort") setSortPopUp(false);
  };

  return (
    <>
      {popup && (
        <ConfirmPopup
          title="Reset"
          onConfirm={() => {
            onReset();
            setMin("");
            setMax("");
            setPopup(false);
          }}
          onCancel={() => setPopup(false)}
        >
          <p className="px-2" style={{ fontWeight: "400" }}>
            Are you sure you want to reset all filters?
          </p>
        </ConfirmPopup>
      )}

      <div className="filter-buttons">
        {data.map(({ icon, title }, i) => (
          <>
            {title === "Amenities" ? (
              <>
                <button
                  key={i}
                  type="button"
                  className={
                    filters.attached_bath ||
                    filters.dishwasher_amenity ||
                    filters.garden_amenity ||
                    filters.hr_common_balcony ||
                    filters.hr_elevator ||
                    filters.hr_fridge ||
                    filters.hr_parking ||
                    filters.hr_pool ||
                    filters.hr_tv ||
                    filters.hr_washing ||
                    filters.hr_wifi ||
                    filters.hr_window ||
                    filters.natural_light ||
                    filters.terrace_amenity ||
                    filters.wheelchair_amenity
                      ? "filter-selected"
                      : ""
                  }
                  onClick={onOpen("Amenities")}
                >
                  {/* <span className="filter-count">2</span> */}

                  {icon && <img src={icon} alt={title} />}
                  <span className="filter-title">{title}</span>

                  {/* <span className="filter-cross">
                    <img src={Cross} alt="Cross - RoomDaddy" />
                  </span> */}
                </button>
              </>
            ) : (
              [
                title === "Budget" ? (
                  <>
                    <button
                      key={i}
                      type="button"
                      className={
                        filters.max_search_price || filters.min_search_price
                          ? "filter-selected"
                          : ""
                      }
                      onClick={onOpen("Budget")}
                    >
                      {icon && <img src={icon} alt={title} />}
                      <span>{title}</span>
                    </button>
                  </>
                ) : (
                  [
                    title === "House rules" ? (
                      <>
                        <button
                          key={i}
                          type="button"
                          className={
                            filters.gender ||
                            filters.hr_children ||
                            filters.hr_couples ||
                            filters.hr_pets ||
                            filters.hr_smoking
                              ? "filter-selected"
                              : ""
                          }
                          onClick={onOpen("House")}
                        >
                          {icon && <img src={icon} alt={title} />}
                          <span>{title}</span>
                        </button>
                      </>
                    ) : (
                      [
                        title === "Listing type" ? (
                          <>
                            <button
                              key={i}
                              type="button"
                              className={
                                filters.bedspace ||
                                filters.room ||
                                filters.verified
                                  ? "filter-selected"
                                  : ""
                              }
                              onClick={onOpen("Listing")}
                            >
                              {icon && <img src={icon} alt={title} />}
                              <span>{title}</span>
                            </button>
                          </>
                        ) : (
                          [
                            title === "Sort by" ? (
                              <>
                                <button
                                  key={i}
                                  type="button"
                                  className={
                                    filters.sort_by ? "filter-selected" : ""
                                  }
                                  onClick={onOpen("Sort")}
                                >
                                  {icon && <img src={icon} alt={title} />}
                                  <span>{title}</span>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  key={i}
                                  type="button"
                                  className="danger"
                                  onClick={() => setPopup(true)}
                                >
                                  {icon && <img src={icon} alt={title} />}
                                  <span>{title}</span>
                                </button>
                              </>
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )}
          </>
        ))}
      </div>
      <WingBlank>
        <Modal
          popup
          visible={budgetPopUp}
          onClose={onClose("Budget")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Budget</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Budget")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mt-2 mb-2">
                  <label className="w-100 text-left text-xyz montserratNB-16 mt-2 mb-2">
                    Min price{" "}
                  </label>
                  <input
                    type="text"
                    name="min"
                    value={min}
                    onChange={(e) => {
                      setMin(e.target.value);
                      change(e, "number", "min_search_price", min);
                    }}
                    className="form-control inputBorder"
                    placeholder="10"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mt-2 mb-2">
                  <label className="w-100 text-left text-xyz montserratNB-16 mt-2 mb-2">
                    Max price{" "}
                  </label>
                  <input
                    type="text"
                    name="max"
                    value={max}
                    onChange={(e) => {
                      setMax(e.target.value);
                      change(e, "number", "max_search_price", max);
                    }}
                    className="form-control inputBorder"
                    placeholder="100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Reset futuraN700-14"
                onClick={() => {
                  const resetFilters = { ...filters };

                  delete resetFilters.min_search_price;
                  delete resetFilters.max_search_price;

                  setMin("");
                  setMax("");
                  setFilters(resetFilters);
                  setBudgetPopUp(false);
                  // onReset();
                }}
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Apply futuraN700-14"
                onClick={() => {
                  setBudgetPopUp(false);
                  onSearch();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={amenitiesPopUp}
          onClose={onClose("Amenities")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Amenities</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Amenities")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio">
                {AmenitiesFilters.map((items, index) => {
                  return (
                    <>
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          checked={filters[childKey.key]}
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <img
                                            class="radio_active"
                                            src={childKey.icon}
                                            alt=""
                                          />
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Reset futuraN700-14"
                onClick={() => {
                  const resetFilters = { ...filters };

                  AmenitiesFilters.forEach((item) => {
                    item.children.forEach((hasChild) => {
                      hasChild[hasChild.key].forEach((childItem) => {
                        console.log("b -->", resetFilters);
                        delete resetFilters[childItem.key];
                        console.log("a -->", resetFilters);
                      });
                    });
                  });

                  setFilters(resetFilters);
                  setAmenitiesPopUp(false);

                  // onReset();
                }}
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Apply futuraN700-14"
                onClick={() => {
                  setAmenitiesPopUp(false);
                  onSearch();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={housePopUp}
          onClose={onClose("House")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>House rules</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("House")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "200px" }}>
                {HouseRulesFilters.map((items, index) => {
                  return (
                    <>
                      {/* <label htmlFor="">{items.display}</label> */}
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type={
                                            hasChild.type === "checkbox"
                                              ? "checkbox"
                                              : "radio"
                                          }
                                          defaultChecked={
                                            hasChild.type === "checkbox"
                                              ? filters[childKey.key]
                                              : filters[childKey.key] ===
                                                childKey.value
                                          }
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <img
                                            class="radio_active"
                                            src={childKey.icon}
                                            alt=""
                                          />
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Reset futuraN700-14"
                onClick={() => {
                  const resetFilters = { ...filters };

                  HouseRulesFilters.forEach((items) => {
                    items.children.forEach((hasChild) => {
                      hasChild[hasChild.key].forEach((childKey) => {
                        delete resetFilters[childKey.key];
                      });
                    });
                  });

                  setFilters(resetFilters);
                  setHousePopUp(false);

                  // onReset();
                }}
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Apply futuraN700-14"
                onClick={() => {
                  setHousePopUp(false);
                  onSearch();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={listingPopUp}
          onClose={onClose("Listing")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Listing types</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Listing")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "100px" }}>
                {ListingFilters.map((items, index) => {
                  return (
                    <>
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          checked={filters[childKey.key]}
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <img
                                            class="radio_active"
                                            src={childKey.icon}
                                            alt=""
                                          />
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Reset futuraN700-14"
                onClick={() => {
                  const resetFilters = { ...filters };

                  ListingFilters.forEach((items) => {
                    items.children.forEach((hasChild) => {
                      hasChild[hasChild.key].forEach((childKey) => {
                        delete resetFilters[childKey.key];
                      });
                    });
                  });

                  setFilters(resetFilters);
                  setListingPopUp(false);

                  // onReset();
                }}
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Apply futuraN700-14"
                onClick={() => {
                  setListingPopUp(false);
                  onSearch();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={sortPopUp}
          onClose={onClose("Sort")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Sort by</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Sort")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "140px" }}>
                {SortFilters.map((items, index) => {
                  return (
                    <>
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          checked={filters[childKey.key]}
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <img
                                            class="radio_active"
                                            src={childKey.icon}
                                            alt=""
                                          />
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Reset futuraN700-14"
                onClick={() => {
                  const resetFilters = { ...filters };

                  SortFilters.forEach((items) => {
                    items.children.forEach((hasChild) => {
                      hasChild[hasChild.key].forEach((childKey) => {
                        delete resetFilters[childKey.key];
                      });
                    });
                  });

                  setFilters(resetFilters);
                  setSortPopUp(false);

                  // onReset();
                }}
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                variant="contained"
                className="w-100 filter-Apply futuraN700-14"
                onClick={() => {
                  setSortPopUp(false);
                  onSearch();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>
    </>
  );
};

export default FilterButtons;
