const data = [
    {
        title: "Terms & Conditions",
        content:   [
            {
                title: `Please read the below mentioned Terms and Conditions (“Terms” or “Terms & Conditions”) carefully before using Roomdaddy platform www.roomdaddy.com (referred to as the “Site”), owned and operated by Roomdaddy (“us”, “we”, or “our”). These terms apply to Site usage and any or all associated services we may offer directly through the site or otherwise (services and the Site are collectively referred to as the “Service”). Your use and access to the Service is conditioned on your acceptance and compliance with these Terms.
                These Terms are applicable to all users, visitors, and others who access or use the Service. By accessing or using the Service, you agree to be bound by our Terms & Conditions. If you disagree with any part of our Terms, your access to the Service will not commence. Upon agreeing with our Terms & Conditions, we hereby grants you a limited, non-exclusive, non-transferable, and revocable license to use the Service for your own personal non-commercial use.`
            }
        ]
    },
    {
        title: "OVERVIEW, BOOKING & CANCELLATION",
        content: [
            {
                title: `A. The purpose of the Service is to connect you with our hotels and renting vendors ( called”Vendor” or “Vendors”), and to facilitate the long-term renting of living space and related services to be provided to you by the Vendors. We make no representations of the services provided to you by our Vendors. The interaction between you and our Vendors is strictly between you and our Vendors, and we are under no obligation to be involved.`,
            },
            {
                title: `B. Booking of rooms and space through the Site may occur through various channels as explained on the Site or in any email, SMS or MMS (collectively called “Message”) generated by us. You may also store your payment information (e.g., credit card) on file with us or our third-party suppliers to facilitate convenient booking.`,
            },
            {
                title: `C. When booking, your credit or debit card may be charged immediately with the specified amount once you book a room or rental space with our Vendors through the Service (e.g., by booking through the website, or replying to a Message for booking purpose). We may enable booking through other means and notify you of those means through a Message. You will receive a Message confirming your booking and containing the receipt of your charges.`,
            },
            {
                title: `D. All rental spaces are subject to availability. Bookings are honoured on first-come-first-served basis. Your booking (including any 'pending' booking) is not confirmed until you receive a confirmation Message from us.`,
            },
            {
                title: `E. When you sign up for the Service, you may be asked to provide us with payment information. Your ability to use the Service may be limited until this step has been completed. By providing us with this information, you represent and warrant that you have the legal right to use all payment method(s) provided.`,
            },
            {
                title: `F. We provide photographs and descriptions of our Vendors’ offerings and use reasonable efforts to ensure that those photographs and descriptions are accurate. You acknowledge that occasionally rental space will not look exactly as photographed and by using the Service you agree to accept such rental spaces where there are reasonable variations between the photographs/descriptions and what the Vendors offer.`,
            },
            {
                title: `G. Cancellation for users:
                In case you want to cancel your booking, you can send a cancellation request to Roomdaddy customer service via email. In order to cancel any booking without any kind of penalty, cancellation must take place 15 days or longer period, prior to the start date. If you cancel your booking 15 or more days prior to the start date, Roomdaddy will reimburse your fees and first transfer. If you cancel your booking 14 days or later, before the start date, Roomdaddy will retain your fee and an amount equal to 50% of the first transfer. If you cancel your booking 6 days or later, before the start date, Roomdaddy will retain your fee and an amount equal to 100% of your first transfer.
                
                Roomdaddy may waive the cancellation policy for you, in extenuating circumstances such as:
                
                1- Serious injury, illness, or death
                
                2- Serious injury, illness or death of a first or second degree relative, a minor or handicapped person to which you are the legal guardian or primary substitution
                
                3- Death of a third degree relative
                
                4- Official declaration of disaster zone in the city where the accommodation is located
                
                5- Unexpected visa denial for unjustified reasons and, not cause either directly or indirectly by your acts, or omissions, which restrict you from relocating to the city where the accommodation is located
                
                Please note that the waiver may only be applied by us if you contact our customer support as soon as possible, and if we provide you with a 7 days period to send the appropriate supporting documents to our customer support which, at the sole discretion of Roomdaddy, are considered sufficient.`,
            },
            {
                title: `H. Cancellation for Vendors:
                In case a Vendor wants to cancel any booking, he/she can send a cancellation request to Roomdaddy customer service via email. In order to cancel any booking without any kind of penalty, cancellation must take place 15 days or longer period, prior to the start date. If the Vendor cancels the booking 15 or more days prior to the start date, Roomdaddy will reimburse his/her fees. If vendor cancels his/her booking 14 days or later, before the start date, Roomdaddy will retain his/her fees and charge him/her as Roomdaddy Penalty an amount equal to 25% of the first transfer, which may be debited from the credit card provided by the Vendor. If Vendor cancels the booking 6 days or later, before the start date, Roomdaddy will retain Vendor’s fee and will also charge him as a Roomdaddy Penalty an amount equal to 50% of the First Transfer, which may be debited from the credit card provided by the Vendor. If the Vendor cancels booking 48 hours or later, which may be debited from the credit card provided by the Vendor, whereas Roomdaddy will reimburse the full amount corresponding to the first transfer. The vendor acknowledges and expressly agrees to pay the above-mentioned amounts and accepts that Roomdaddy will send a payment request which shall be payable by the Vendor within 30 days.
                
                Roomdaddy may waive the cancellation policy for Vendor, in extenuating circumstances such as:
                1- Serious injury, illness or death of the Vendor
                
                2- Serious injury, illness or death of a first or second degree relative, a minor or handicapped person to which the Vendor is the legal guardian or primary substitution
                
                3- Death of a third degree relative
                
                4- Serious damage to the accommodation
                
                5- Official declaration of disaster zone in the city where the accommodation is located
                
                Please note that the waiver may only be applied by us if the Vendor contacts our customer support as soon as possible, and if we provide the Vendor with a 7 days period to send the appropriate supporting documents to our customer support which, at the sole discretion of Roomdaddy, are considered sufficient.`,
            },
        ]
    },
    {
        title: "USE POLICY",
        content: [
            {
                title: `A. You agree not to use the Service in any manner inconsistent with applicable law, or in any manner that causes us reputational, financial, security, or any other form of damage. This includes, but is not limited to, hacking or other cyber threats, violating any third-party rights, sending unauthorized spam, junk mail, advertisements or other promotional materials, compiling or harvesting information about our users, interfering with our network infrastructure, introducing scripts or other programs to produce multiple accounts, generate multiple queries, or otherwise use the Service for purposes other than the intended purpose.`,
            },
            {
                title: `B. You may not copy, duplicate, reproduce, post, transmit, or distribute the content provided by the Service, in any form and by any means without prior specific authorization by us.`,
            }
        ]
    },
    {
        title: "EMAIL & MESSAGING SERVICES",
        content: [
            {
                title: `A. When you sign up for our Service, you may be opted-in to our email and/or SMS booking and notifications. Upon sign up, we may send you a confirmation message informing you of the primary medium we will be using to communicate with you (either email or SMS).`,
            },
            {
                title: `B. You can always opt-out of email, SMS and/or MMS notifications by changing your preferences online in your account profile.`,
            },
            {
                title: `C. We may also send you updates or promotional materials from time-to-time, which you have the right to opt-out of at any time.`,
            },
            {
                title: `D. In case you need any help, information, or have questions, you can reach out to our customer support team at info@roomdaddy.com`,
            },
            {
                title: `E. SMS, MMS, and data charges may apply for any messages or images sent to you from us and/or us from you. If you have any questions about your mobile, messaging or data plans, please contact your internet service provider.`,
            },
            {
                title: `F. In case of questions about privacy, please go through our privacy policy.`,
            },
        ]
    },
    {
        title: "ACCOUNTS",
        content: [
            {
                title: `A. You must provide complete, accurate and current information when you create an account with us. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.`,
            },
            {
                title: `B. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.`,
            },
            {
                title: `C. You agree not to disclose your password to any third-party. Upon awareness on any security breach or unauthorized use of your account, you must notify us immediately so we can take reasonable action accordingly and timely.`,
            },
            {
                title: `D. We may enable you to login to the Service using your login credentials from Facebook, Google, or other social networking sites. If you utilize this function, we may receive information about you from such sites, and we may share information about you with such sites.`,
            }
        ]
    },
    {
        title: "INTELLECTUAL PROPERTY & LINKS TO OTHER WEBSITES",
        content: [
            {
                title: `A. All intellectual property that the Site contains, including trademarks, copyrights, patents, and other trade secrets are owned by our licensers. Your use of the Site does not grant you any rights, title, or interest in any such intellectual property. This includes any content that you may provide to us, such as feedback regarding the Service. By using the Site you hereby assign all rights to us, including the right to use such feedback in any manner we see fit.`,
            },
            {
                title: `B. Our Service may contain links to third-party web sites or services that are not owned or controlled by Roomdaddy.`,
            },
            {
                title: `C. Roomdaddy has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You agree that Roomdaddy shall not be liable or responsible, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or services.`,
            },
            {
                title: `D. We strongly suggest you read all the Terms & Conditions of any third-party websites and services.`,
            }
        ]
    },
    {
        title: "TERMINATION",
        content: [
            {
                title: `A. We may suspend or terminate your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms`,
            },
            {
                title: `B. Your right to use the Service will immediately cease upon termination of your account. If you wish to terminate your account, simply discontinue the use of Service.`,
            },
        ]
    },
    {
        title: "GOVERNING LAW & ATTRIBUTION",
        content: [
            {
                title: `A. These Terms shall be governed and construed in accordance with the laws of the United Arab Emirates.`,
            },
            {
                title: `B. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.`,
            },
            {
                title: `C. All disputes arising out of or in connection with these Terms shall be finally settled under the Rules of Arbitration of the International Chamber of Commerce by one or more arbitrators appointed in accordance with said Rules, and in Dubai as the venue.`,
            },
        ]
    },
    {
        title: "CHANGES",
        content: [
            {
                title: `A. Roomdaddy, at its sole discretion, reserves the right to modify or replace these terms at anytime. You will be notified of any or all changes of the new Terms on the Site. We advise you to read these Terms periodically for any changes.`,
            },
            {
                title: `B. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.`,
            },
        ]
    },
    {
        title: "INDEMNIFICATION, DISCLAIMERS & LIMITATIONS ON LIABILITY",
        content: [
            {
                title: `A. You agree to indemnify and hold us (and our officers, employees, and agents) harmless, including attorneys’ fees and costs, from any claim or demand made by any third-party arising out of your use of the Service, your interactions with any Vendors, or your violation of these Terms or applicable laws.`,
            },
            {
                title: `B. The Service is “as-is” provided and available. We and our vendors expressly disclaim any express or implied warranties and conditions of any kind.`,
            },
            {
                title: `C. In any event shall Roomdaddy be liable to you or any third-party for any loss of profits or any indirect, incidental, consequential, exemplary, special or punitive damages arising from or relating to this agreement, or your use of or inability to use the Service, even if we have been advised of the possibility of such damages.`,
            },
            {
                title: `D. You hereby release and forever discharge us (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or relates directly or indirectly to, any interactions with, or act or omission of, other Service users or our Vendors.`,
            }
        ]
    },
    {
        title: "ENTIRE AGREEMENT",
        content: [
            {
                title: `These Terms constitute the entire agreement between us and you with respect to the Service and supersedes all prior agreements, representations and understandings of the parties, written or oral.

                All notices, requests, claims, demands and other communications hereunder shall be made in writing (e.g., via email)`,
            },
        ]
    },
    {
        title: "CONTACT US",
        content: [
            {
                title: `In case of queries and questions about these Terms, please contact us at info@roomdaddy.com `,
            },
        ]
    },
]
export default data;