import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import { message } from "antd";
import app from "../utils/appConfig";
import { stripTags } from "../../utils/functions";
import { MetaTags } from "react-meta-tags";
import PageHeader from "../_newComponents/pageHeader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import MobileNav from "../_newComponents/MobileNav";
import BackIcon from "../icons/back.svg";
import "./style/blog.scss";

const Blog = () => {
  const history = useHistory();
  const { slug } = useParams();

  const [blog, setBlog] = useState({});
  const [suggests, setSuggests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${app.blogUrl}/wp-json/wp/v2/posts`)
      .then((res) => {
        const { data } = res;
        const blog = data.find((item) => item.slug === slug);
        const suggests = data.filter((item) => item.slug !== slug).slice(0, 2);

        setBlog(blog);
        setSuggests(suggests);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Oops! Something went wrong.");
        console.log(err);
      });

    window.scrollTo(0, 0);
  }, [slug]);

  return (
    <div className="blog">

       <MetaTags>
        <title>{stripTags(blog?.title?.rendered)}</title>
        <meta name="description" content={stripTags(blog?.excerpt?.rendered)} />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>

      <PageHeader homeLink="/" isCustom title="Blogs & News" />
      
      {loading ? (
        <>
          <ContentLoader
            speed={2}
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 400 240"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="-2" y="-2" rx="0" ry="0" width="400" height="240" />
          </ContentLoader>

          <ContentLoader
            speed={2}
            style={{ width: "100%", height: "100%", padding: "0 15px" }}
            viewBox="0 0 400 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="-2" y={265 - 240} rx="0" ry="0" width="100" height="12" />
            <rect x="-2" y={289 - 240} rx="0" ry="0" width="300" height="12" />
            <rect x="-2" y={340 - 240} rx="0" ry="0" width="400" height="12" />
            <rect x="-2" y={365 - 240} rx="0" ry="0" width="400" height="12" />
            <rect x="-2" y={390 - 240} rx="0" ry="0" width="400" height="12" />
            <rect x="-2" y={415 - 240} rx="0" ry="0" width="225" height="12" />
          </ContentLoader>
        </>
      ) : (
        <>
          <div className="blog__img">
            <div className="back">
              <img
                src={BackIcon}
                alt="Go Back - RoomDaddy"
                onClick={() => history.push("/blogs")}
              />
            </div>

            <ResponsiveAd />

            <img
              src={blog?.better_featured_image?.source_url}
              alt={
                blog?.better_featured_image?.alt_text?.split("-")?.join(" ") +
                " - RoomDaddy"
              }
            />
          </div>

         

          <div className="blog__body">
            <div className="blog__body-title">
              <span>
                Posted on{" "}
                {moment(blog?.date?.split("T")[0], "YYYY-MM-DD").format(
                  "DD MMM YYYY"
                )}
              </span>

              <h1>{stripTags(blog?.title?.rendered)}</h1>
            </div>

            <div className="blog__body-content">
              <p
                dangerouslySetInnerHTML={{
                  __html: blog?.content?.rendered,
                }}
              ></p>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          <div className="blog__recommend">
            <h2>You may also like</h2>
          </div>

          <div className="blog__suggests">
            {suggests.map((blog, i) => (
              <div
                className="blog__suggest"
                key={i}
                onClick={() => setLoading(true)}
              >
                <Link to={`/blogs/${blog?.slug}`}>
                  <div className="blog__suggest-img">
                    <img
                      src={blog?.better_featured_image?.source_url}
                      alt={
                        blog?.better_featured_image?.alt_text
                          ?.split("-")
                          ?.join(" ") + " - RoomDaddy"
                      }
                    />
                  </div>

                  <div className="blog__suggest-body">
                    <h3>{stripTags(blog?.title?.rendered)}</h3>

                    <p>
                      {moment(
                        blog?.modified?.split("T")[0],
                        "YYYY-MM-DD"
                      ).format("DD MMM YYYY")}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
<ResponsiveAd />
      <MobileNav />
    </div>
  );
};

export default Blog;
