//import React, { useEffect, useState } from "react";
//import Timeline from '../_newComponents/Timeline/Timeline'
//import "../_newComponents/Timeline/Timeline.scss"
//import { useHistory, useParams } from "react-router";
//import axios from "axios";
//import moment from "moment";
//import { getUser } from "../../utils/functions";
//import app from "../utils/appConfig";
import PaymentHistoryCard from "../_newComponents/PaymentHistoryCard";
//import BackIcon from "../icons/back.svg";
import "./style/paymentHistory.scss";


//import timelineData from '_path_to_file_';
//import TimelineItem from './TimelineItem';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { getUser } from "../../utils/functions";
import BackIcon from "../icons/back.svg";
import CrossIcon from "../icons/cross_dark.svg";
import CurrencyIcon from "../icons/currency.svg";
import PageHeader from "../_newComponents/pageHeader";
import Modal from "../_newComponents/Modal";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import moment from "moment";
import axios from 'axios';


const dateFormat = "YYYY-MM-DD";

 const Timeline = () => {

const user = getUser();
const history = useHistory();
const [loading, setLoading] = useState(true);
const [rentInvoice, setRentInvoice] = useState([]);
const [depositInvoice, setDepositInvoice] = useState([]);
const [booking, setBooking] = useState({});
const [rentData, setRentData] = useState([]);
const [depositData, setDepositData] = useState([]);
const [showModal, setShowModal] = useState(false);
const [notice, setNotice] = useState({});
const [moveOut, setMoveOut] = useState();
const [reason, setReason] = useState();
const { booking_id } = useParams();

useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/my-bookings-details/${booking_id}`)
      .then((res) => {
        if (res.data.data[0].id != user.id) history.push("/my_booking");

        axios
          .get(`${app.baseUrl}/api/view_notice/${booking_id}`)
          .then((res) => {
            const { data } = res.data;

            setNotice(data);
            setMoveOut(
              data.fld_move_out_date || moment().add(1, "M").format(dateFormat)
            );
            setReason(data.move_out_reason);
          })
          .catch((err) => console.log(err));

        axios
          .get(`${app.baseUrl}/api/pay-history/${btoa(booking_id)}`)
          .then((res) => {
            
            setRentData([
              ...res.data.rent,
            ]);
            setDepositData([
                ...res.data.deposit,
            ]);
            setRentInvoice([
                 ...res.data.invoice_rent
            ]);
            setDepositInvoice([
                 ...res.data.invoice_deposit
            ]);
          })
          .catch((err) => console.log(err));

        setBooking(res.data.data[0]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [booking_id]);

  return(
      
    
    rentInvoice.length > 0 && (
        <div>
            <PageHeader homeLink="/" isCustom title="Overview" />
            <div className="payment-history">
            <div className="payment-history__top">
              <div className="top--back-arrow">
                <img
                  onClick={() => {
                    const prevHost = window.document.referrer
                      ? new URL(window.document.referrer).hostname
                      : null;
                    const currHost = window.location.hostname;

                    if (!prevHost || prevHost !== currHost)
                      history.push("/my_booking");
                    else history.goBack();
                  }}
                  src={BackIcon}
                  alt="Go Back"
                />
              </div>

              <div className="top--title">Booking Timeline</div>
              {/* <div className="top--void">{booking.fld_name}</div> */}
            </div>

        <div className="timeline-container">

            
            <div>
            <VerticalTimeline
            lineColor='rgb(204 230 237)'
                >

            <VerticalTimelineElement
                position='left'
                className="vertical-timeline-element--work"
                date={moment(booking.fld_created_at,
                  dateFormat
                ).format("D MMM, YYYY")}
                iconStyle={{ background: 'rgb(204 230 237)', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Booking Created</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                
            </VerticalTimelineElement>

            <VerticalTimelineElement
                position='left'
                className="vertical-timeline-element--work"
                date={moment(booking.fld_move_in_date, dateFormat).format("D MMM, YYYY")}
                iconStyle={{ background: 'rgb(204 230 237)', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Moved In</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                
            </VerticalTimelineElement>

            {depositInvoice.map((data, idx) => (
                
                
            <VerticalTimelineElement
                position='left'
                className="vertical-timeline-element--work"
                date={moment(data.fld_Invoice_date, dateFormat).format("D MMM, YYYY")}
                iconStyle={{ background: 'rgb(204 230 237)', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Deposit Invoice</h3>
                {/* <h4 key={idx}  className="vertical-timeline-element-subtitle">{data.fld_id}</h4> */}
                <p>
                    Invoice ID: {data.fld_id}<br></br>
                    Amount: {data.fld_invoice_amount}<br></br>
                    Status: {data.fld_paid_status == 1 ? "Partially Paid" : data.fld_paid_status == 2 ? "Full Paid" : "Not Paid" }<br></br>
                    Due: {moment(data.fld_invoice_due_date, dateFormat).format("D MMM, YYYY")}
                </p>
                
            </VerticalTimelineElement>
                
            ))}

            {depositData.map((data, idx) => (
                
                
            <VerticalTimelineElement
                position='right'
                className="vertical-timeline-element--work"
                date={moment(data.fld_payment_date, dateFormat).format("D MMM, YYYY")}
                iconStyle={{ background: 'rgb(204 230 237)', color: '#fff' }}
                >
                <h3 className="vertical-timeline-element-title">Deposit Payment</h3>
                {/* <h4 key={idx}  className="vertical-timeline-element-subtitle">{data.transaction_id}</h4> */}
                <p>
                    Trx. ID: {data.transaction_id}<br></br>
                    Trx. Amount: {data.fld_paid_amount}<br></br>
                    Type: {data.fld_payment_type == 1 ? "Bank Transfer" : data.fld_payment_type == 2 ? "Cash" : "Online" }
                </p>
            </VerticalTimelineElement>
                
            ))}

            {rentInvoice.map((data, idx) => (
                
                <div>

                
                <VerticalTimelineElement
                position='left'
               // position={data.category.tag == "Rent Payment" || data.category.tag == "Deposit Payment" ? "right" : "left"}
                className="vertical-timeline-element--work"
                //contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                //contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                date={moment(data.fld_Invoice_date, dateFormat).format("D MMM, YYYY")}
                iconStyle={{ background: 'rgb(204 230 237)', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                <h3 className="vertical-timeline-element-title">Rent Invoice</h3>
                {/* <h4 key={idx} className="vertical-timeline-element-subtitle">{data.fld_id}</h4> */}
                <p>
                    Invoice ID: {data.fld_id}<br></br>
                    Amount: {data.fld_invoice_amount}<br></br>
                    Status: {data.fld_paid_status == 1 ? "Partially Paid" : data.fld_paid_status == 2 ? "Full Paid" : "Not Paid" }<br></br>
                    Due: {moment(data.fld_invoice_due_date, dateFormat).format("D MMM, YYYY")}
                </p>
                <br></br>
                {data.fld_paid_status == 0 &&  (
                      <Button
                      className="bookN-s custom-button"
                      onClick={() => setShowModal(true)}
                      >
                      <img src={CurrencyIcon} alt="Currency" />
                      <span className="pl-2">Pay now</span>
                    </Button>
                    )} 
                </VerticalTimelineElement>
                {data.rent_details.length > 0 && data.rent_details.map((value, idx) => (

                    <VerticalTimelineElement
                    position='right'
                    // position={data.category.tag == "Rent Payment" || data.category.tag == "Deposit Payment" ? "right" : "left"}
                    className="vertical-timeline-element--work"
                    //contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    //contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date={moment(value.fld_payment_date, dateFormat).format("D MMM, YYYY")}
                    iconStyle={{ background: 'rgb(204 230 237)', color: '#fff' }}
                    // icon={<WorkIcon />}
                    >
                    <h3 className="vertical-timeline-element-title">Rent Payment</h3>
                    {/* <h4 key={idx}  className="vertical-timeline-element-subtitle">{value.fld_invoice_id}|{value.transaction_id}</h4> */}
                    <p>
                    Trx. ID: {value.transaction_id}<br></br>
                    Trx. Amount: {value.fld_paid_amount}<br></br>
                    Type: {value.fld_payment_type == 1 ? "Bank Transfer" : value.fld_payment_type == 2 ? "Cash" : "Online" }
                    </p>
                    </VerticalTimelineElement>

                ))} 
                </div>
                
            ))}

           
            
            </VerticalTimeline>
            </div>
        </div>
        </div>
        <Modal visible={showModal}>
            <div className="d-flex align-items-center justify-content-between p-3">
              <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

              <h5
                className="mb-0"
                style={{ color: "#1c1c1c", fontFamily: "futuraMdBt" }}
              >
                Pay Invoice
              </h5>

              <div>
                <img
                  src={CrossIcon}
                  alt="Close - RoomDaddy"
                  onClick={() => setShowModal(false)}
                />
              </div>
            </div>

            <div className="container d-flex flex-column px-3 py-4">
              <Link
                to={`/my_booking/co-living/${booking_id}/service-requests/new`}
                className="mb-3"
              >
                <Button
                  variant="contained"
                  className="w-100 filter-Apply futuraN700-14"
                >
                  Pay with Card
                </Button>
              </Link>

              <Link to={`https://roomdaddy.com/pay_invoice`}>
                <Button
                  variant="contained"
                  className="w-100 filter-Reset futuraN700-14 mb-2 mt-1"
                  
                >
                  Bank Transfer
                </Button>
              </Link>
              <br></br>

              <form action="https://roomdaddy.com/pay_invoice" method="POST">
              <input type="hidden" name="_token" value="{csrfToken}" />
                        <input type="hidden" name="invoice_amount" value="250"/>
          <button type="submit">
            Submit
          </button>
        </form>

            </div>
          </Modal>
        </div>
    )
            )

            }
    export default Timeline;