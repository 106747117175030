import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { DatePicker, message } from "antd";
import { Button } from "@material-ui/core";
import Modal from "../_newComponents/Modal";
import app from "../utils/appConfig";
import { getUser } from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import Calendar from "../icons/calendar.svg";
import BackIcon from "../icons/back.svg";
import CurrencyIcon from "../icons/currency.svg";
import RepairIcon from "../icons/repair-tool.svg";
import CrossIcon from "../icons/cross_dark.svg";
import "./style/coLivingBookingPage.scss";

const dateFormat = "YYYY-MM-DD";

const CoLivingBookingPage = () => {
  const user = getUser();

  const history = useHistory();
  const { booking_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [processing, setProcessing] = useState(false);
  const [booking, setBooking] = useState({});
  const [lastPayment, setLastPayment] = useState({});
  const [notice, setNotice] = useState({});
  const [moveOut, setMoveOut] = useState();
  const [reason, setReason] = useState();
  const [showModal, setShowModal] = useState(false);
  const [noticeModal, setNoticeModal] = useState(false);
  const [rentData, setRentData] = useState([]);

  if (!user) history.push("/login");

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/my-bookings-details/${booking_id}`)
      .then((res) => {
        if (res.data.data[0].id != user.id) history.push("/my_booking");

        axios
          .get(`${app.baseUrl}/api/view_notice/${booking_id}`)
          .then((res) => {
            const { data } = res.data;

            setNotice(data);
            setMoveOut(
              data.fld_move_out_date || moment().add(1, "M").format(dateFormat)
            );
            setReason(data.move_out_reason);
          })
          .catch((err) => console.log(err));

        axios
          .get(`${app.baseUrl}/api/pay-history/${btoa(booking_id)}`)
          .then((res) => {
            setInvoice(res.data);
            setRentData([
              ...res.data.invoice_deposit,
              ...res.data.invoice_rent,
              ...res.data.rent,
            ]);
          })
          .catch((err) => console.log(err));

        setBooking(res.data.data[0]);
        setLastPayment(res.data.data[1]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [booking_id]);

  const getDueDate = () => {
    const today = moment();
    const desiredDate = moment().startOf("month");
    const isPassed = today.isAfter(desiredDate);

    return isPassed
      ? desiredDate.add("month", 1).format("D MMM, YYYY")
      : desiredDate;
  };

  const submitNotice = () => {
    if (!moveOut || !reason) {
      message.error("One or more fields are empty");
      return;
    }

    setProcessing(true);

    const data = new FormData();

    data.append('booking_id', booking_id);
    data.append(
      "fld_room_name",
      booking.fld_custom_room_name || booking.fld_name
    );
    data.append('data', moveOut);
    data.append('reason', reason);

   

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }
    axios
      .post(`${app.baseUrl}/api/add_notice`, data, config)
      .then((res) => {
        message.success("Notice submitted");
        //setNotice(res.data);
        setNoticeModal(false);
        console.log(res);
       // window.location.reload();
        setProcessing(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
        setProcessing(false);
      });
  };

  const cancelNotice = () => {
    const data = new FormData();

    data.append("booking_id", booking_id);
    data.append(
      "fld_room_name",
      booking.fld_custom_room_name || booking.fld_name
    );

    axios
      .post(`${app.baseUrl}/api/cancel_notice`, data)
      .then((res) => {
        message.success("Notice cancelled");
        window.location.reload();
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  };

  return (
    <div className="coliving-booking">
      <PageHeader homeLink="/" isCustom title="Overview" />

      {loading ? (
        <></>
      ) : (
        <>
          <div className="coliving-booking__body">
            <div className="coliving-booking__top">
              <div className="top--back-arrow">
                <img
                  onClick={() => {
                    const prevHost = window.document.referrer
                      ? new URL(window.document.referrer).hostname
                      : null;
                    const currHost = window.location.hostname;

                    if (!prevHost || prevHost !== currHost)
                      history.push("/my_booking");
                    else history.goBack();
                  }}
                  src={BackIcon}
                  alt="Go Back"
                />
              </div>

              <div className="top--title">{booking.fld_building}</div>
              <div className="top--void">{booking.fld_name}</div>
            </div>

            <div className="card mb-3">
              <div style={{ height: "225px", overflow: "hidden" }}>
                <img
                  className="card-img"
                  src={booking.room_image}
                  alt={booking.fld_custom_room_name}
                />
              </div>

              <div className="card-body info-card">
                <h4>{booking.fld_custom_room_name}</h4>

                <p>
                  <span>Balance Due</span>
                  <span>AED {booking.bal}</span>
                </p>

                <div className="card-btns">
                  <Button
                    className="bookN-s custom-button"
                    onClick={() => setShowModal(true)}
                  >
                    <img src={RepairIcon} alt="Repair Tool" />
                    <span className="pl-2">Service request</span>
                  </Button>

                  {/* <Button className="bookN-s custom-button">
                    <img src={CurrencyIcon} alt="Currency" />
                    <span className="pl-2">Pay now</span>
                  </Button> */}
                </div>

                {/* <div className="card--footer">
                  <div className="content">
                    <img src={Calendar} alt="Calendar" />

                    <span className="pl-2">
                      Due{" "}
                      {moment(
                        invoice?.rent?.[0]?.fld_invoice_due_date,
                        dateFormat
                      ).format("D MMM, YYYY")}
                      
                    </span>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="card">
              <div className="card-body summary-card">
                <h4>Summary</h4>

                <div className="summaries">
                 

                  <p className="summary">
                    <span>Agreed Deposit</span>
                    <span className="summary-value">
                      AED {booking.Deposit_committed}
                    </span>
                  </p>

                  <p className="summary">
                    <span>Paid Deposit</span>
                    <span className="summary-value">AED {booking.Deposit_paid}</span>
                  </p>

                  <p className="summary">
                    <span>Minimum stay</span>
                    <span className="summary-value">
                      {booking.fld_minimum_stay} month
                    </span>
                  </p>

                  <p className="summary">
                    <span>Agreed rent</span>
                    <span className="summary-value">
                      AED {booking.fld_rent}
                    </span>
                  </p>

                  {
                    lastPayment && (
                      <div>
                      <p className="summary">
                        <span>Last Payment</span>
                        <span className="summary-value">
                          AED {lastPayment.last_payment_amount}
                        </span>
                      </p>

                      <p className="summary">
                        <span>Last Payment Date</span>
                        <span className="summary-value">
                          {moment(lastPayment.last_payment_date, dateFormat).format("D MMM, YYYY")}
                        </span>
                      </p>
                      </div>
                    )
                  }
                                  

                  <p className="summary">
                    <span>Next Payment Date</span>
                    <span className="summary-value">
                      {moment(booking.next_payment_date, dateFormat).format("D MMM, YYYY")}
                    </span>
                  </p>

                  {/* <p className="summary">
                    <span>Last payment</span>
                    <span className="summary-date">3 Jun, 2021</span>
                    <span className="summary-value">AED 100</span>
                  </p> */}
                </div>

                <div className="text-center mt-2">
                  <Button
                    className="filter-Reset custom-btn w-100"
                    onClick={() =>
                      history.push(
                        `/my_booking/co-living/${booking_id}/payment-history`
                      )
                    }
                  >
                    Payment history
                  </Button>
                </div>
              </div>
            </div>

            <div className="coliving-booking__buttons">
              {!notice?.fld_move_out_date && !notice?.move_out_reason ? (
                <Button
                  className="bookN-s custom-button"
                  onClick={() => setNoticeModal(true)}
                >
                  Submit a notice
                  {/* {!notice?.fld_move_out_date && !notice?.move_out_reason
                  ? "Submit a notice"
                  : "Re-submit notice"} */}
                </Button>
              ) : null}

              {!notice?.fld_move_out_date && !notice?.move_out_reason ? null : (
                <Button
                  className="bookDanger custom-button"
                  onClick={cancelNotice}
                >
                  Cancel notice
                </Button>
              )}
            </div>
          </div>

          <Modal visible={noticeModal}>
            <div className="d-flex align-items-center justify-content-between p-3">
              <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

              <h5
                className="mb-0"
                style={{ color: "#1c1c1c", fontFamily: "futuraMdBt" }}
              >
                {!notice?.fld_move_out_date && !notice?.move_out_reason
                  ? "Submit a notice"
                  : "Re-submit notice"}
              </h5>

              <div>
                <img
                  src={CrossIcon}
                  alt="Close - RoomDaddy"
                  onClick={() => setNoticeModal(false)}
                />
              </div>
            </div>

            <div className="container d-flex flex-column px-3 py-4">
              <div className="submit-notice mb-4">
                <label className="mb-2" htmlFor="date">
                  Move out date
                </label>

                <div>
                  <DatePicker
                    id="date"
                    placeholder={dateFormat}
                    format={dateFormat}
                    value={moment(moveOut, dateFormat)}
                    onChange={(date, dateStr) => setMoveOut(dateStr)}
                  />
                </div>
              </div>

              <div className="submit-notice mb-4">
                <label className="mb-2" htmlFor="reason">
                  Reason
                </label>

                <textarea
                  id="reason"
                  className="rd-custom-textarea mt-0"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                ></textarea>
              </div>

              <div className="submit-notice">
                <Button
                  disabled={processing}
                  className="bookN w-100"
                  onClick={submitNotice}
                >
                  {processing ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </div>
          </Modal>

          <Modal visible={showModal}>
            <div className="d-flex align-items-center justify-content-between p-3">
              <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

              <h5
                className="mb-0"
                style={{ color: "#1c1c1c", fontFamily: "futuraMdBt" }}
              >
                Options
              </h5>

              <div>
                <img
                  src={CrossIcon}
                  alt="Close - RoomDaddy"
                  onClick={() => setShowModal(false)}
                />
              </div>
            </div>

            <div className="container d-flex flex-column px-3 py-4">
              <Link
                to={`/my_booking/co-living/${booking_id}/service-requests/new`}
                className="mb-3"
              >
                <Button
                  variant="contained"
                  className="w-100 filter-Apply futuraN700-14"
                >
                  Request a service
                </Button>
              </Link>

              <Link to={`/my_booking/co-living/${booking_id}/service-requests`}>
                <Button
                  variant="contained"
                  className="w-100 filter-Reset futuraN700-14 mb-2 mt-1"
                >
                  Previous requests
                </Button>
              </Link>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CoLivingBookingPage;
