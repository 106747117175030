import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import { decode } from "html-entities";
import { message } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import Pagination from "../Pagination.js";
import { stripTags } from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import MyLeaderBoardAd from "../_newComponents/GoogleAds";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import MobileNav from "../_newComponents/MobileNav";
import NoImage from "../images/no-image.png";
import "./style/blogs.scss";

const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [nrofpages, setNumberofpage] = useState(1);

  const fetchBlogs = () => {
    axios
      .get(`${app.blogUrl}/wp-json/wp/v2/posts`, {
        params: { page: page }
      })
      .then((res) => {
        //console.log(res);
        setNumberofpage(res.headers["x-wp-totalpages"]);
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        message.error("Oops! Something went wrong.");
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, [page, setBlogs]);

  return (
    <div className="blogs">
      <PageHeader homeLink="/" isCustom title="Blogs & News" />
      <ResponsiveAd />
      {/* <MyLeaderBoardAd /> */}
      
      

      <div className="blogs__body">
        {loading ? (
          <div style={{ padding: 10 }}>
            <ContentLoader
              speed={2}
              style={{ width: "100%", height: "100%" }}
              viewBox="0 0 300 250"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
              <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
              <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
            </ContentLoader>
          </div>
        ) : (
          blogs.map((blog, i) => (
            <div key={i} className="card mb-3 mt-2">
              <Link to={`/blogs/${blog?.slug}`}>
                <div className="card-img">
                  <img
                    className="d-block w-100 carousalImg"
                    src={blog?.better_featured_image?.source_url || NoImage}
                    alt={
                      blog?.better_featured_image?.alt_text
                        ?.split("-")
                        ?.join(" ") + " - RoomDaddy"
                    }
                  />
                </div>

                <div className="card-body pb-2">
                  <p style={{ fontFamily: "futuraBkBt" }} className="mb-1">
                    {moment(blog?.date?.split("T")[0], "YYYY-MM-DD").format(
                      "DD MMM YYYY"
                    )}
                  </p>

                  <div className="futuraBold18 mb-2">
                    {/* <Link to={`/blogs/${blog?.slug}`}> */}
                    <p className="blog-title">{blog?.title?.rendered}</p>
                    {/* </Link> */}
                  </div>

                  <div className="futuraBkBtN-14">
                    <p className="text-gray pb-2">
                      {decode(stripTags(blog?.excerpt?.rendered))}
                    </p>
                  </div>

                  {/* <div className="py-2">
                  <Link to={`/blogs/${blog?.slug}`}>
                    <Button className="bookN">View More</Button>
                  </Link>
                </div> */}
                </div>
              </Link>
            </div>
          ))
        )}
      </div>

      <Pagination
        shape="rounded"
        nrOfPages={nrofpages}
        currentpage={page}
        onSelectPage={n => {
          setPage(n);
        }}
      />

      <ResponsiveAd />

      <MobileNav />
    </div>
  );
};

export default BlogsPage;
