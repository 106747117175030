import React, { useEffect, useState } from "react";
import { Badge } from "antd";
import { Link } from "react-router-dom";
import { MobileNavData } from "./MobileNavData";
import http from "../../../service/http";
import pusher from "../../../utils/pusher";
import { getUser } from "../../../utils/functions";
import "./index.scss";

export default function MobileNav(props) {
  const user = getUser();

  const [unreadMessages, setUnreadMessages] = useState(0);
  const [unreadNotifs, setUnreadNotifs] = useState(0);

  useEffect(() => {
    const getChats = async () => {
      await http
        .get(`getAllChatUser/${user?.id}`)
        .then((res) => setUnreadMessages(res.data.total_unread_chats))
        .catch((err) => console.log(err));
    };

    const getNotifs = async () => {
      await http
        .get(`get-notification/${user?.id}`)
        .then((res) => setUnreadNotifs(res.data.total_unread))
        .catch((err) => console.log(err));
    };

    const navChatChannel = pusher.subscribe("rd-channel");
    navChatChannel.bind("rd-event", (data) => {
      getChats();
    });

    const navNotifChannel = pusher.subscribe("all-notification");
    navNotifChannel.bind("App\\Events\\NotificationEvent", (data) => {
      getNotifs();
    });

    getChats();
    getNotifs();

    return () => {
      navChatChannel.unbind_all();
      navNotifChannel.unbind_all();
    };
  }, []);

  return (
    <div
      className="bottom-navigation-wrap display-flex max-width750 mobile-nav"
      style={{ padding: 0, boxShadow: "-2px -2px 8px #cce6ed !important" }}
    >
      {MobileNavData.map((item, index) => {
        return (
          <Link
            to={item.path}
            key={index}
            className="bottom-navigation center-center"
          >
            <img
              style={{ height: "22px", width: "20px" }}
              src={item.img}
              alt={item.title}
              srcSet=""
            />

            {item.title == "notification" && unreadNotifs ? (
              <Badge count={user && unreadNotifs}>
                {/* <Badge count={props.newNotification}> */}
                <p className="mb-0">{item.title}</p>
              </Badge>
            ) : item.title == "Chat" && unreadMessages ? (
              <Badge
                count={user && unreadMessages}
                className="chat-count-badge"
              >
                <p className="mb-0">{item.title}</p>
              </Badge>
            ) : (
              <p>{item.title}</p>
            )}
          </Link>
        );
      })}
    </div>
  );
}
