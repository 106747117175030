import React, { useState, useEffect, useContext } from "react";
//import OneSignal from "react-onesignal";
import { Select, Input, message } from "antd";
import { WingBlank } from "antd-mobile";
import Modal from "../../_newComponents/Modal";
 import SearchLocationInput from "../../components/GoogleAutoComplete";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import axios from "axios";
import Cross from "../../icons/cross.svg";
import { Button } from "@material-ui/core";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { getUser } from "../../../utils/functions";
const { Option } = Select;
let timer;
let contryId;
let stepOneData={};
export default function StepOne({ nextStep, to, oneInfo }) {
    const { userInfo, setUserInfo, editHomeDetail ,editHomeStepsDetail, setEditHomeStepsDetail } = useContext(AppContext);
    const [stepOneInfo, setStepOneInfo] = useState({
        hotel_type: "", hotel_city: "", hotel_country: 229, hotel_address_line: "",
        hotel_street_address: "", hotel_pin_location: '',hotel_currency:"AED",
        hotel_latitude: userInfo.lat, hotel_longitude: userInfo.lng,
    });
    const uaeCitiesLatLng = [
        {
            id: 48973,
            name : "Abu Dhabi",
            latlong : {
                lat: 24.466667,
                lng: 54.366669
            }
        },
        {
            id: 48974,
            name : "Ajman",
            latlong : {
                lat: 25.4052,
                lng: 55.5136 
            }
        },
        {
            id: 48974,
            name : "Al Khaimah",
            latlong : {
                lat: 25.6741,
                lng: 55.9804
            }
        },
        {
            id: 48981,
            name : "ash-Shariqah",
            latlong : {
                lat: 25.3462,
                lng: 55.4211
            }
        },
        {
            id: 48975,
            name : "Dubai",
            latlong : {
                lat: 25.276987,
                lng: 55.296249
            }
        },
        {
            id: 48980,
            name : "Fujeirah ",
            latlong : {
                lat: 25.1288,
                lng: 56.3265
            }
        },
        {
            id: 48976,
            name : "Ras Al-Khaimah",
            latlong : {
                lat: 25.800694,
                lng: 55.976200
            }
        },
        {
            id: 48977,
            name : "Sharjah",
            latlong : {
                lat: 25.348766,
                lng: 55.405403
            }
        },
        {
            id: 48979,
            name : "Umm al Qaywayn",
            latlong : {
                lat: 25.5325,
                lng: 55.5492
            }
        },
    ]
    
   
 
    // const [countryList, setCountryList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [buildingsList, setBuildingsList] = useState({});
    const [locationsList, setLocationsList] = useState([]);
    const [locationFilter, setLocationFilter] = useState("");
    const [buildingFilter, setBuildingFilter] = useState("");
    const [isModal, setIsModal] = useState("");
    const [newBuilding, setNewBuilding] = useState("");
    const [newLocation, setNewLocation] = useState("");

    function sentFormData() {

        let { address, lat, lng } = userInfo;
        stepOneInfo.hotel_pin_location = address;
        stepOneInfo.hotel_latitude = lat;
        stepOneInfo.hotel_longitude = lng;
        setEditHomeStepsDetail({stepOneInfo});
        nextStep(1);
        
        let data = new FormData();
        for(let i in stepOneInfo ) {
            data.append(i, stepOneInfo[i]);
        }
        data.append("fld_tanent", userInfo.id);
    
        
    }
   
    useEffect(() => {
        fetchCities();
    }, []);
    useEffect(() => {
        fetchBuildings();
    }, [buildingFilter]);
    useEffect(() => {
        fetchLocations();
    }, [locationFilter]);
    useEffect(() => {
        console.log("target",stepOneInfo);
    }, [stepOneInfo]);
    useEffect(() => {
        if(editHomeDetail.id) {
            setStepOneInfo({
                id: editHomeDetail.id,
                hotel_type: editHomeDetail.hotel_type ? editHomeDetail.hotel_type : "apartment",
                hotel_city: editHomeDetail.location.city ? editHomeDetail.location.city : "",
                hotel_country: editHomeDetail.hotel_country ? editHomeDetail.hotel_country : 229,
                hotel_address_line: editHomeDetail.address_line_two ? editHomeDetail.address_line_two : "",
                hotel_street_address: editHomeDetail.street_address ? editHomeDetail.street_address : "",
                hotel_pin_location: editHomeDetail.hotel_pin_location ? editHomeDetail.hotel_pin_location : '',
                user_id: userInfo.id ? userInfo.id : 191,
                hotel_latitude: editHomeDetail.hotel_latitude ? editHomeDetail.hotel_latitude : 0,
                hotel_longitude: editHomeDetail.hotel_longitude ? editHomeDetail.hotel_longitude : 0
            });
        }
    }, [editHomeDetail]);
    function addNewBuilding(key) {
        if (newBuilding !== "") {
          axios
            .post(`${app.baseUrl}/api/add_building`, {
              country_id: 229,
              city_id: stepOneInfo.city_id,
              name: newBuilding,
            })
            .then((res) => {
              console.log(res);
              setBuildingsList(res.data.data);
            })
            .catch((err) => console.log(err));
        }
      }
    
      function addNewLocation(key) {
        if (newLocation !== "") {
          axios
            .post(`${app.baseUrl}/api/add_location`, {
              country_id: 229,
              city_id: stepOneInfo.city_id,
              name: newLocation,
            })
            .then((res) => {
              console.log(res);
              setLocationsList(res.data.data);
            })
            .catch((err) => console.log(err));
        }
      }
    function fetchCities() {
        axios.get(`${app.baseUrl}/api/cities_list/${229}`).then( res => {
            console.log("cities API",res);
            setCitiesList(res.data.data);
        }).catch( err => console.log(err));
    }
    function fetchBuildings() {
        if(buildingFilter !== ""){
            axios.get(`${app.baseUrl}/api/building_filter/${buildingFilter}`).then( res => {
                console.log(res);
                setBuildingsList(res.data.data);
            }).catch( err => console.log(err));
        }
    }
    function fetchLocations() {
        if(locationFilter !== ""){
            axios.get(`${app.baseUrl}/api/location_filter/${locationFilter}`).then( res => {
                console.log(res);
                setLocationsList(res.data.data);
            }).catch( err => console.log(err));
        }
    }
    function addNewBuilding(key) {
        if(newBuilding !== "") {
            axios.post(`${app.baseUrl}/api/add_building`, {
                country_id: 229, city_id: stepOneInfo.city_id, name: newBuilding
            }).then( res => {
                console.log(res);
                setBuildingsList(res.data.data);
            }).catch( err => console.log(err));
        }
    }
    useEffect(() => {
        console.log("state",stepOneInfo)
    }, [])
    function updateFirstForm(property, value) {
        if(property == "hotel_city"){
            uaeCitiesLatLng.map((item, index)=>{
                if(item.name == value){
                    setUserInfo(
                        {
                            ...userInfo,
                            lat: item.latlong.lat,
                            lng: item.latlong.lng
                        }

                    )
                } 
            })
        }
        console.log(`selected form ${value}`);
        setStepOneInfo({...stepOneInfo, [property]: value});
        if(editHomeDetail.room_id) {
            editHomeDetail[property] = value;
        }
    }
    function onSearch(key, value) {
        console.log(`${key} ${value}`);
        clearTimeout(timer);
        timer = setTimeout(function() {
            if(key === "building") {
                setBuildingFilter(value);
            } else if(key === "location") {
                setLocationFilter(value);
            }
        }, 400);
    }
    return (
        <div className="py-3 px-2">
          <div className="container pb-4 px-3">
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Choose your Country
              </label>
    
              <div className="form-control inputBorder">
              <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder={"United Arab Emirates"}
                    value={"United Arab Emirates"}
                    
                >
                    <Option key="" value="229">United Arab Emirates</Option>
                    
                </Select>
              </div>
            </div>
    
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Choose your City
              </label>
              <div className="form-control inputBorder">
              <Select
                showSearch
                style={{ width: "100%" }}
                // placeholder={ editHomeDetail.city_name ? editHomeDetail.city_name : "Select city"}
                placeholder={"Select city"}
                value={stepOneInfo.hotel_city ? stepOneInfo.hotel_city : editHomeDetail.hotel_city}
                onChange={ value => updateFirstForm("hotel_city", value)}
                filterOption={ (input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                { 
                    citiesList.length !== 0 
                    &&
                    citiesList.map( city => <Option value={city.name}>{city.name}</Option> )
                }
            </Select>
              </div>
            </div>
    
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Choose property type?
              </label>
              <div className="mr-3"></div>
              {/* <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  checked={
                    stepOneInfo.property_type === "villa" ? "checked" : false
                  }
                  name="optradio"
                  className="custom-control-input"
                  id="villa"
                  onChange={() => updateFirstForm("property_type", "villa")}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="villa"
                >
                  Villa
                </label>
              </div> */}
    
              <Checkbox
                checked={stepOneInfo.hotel_type === "villa" ? "checked" : false}
                name="optradio"
                onChange={() => updateFirstForm("hotel_type", "villa")}
              >
                Villa
              </Checkbox>
    
              <Checkbox
                checked={
                  stepOneInfo.hotel_type === "apartment" ? "checked" : false
                }
                name="optradio"
                onChange={() => updateFirstForm("hotel_type", "apartment")}
              >
                Apartment
              </Checkbox>
    
              {/* <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  checked={
                    stepOneInfo.property_type === "apartment" ? "checked" : false
                  }
                  name="optradio"
                  className="custom-control-input"
                  id="apartment"
                  onChange={() => updateFirstForm("property_type", "apartment")}
                />
                <label
                  className="custom-control-label futuraBkBtN-14 pl-5"
                  for="apartment"
                >
                  Apartment
                </label>
              </div> */}
            </div>
    
            {stepOneInfo.hotel_type === "apartment" && (
              <div className="form-group">
                <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                  Select Building
                </label>
                <div
                  className="form-control inputBorder"
                  style={{ marginBottom: 8 }}
                >
                 <Select
                    showSearch
                    style={{ width: "100%" }}
                    // placeholder={editHomeDetail.property_name ? editHomeDetail.property_name : "Select building"}
                    placeholder={"Select building"}
                    value={stepOneInfo.hotel_address_line}
                    onChange={ value => updateFirstForm("hotel_address_line", value)}
                    onSearch={ val => onSearch("building", val)}
                    filterOption={ (input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={null}
                >
                    { 
                        Object.keys(buildingsList).length !== 0 
                        &&
                        Object.keys(buildingsList).map(building => <Option value={buildingsList[building]}>{buildingsList[building]}</Option>)
                    }
                </Select>
                </div>
    
                {/* {stepOneInfo.city_id !== "" &&
                  Object.keys(buildingsList).length === 0 && (
                    <> */}
                <label className="futuraBkBtN-14">
                  *Not able to find you building{" "}
                </label>
    
                <p
                  className="futuraBkBtN-14 text-danger"
                  onClick={() => setIsModal("building")}
                  type="primary"
                  shape="circle"
                >
                  + Add new building
                </p>
                {/* </>
                  )} */}
              </div>
            )}
    
            <div className="form-group">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Select Location*
              </label>
    
              <div className="form-control inputBorder">
              <Select
                showSearch
                style={{ width: "100%" }}
                // placeholder={editHomeDetail && editHomeDetail.fld_area ? editHomeDetail.fld_area : "Select location"}
                placeholder={"Select location"}
                value={ stepOneInfo.hotel_street_address }
                onChange={ (value) => updateFirstForm("hotel_street_address", value)}
                onSearch={(val)=> onSearch("location", val)}
                filterOption={ (input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={null}
            >
                { 
                    Object.keys(locationsList).length !== 0 
                    &&
                    Object.keys(locationsList).map(building=> <Option value={locationsList[building]}>{locationsList[building]}</Option>)
                }
            </Select>
              </div>
    
              {/* {stepOneInfo.city_id !== "" &&
                Object.keys(locationsList).length === 0 && (
                  <> */}
              <label className="futuraBkBtN-14">
                *Not able to find you location{" "}
              </label>
    
              <p
                className="futuraBkBtN-14 text-danger"
                onClick={() => setIsModal("location")}
                type="primary"
                shape="circle"
              >
                + Add new location
              </p>
              {/* </>
                )} */}
            </div>
    
            <div className="form-group mx-0">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Pin your location*
              </label>
    
                 <SearchLocationInput />
            </div>
          </div>
    
          <WingBlank>
            <Modal
              popup
              visible={isModal}
              onClose={() => setIsModal(false)}
              animationType="slide-up"
            >
              <div class="container">
                <div className="row">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                    <div className="row">
                      <div className="col-10">
                        <p>
                          {"Add New " +
                            (isModal === "building" ? "Building" : "Location")}
                        </p>
                      </div>
                      <div className="col-2">
                        <span onClick={() => setIsModal(false)}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Input
                      className="rd-custom-input"
                      placeholder={"Add new " + isModal}
                      value={isModal === "building" ? newBuilding : newLocation}
                      onChange={(e) => {
                        if (isModal === "building") setNewBuilding(e.target.value);
                        else setNewLocation(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="form-group mt-2 mb-4">
                  <Button
                    className="w-100 filter-Apply futuraN700-14"
                    onClick={() => {
                      setIsModal(false);
                      if (isModal === "building") addNewBuilding(isModal);
                      else addNewLocation(isModal);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Modal>
          </WingBlank>
    
          <Button
            className="bookN futuraN700-14 w-100"
            type="button"
            onClick={sentFormData}
          >
            Continue
          </Button>
        </div>
      );
    }
    