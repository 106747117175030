import React, { useState, useEffect, useContext } from "react";
//import OneSignal from "react-onesignal";
import { Checkbox, message } from "antd";
import axios from "axios";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import imageCompression from "browser-image-compression";
import Cross from "../../icons/cross.svg";
import Plus from "../../icons/plus.svg";
import { Button } from "@material-ui/core";
export default function StepFour({nextStep, step1,step2,step3, to,user,stepFourInfo }) {
    const { userInfo,setUserInfo, editHomeDetail,editHomeStepsDetail,setEditCalendarDetail } = useContext(AppContext);
    const [fourStepInfo, setFourStepInfo] = useState({
        hotel_name: "", hotel_desc: "", room_pics: [],
        fld_notes: "",
        name:user.name,email:user.email,phone:user.phone,user_id:user.id
    });

    // useEffect(() => {
    //     console.log(fourStepInfo);
    // }, [fourStepInfo]);

    function sentFormData() {
       
      
      let formData = new FormData();    
        if(editHomeDetail.id)
        {
            formData.append('hotel_id',editHomeDetail.id);

        }
        formData.append('hotel_name',fourStepInfo.hotel_name);
        formData.append('hotel_desc',fourStepInfo.hotel_desc);
        formData.append('fld_notes',fourStepInfo.fld_notes);
        formData.append('hotel_contact_name',userInfo.name);
        formData.append('hotel_contact_email',userInfo.email);
        formData.append('hotel_phone_number',userInfo.phone);
        formData.append('hotel_phone_alternate',userInfo.phone);
        formData.append('hotel_street_address',fourStepInfo.stepOneInfo.hotel_street_address);
        formData.append('hotel_address_line',fourStepInfo.stepOneInfo.hotel_address_line);
        formData.append('hotel_country',fourStepInfo.stepOneInfo.hotel_country);
        formData.append('hotel_city',fourStepInfo.stepOneInfo.hotel_city);
        formData.append('hotel_postal','54000');
        formData.append('hotel_currency',"AED");
        formData.append('hotel_type',fourStepInfo.stepOneInfo.hotel_type);
        formData.append('hotel_pin_location',fourStepInfo.stepOneInfo.hotel_pin_location);
        formData.append('hotel_latitude',fourStepInfo.stepOneInfo.hotel_latitude);
        formData.append('hotel_longitude',fourStepInfo.stepOneInfo.hotel_longitude);
        //services
        formData.append('hotel_services['+0+'][hotel_bills]',fourStepInfo.thirdStepInfo.bills_included);
        formData.append('hotel_services['+0+'][hotel_parking]',fourStepInfo.secondStepInfo.hotel_parking);
        formData.append('hotel_services['+0+'][hotel_parking_type]',"");
        formData.append('hotel_services['+0+'][hotel_parking_site]',"");
        formData.append('hotel_services['+0+'][hotel_parking_reservation]',"");
        formData.append('hotel_services['+0+'][hotel_parking_price]',"");
        formData.append('hotel_services['+0+'][hotel_fullboard]',"");
        formData.append('hotel_services['+0+'][hotel_basic_price_fullboard]',"");
        formData.append('hotel_services['+0+'][hotel_halfboard]',"");
        formData.append('hotel_services['+0+'][hotel_basic_price_halfboard]',"");
        formData.append('hotel_services['+0+'][hotel_breakfast]',"");
        formData.append('hotel_services['+0+'][hotel_basic_price_breakfast]',"");
        formData.append('hotel_services['+0+'][hotel_languages]','english,urdu,hindi');
        formData.append('hotel_services['+0+'][hotel_amenities_media]',"");
        formData.append('hotel_services['+0+'][hotel_amenities_food]',"");
        formData.append('hotel_services['+0+'][hotel_amenities_services]',"");
        formData.append('hotel_services['+0+'][hotel_amenities_outdoor]',"");
        formData.append('hotel_services['+0+'][hotel_amenities_entertainment]',"");
        formData.append('hotel_services['+0+'][hotel_facilities]',fourStepInfo.secondStepInfo.hotel_facilities);
        //policies
        formData.append('hotel_policies_info['+0+'][hotel_cancel_free]',fourStepInfo.thirdStepInfo.hotel_cancel_free);
        formData.append('hotel_policies_info['+0+'][hotel_cancel_paid]',fourStepInfo.thirdStepInfo.hotel_cancel_paid);
        formData.append('hotel_policies_info['+0+'][hotel_accidental_bookings]',fourStepInfo.thirdStepInfo.hotel_accidental_bookings);
        formData.append('hotel_policies_info['+0+'][hotel_checkin_from]',fourStepInfo.thirdStepInfo.hotel_checkin_from);
        formData.append('hotel_policies_info['+0+'][hotel_checkin_to]',"");
        formData.append('hotel_policies_info['+0+'][hotel_checkout_from]',"");
        formData.append('hotel_policies_info['+0+'][hotel_checkout_to]',fourStepInfo.thirdStepInfo.hotel_checkout_to);
        formData.append('hotel_policies_info['+0+'][hotel_accommodate_children]',fourStepInfo.secondStepInfo.hotel_accommodate_children);
        formData.append('hotel_policies_info['+0+'][hotel_live_booking]',fourStepInfo.thirdStepInfo.hotel_live_booking);
        formData.append('hotel_policies_info['+0+'][hotel_allow_pets]',fourStepInfo.secondStepInfo.hotel_allow_pets);
        formData.append('hotel_policies_info['+0+'][hotel_additional_charge_pet]',"");
        formData.append('hotel_policies_info['+0+'][hotel_additional_charge_pet_price]',"");
        //user
        formData.append('user_id',userInfo.id);
        //apartment
        // let i=0;
           if(editHomeDetail.id)
            {
                formData.append('selected_apartment['+0+'][id]',editHomeDetail.apartments[0].id);
                
            }
            formData.append('selected_apartment['+0+'][hotel_apartment_type]',fourStepInfo.secondStepInfo.type);
            formData.append('selected_apartment['+0+'][hotel_apartment_room_name]',fourStepInfo.secondStepInfo.type);
            formData.append('selected_apartment['+0+'][hotel_apartment_custom_name]',fourStepInfo.secondStepInfo.custom_name);
            formData.append('selected_apartment['+0+'][hotel_apartment_smoking_option]',fourStepInfo.secondStepInfo.smoking);
            
            formData.append('selected_apartment['+0+'][hotel_apartment_size]',fourStepInfo.secondStepInfo.size);
            formData.append('selected_apartment['+0+'][hotel_apartment_amenities]',fourStepInfo.secondStepInfo.amenities);
            formData.append('selected_apartment['+0+'][hotel_number_guests]',fourStepInfo.secondStepInfo.number_guests);
  
            formData.append('selected_apartment['+0+'][hotel_apartment_both_smoking]',"");
            formData.append('selected_apartment['+0+'][hotel_apartment_both_non_smoking]',"");
            formData.append('selected_apartment['+0+'][hotel_apartment_bedroom_quantity]',fourStepInfo.secondStepInfo.bedroom_quantity);
            formData.append('selected_apartment['+0+'][hotel_apartment_bathroom_quantity]',fourStepInfo.secondStepInfo.bathroom_quantity);
            formData.append('selected_apartment['+0+'][hotel_apartment_livingroom_quantity]',fourStepInfo.secondStepInfo.livingroom_quantity);
            formData.append('selected_apartment['+0+'][hotel_apartment_quantity]',fourStepInfo.secondStepInfo.quantity);
            formData.append('selected_apartment['+0+'][bedroom]['+0+'][number_guests]',fourStepInfo.secondStepInfo.number_guests);
           if(userInfo.house_pics){
            let p=0;
            for(let image of userInfo.house_pics){
                formData.append('selected_apartment['+0+'][bedroom]['+0+'][images]['+p+']',image);
                p++;
            }
            } 
  
            formData.append('hotel_discounts_info['+0+'][restaurant_name]', "");
            formData.append('hotel_discounts_info['+0+'][restaurant_type]', "");
            formData.append('hotel_discounts_info['+0+'][discount_applicable]', "");
            formData.append('hotel_discounts_info['+0+'][discount_all]', "");
  
            formData.append('hotel_taxes_info['+0+'][hotel_tax_name]',"");
            formData.append('hotel_taxes_info['+0+'][hotel_tax_percentage]',"");
            formData.append('hotel_taxes_info['+0+'][hotel_tax_type]',"");
  
     
  
            formData.append('hotel_payments_info['+0+'][hotel_credit_property]',"no");
            formData.append('hotel_payments_info['+0+'][hotel_credit_cards_list]',"euro/mastercard,visa,Union Pay Credit Card");
            formData.append('hotel_payments_info['+0+'][hotel_invoice_name]',userInfo.name);
            formData.append('hotel_payments_info['+0+'][hotel_recipeient]',"yes");
            formData.append('hotel_payments_info['+0+'][hotel_recipeient_country]',"");
            formData.append('hotel_payments_info['+0+'][hotel_recipeient_city]',"");
            formData.append('hotel_payments_info['+0+'][hotel_recipeient_postal_code]',"");
            formData.append('hotel_payments_info['+0+'][hotel_recipeient_address]',"");
  
        console.log(formData);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post(`${app.homeBaseUrl}/api/add-hotel/create`, formData, config).then(res=> {
           
            message.success('Processing complete!');
            setUserInfo({...userInfo, hotel_id: res.data.id,hotel_apartment_id:res.data.room_data});
            setEditCalendarDetail({ApartmentId:res.data.room_data});
            nextStep(4);
            
        }).catch( err=> console.log(err));
    
      
    }

    function removeImg(from, name) {
        let remaining = fourStepInfo[from].filter(item => item.name != name);
        updateForm(from, remaining);
       
    }
    function updateForm(key, value) {
        setFourStepInfo({...fourStepInfo,...userInfo,...editHomeStepsDetail, [key]: value});
    }

    function uploadFiles(key, e) {
       setUserInfo({...userInfo,"house_pics": e.target.files});
        if(e.target.files) {
            
            const fileArray = Array.from(e.target.files).map(file=> readFile(file));
         
                Promise.all(fileArray).then( res => {
                    console.log(res);
                    updateForm(key, res);
                }).catch( err => console.log("error occured while reading file"));
            
        }
    }
    function readFile(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function() {
                let dataURL = reader.result;
                resolve({"name": file.name, "src": dataURL, "img": file});
            };
            reader.onerror = function() {
                reject("something went wrong while reading file");
            };
            reader.readAsDataURL(file);
        });
    }
    function renderPhotos(of, source) {
        return source.map( photo => {
            return (
                <>
                    <div className="col-4">
                        <div className="mt-3 mb-3" >
                            <img className="prevImg" src={photo.src} key={photo.name} />
                            <div id="ab" className="cross" onClick={()=> removeImg(of, photo.name)}>
                                <svg xmlns='http://www.w3.org/2000/svg' className="icon-size-24" viewBox='0 0 512 512'>
                                    <title>ionicons-v5-l</title>
                                    <line x1='368' y1='368' x2='144' y2='144' style={{fill:'none',stroke:'#000',strokeLinecap:'round',strokeLinejoin:'round',strokeWidth:'32px'}} />
                                    <line x1='368' y1='144' x2='144' y2='368' style={{fill:'none',stroke:'#000',strokeLinecap:'round',strokeLinejoin:'round',strokeWidth:'32px'}} />
                                </svg>
                            </div>
                        </div>
                    </div>
                </>   
            );
        });
    }
    useEffect(() => {
        if(editHomeDetail.id) {
            // debugger;
            setFourStepInfo({...editHomeStepsDetail,...userInfo,
                hotel_name: editHomeDetail.hotel_name ,
                hotel_desc: editHomeDetail.hotel_desc,
                fld_notes: editHomeDetail.fld_notes ? editHomeDetail.fld_notes : "",
                room_pics: editHomeDetail.apartments[0].apartment_rooms[0].room_image ? editHomeDetail.apartments[0].apartment_rooms[0].room_image.map(img=> ({"name": img.apartment_room_id, "src": img.image})) : [],
                });
        }
       
    }, [editHomeStepsDetail]);
    return (
        <div className="py-3 px-2">
          <div className="container pb-4 px-3">
            <div className="row">
              <div className="col pb-4">
                <div className="">
                  <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                    Give your listing a title (Max 10 words){" "}
                  </label>
                  <input
                    type="text"
                    onChange={ e=> updateForm("hotel_name", e.target.value) }
                    className="form-control inputBorder"
                    value={editHomeDetail.hotel_name ? editHomeDetail.hotel_name : fourStepInfo.hotel_name}
                  />
                </div>
                <div className="">
                  <label className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
                    Tell us more... {" "}
                  </label>
                  <textarea
                    type="text"
                    onChange={ e=> updateForm("hotel_desc", e.target.value) }
                    className="form-control inputBorder"
                    value={editHomeDetail.hotel_desc ? editHomeDetail.hotel_desc : fourStepInfo.hotel_desc}
                  />
                </div>

                <div className="">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
                Add Room Notes (Max 10 chars){" "}
              </label>
              <input
                maxLength={10}
                type="text"
                onChange={(e) =>
                  updateForm("fld_notes", e.target.value)
                }
                className="form-control inputBorder"
                value={fourStepInfo.fld_notes}
              />
            </div>
    
                <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
                  House Photos
                </h6>
                <input
                  id="file"
                  onChange={e=> uploadFiles("room_pics", e)}
                  type="file"
                  multiple
                  style={{ visibility: "visibel" }}
                />
                <div className="label-holder mt-2">
                  <label htmlFor="file" className="label">
                    <img src={Plus} alt="add_photos" />
                    &nbsp;&nbsp;Add photos
                  </label>
                </div>
                <div className="result">
                  <div className="row">
                  {renderPhotos("room_pics", fourStepInfo.room_pics)}
                    {/* {renderPhotos("room_pics", imgs.room_pics)} */}
                  </div>
                </div>

              
              </div>
            </div>
          </div>
          {/* </form> */}
          <Button
            className="bookN futuraN700-14 w-100"
            type="button"
            onClick={sentFormData}
          >
            Complete
          </Button>
          {/* <button onClick={sentFormData}>send</button> */}
        </div>
      );
    }
    