import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, ButtonBase } from "@material-ui/core";
import app from "../utils/appConfig";
import { getLsValue, getUser, setLsValue } from "../../utils/functions";
import PostData from "../data/postnAddData";
import ConfirmPopup from "../_newComponents/ConfirmPopup";
import MobileNav from "../_newComponents/MobileNav";
import "./style/postnAdd.scss";
import { message } from "antd";
import { CACHE__AUTH_USER } from "../data/cacheKeys";

export default function PostnAdd() {
  const user = getUser();
  const history = useHistory();

  const [phModal, setPhModal] = useState(false);
  const [phVerify, setPhVerify] = useState(false);
  const [coCode, setCoCode] = useState("");
  const [phNumber, setPhNumber] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [sending, setSending] = useState(false);
  const [verify, setVerify] = useState({
    otpOne: "",
    otpTwo: "",
    otpThree: "",
    otpFour: "",
  });

  useEffect(() => {
    if (!user.phone) setPhModal(true);
  }, [user]);

  const sendCode = () => {
    axios
      .get(
        `${app.baseUrl}/api/verifymobile?country_code=${coCode}&phone_number=${phNumber}&user_id=${user.id}`
      )
      .then((res) => {
        console.log(">>> phone verify ->", res);
        setPhVerify(true);
        setSending(false);
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
        setSending(false);
      });
  };

  const verifyCode = () => {
    const data = new FormData();

    data.append("user_id", user.id);
    data.append(
      "code",
      `${verify.otpOne}${verify.otpTwo}${verify.otpThree}${verify.otpFour}`
    );

    axios
      .post(`${app.baseUrl}/api/code_verify`, data)
      .then((res) => {
        console.log("--> phone verify ->", res);

        if (res.data.status) {
          const profileData = new FormData();

          profileData.append("user_id", user.id);
          profileData.append("country_code", coCode);
          profileData.append("fld_number", phNumber);

          axios
            .post(`${app.baseUrl}/api/edit-profile`, profileData)
            .then((res) => {
              const userData = getLsValue(CACHE__AUTH_USER);
    
        let appState = {
          ...userData,
                user: { ...user, phone: `+${coCode}${phNumber}` },
        };

        setLsValue(CACHE__AUTH_USER, appState);

              // setLsValue("user", {
              //   ...userData,
              //   user: { ...user, phone: `+${coCode}${phNumber}` },
              // });

              setPhModal(false);
              setPhVerify(false);
              setVerifying(false);
              console.log(">>> phone  ->", user.phone);
              message.success("Phone number successfully verified");
            })
            .catch((err) => {
              message.error("An error occurred! Please try again.");
              console.log(err);
            });
        } else {
          message.error("Verification code didn't match");
          setVerifying(false);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("An error occurred! Please try again.");
        setVerifying(false);
      });
  };

  const handleInputs = (index) => {
    const elems = document.getElementsByClassName("verify__input");

    if (index < elems.length)
      document.getElementById(`otp-${index + 1}`).focus();
    else document.getElementById("verify-btn").focus();

    return;
  };

  return (
    <div className="post-an-ad">
      {phModal && (
        <ConfirmPopup
          isModal
          isVerify
          title="Info Required"
          onCancel={() => {
            setPhModal(false);
            history.push("/");
          }}
        >
          <div className="text-left" style={{ maxWidth: 275 }}>
            <div className="d-flex align-items-center">
              <input
                type="text"
                placeholder="971"
                maxLength={4}
                className="rd-custom-input mb-3 mr-2"
                value={coCode}
                onChange={(e) => {
                  const val = e.target.value;
                  setCoCode(val);

                  if (val.length >= 3) document.getElementById("phone").focus();
                }}
                style={{ flex: "0.21", marginLeft: 2 }}
              />

              <input
                id="phone"
                type="text"
                placeholder="xxx xxx xxx"
                className="rd-custom-input mb-3 ml-1"
                maxLength={20}
                value={phNumber}
                onChange={(e) => setPhNumber(e.target.value)}
                style={{ flex: "0.79", marginRight: 2 }}
              />
            </div>

            {phVerify ? (
              <div
                className="w-100 text-right px-1"
                style={{ marginTop: -12, marginBottom: 6 }}
              >
                <p
                  style={{ fontSize: 14 }}
                  className="text-darkBlue"
                  onClick={() => sendCode()}
                >
                  Resend Code
                </p>
              </div>
            ) : (
              <Button
                disabled={sending}
                className="bookN-s mb-4 w-100"
                onClick={() => {
                  setSending(true);
                  sendCode();
                }}
              >
                {sending ? "Sending" : "Send Code"}
              </Button>
            )}

            {phVerify && (
              <>
                <div className="verify__inputs" style={{ marginBottom: 10 }}>
                  <input
                    id="otp-1"
                    value={verify.otpOne}
                    maxLength="1"
                    onChange={(e) => {
                      handleInputs(1);
                      setVerify({ ...verify, otpOne: e.target.value });
                    }}
                    className="verify__input"
                  />

                  <input
                    id="otp-2"
                    value={verify.otpTwo}
                    maxLength="1"
                    onChange={(e) => {
                      handleInputs(2);
                      setVerify({ ...verify, otpTwo: e.target.value });
                    }}
                    className="verify__input"
                  />

                  <input
                    id="otp-3"
                    value={verify.otpThree}
                    maxLength="1"
                    onChange={(e) => {
                      handleInputs(3);
                      setVerify({ ...verify, otpThree: e.target.value });
                    }}
                    className="verify__input"
                  />

                  <input
                    id="otp-4"
                    value={verify.otpFour}
                    maxLength="1"
                    onChange={(e) => {
                      handleInputs(4);
                      setVerify({ ...verify, otpFour: e.target.value });
                    }}
                    className="verify__input"
                  />
                </div>

                <Button
                  id="verify-btn"
                  disabled={verifying}
                  className="bookN-s w-100 mb-4"
                  onClick={() => {
                    setVerifying(true);
                    verifyCode();
                  }}
                >
                  {verifying ? "Verifying" : "Verify"}
                </Button>
              </>
            )}

            <p style={{ color: "rgba(0, 0, 0, 0.4)", fontSize: 14 }}>
              * Please enter your phone number with your country code and verify
              it before hosting with us
            </p>
          </div>
        </ConfirmPopup>
      )}

      <div className="layoutBody">
        <div className="container-fluid">
          <div className="montserratN900-22">
            <p>What are you listing?</p>
          </div>

          <div className="futuraBkBtN-14 text-gray mb-3">
            <p>Select category</p>
          </div>

          {PostData.map((item, index) => (
            <div className={`card card-${index} mb-4`} key={index}>
              <ButtonBase>
                <div className="card-body">
                  <Link to={item.linkTo}>
                    <div class="row">
                      <div class="col-6">
                        <div class="side-image">
                          <img src={item.icon} alt={item.name} />
                        </div>
                      </div>

                      <div class="col-6">
                        <div className="post-card-vm text-black">
                          <p class="futuraN700-17 m-0">{item.name}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </ButtonBase>
            </div>
          ))}
        </div>
      </div>

      <MobileNav />
    </div>
  );
}
