import React, { useEffect, useState } from "react";
import { AutoComplete, message } from "antd";
import { Button } from "@material-ui/core";

export default function StepTwo({ nextStep, to }) {
  const [stepTwoInfo, setStepTwoInfo] = useState({
    title: "",
    parking_bay_value: "",
    parking_type: "",
  });
  function updateSecondForm(property, value) {
    setStepTwoInfo({ ...stepTwoInfo, [property]: value });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function sentFormData() {
    let parkingAdData = JSON.parse(localStorage.getItem("_RMDParkingAd"));

    parkingAdData.title = stepTwoInfo.title;
    parkingAdData.parking_bay_value = stepTwoInfo.parking_bay_value;
    parkingAdData.parking_type = stepTwoInfo.parking_type;
    if (
      stepTwoInfo.title &&
      stepTwoInfo.parking_bay_value &&
      stepTwoInfo.parking_type != ""
    ) {
      message.success("Step two complete!");
      localStorage.setItem("_RMDParkingAd", JSON.stringify(parkingAdData));
      nextStep(2);
    } else {
      message.error("Please fill the values");
    }
  }

  return (
    <div className="px-2 py-3">
      <div className="container pb-4 px-3">
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Type title{" "}
          </label>
          <input
            type="text"
            onChange={(e) => updateSecondForm("title", e.target.value)}
            className="form-control inputBorder"
            value={stepTwoInfo.title}
          />
        </div>
        <br />
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose parking level
          </label>
          <AutoComplete
            style={{ width: "100%" }}
            placeholder={"select floor"}
            options={[
              { value: "M1" },
              { value: "M2" },
              { value: "M3" },
              { value: "M4" },
              { value: "G" },
              { value: "B1" },
              { value: "B2" },
              { value: "B3" },
              { value: "B4" },
              { value: "A" },
            ]}
            onChange={(value) => updateSecondForm("parking_bay_value", value)}
          />
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Parking Type{" "}
          </label>
          <div className="custom-control custom-switch mt-3">
            <input
              type="radio"
              name="parkingType"
              className="custom-control-input"
              id="outDoor"
              onChange={() => updateSecondForm("parking_type", 1)}
            />
            <label
              className="custom-control-label futuraBkBtN-14"
              for="outDoor"
            >
              &nbsp;&nbsp;Out-Door
            </label>
          </div>
          <div className="custom-control custom-switch">
            <input
              type="radio"
              name="parkingType"
              className="custom-control-input"
              id="covered"
              onChange={() => updateSecondForm("parking_type", 0)}
            />
            <label
              className="custom-control-label futuraBkBtN-14"
              for="covered"
            >
              &nbsp;&nbsp;Covered
            </label>
          </div>
        </div>
      </div>
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Continue
      </Button>
    </div>
  );
}
