import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import moment from "moment";
import { DatePicker, message, Select, Upload } from "antd";
import { Button } from "@material-ui/core";
import { AppContext } from "../AppContext";
import { getLsValue, getUser, setLsValue } from "../../utils/functions";
import { CACHE__AUTH_USER } from "../data/cacheKeys";
import app from "../utils/appConfig";
import ManIcon from "../icons/man.svg";
import WomanIcon from "../icons/woman.svg";
import BackIcon from "../icons/back.svg";
import AddIcon from "../icons/add-circle.svg";
// import CrossIcon from "../icons/cross.svg";
import "react-phone-input-2/lib/style.css";
import "./style/profileEdit.scss";

const { Option } = Select;
const user = getUser();
const ProfileEdit = () => {
  let regExp = {
    password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/,
  };

  const history = useHistory();
  const { userInfo } = useContext(AppContext);

  const [allLanguages, setAllLanguages] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [countryList, setCountryList] = useState([]);

  const [editUser, setEditUser] = useState({
    user_id: "",
    username: "",
    email: "",
    fld_number: 0,
    country_code: 0,
    languages: [],
    fld_profile_pic: [],
    dob: "",
    sex: "",
    password: "",
    password_confirm: "",
    nationality: "",
  });
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [passwordVal, setPasswordVal] = useState("");
  const [repeatpasswordVal, setRepeatPasswordVal] = useState("");

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/getallcountry`)
      .then((res) => setCountryList(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/get-Language`)
      .then((res) => setAllLanguages(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (userInfo.id) {
      axios
        .get(`${app.baseUrl}/api/get-profile?user_id=${userInfo.id}`)
        .then((res) => {
          let data = res.data.data;
          let lng = [];

          if (data.languages) {
            data.languages = data.languages.split(",");
            lng = data.languages;
          }

          setEditUser({
            // password: "",
            // password_confirm: "",
            country_code: data.country_code,
            nationality: data.fld_country_id,
            user_id: data.id,
            username: data.fld_name,
            email: data.email,
            fld_number: data.fld_number,
            //mo_number: `+${data.country_code}${data.fld_number}`,
            languages: lng,
            fld_profile_pic: [
              {
                uid: "-1",
                name: "image.png",
                status: "done",
                url: data.fld_profile_pic,
              },
            ],
            dob: `${
              data.dob_day
                ? data.dob_month + "/" + data.dob_day + "/" + data.dob_year
                : ""
            }`,
            sex: `${
              data.fld_sex ? (data.fld_sex === "M" ? "male" : "female") : ""
            }`,
          });

          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [userInfo]);

  const onChange = ({ fileList: newFileList }) => {
    setEditUser({ ...editUser, fld_profile_pic: newFileList });
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src)
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);

    imgWindow.document.write(image.outerHTML);
  };

  function editUserData() {
    let data = new FormData();

    for (let key in editUser) {
      if (key === "languages")
        editUser[key].map((lng) => data.append(key + "[]", lng));
      else if (key === "fld_profile_pic")
        data.append(key, editUser.fld_profile_pic[0]?.originFileObj);
      else data.append(key, editUser[key]);
    }

    axios
      .post(`${app.baseUrl}/api/edit-profile`, data)
      .then((res) => message.success("Profile updated successfully!"))
      .catch((err) => console.log(err));
  }

  const handleClick = () => {
    if (passwordVal.match(regExp.password)) {
      if (passwordVal == repeatpasswordVal) {
        let myUpdatePassword = repeatpasswordVal;
        setEditUser({ ...editUser, password_confirm: myUpdatePassword });
      } else {
        setIsPasswordMatch(true);

        setTimeout(() => {
          setIsPasswordMatch(false);
        }, 5000);
      }
    } else {
      setIsPasswordInvalid(true);
      setTimeout(() => {
        setIsPasswordInvalid(false);
      }, 5000);
    }
  };

  const updateProfile = () => {
    let data = new FormData();

    if (passwordVal == "") {
      for (let key in editUser) {
        if (key === "languages")
          editUser[key].map((lng) => data.append(key + "[]", lng));
        else if (key === "fld_profile_pic")
          data.append(key, editUser.fld_profile_pic[0]?.originFileObj);
        else data.append(key, editUser[key]);
      }

      axios
        .post(`${app.baseUrl}/api/edit-profile`, data)
        .then((res) => {
          message.success("Profile updated successfully!");

          const userData = getLsValue(CACHE__AUTH_USER);
     
          let appState = {
            ...userData,
                user: { ...user, phone: editUser['country_code'] && editUser['fld_number']
                  ? `+${editUser['country_code']}${editUser['fld_number']}`
                  : null, },
          };

        setLsValue(CACHE__AUTH_USER, appState);

          setTimeout(() => {
            window.location.href = "/profile";
          }, 10);
        })
        .catch((err) => console.log(err));
    } else {
      if (passwordVal.match(regExp.password)) {
        if (passwordVal == repeatpasswordVal) {
          let myUpdatePassword = repeatpasswordVal;

          for (let key in editUser) {
            if (key === "languages")
              editUser[key].map((lng) => data.append(key + "[]", lng));
            else if (key === "fld_profile_pic")
              data.append(key, editUser.fld_profile_pic[0]?.originFileObj);
            else if (key === "password") {
              data.append(key, (editUser.password = myUpdatePassword));
              data.append(key, (editUser.password_confirm = myUpdatePassword));
            } else data.append(key, editUser[key]);
          }

          axios
            .post(`${app.baseUrl}/api/edit-profile`, data)
            .then((res) => {
              message.success("Profile updated successfully!");

              setTimeout(() => {
                window.location.href = "/profile";
              }, 10);
            })
            .catch((err) => console.log(err));
        } else {
          setIsPasswordMatch(true);

          setTimeout(() => {
            setIsPasswordMatch(false);
          }, 5000);
        }
      } else {
        setIsPasswordInvalid(true);

        setTimeout(() => {
          setIsPasswordInvalid(false);
        }, 5000);
      }
    }
  };

  return (
    <div className="profile-edit">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={BackIcon}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push("/profile");
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Edit profile</div>
          </div>
        </div>
      </div>

      <div className="profile-edit__image">
        <h4>Add Profile Image</h4>

        <div className="row ml-0 mr-0 pt-3">
          {/* {editUser?.fld_profile_pic?.length ? (
            <div className="col-4 pr-2">
              <div className="gallery my-3">
                <img
                  className="prevImg"
                  src={editUser.fld_profile_pic[0].url}
                  key={editUser.username}
                  alt="Profile - RoomDaddy"
                />

                <div
                  id="ab"
                  className="cross"
                >
                  <img src={CrossIcon} alt="cancel_icon" />
                </div>
              </div>
            </div>
          ) : null} */}

          <div className="col-4 my-3 pl-2 pr-2 mx-auto">
            {/* <div
              className="profile-edit__upload"
              onClick={() =>
                document.getElementById("profile-edit__file").click()
              }
            >
              <input
                type="file"
                id="profile-edit__file"
                className="profile-edit__file"
                onChange={onChange}
              />

              <div className="edit-container">
                <img src={AddIcon} alt="Upload - RoomDaddy" />
                <span>Add photo</span>
              </div>
            </div> */}

            <Upload
              action=""
              listType="picture-card"
              fileList={editUser.fld_profile_pic}
              onChange={onChange}
              onPreview={onPreview}
            >
              {editUser.fld_profile_pic.length < 1 && "+ Upload"}
            </Upload>
          </div>
        </div>
      </div>

      <div className="profile-edit__body">
        <div className="profile-edit__info">
          <label htmlFor="name">Name</label>

          <input
            type="text"
            id="name"
            placeholder="Enter your name"
            value={editUser.username}
            onChange={(e) =>
              setEditUser({ ...editUser, username: e.target.value })
            }
          />
        </div>

        <div className="profile-edit__info">
          <span>Email</span>
          <p>{editUser.email}</p>
        </div>

        <div className="profile-edit__info">
          <label htmlFor="number">Phone number</label>

          <div>
            <PhoneInput
              value={`+${editUser.country_code}${editUser.fld_number}`}
              onChange={(value, country) =>
                setEditUser({
                  ...editUser,
                  fld_number: country.dialCode ? value.slice(country.dialCode.length) : value,
                  country_code: country.dialCode,
                      // .split(editUser.country_code)
                      // .slice(1)
                      // .join(editUser.country_code) || val,
                })
              }
            />
          </div>
        </div>

        <div className="profile-edit__info">
          <label htmlFor="name">Nationality</label>

          <div>
            <Select
              showSearch
              className="nationality-input"
              placeholder="Select a person"
              optionFilterProp="children"
              value={editUser.nationality}
              onChange={(val) => setEditUser({ ...editUser, nationality: val })}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countryList.map((cty, i) => (
                <Option key={i} value={cty.nationality_code}>
                  {cty.nationality}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="profile-edit__info">
          <label htmlFor="birth">Date of birth</label>

          <div>
            <DatePicker
              id="birth"
              placeholder={"MM/DD/YYYY"}
              value={editUser?.dob && moment(editUser?.dob, "MM/DD/YYYY")}
              format="MM/DD/YYYY"
              onChange={(date, dateString) =>
                setEditUser({ ...editUser, dob: date.format("MM/DD/YYYY") })
              }
            />
          </div>
        </div>

        <div className="profile-edit__info">
          <label htmlFor="languages">Languages you speak</label>

          <div>
            <Select
              className="language-input"
              mode="multiple"
              style={{ width: "100%" }}
              value={editUser.languages}
              onChange={(val) => setEditUser({ ...editUser, languages: val })}
            >
              {allLanguages.map((lng) => (
                <Option
                  value={lng.value}
                  label={lng.label}
                  key={`lngid${lng.id}`}
                >
                  {lng.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="profile-edit__extra">
        <label className="ml-3">Gender</label>

        <div className="get_started_radio">
          <ul className="container_ul">
            <li className="mr-3 ml-3">
              <input
                type="radio"
                id="s-option"
                name="gender"
                value="male"
                checked={editUser.sex === "male" ? "checked" : false}
                onChange={() => setEditUser({ ...editUser, sex: "male" })}
              />

              <div className="check input-radio-custom">
                <img
                  className="radio_active"
                  src={ManIcon}
                  alt="Refinancing_hover"
                />
              </div>

              <label
                htmlFor="s-option"
                className="label_active font-weight-bold"
                style={{ color: "#1c1c1c" }}
              >
                Male
              </label>
            </li>

            <li>
              <input
                type="radio"
                id="f-option"
                name="gender"
                value="female"
                onChange={() => setEditUser({ ...editUser, sex: "female" })}
                checked={editUser.sex === "female" ? "checked" : false}
                
              />

              <div className="check input-radio-custom">
                <img
                  className="radio_active"
                  src={WomanIcon}
                  alt="Purchasing_hover"
                />
              </div>

              <label
                htmlFor="f-option"
                className="label_active font-weight-bold"
                style={{ color: "#1c1c1c" }}
              >
                Female
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="profile-edit__update">
        <Button className="bookN" onClick={updateProfile}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default ProfileEdit;
