const data = [
    {
        title: "HOW TO FIND A PROPERTY",
        content:   [
            {
                title: `Q1- How do I start my Property Search?`,
            },
            {
                title: `With RoomDaddy you have multiple options to choose from on monthly rental. Choose the category from Hotels, Hotel Apartments, Flatshare, Holiday Homes. Once you know what proeprty type you are looking for you can easily start your search.`,
            },
            {
                title: `Q2- Location Search?`,
            },
            {
                title: `To speed up your search process you should be using location filters to find listings. This will shortlist the available options that you can choose from.`,
            },
            {
                title: `Q3- How Do I contact the Property Manager?`,
            },
            {
                title: `RoomDaddy gives you access to either call, chat, or email the property owner. This way you can choose the most suitable option you want to get in contact with the property manager.`,
            },
            {
                title: `Q4- How do I book and pay my owner?`,
            },
            {
                title: `RoomDaddy does not take any guarantee for any direct payments between you and your landlord, unless the payment is handled by RoomDaddy. It is adviseable to book with our payment protection to secure your payments. Where we will hold the payment till 24Hrs of your check-in date. If you dont raise any complaint the money will be released to the owenr.`,
            },
            {
                title: `Q5- What if the property is not the same which I have booked?`,
            },
            {
                title: `We advise you to book verified listings since they are pre-checked by our team as authentic as per pictures. However you can raise a claim for any booking within 24hours of your check-in till then we are holding the deposit payment on your behalf. You will be refunded your money either through Wallet payment or returned to the same source of payment.`,
            },
            {
                title: `Q6- Are there any RoomDaddy managed properties?`,
            },
            {
                title: `RoomDaddy is not a property management company thus all listings on our website are independlty managed by third party owners. We have no listings of our own.`,
            },
            {
                title: `Q7- Is there any RoomDaddy Fee charged to the Tenant?`,
            },
            {
                title: `As long as you are dealing directly with the property manager there is no fee from our end. However you can seek our assistance to find your next home and we have a fee for our services which is AED 200/person.`,
            },
            {
                title: `Q8- Are listing owners verified?`,
            },
            {
                title: `For Flat-share listngs we verify owners by their Phone number and e-Mail address. For Short Team Rental Entire Homes we have a mix of business verfied owners as well as independenet operators, while all Hotel properties are verified through their business trade license.`,
            },
        ]
    },
    {
        title: "BOOKING AND PAYMENTS",
        content: [
            {
                title: `Q1- Is RoomDaddy a Booking Platform?`,
            },
            {
                title: `Yes. We help landlord and tenants to book and manage their bookings on our platforms. And we support deposit and rent payments through our portal.`,
            },
            {
                title: `Q2- What are payment methods on RoomDaddy?`,
            },
            {
                title: `RoomDaddy supports Credit, Debit card payments linked through Visa and Master Card. We also support bank to bank transfers through Dapi, a digital payment platform for cheap and quick transfers.`,
            },
            {
                title: `Q3- Is there any cost of payment to the Tenant or the Landlord?`,
            },
            {
                title: `On credit card payments 3% payment chage is charged either to the owner or the tenant as per their agreement. For Bank to Bank transfer we charge AED 10/ transaction.`,
            },
            
        ]
    },
    {
        title: "PAYMENT",
        content: [
            {
                title: `Q1- How do I collect Payment as an owner?`,
            },
            {
                title: `Any booking invoice paid to you by your guest will reflect in your Wallet. You can withdraw this amount from your wallet by sending a direct request from your login.`,
            },
            {
                title: `Q2- What does available balance and ledger balance in Wallet means?`,
            },
            {
                title: `In your wallet your ledger balance reflects all the authenticated payments however available balance is the cash settled amount of payments. To explain it further for example, credit card payments take 3 days to settle with our bank, however the payment from the client has been successfully authroized from their issuing bank. This amount will reflect in your Ledger Balance however will be added in your available balance after 3 days.`,
            },
            {
                title: `Q3- What Balance can I withdraw?`,
            },
            {
                title: `You can withdraw only the amount upto your available balance, once you send the request for transfer it will be transferred to your account within 1 Working Day.`,
            },
            {
                title: `Q4- Can I make Wallet to Wallet Payments?`,
            },
            {
                title: `Yes!! We support Wallet to Wallet payments.`,
            },
            
        ]
    },
    {
        title: "RATING",
        content: [
            {
                title: `Q1- How do I Rate my experience?`,
            },
            {
                title: `Upon each payment we request you to review and rate your experience with the property. You can only leave your comment at the time of check-out.`,
            },
            {
                title: `Q2- As an Owner can i Rate my Guest?`,
            },
            {
                title: `You can review your guest upon check-out as well. However we review the payment history for guests and rate them through our automated cirteria.`,
            },
           
        ]
    },
    
    {
        title: "CONTACT US",
        content: [
            {
                title: `In case of any queries and questions, please contact us at info@roomdaddy.com `,
            },
        ]
    },
]
export default data;