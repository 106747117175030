export const CACHE__AUTH_USER = "_RD_User";
export const CACHE__COLIVINGS = "_RD_CoLivings";
export const CACHE__CO_FILTERS = "_RD_CoFilters";
export const CACHE__CO_PAGENUM = "_RD_CoPageNumber";
export const CACHE__CO_API = "_RD_CoApi";
export const CACHE__H_ROOMS = "_RD_HoRooms";
export const CACHE__H_ROOMS_FILTERS = "_RD_HoRoomsFilters";
export const CACHE__H_ROOMS_F_PRICE = "_RD_HoRoomsFiltersPrice";
export const CACHE__H_ROOMS_CART = "_RD_HoRoomsCart";
export const CACHE__H_APTS = "_RD_HoApts";
export const CACHE__H_APTS_FILTERS = "_RD_HoAptsFilters";
export const CACHE__H_APTS_F_PRICE = "_RD_HoAptsFiltersPrice";
export const CACHE__H_APTS_CART = "_RD_HoAptsCart";
export const CACHE__HOMES = "_RD_Homes";
export const CACHE__HOMES_FILTERS = "_RD_HomesFilters";
export const CACHE__HOMES_F_PRICE = "_RD_HomesFiltersPrice";
export const CACHE__HOMES_CART = "_RD_HomesCart";
export const CACHE__PWD_RESET_MAIL = "_RD_PwdResetEmail";
