import FacebookIcon from "../icons/logo-facebook.svg";
import InstagramIcon from "../icons/logo-instagram.svg";
import TwitterIcon from "../icons/logo-twitter.svg";
import YouTubeIcon from "../icons/logo-youtube.svg";

const socials = [
  {
    title: "Facebook - RoomDaddy",
    icon: FacebookIcon,
    link: "https://www.facebook.com/roomdaddy1",
  },
  {
    title: "Instagram - RoomDaddy",
    icon: InstagramIcon,
    link: "https://www.instagram.com/_roomdaddy",
  },
  {
    title: "Twitter - RoomDaddy",
    icon: TwitterIcon,
    link: "https://twitter.com/_roomdaddy",
  },
  {
    title: "Youtube - RoomDaddy",
    icon: YouTubeIcon,
    link: "https://www.youtube.com/channel/UCvZIqE-Ixx3KZJtCyzGTXTQ",
  },
];

export default socials;
