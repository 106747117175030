import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import ContentLoader from "react-content-loader";
import { JsonLd } from "react-schemaorg";
import axios from "axios";
import moment from "moment";
import { AutoComplete, Select, DatePicker, message } from "antd";
import { Carousel, WingBlank } from "antd-mobile";
import { Button } from "@material-ui/core";
import {
  CACHE__HOMES,
  CACHE__HOMES_FILTERS,
  CACHE__HOMES_F_PRICE,
} from "../data/cacheKeys";
import {
  HotelAmenities,
  HotelFacilities,
  HotelAddOn,
  HotelRating,
  HotelSortby,
} from "../_newComponents/FilterButtons/filtersData";
import app from "../utils/appConfig";
import { getLsValue, separateNumbers, setLsValue } from "../../utils/functions";
import { AppContext } from "../AppContext";
import Modal from "../_newComponents/Modal";
import HotelFilterButtons from "../_newComponents/FilterButtons/hotelsRFiltersBtn";
import PageHeader from "../_newComponents/pageHeader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import FixedHorizontalAd from "../_newComponents/GoogleAds/fixedhorizontal";
import MyLeaderBoardAd from "../_newComponents/GoogleAds";
import CrossIcon from "../icons/cross_dark.svg";
import SearchIcon from "../icons/search.svg";
import Plus from "../images/plus.png";
import Minus from "../images/minus.png";
import SearchListing from "../_newComponents/SearchListing";
import ConfirmPopup from "../_newComponents/ConfirmPopup";
import MobileNav from "../_newComponents/MobileNav";
import UserIcon from "../icons/guestIcon.svg";
import NoImage from "../images/no-image.png";
import StarActiveIcon from "../icons/star_active.svg";
import Cross from "../icons/cross.svg";
import "./style/entireHome.scss";

const dateFormat = "YYYY-MM-DD";

const EntireHomePage = () => {
  const { selectedHotel, setSelectedHotel, userInfo, setUserInfo } =
    useContext(AppContext);
    const { country, city, location } = useParams();
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState([]);
    const [seoSelectedLocBuil, setSeoSelectedLocBuil] = useState([]);
    const [selectedCity, setSelectedCity] = useState("All Cities");
    const [locAndCityid, setLocAndCityId] = useState({
      city_id: city ? city : 48975,
      data: "",
    });
  const [loading, setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState(true);
  const [guestPopUp, setGuestPopUp] = useState(false);
  const [guestNumbers, setGuestNumbers] = useState(userInfo.adult);
  const [popup, setPopup] = useState(false);
  const [budgetPopUp, setBudgetPopUp] = useState(false);
  const [amenitiesPopUp, setAmenitiesPopUp] = useState(false);
  const [facilitiesPopUp, setFacilitiesPopUp] = useState(false);
  const [addonPopUp, setAddonPopUp] = useState(false);
  const [ratingPopUp, setRatingPopUp] = useState(false);
  const [sortPopUp, setSortPopUp] = useState(false);
  const [value, setValue] = useState([
    moment(userInfo.checkIn, dateFormat),
    moment(userInfo.checkOut, dateFormat),
  ]);
  const [dates, setDates] = useState([
    moment(userInfo.checkIn, dateFormat),
    moment(userInfo.checkOut, dateFormat),
  ]);
  const [hackValue, setHackValue] = useState([
    moment(userInfo.checkIn, dateFormat),
    moment(userInfo.checkOut, dateFormat),
  ]);

  const [first, setFirst] = useState(true);
  const [homes, setHomes] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [val, setVal] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [options, setOptions] = useState([]);
  const [price, setPrice] = useState({ min: 0, max: 0 });
  const [isFilter, setIsFilter] = useState(false);
  const [roomQuantity, setRoomQuantity] = useState(1);
  const [filters, setFilters] = useState([
    ["hotels.admin_status", 1],
    ["hotels.status", 1],
    ["hotels.hotel_type", "apartment"],
  ]);
  
  if(location !== undefined ){
    filters.push([`hotels.address_line_two`,"like", `%${location.replace('-', ' ')}%`],)
  }

  const [tempFilters, setTempFilters] = useState([
    ["hotels.admin_status", 1],
    ["hotels.status", 1],
    ["hotels.hotel_type", "apartment"],
  ]);

  const onOpen = (key) => (e) => {
    e.preventDefault();

    if (key === "Guest") setGuestPopUp(true);
    if (key === "Budget") setBudgetPopUp(true);
    if (key === "Amenities") setAmenitiesPopUp(true);
    if (key === "Facilities") setFacilitiesPopUp(true);
    if (key === "Add on") setAddonPopUp(true);
    if (key === "Rating") setRatingPopUp(true);
    if (key === "Sort") setSortPopUp(true);
  };

  const onClose = (key) => (e) => {
    if (key === "Guest") setGuestPopUp(false);
    if (key === "Budget") setBudgetPopUp(false);
    if (key === "Amenities") setAmenitiesPopUp(false);
    if (key === "Facilities") setFacilitiesPopUp(false);
    if (key === "Add on") setAddonPopUp(false);
    if (key === "Rating") setRatingPopUp(false);
    if (key === "Sort") setSortPopUp(false);
  };

  const disabledCheckInDate = (current) =>
    current && current < moment().subtract(1, "d");

  const disabledCheckOutDate = (current) => {
    const checkIn = dates[0];
    const isAfter = checkIn?.isAfter(moment());
    const diffInDays = isAfter ? checkIn?.diff(moment(), "days") : 0;

    return current && current < moment().add(30 + diffInDays, "days");
  };

  const { Option } = Select;
  
  const cities = [
    { label: "Abu Dhabi", value: "abu-dhabi", id: 48973 },
    { label: "Ajman", value: "ajman", id: 48974 },
    { label: "Dubai", value: "dubai", id: 48975 },
    { label: "Ras Al Khaimah", value: "ras-al-khaimah", id: 48976 },
    { label: "Sharjah", value: "sharjah", id: 48977 },
    { label: "Umm Al Quwain", value: "umm-al-quwain", id: 48979 },
    { label: "Fujairah", value: "fujairah", id: 48980 },
  ];

  const removeList = (e) => {
    let locationArray = [...selectedLocation];
    let locArrayIndex = locationArray.indexOf(
      e.target.parentElement.textContent
    );
    let buildingArray = [...selectedBuilding];
    let builArrayIndex = buildingArray.indexOf(
      e.target.parentElement.textContent
    );
    // let seoLocBuildArray = [...seoSelectedLocBuil]
    // let seoIndex = seoLocBuildArray.indexOf(e.target.parentElement.textContent)

    if (locArrayIndex !== -1) {
      locationArray.splice(locArrayIndex, 1);
      setSelectedLocation([...locationArray]);
    }
    if (builArrayIndex !== -1) {
      buildingArray.splice(builArrayIndex, 1);
      setSelectedBuilding([...buildingArray]);
    }

    // setTimeout(() => {
    //   if (selectedLocation.length && selectedBuilding.length) {
    //     applyFilters();
    //     document.getElementById("filter-it-btn").click();
    //   } else {
    //     reset();
    //   }
    // }, 100);

    // if (seoIndex !== -1) {
    //     seoLocBuildArray.splice(seoIndex, 1);
    //     setSeoSelectedLocBuil([...seoSelectedLocBuil]);
    // }
  };

  const separateNumbers = (num) =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;

  console.log(selectedCity, locAndCityid);
console.log(country, city, location);



  const getAptBasePrice = (hotel) => {
    let tax, total;

    if (hotel?.date_check_status === 0) {
      if (hotel?.room_rent_type?.room_rent_tax === "no") {
        tax = (hotel?.hotel_tax / 100) * hotel?.hotel_room_base_price;
        tax = tax + hotel?.hotel_room_base_price + hotel?.hotel_tax_amount;
      } else {
        tax = hotel?.hotel_room_base_price;
      }

      total = Math.ceil(roomQuantity * tax);

      return total !== 0
        ? `${hotel?.hotel_info?.hotel_currency} ${separateNumbers(total)}`
        : "Inquire Now";
    } else {
      return "Inquire Now";
    }
  };

  const guestIncrement = () => {
    if (guestNumbers == 6) {
      return;
    } else {
      setGuestNumbers((prev) => prev + 1);
    }
  };

  const guestDecrement = () => {
    if (guestNumbers == 1) {
      return;
    } else {
      setGuestNumbers((prev) => prev - 1);
    }
  };


  const fetchData = () => {
    const prevItems = getLsValue(CACHE__HOMES);

    if (prevItems && prevItems.length) setHomes(prevItems);

    axios
      .post(`${app.homeBaseUrl}/api/getAllHomes`, {
        search_latitude: userInfo.lat,
        search_longitude: userInfo.lng,
        check_in: userInfo.checkIn,
        check_out: userInfo.checkOut,
        diff_in_days: userInfo.diffInDays,
        min_price: price.min,
        max_price: price.max,
        currency: "AED",
        where: filters,
      })
      .then((res) => {
        const data =
          Object.getPrototypeOf(res.data.data) === Object.prototype
            ? Object.values(res.data.data).reverse()
            : res.data.data.reverse();

        data.forEach((item) => {
          item.price = getAptBasePrice(item);
        });

        if (
          !prevItems ||
          !prevItems.length ||
          JSON.stringify(data[0]) !== JSON.stringify(prevItems[0])
        ) {
          //console.log('API Response1',data)
          setHomes(data);
          setLsValue(CACHE__HOMES, data);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    
    if (
      location !== undefined 
    ) {
      let splitLocation = location.split("-");
      let joinResult = splitLocation.join(" ");

      if (seoSelectedLocBuil.indexOf(joinResult) === -1)
        setSeoSelectedLocBuil([...seoSelectedLocBuil, joinResult]);
    }

//console.log('Im called first time')
    fetchData();
  }, [isFilter, filters, min, max, userInfo]);

  function applyFilters() {
    if (val) {
      setSelectedCity(val);
      setLocAndCityId({ ...locAndCityid, city_id: val });
    }

    setIsFilter(true);
    setLoading(true);

    let selectedFilter = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let where = [
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
      //["tbl_hotel_room.number_guests_room", guestNumbers],
      ['hotels.address_line_two', 'like', `%${selectedLocation}%`],
    ];
    for (let s of selectedFilter) {
      let childof = s.getAttribute("childof");
      if (childof === "facilities_array") {
        where.push([
          `facilities_and_services.${childof}`,
          "like",
          `%${s.name}%`,
        ]);
      } else if (
        childof === "internet" ||
        childof === "parking" ||
        childof === "breakfast"
      ) {
        where.push([`facilities_and_services.${childof}`, s.name]);
      } else if (
        childof === "hotel_amenities_food" ||
        childof === "hotel_amenities_entertainment" ||
        childof === "hotel_amenities_services"
      ) {
        where.push([
          `facilities_and_services.${childof}`,
          "like",
          `%${s.name}%`,
        ]);
      } else if (childof === "room_amenities") {
        where.push([`tbl_hotel_room.${childof}`, "like", `%${s.name}%`]);
      }
    }

    setFilters(where);
    setMin(price.min);
    setMax(price.max);
    setLsValue(CACHE__HOMES_FILTERS, filters);
    setLsValue(CACHE__HOMES_F_PRICE, price);
  }

  // const applyFilters = () => {
  //   setLoading(true);
  //   setIsFilter(true);
  //   setFilters(tempFilters);
  //   setMin(price.min);
  //   setMax(price.max);
  //   setLsValue(CACHE__HOMES_FILTERS, filters);
  //   setLsValue(CACHE__HOMES_F_PRICE, price);
  // };

  const change = (e, type, key, value) => {
    const newFilters = { ...tempFilters };

    if (type === "checkbox") newFilters[key] = value || e.target.checked;
    else if (type === "radio") newFilters[key] = value;
    else if (type === "number") newFilters[key] = e.target.value;

    setTempFilters(newFilters);
    // setLsValue(CACHE__HOMES_FILTERS, newFilters);
  };

  const onSelect = (data, e) => {
    const name = e.value;
    const type = e.type;

    if (type === "location")
      if (!selectedLocation.includes(name))
        setSelectedLocation([...selectedLocation, name]);

    if (type === "building")
      if (!selectedBuilding.includes(name))
        setSelectedBuilding([...selectedBuilding, name]);

    console.log("-->", data);
    // applyFilters();
  };

  useEffect(() => {
    const elem = document.getElementById("search-autocomplete");

    elem.onblur = () => {
      if (selectedBuilding.length || selectedLocation.length) {
        setVal("");

        // elem.value = "";
        // elem.placeholder = "Find a place to stay";
      }
    };
  }, [selectedBuilding, selectedLocation]);

  const onSearch = (key, value) => {
    setLocAndCityId({ ...locAndCityid, [key]: value });

    if(selectedLocation.length === 0)
    {
    if (value.length > 2) {
      let data = new FormData();
      for (let i in locAndCityid) data.append(i, locAndCityid[i]);

      axios
        .post(`${app.baseUrl}/api/location_filter`, data)
        .then((res) => {
          console.log(res);

          for (let i = 0; i < options.length; i++)
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j].value === options[i].value)
                options.splice(i, 1);
              if (res.data.data[j].id === options[i].id) options.splice(i, 1);
            }

          setOptions([...options, ...res.data.data]);
        })
        .catch((err) => console.log(err));
    }
  }
  };



  const reset = () => {
    setIsFilter(false);

    setSelectedCity(48975);
    setLocAndCityId({
      city_id: 48975,
      data: "",
    });
    setSelectedLocation([]);
    setSelectedBuilding([]);

    setTempFilters([
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
    ]);
    setFilters([
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
    ]);

    const elem = document.getElementsByClassName("pac-target-input")[0];
    elem.value = "";

    setMin(0);
    setMax(0);
    setPrice({ min: 0, max: 0 });

    setLsValue(CACHE__HOMES_F_PRICE, { min: 0, max: 0 });
    setLsValue(CACHE__HOMES_FILTERS, [
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
    ]);
  };
  const  fetchAllHome=(pageNumber)=>{
setPageNumber(pageNumber)
axios
.post(`${app.homeBaseUrl}/api/getAllHomes?page=${pageNumber}`, {
  search_latitude: userInfo.lat,
  search_longitude: userInfo.lng,
  check_in: userInfo.checkIn,
  check_out: userInfo.checkOut,
  diff_in_days: userInfo.diffInDays,
  min_price: price.min,
  max_price: price.max,
  currency: "AED",
  where: filters,
}).then((data)=>{
  console.log('homes', homes)
  console.log('API Response',data)
  setLoading(false);
  const data1 =
  Object.getPrototypeOf(data.data.data) === Object.prototype
    ? Object.values(data.data.data).reverse()
    : data.data.data.reverse();

data1.forEach((item) => {
  item.price = getAptBasePrice(item);
});

  setHomes((prev) => [...prev, ...data1]);
  
})
}
  return (
    <>
    {/* if SEO url not have 4 prams */}
    {location !== undefined ? (
        <MetaTags>
          <title>
          {`Find Your Perfect Home in ${seoSelectedLocBuil}, ${city} with RoomDaddy's Monthly Apartment for Rent.`}
          </title>
          <meta
            name="description"
            content={`Looking for a monthly apartment for rent in ${seoSelectedLocBuil}, ${city}? Discover the best options with Roomdaddy, offering a wide range of high-quality, well-maintained properties in prime locations throughout the city.`}
          />
        </MetaTags>
      ) : (
        [
          
            <MetaTags>
              <title>
              Find Your Perfect Home in UAE with RoomDaddy's Monthly Apartment for Rent.
              </title>
              <meta
                name="description"
                content="Looking for a monthly apartment for rent in UAE? Discover the best options with Roomdaddy, offering a wide range of high-quality, well-maintained properties in prime locations throughout the city."
              />
            </MetaTags>
         
        ]
      )}

    <div
      className="entire-home"
      style={{ paddingBottom: "75px", paddingTop: "10px" }}
    >
      {popup && (
        <ConfirmPopup
          title="Reset"
          onConfirm={() => {
            reset();
            setPopup(false);
          }}
          onCancel={() => setPopup(false)}
        >
          <p className="px-2" style={{ fontWeight: "400" }}>
            Are you sure you want to reset all filters?
          </p>
        </ConfirmPopup>
      )}

      <PageHeader homeLink="/" title="Entire Homes" isCustom />

      <div className="entire-home__body">
        {/* <SearchListing isButton search={fetchData} /> */}
        <h1
      style={{
        fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 22,
      lineHeight: "145.9%",
      color: "rgb(0 0 0 / 7%)",
      }}

      ><b>Entire Home for Rent in UAE</b></h1>
      <FixedHorizontalAd />
        <div className={`search-listing p-0`}>

<form
     className="search-listing__container mr-2"
     style={{ flex: 1 }}
     onSubmit={(e) => {
       e.preventDefault();
       //applyFilters();
     }}
   >
     <img src={SearchIcon} alt="Search" />

     <AutoComplete
       style={{
         background: "inherit",
         borderRadius: "999px",
         boxShadow: "none",
       }}
       id="search-autocomplete"
       className="form-control search"
       // type="text"
       // value={terms}
       // onChange={(e) => setTerm(e.target.value)}
       value={val}
       placeholder="Find a place to stay"
       allowClear={true}
       options={options}
       
       onSelect={async (data, e) => {
         await onSelect(data, e);
         setVal("");

         setTimeout(() => {
           //applyFilters();
           document.getElementById("filter-it-btn").click();
         }, 100);

         // const elem = document.getElementById("search-autocomplete");

         // elem.value = "";
         // elem.placeholder = "Find a place to stay";
       }}
       onSearch={(value) => {
         onSearch("data", value);
         setVal(value);
       }}
       filterOption={(inputValue, option) =>
         option.value
           .toUpperCase()
           .indexOf(inputValue.toUpperCase()) !== -1
       }
       // options={options}
       // onSelect={onSelect}
       // onSearch={(value) => {
       //   onSearch("data", value);
       //   setVal(value);
       // }}
       // filterOption={(inputValue, option) =>
       //   option.value
       //     .toUpperCase()
       //     .indexOf(inputValue.toUpperCase()) !== -1
       // }
     />

     {/* <button type="submit" className="search-listing__action">
       <img src={RightIcon} alt="Right Arrow - RoomDaddy" />
     </button> */}
   </form>

   <div className="search-listing__actions">
     <Select
       style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
       value={selectedCity}
       onChange={(value) => {
         setSelectedCity(value);
         setLocAndCityId({ ...locAndCityid, city_id: value });

         // setTimeout(() => applyFilters(val), 100);
       }}
     >
       {cities.map((city, i) => (
         <Option key={i} value={city.id}>
           {city.label}
         </Option>
       ))}
     </Select>

     <Button
       id="filter-it-btn"
       onClick={() => applyFilters()}
       className="bookN-s d-none"
       style={{ fontWeight: "bold" }}
     >
       Search
     </Button>
   </div>


      </div>
      </div>

      {!seoSelectedLocBuil.length &&
          !selectedBuilding.length &&
          !selectedLocation.length ? null : (
            <div className="px-3 loc-info">
              {seoSelectedLocBuil.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">You searched for</p>
                  <ul className="text-secondary small lists pl-0">
                    {seoSelectedLocBuil.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              {selectedLocation.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">Locations</p>
                  <ul className="text-secondary small lists pl-0">
                    {selectedLocation.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              {selectedBuilding.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">Buildings</p>
                  <ul className="text-secondary small lists pl-0">
                    {selectedBuilding.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          )}

      <div className="entire-home__filters">
        <div className="entire-home__date d-flex justify-content-between align-items-center mb-2">
          <DatePicker
            format={dateFormat}
            disabledDate={disabledCheckInDate}
            value={dates[0] || moment(userInfo.checkIn, dateFormat)}
            onChange={(date, dateStr) => {
              setUserInfo({
                ...userInfo,
                checkIn: dateStr,
                checkOut: moment(dateStr, dateFormat).add(30, "days").format(dateFormat),
              });

              setDates([date, moment(dateStr, dateFormat).add(30, "days")]);
            }}
          />

          <DatePicker
            format={dateFormat}
            disabledDate={disabledCheckOutDate}
            value={dates[1] || moment(userInfo.checkOut, dateFormat)}
            onChange={(date, dateStr) => {
              setUserInfo({
                ...userInfo,
                checkOut: dateStr,
                diffInDays: dates[0] && date ? date.diff(dates[0], "days") : 30,
              });

              setDates([moment(userInfo.checkIn, dateFormat), date]);
            }}
          />

          <div className="guest" onClick={onOpen("Guest")}>
            <img src={UserIcon} alt="" srcset="" />
            <span className="pl-2">{guestNumbers} guest</span>
          </div>
        </div>

        <div className="hotel-apartment__filters">
          <div className="filter-buttons">
            {HotelFilterButtons.map(({ icon, title }, i) => (
              <>
                {title === "Amenities" ? (
                  <>
                    <button key={i} type="button" onClick={onOpen("Amenities")}>
                      {icon && <img src={icon} alt={title} />}
                      <span>{title}</span>
                    </button>
                  </>
                ) : (
                  [
                    title === "Budget" ? (
                      <>
                        <button
                          key={i}
                          type="button"
                          onClick={onOpen("Budget")}
                        >
                          {icon && <img src={icon} alt={title} />}
                          <span>{title}</span>
                        </button>
                      </>
                    ) : (
                      [
                        title === "Facilities" ? (
                          <>
                            <button
                              key={i}
                              type="button"
                              onClick={onOpen("Facilities")}
                            >
                              {icon && <img src={icon} alt={title} />}
                              <span>{title}</span>
                            </button>
                          </>
                        ) : (
                          [
                            title === "Add on" ? (
                              <>
                                <button
                                  key={i}
                                  type="button"
                                  onClick={onOpen("Add on")}
                                >
                                  {icon && <img src={icon} alt={title} />}
                                  <span>{title}</span>
                                </button>
                              </>
                            ) : (
                              [
                                title === "Rating" ? (
                                  <>
                                    <button
                                      key={i}
                                      type="button"
                                      onClick={onOpen("Rating")}
                                    >
                                      {icon && <img src={icon} alt={title} />}
                                      <span>{title}</span>
                                    </button>
                                  </>
                                ) : (
                                  [
                                    <>
                                      <button
                                        key={i}
                                        type="button"
                                        onClick={onOpen("Sort")}
                                      >
                                        {icon && <img src={icon} alt={title} />}
                                        <span>{title}</span>
                                      </button>

                                      <button
                                        key={i}
                                        type="button"
                                        className="danger"
                                        onClick={() => setPopup(true)}
                                      >
                                        {/* {icon && (
                                          <img src={icon} alt={title} />
                                        )} */}

                                        <span>Reset all</span>
                                      </button>
                                    </>,
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )}
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="entire-home__listing">
        

        {homes.map((home, i) => {
              if(i === 2 || i === 13 || i === 21 || i ===33 || i===45)
              return <MyLeaderBoardAd/>

               return(
          <div key={i} className="entire-home__list">
            <Link to={`/short-term-rental/detail/${home.hotel_info.hotel_name.replaceAll(" ", "-")}/${home.id}`}>
              <Carousel className="entire-home__carousel">
                {home.hotel_rooms.hotel_rooms_images.map(({ image }, i) => (
                  <img key={i} src={image} alt="hotel" />
                ))}

                {!home.hotel_rooms.hotel_rooms_images.length && (
                  <img src={NoImage} alt="Empty - RoomDaddy" />
                )}
              </Carousel>
            </Link>

            <JsonLd

                      item={{
                        "@context":"http://schema.org",
                        "@type":"SingleFamilyResidence",
                        "name": (home.hotel_info.hotel_name),
                        "description": (home.hotel_info.hotel_name),
                        "numberOfRooms": (home.hotel_rooms.number_bedrooms_apartments),
                        "occupancy": {
                            "@type": "QuantitativeValue",
                            "minValue": 1,
                            "maxValue": 5
                        },
                        "floorSize": {
                            "@type": "QuantitativeValue",
                            "value": home.hotel_rooms.apartment_size,
                            "unitCode": "sqft"
                        },
                        "leaseLength": {
                            "@type": "QuantitativeValue",
                            "value": 1,
                            "unitText": "months"
                        },
                        "numberOfBathroomsTotal": (home.hotel_rooms.number_bathrooms_apartments),
                        "numberOfBedrooms": (home.hotel_rooms.number_bedrooms_apartments),
                        "permittedUsage": "Perfectly suitable for a family with two kids.",
                        "petsAllowed": "yes",
                         "address": {
                        "@type": "PostalAddress",
                        "addressCountry": "AE",
                        "addressLocality":  home.hotel_info.address_line_two,
                        "addressRegion": "Dubai",
                        "streetAddress": home.hotel_info.hotel_pin_location},
                    
                        "latitude": home.hotel_info.hotel_latitude,
                        "longitude": home.hotel_info.hotel_longitude,
                      }}
                />

            <Link to={`/short-term-rental/detail/${home.hotel_info.hotel_name.replaceAll(" ", "-")}/${home.id}`}>
              <div className="entire-home__list-body">
                <div className="entire-home__top">
                  <div>
                    <h2>{home.hotel_info.hotel_name}</h2>

                    {home.hotel_info.hotel_rating ? (
                      <div className="entire-home__star">
                        {[...Array(home.hotel_info.hotel_rating)].map((n) => (
                          <img key={n} src={StarActiveIcon} alt="Star" />
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>

                <p>{home.hotel_pin_location}</p>
              </div>

              <div className="entire-home__list-footer">
                <div className="entire-home__pricing">
                  <p className="entire-home__room-type">
                  {home.hotel_rooms.apartment_room_name == "0 bedroom" ? "Studio" :
                    home.hotel_rooms.apartment_custom_name ||
                      home.hotel_rooms.apartment_room_name}
                  </p>

                  <p
                    style={{
                      width: "240px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {home.hotel_info.hotel_pin_location}
                  </p>
                </div>

                <div className="text-right">
                  <p className="entire-home__price mb-0">
                    <span>
                      {home.price === "Inquire Now" ? (
                        <Link to={`/short-term-rental/detail/${home.id}`}>
                          {home.price}
                        </Link>
                      ) : (
                        home.price
                      )}
                    </span>
                  </p>

                  <p style={{ color: "#5E5E5E", marginBottom: "3px" }}>
                    {userInfo.diffInDays} nights
                  </p>
                </div>
              </div>
            </Link>
          </div>);
        })}
        {loading && (
          <ContentLoader
            speed={2}
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 300 340"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="-2" y="-2" rx="0" ry="0" width="400" height="225" />
            <rect x="12" y="240" rx="0" ry="0" width="135" height="12" />
            <rect x="12" y="265" rx="0" ry="0" width="215" height="12" />
            <rect x="12" y="300" rx="0" ry="0" width="85" height="12" />
            <rect x="190" y="300" rx="0" ry="0" width="100" height="12" />
          </ContentLoader>
        )}
         <Button
                      className="filter-Reset w-100"
                      onClick={() => {
                        fetchAllHome(pageNumber+1)
                        // setPageNumber(pageNumber + 1);
                        // setLoadMore(false);
                        // setIsLoadMore(true);
                         setLoading(true);
                        // setLsValue(CACHE__CO_PAGENUM, pageNumber + 1);
                      }}
                       disabled={loading}
                    >
                      {loading ? "Loading..." : "Load more"}
                    </Button>
      </div>

      <WingBlank>
            <Modal
              popup
              visible={guestPopUp}
              onClose={onClose("Guest")}
              animationType="slide-up"
              // afterClose={() => { alert('afterClose'); }}
            >
              <div class="container">
                <div className="row">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                    <div className="row">
                      <div className="col-10">
                        <p>Select Guests</p>
                      </div>
                      <div className="col-2">
                        <span onClick={onClose("Guest")}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex">
                            Number of occupants
                            <div className="counter pl-5">
                              <img
                                id="decrement"
                                className="decrement button-counter"
                                src={Minus}
                                alt=""
                                onClick={guestDecrement}
                              />
                              <span id="tottleCount" className="pl-2 pr-2">
                                {guestNumbers}
                              </span>
                              <img
                                id="increment"
                                className="increment button-counter"
                                src={Plus}
                                alt=""
                                onClick={guestIncrement}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex justify-content-between">
                <div className="form-group mt-2 mb-4">
                  <Button
                    onClick={() => onClose("Guest")()}
                    className="w-100 filter-Reset futuraN700-14"
                  >
                    Reset
                  </Button>
                </div>
                <div className="form-group mt-2 mb-4">
                  <Button
                    onClick={() => {
                      onClose("Guest")();
                      setUserInfo({ ...userInfo, adult: guestNumbers });
                      applyFilters();
                    }}
                    className="w-100 filter-Apply futuraN700-14"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={budgetPopUp}
          onClose={onClose("Budget")}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Budget</p>
                  </div>

                  <div className="col-2">
                    <span onClick={onClose("Budget")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group mt-2 mb-2">
                  <label className="w-100 text-left text-xyz montserratNB-16 mt-2 mb-2">
                    Min price{" "}
                  </label>

                  <input
                    type="text"
                    name=""
                    className="form-control inputBorder"
                    placeholder="10"
                    value={price.min}
                    onChange={(e) => {
                      setPrice({ ...price, min: e.target.value });
                      // change(e, "number", "min_search_price", price.min);
                    }}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group mt-2 mb-2">
                  <label className="w-100 text-left text-xyz montserratNB-16 mt-2 mb-2">
                    Max price{" "}
                  </label>

                  <input
                    type="text"
                    name=""
                    className="form-control inputBorder"
                    placeholder="100"
                    value={price.max}
                    onChange={(e) => {
                      setPrice({ ...price, max: e.target.value });
                      // change(e, "number", "max_search_price", price.max);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Budget")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>

            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Budget")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={amenitiesPopUp}
          onClose={onClose("Amenities")}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Amenities</p>
                  </div>

                  <div className="col-2">
                    <span onClick={onClose("Amenities")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="get_started_radio" style={{ height: "199px" }}>
                {HotelAmenities.map((items, i) => (
                  <ul class="container_ul" key={i}>
                    {items.children?.map((hasChild, childIndex) => (
                      <>
                        <label
                          className="w-100 text-left text-xyz montserratNB-16 "
                          key={childIndex}
                        >
                          {hasChild.display}
                        </label>

                        {hasChild[hasChild.key]?.map(
                          (childKey, childKeyIndex) => (
                            <li>
                              <input
                                type="checkbox"
                                id={childKey.key + childIndex + childKeyIndex}
                                name={childKey.key}
                                childof={hasChild.key}
                                onChange={(e) =>
                                  change(
                                    e,
                                    hasChild.type,
                                    childKey.key,
                                    childKey.value
                                  )
                                }
                              />

                              <div class="check d-flex p-1 filter-Am">
                                <label for="s-option" class="label_active p-1">
                                  {childKey.display}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                      </>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Amenities")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>

            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Amenities")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={facilitiesPopUp}
          onClose={onClose("Facilities")}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Facilities</p>
                  </div>

                  <div className="col-2">
                    <span onClick={onClose("Facilities")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="get_started_radio" style={{ height: "199px" }}>
                {HotelFacilities.map((items, index) => (
                  <ul class="container_ul">
                    {items.children?.map((hasChild, childIndex) => (
                      <>
                        <label
                          className="w-100 text-left text-xyz montserratNB-16 "
                          key={childIndex}
                        >
                          {hasChild.display}
                        </label>

                        {hasChild[hasChild.key]?.map(
                          (childKey, childKeyIndex) => (
                            <li>
                              <input
                                type={
                                  hasChild.type === "checkbox"
                                    ? "checkbox"
                                    : "radio"
                                }
                                id={childKey.key + childIndex + childKeyIndex}
                                name={childKey.key}
                                childof={hasChild.key}
                                onChange={(e) =>
                                  change(
                                    e,
                                    hasChild.type,
                                    childKey.key,
                                    childKey.value
                                  )
                                }
                              />

                              <div class="check d-flex p-1 filter-Am">
                                <label for="s-option" class="label_active p-1">
                                  {childKey.display}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                      </>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Facilities")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>

            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Facilities")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={addonPopUp}
          onClose={onClose("Add on")}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Add on</p>
                  </div>

                  <div className="col-2">
                    <span onClick={onClose("Add on")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="get_started_radio" style={{ height: "119px" }}>
                {HotelAddOn.map((items, index) => (
                  <ul class="container_ul">
                    {items.children?.map((hasChild, childIndex) => (
                      <>
                        <label
                          className="w-100 text-left text-xyz montserratNB-16 "
                          key={childIndex}
                        >
                          {hasChild.display}
                        </label>

                        {hasChild[hasChild.key]?.map(
                          (childKey, childKeyIndex) => (
                            <li>
                              <input
                                type="checkbox"
                                id={childKey.key + childIndex + childKeyIndex}
                                name={childKey.key}
                                childof={hasChild.key}
                                onChange={(e) =>
                                  change(
                                    e,
                                    hasChild.type,
                                    childKey.key,
                                    childKey.value
                                  )
                                }
                              />

                              <div class="check d-flex p-1 filter-Am">
                                <label for="s-option" class="label_active p-1">
                                  {childKey.display}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                      </>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Add on")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>

            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Add on")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={ratingPopUp}
          onClose={onClose("Rating")}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Rating</p>
                  </div>

                  <div className="col-2">
                    <span onClick={onClose("Rating")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="get_started_radio" style={{ height: "99px" }}>
                {HotelRating.map((items, index) => (
                  <ul class="container_ul">
                    {items.children?.map((hasChild, childIndex) => (
                      <>
                        <label
                          className="w-100 text-left text-xyz montserratNB-16 "
                          key={childIndex}
                        >
                          {hasChild.display}
                        </label>

                        {hasChild[hasChild.key]?.map(
                          (childKey, childKeyIndex) => (
                            <li>
                              <input
                                type="checkbox"
                                id={childKey.key + childIndex + childKeyIndex}
                                name={childKey.key}
                                childof={hasChild.key}
                                onChange={(e) =>
                                  change(
                                    e,
                                    hasChild.type,
                                    childKey.key,
                                    childKey.value
                                  )
                                }
                              />

                              <div class="check d-flex p-1 filter-Am">
                                <label for="s-option" class="label_active p-1">
                                  {childKey.display}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                      </>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Rating")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>

            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Rating")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={sortPopUp}
          onClose={onClose("Sort")}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Sort by</p>
                  </div>

                  <div className="col-2">
                    <span onClick={onClose("Sort")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="get_started_radio" style={{ height: "119px" }}>
                {HotelSortby.map((items, index) => (
                  <ul class="container_ul">
                    {items.children?.map((hasChild, childIndex) => (
                      <>
                        <label
                          className="w-100 text-left text-xyz montserratNB-16 "
                          key={childIndex}
                        >
                          {hasChild.display}
                        </label>

                        {hasChild[hasChild.key]?.map(
                          (childKey, childKeyIndex) => (
                            <li>
                              <input
                                type="checkbox"
                                id={childKey.key + childIndex + childKeyIndex}
                                name={childKey.key}
                                childof={hasChild.key}
                                onChange={(e) =>
                                  change(
                                    e,
                                    hasChild.type,
                                    childKey.key,
                                    childKey.value
                                  )
                                }
                              />

                              <div class="check d-flex p-1 filter-Am">
                                <label for="s-option" class="label_active p-1">
                                  {childKey.display}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                      </>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>

          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Sort")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>

            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Sort")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <MobileNav />
    </div>
    </>
  );
};

export default EntireHomePage;
