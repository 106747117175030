import React from "react";
import { Link } from 'react-router-dom';
import { Switch } from "antd";

import Logo from "../../img/logo.png"
import backArrow from "../../img/icons/back-arrow.svg";
import "./index.scss";

export default function PageHeader(props) {
    return (

        // <div className="page-header">
        //     <Link to={props.link}>
        //         <img src={backArrow} />
        //     </Link>
        //     <h5>{props.heading}</h5>
        // </div>

        <div className="header" style={{ zIndex: "999" }}>
            <div className="row">
                <div className="col-auto px-0">
                    <Link to={props.arrowLink} className="menu-btn btn btn-link-default">
                        <img className="icon-size-24" src={backArrow} alt="back" />
                    </Link>
                </div>
                <div className="col text-left">
                    <Link to={props.homeLink} className="navbar-brand">
                        {/* <div className="icon icon-44">
                            <img className="icon-size-24" src={Logo}></img>
                        </div> */}
                        <h1>{props.heading}</h1>
                    </Link>
                </div>
                {
                    props.sideLink &&
                    <div className="col-auto ml-auto">
                        <Link to={props.sideLink} className="icon icon-44 shadow-s">
                            <img className="icon-size-24" src={props.icon} alt="icon" />
                        </Link>
                    </div>
                }
                {
                    props.mapBtn &&
                    <div className="col-auto" style={{ margin: "auto" }}>
                        <Switch onChange={props.toggleMap} />
                    </div>
                }
            </div>
        </div>
    );
}