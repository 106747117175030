import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import ContentLoader from "react-content-loader";
import axios from "axios";
import moment from "moment";
import { Button } from "@material-ui/core";
import { JsonLd } from "react-schemaorg";
import { AutoComplete, Select, DatePicker, message } from "antd";
import { Carousel, WingBlank } from "antd-mobile";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import { getLsValue, separateNumbers, setLsValue } from "../../utils/functions";
import {
  CACHE__H_APTS,
  CACHE__H_APTS_FILTERS,
  CACHE__H_APTS_F_PRICE,
} from "../data/cacheKeys";
import {
  HotelAmenities,
  HotelFacilities,
  HotelAddOn,
  HotelRating,
  HotelSortby,
} from "../_newComponents/FilterButtons/filtersData";
import HotelFilterButtons from "../_newComponents/FilterButtons/hotelsRFiltersBtn";
import Modal from "../_newComponents/Modal";
import ConfirmPopup from "../_newComponents/ConfirmPopup";
import CrossIcon from "../icons/cross_dark.svg";
import PageHeader from "../_newComponents/pageHeader";
import MyLeaderBoardAd from "../_newComponents/GoogleAds";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import FixedHorizontalAd from "../_newComponents/GoogleAds/fixedhorizontal";
import MobileNav from "../_newComponents/MobileNav";
import SearchListing from "../_newComponents/SearchListing";
import UserIcon from "../icons/guestIcon.svg";
import SearchIcon from "../icons/search.svg";
import StarActiveIcon from "../icons/star_active.svg";
import StarInactiveIcon from "../icons/star_inactive.svg";
import Cross from "../icons/cross.svg";
import Plus from "../images/plus.png";
import Minus from "../images/minus.png";
import "./style/hotelApartment.scss";

// const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";

const HotelApartmentPage = () => {
  const { selectedHotel, setSelectedHotel, userInfo, setUserInfo } =
    useContext(AppContext);
    const { country, city, location } = useParams();
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState([]);
    const [seoSelectedLocBuil, setSeoSelectedLocBuil] = useState([]);
    const [selectedCity, setSelectedCity] = useState("All Cities");
    const [locAndCityid, setLocAndCityId] = useState({
      city_id: city ? city : 48975,
      data: "",
    });
  const [guestPopUp, setGuestPopUp] = useState(false);
  const [popup, setPopup] = useState(false);
  const [budgetPopUp, setBudgetPopUp] = useState(false);
  const [amenitiesPopUp, setAmenitiesPopUp] = useState(false);
  const [facilitiesPopUp, setFacilitiesPopUp] = useState(false);
  const [addonPopUp, setAddonPopUp] = useState(false);
  const [ratingPopUp, setRatingPopUp] = useState(false);
  const [sortPopUp, setSortPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [apartments, setApartments] = useState([]);
  const [guestNumbers, setGuestNumbers] = useState(userInfo.adult);
  const [roomQuantity, setRoomQuantity] = useState(1);
  const [price, setPrice] = useState({ min: 0, max: 0 });
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [val, setVal] = useState("");
  const [options, setOptions] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState([
    ["hotels.admin_status", 1],
    ["hotels.status", 1],
    ["hotels.hotel_type", "apartment"],
  ]);
  
  if(location !== undefined ){
    filters.push([`hotels.address_line_two`,"like", `%${location.replace('-', ' ')}%`],)
  }

  const [tempFilters, setTempFilters] = useState([
    ["hotels.admin_status", 1],
    ["hotels.status", 1],
    ["hotels.hotel_type", "apartment"],
  ]);
  const [value, setValue] = useState([
    moment(userInfo.checkIn, "YYYY-MM-DD"),
    moment(userInfo.checkOut, "YYYY-MM-DD"),
  ]);
  const [dates, setDates] = useState([
    moment(userInfo.checkIn, "YYYY-MM-DD"),
    moment(userInfo.checkOut, "YYYY-MM-DD"),
  ]);
  const [hackValue, setHackValue] = useState([
    moment(userInfo.checkIn, "YYYY-MM-DD"),
    moment(userInfo.checkOut, "YYYY-MM-DD"),
  ]);

  const { Option } = Select;
  
  const cities = [
    { label: "Abu Dhabi", value: "abu-dhabi", id: 48973 },
    { label: "Ajman", value: "ajman", id: 48974 },
    { label: "Dubai", value: "dubai", id: 48975 },
    { label: "Ras Al Khaimah", value: "ras-al-khaimah", id: 48976 },
    { label: "Sharjah", value: "sharjah", id: 48977 },
    { label: "Umm Al Quwain", value: "umm-al-quwain", id: 48979 },
    { label: "Fujairah", value: "fujairah", id: 48980 },
  ];

  const removeList = (e) => {
    let locationArray = [...selectedLocation];
    let locArrayIndex = locationArray.indexOf(
      e.target.parentElement.textContent
    );
    let buildingArray = [...selectedBuilding];
    let builArrayIndex = buildingArray.indexOf(
      e.target.parentElement.textContent
    );
    // let seoLocBuildArray = [...seoSelectedLocBuil]
    // let seoIndex = seoLocBuildArray.indexOf(e.target.parentElement.textContent)

    if (locArrayIndex !== -1) {
      locationArray.splice(locArrayIndex, 1);
      setSelectedLocation([...locationArray]);
    }
    if (builArrayIndex !== -1) {
      buildingArray.splice(builArrayIndex, 1);
      setSelectedBuilding([...buildingArray]);
    }

    // setTimeout(() => {
    //   if (selectedLocation.length && selectedBuilding.length) {
    //     applyFilters();
    //     document.getElementById("filter-it-btn").click();
    //   } else {
    //     reset();
    //   }
    // }, 100);

    // if (seoIndex !== -1) {
    //     seoLocBuildArray.splice(seoIndex, 1);
    //     setSeoSelectedLocBuil([...seoSelectedLocBuil]);
    // }
  };

  const separateNumbers = (num) =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;

  console.log(selectedCity, locAndCityid);
console.log(country, city, location);


  const getAptBasePrice = (hotel) => {
    let tax, total;

    if (hotel?.date_check_status === 0) {
      if (hotel?.room_rent_type?.room_rent_tax === "no") {
        tax = (hotel?.hotel_tax / 100) * hotel?.hotel_room_base_price;
        tax = tax + hotel?.hotel_room_base_price + hotel?.hotel_tax_amount;
      } else {
        tax = hotel?.hotel_room_base_price;
      }

      total = Math.ceil(roomQuantity * tax);

      return total !== 0
        ? `${hotel?.hotel_info?.hotel_currency} ${separateNumbers(total)}`
        : "Inquire Now";
    } else {
      return "Inquire Now";
    }

    // if (hotel.room_rent_type && hotel.room_rent_type.room_rent_tax === "yes")
    //   return Math.round(hotel.hotel_room_base_price);

    // let percent = Number(hotel.hotel_tax) / 100;
    // let tax = percent * Math.round(hotel.hotel_room_base_price);

    // return (
    //   Math.round(tax + hotel.hotel_room_base_price + hotel.hotel_tax_amount) ||
    //   0
    // );

    // const getRoomPrice = (index) => {
    //   if (hotel.hotel_active_rooms) {
    //     let tax = 0;
    //     if (
    //       hotel["hotel_active_rooms"][index]["room_rent_type"][
    //         "room_rent_tax"
    //       ] === "no"
    //     ) {
    //       tax =
    //         (parseInt(hotel["hotel_tax"]) / 100) *
    //         hotel["hotel_active_rooms"][index]["room_base_price"];
    //       tax =
    //         tax +
    //         hotel["hotel_active_rooms"][index]["room_base_price"] +
    //         hotel["hotel_tax_amount"];
    //     } else {
    //       tax = hotel["hotel_active_rooms"][index]["room_base_price"];
    //     }

    //     return Math.ceil(tax);
    //   }
    // };

    // const getMinPrice = (rooms) => {
    //   const prices = [];

    //   for (let i = 0; i < rooms?.length; i++) {
    //     const price = getRoomPrice(i);
    //     prices.push(price);
    //   }

    //   console.log(prices);

    //   return Math.min(...prices) !== Infinity ? Math.min(...prices) : 0;
    // };

    // console.log(hotel);

    // return getMinPrice(hotel?.hotel_active_rooms);
  };

  const guestIncrement = () => {
    if (guestNumbers == 6) {
      return;
    } else {
      setGuestNumbers((prev) => prev + 1);
    }
  };

  const guestDecrement = () => {
    if (guestNumbers == 1) {
      return;
    } else {
      setGuestNumbers((prev) => prev - 1);
    }
  };


  const onOpen = (key) => (e) => {
    e.preventDefault(); // Android
    if (key === "Guest") {
      setGuestPopUp(true);
    }
    if (key === "Budget") {
      setBudgetPopUp(true);
    }
    if (key === "Amenities") {
      setAmenitiesPopUp(true);
    }
    if (key === "Facilities") {
      setFacilitiesPopUp(true);
    }
    if (key === "Add on") {
      setAddonPopUp(true);
    }
    if (key === "Rating") {
      setRatingPopUp(true);
    }
    if (key === "Sort") {
      setSortPopUp(true);
    }
  };

  const onClose = (key) => (e) => {
    if (key === "Guest") {
      setGuestPopUp(false);
    }
    if (key === "Budget") {
      setBudgetPopUp(false);
    }
    if (key === "Amenities") {
      setAmenitiesPopUp(false);
    }
    if (key === "Facilities") {
      setFacilitiesPopUp(false);
    }
    if (key === "Add on") {
      setAddonPopUp(false);
    }
    if (key === "Rating") {
      setRatingPopUp(false);
    }
    if (key === "Sort") {
      setSortPopUp(false);
    }
  };

  const fetchData = () => {
    const prevItems = getLsValue(CACHE__H_APTS);

    if (prevItems && prevItems.length) setApartments(prevItems);

    axios
      .post(`${app.hotelBaseUrl}/api/get_hotels_apartments`, {
        search_latitude: userInfo.lat,
        search_longitude: userInfo.lng,
        check_in: userInfo.checkIn,
        check_out: userInfo.checkOut,
        diff_in_days: userInfo.diffInDays,
        min_price: price.min,
        max_price: price.max,
        currency: "AED",
        where: filters,
      })
      .then((res) => {
        const data =
          Object.getPrototypeOf(res.data.data) === Object.prototype
            ? Object.values(res.data.data).reverse()
            : res.data.data.reverse();

        data.forEach((item) => {
          item.price = getAptBasePrice(item);
        });

        if (
          !prevItems ||
          !prevItems.length ||
          JSON.stringify(data[0]) !== JSON.stringify(prevItems[0])
        ) {
          setApartments(data);
          setLsValue(CACHE__H_APTS, data);
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {

    if (
      location !== undefined 
    ) {
      let splitLocation = location.split("-");
      let joinResult = splitLocation.join(" ");

      if (seoSelectedLocBuil.indexOf(joinResult) === -1)
        setSeoSelectedLocBuil([...seoSelectedLocBuil, joinResult]);
    }


    fetchData();
  }, [isFilter, filters, min, max, userInfo]);

  function applyFilters() {

    if (val) {
      setSelectedCity(val);
      setLocAndCityId({ ...locAndCityid, city_id: val });
    }

    setIsFilter(true);
    setLoading(true);

    let selectedFilter = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let where = [
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
      //["tbl_hotel_room.number_guests_room", guestNumbers],
      ['hotels.address_line_two', 'like', `%${selectedLocation}%`],
    ];
    for (let s of selectedFilter) {
      let childof = s.getAttribute("childof");
      if (childof === "facilities_array") {
        where.push([
          `facilities_and_services.${childof}`,
          "like",
          `%${s.name}%`,
        ]);
      } else if (
        childof === "internet" ||
        childof === "parking" ||
        childof === "breakfast"
      ) {
        where.push([`facilities_and_services.${childof}`, s.name]);
      } else if (
        childof === "hotel_amenities_food" ||
        childof === "hotel_amenities_entertainment" ||
        childof === "hotel_amenities_services"
      ) {
        where.push([
          `facilities_and_services.${childof}`,
          "like",
          `%${s.name}%`,
        ]);
      } else if (childof === "room_amenities") {
        where.push([`tbl_hotel_room.${childof}`, "like", `%${s.name}%`]);
      }
    }

    setFilters(where);
    setMin(price.min);
    setMax(price.max);
    setLsValue(CACHE__H_APTS_FILTERS, filters);
    setLsValue(CACHE__H_APTS_F_PRICE, price);
  }

  // const applyFilters = () => {
  //   setLoading(true);
  //   setIsFilter(true);
  //   setFilters(tempFilters);
  //   setMin(price.min);
  //   setMax(price.max);
  //   setLsValue(CACHE__H_APTS_FILTERS, filters);
  //   setLsValue(CACHE__H_APTS_F_PRICE, price);
  // };

  const change = (e, type, key, value) => {
    const newFilters = { ...tempFilters };

    if (type === "checkbox") newFilters[key] = value || e.target.checked;
    else if (type === "radio") newFilters[key] = value;
    else if (type === "number") newFilters[key] = e.target.value;

    setTempFilters(newFilters);
    // setLsValue(CACHE__H_APTS_FILTERS, newFilters);
  };

  const onSelect = (data, e) => {
    const name = e.value;
    const type = e.type;

    if (type === "location")
      if (!selectedLocation.includes(name))
        setSelectedLocation([...selectedLocation, name]);

    if (type === "building")
      if (!selectedBuilding.includes(name))
        setSelectedBuilding([...selectedBuilding, name]);

    console.log("-->", data);
    // applyFilters();
  };

  useEffect(() => {
    const elem = document.getElementById("search-autocomplete");

    elem.onblur = () => {
      if (selectedBuilding.length || selectedLocation.length) {
        setVal("");

        // elem.value = "";
        // elem.placeholder = "Find a place to stay";
      }
    };
  }, [selectedBuilding, selectedLocation]);

  const onSearch = (key, value) => {
    setLocAndCityId({ ...locAndCityid, [key]: value });

    if(selectedLocation.length === 0)
    {
    if (value.length > 2) {
      let data = new FormData();
      for (let i in locAndCityid) data.append(i, locAndCityid[i]);

      axios
        .post(`${app.baseUrl}/api/location_filter`, data)
        .then((res) => {
          console.log(res);

          for (let i = 0; i < options.length; i++)
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j].value === options[i].value)
                options.splice(i, 1);
              if (res.data.data[j].id === options[i].id) options.splice(i, 1);
            }

          setOptions([...options, ...res.data.data]);
        })
        .catch((err) => console.log(err));
    }
  }
  };


  const reset = () => {
    setIsFilter(false);

    setSelectedCity(48975);
    setLocAndCityId({
      city_id: 48975,
      data: "",
    });
    setSelectedLocation([]);
    setSelectedBuilding([]);

    setTempFilters([
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
    ]);
    setFilters([
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
    ]);

    const elem = document.getElementsByClassName("pac-target-input")[0];
    elem.value = "";

    setMin(0);
    setMax(0);
    setPrice({ min: 0, max: 0 });

    setLsValue(CACHE__H_APTS_F_PRICE, { min: 0, max: 0 });
    setLsValue(CACHE__H_APTS_FILTERS, [
      ["hotels.admin_status", 1],
      ["hotels.status", 1],
      ["hotels.hotel_type", "apartment"],
    ]);
  };

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) return false;
    else return dates[0] && current.diff(dates[0], "days") < 30;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  // const applyOptions = () => {
  //   setUserInfo({
  //     ...userInfo,
  //     adult: guestNumbers,
  //     checkIn: dates[0].format("YYYY-MM-DD"),
  //     checkOut: dates[1].format("YYYY-MM-DD"),
  //     diffInDays: dates[1].diff(dates[2], "days"),
  //   });
  // };

  const disabledCheckInDate = (current) =>
    current && current < moment().subtract(1, "d");

  const disabledCheckOutDate = (current) => {
    const checkIn = dates[0];
    const isAfter = checkIn?.isAfter(moment());
    const diffInDays = isAfter ? checkIn?.diff(moment(), "days") : 0;

    return current && current < moment().add(30 + diffInDays, "days");
  };

  return (
    
    <>
      {/* if SEO url not have 4 prams */}
      {location !== undefined ? (
        <MetaTags>
          <title>
          {`Monthly Hotel Apartments For Short Term Rent In ${seoSelectedLocBuil}.`}
          </title>
          <meta
            name="description"
            content={`Look for Hotel Apartments in ${seoSelectedLocBuil}, ${city}. Look no further, with RoomDaddy now you can find best and cheap bed space for short term monthly rent in ${seoSelectedLocBuil} ${city}. Also place free ad today`}
          />
        </MetaTags>
      ) : (
        [
          
            <MetaTags>
              <title>
              Find A Hotel Apartment For Monthly Rent
              </title>
              <meta
                name="description"
                content="Find the best and cheap Monthly hotel rooms for Rent in UAE with RoomDaddy. We have a wide range of hotel rooms options for you."
              />
            </MetaTags>
         
        ]
      )}

    <div
      className="hotel-apartment"
      style={{ paddingBottom: "75px", paddingTop: "10px" }}
    >
      <PageHeader homeLink="/" title="ApartHotel" isCustom />

      {popup && (
        <ConfirmPopup
          title="Reset"
          onConfirm={() => {
            reset();
            setPopup(false);
          }}
          onCancel={() => setPopup(false)}
        >
          <p className="px-2" style={{ fontWeight: "400" }}>
            Are you sure you want to reset all filters?
          </p>
        </ConfirmPopup>
      )}

      <div className="hotel-apartment__body">
        {/* <SearchListing
          isButton
          // handleFocus={handleFocus}
          onAutoLocation={fetchData}
          search={fetchData}
        /> */}

          <h1
                style={{
                  fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 22,
                lineHeight: "145.9%",
                color: "rgb(0 0 0 / 7%)",
                }}

                ><b>Hotel Apartment for Rent in UAE</b>
            </h1>
            <FixedHorizontalAd />

<div className={`search-listing p-0`}>

<form
     className="search-listing__container mr-2"
     style={{ flex: 1 }}
     onSubmit={(e) => {
       e.preventDefault();
       //applyFilters();
     }}
   >
     <img src={SearchIcon} alt="Search" />

     <AutoComplete
       style={{
         background: "inherit",
         borderRadius: "999px",
         boxShadow: "none",
       }}
       id="search-autocomplete"
       className="form-control search"
       // type="text"
       // value={terms}
       // onChange={(e) => setTerm(e.target.value)}
       value={val}
       placeholder="Find a place to stay"
       allowClear={true}
       options={options}
       
       onSelect={async (data, e) => {
         await onSelect(data, e);
         setVal("");

         setTimeout(() => {
           //applyFilters();
           document.getElementById("filter-it-btn").click();
         }, 100);

         // const elem = document.getElementById("search-autocomplete");

         // elem.value = "";
         // elem.placeholder = "Find a place to stay";
       }}
       onSearch={(value) => {
         onSearch("data", value);
         setVal(value);
       }}
       filterOption={(inputValue, option) =>
         option.value
           .toUpperCase()
           .indexOf(inputValue.toUpperCase()) !== -1
       }
       // options={options}
       // onSelect={onSelect}
       // onSearch={(value) => {
       //   onSearch("data", value);
       //   setVal(value);
       // }}
       // filterOption={(inputValue, option) =>
       //   option.value
       //     .toUpperCase()
       //     .indexOf(inputValue.toUpperCase()) !== -1
       // }
     />

     {/* <button type="submit" className="search-listing__action">
       <img src={RightIcon} alt="Right Arrow - RoomDaddy" />
     </button> */}
   </form>

   <div className="search-listing__actions">
     <Select
       style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
       value={selectedCity}
       onChange={(value) => {
         setSelectedCity(value);
         setLocAndCityId({ ...locAndCityid, city_id: value });

         // setTimeout(() => applyFilters(val), 100);
       }}
     >
       {cities.map((city, i) => (
         <Option key={i} value={city.id}>
           {city.label}
         </Option>
       ))}
     </Select>

     <Button
       id="filter-it-btn"
       onClick={() => applyFilters()}
       className="bookN-s d-none"
       style={{ fontWeight: "bold" }}
     >
       Search
     </Button>
   </div>


      </div>

      </div>

        {!seoSelectedLocBuil.length &&
          !selectedBuilding.length &&
          !selectedLocation.length ? null : (
            <div className="px-3 loc-info">
              {seoSelectedLocBuil.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">You searched for</p>
                  <ul className="text-secondary small lists pl-0">
                    {seoSelectedLocBuil.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              {selectedLocation.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">Locations</p>
                  <ul className="text-secondary small lists pl-0">
                    {selectedLocation.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}

              {selectedBuilding.length !== 0 && (
                <>
                  <p className="mb-1 small text-secondary">Buildings</p>
                  <ul className="text-secondary small lists pl-0">
                    {selectedBuilding.map((item, index) => {
                      return (
                        <>
                          <li key={index} name={item} className="loc-list">
                            <img
                              onClick={removeList}
                              className="remove pr-2"
                              width="17"
                              src={CrossIcon}
                              alt=""
                            />
                            {item}
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          )}

      <div className="hotel-apartment__filters">
        <div
          className="hotel-apartment__date d-flex justify-content-between mb-2"
          style={{ padding: "0 11px" }}
        >
          {/* <RangePicker
            style={{ width: "55%" }}
            size="small"
            format="DD-MM-YYYY"
            value={hackValue || value}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => setValue(val)}
            onOpenChange={onOpenChange}
          /> */}

          <DatePicker
            format={dateFormat}
            disabledDate={disabledCheckInDate}
            value={dates[0] || moment(userInfo.checkIn, dateFormat)}
            onChange={(date, dateStr) => {
              setUserInfo({
                ...userInfo,
                checkIn: dateStr,
                checkOut: moment(dateStr, dateFormat).add(30, "days").format(dateFormat),
              });

              setDates([date, moment(dateStr, dateFormat).add(30, "days")]);
            }}
          />

          {/* <div style={{ opacity: 0.5, zIndex: 1 }} className="ml-n3 mr-n3">
            <SwapRightOutlined />
          </div> */}

          <DatePicker
            format={dateFormat}
            disabledDate={disabledCheckOutDate}
            value={dates[1] || moment(userInfo.checkOut, dateFormat)}
            onChange={(date, dateStr) => {
              setUserInfo({
                ...userInfo,
                checkOut: dateStr,
                diffInDays: dates[0] && date ? date.diff(dates[0], "days") : 30,
              });

              setDates([moment(userInfo.checkIn, dateFormat), date]);
            }}
          />

          <div className="guest" onClick={onOpen("Guest")}>
            <img src={UserIcon} alt="" srcset="" />
            <span className="pl-2">{guestNumbers} guest</span>
          </div>

          {/* <div className="futuraN5-14 ">
            <button type="button" className="bookN-s" onClick={applyOptions}>
              Apply
            </button>
          </div> */}
        </div>

        <div className="hotel-apartment__filters">
          <div className="filter-buttons">
            {HotelFilterButtons.map(({ icon, title }, i) => (
              <>
                {title == "Amenities" ? (
                  <>
                    <button key={i} type="button" onClick={onOpen("Amenities")}>
                      {icon && <img src={icon} alt={title} />}
                      <span>{title}</span>
                    </button>
                  </>
                ) : (
                  [
                    title == "Budget" ? (
                      <>
                        <button
                          key={i}
                          type="button"
                          onClick={onOpen("Budget")}
                        >
                          {icon && <img src={icon} alt={title} />}
                          <span>{title}</span>
                        </button>
                      </>
                    ) : (
                      [
                        title == "Facilities" ? (
                          <>
                            <button
                              key={i}
                              type="button"
                              onClick={onOpen("Facilities")}
                            >
                              {icon && <img src={icon} alt={title} />}
                              <span>{title}</span>
                            </button>
                          </>
                        ) : (
                          [
                            title == "Add on" ? (
                              <>
                                <button
                                  key={i}
                                  type="button"
                                  onClick={onOpen("Add on")}
                                >
                                  {icon && <img src={icon} alt={title} />}
                                  <span>{title}</span>
                                </button>
                              </>
                            ) : (
                              [
                                title == "Rating" ? (
                                  <>
                                    <button
                                      key={i}
                                      type="button"
                                      onClick={onOpen("Rating")}
                                    >
                                      {icon && <img src={icon} alt={title} />}
                                      <span>{title}</span>
                                    </button>
                                  </>
                                ) : (
                                  [
                                    <>
                                      <button
                                        key={i}
                                        type="button"
                                        onClick={onOpen("Sort")}
                                      >
                                        {icon && <img src={icon} alt={title} />}
                                        <span>{title}</span>
                                      </button>

                                      <button
                                        key={i}
                                        type="button"
                                        className="danger"
                                        onClick={() => setPopup(true)}
                                      >
                                        {/* {icon && (
                                            <img src={icon} alt={title} />
                                          )} */}
                                        <span>Reset all</span>
                                      </button>
                                    </>,
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )}
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="hotel-apartment__listing">
        

        {apartments.map((apartment, i) => {
          if(i === 2 || i === 13 || i === 21 || i ===33 || i===45)
          return <MyLeaderBoardAd/>
          return (
          <div key={i} className="hotel-apartment__list">
            <Carousel className="hotel-apartment__carousel">
              {apartment.hotel_images.map(({ image }, i) => (
                <img key={i} src={image} alt="hotel" />
              ))}
            </Carousel>

            <JsonLd

                      item={{
                        "@context":"http://schema.org",
                        "@type":"SingleFamilyResidence",
                        "name": (apartment.hotel_info.hotel_name),
                        "description": (apartment.hotel_info.hotel_name),
                        "numberOfRooms": (apartment.hotel_rooms.number_bedrooms_apartments),
                        "occupancy": {
                            "@type": "QuantitativeValue",
                            "minValue": 1,
                            "maxValue": 5
                        },
                        "floorSize": {
                            "@type": "QuantitativeValue",
                            "value": apartment.hotel_rooms.apartment_size,
                            "unitCode": "sqft"
                        },
                        "leaseLength": {
                            "@type": "QuantitativeValue",
                            "value": 1,
                            "unitText": "months"
                        },
                        "numberOfBathroomsTotal": (apartment.hotel_rooms.number_bathrooms_apartments),
                        "numberOfBedrooms": (apartment.hotel_rooms.number_bedrooms_apartments),
                        "permittedUsage": "Perfectly suitable for a family with two kids.",
                        "petsAllowed": "yes",
                         "address": {
                        "@type": "PostalAddress",
                        "addressCountry": "AE",
                        "addressLocality":  apartment.hotel_info.address_line_two,
                        "addressRegion": "Dubai",
                        "streetAddress": apartment.hotel_info.hotel_pin_location},
                    
                        "latitude": apartment.hotel_info.hotel_latitude,
                        "longitude": apartment.hotel_info.hotel_longitude,
                      }}
                />

            <Link to={`/hotel-apartment/detail/${apartment.hotel_info.hotel_name.replaceAll(" ", "-")}/${apartment.id}`}>
              <div className="hotel-apartment__list-body">
                <div className="hotel-apartment__top">
                  <div>
                    <h2>{apartment.hotel_info.hotel_name}</h2>

                    <div className="hotel-apartment__star">
                      {[...Array(apartment.hotel_info.hotel_rating)].map(
                        (n, i) => (
                          <img key={i} src={StarActiveIcon} alt="Star" />
                        )
                      )}

                      {/* {[...Array(5 - apartment.hotel_info.hotel_rating)].map(
                        (n, i) => (
                          <img key={i} src={StarInactiveIcon} alt="Star" />
                        )
                      )} */}
                    </div>
                  </div>

                  {/* <div className="hotel-apartment__reviews">
                    <span>4.6</span>
                    <p>All reviews</p>
                  </div> */}
                </div>

                <p>{apartment.hotel_pin_location}</p>
              </div>

              <div className="hotel-apartment__list-footer">
                <div className="hotel-apartment__pricing">
                  <p className="hotel-apartment__room-type">
                    {apartment.hotel_rooms.apartment_room_name}
                  </p>

                  <p>Free Cancelation</p>
                </div>

                <div className="text-right">
                  <p className="hotel-apartment__price mb-0">
                    <span>
                      {apartment.price === "Inquire Now" ? (
                        <Link to={`/hotel-apartment/detail/${apartment.hotel_info.hotel_name.replaceAll(" ", "-")}/${apartment.id}`}>
                          {apartment.price}
                        </Link>
                      ) : (
                        apartment.price
                      )}
                    </span>
                  </p>

                  <p style={{ color: "#5E5E5E", marginBottom: "3px" }}>
                    {userInfo.diffInDays} nights
                  </p>
                </div>
              </div>
            </Link>
          </div> );
        })}

<JsonLd
      item={{
        "@context":"http://schema.org",
        "@type":"FAQPage",
        "mainEntity" : [
          {
          "name" : "What is meant by extended stay hotel?",
          "acceptedAnswer" :
          {
          "text" : "A hotel with an extended stay programme provides customers with long-term accommodation on a special rate. It may in some cases come with extra services like in-suite kitchens and self-serve laundry, which make them a useful and cost-effective alternative.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "How long is the maximum stay length in an extended-stay hotel?",
          "acceptedAnswer" :
          {
          "text" : "Hotel prices and demand is season, so they may change prices accordingly. However, many hotels in UAE can offer yearly rates as well. This is best for companies and big corporates who have rotational staff always coming and going. For shorter stays it is monthly based and can lead up to a yearly contract. Monthly bookers have the risk of hotels closing down on Extended stay during peak seasons.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "Why is a month-long hotel stay called extended-stay?",
          "acceptedAnswer" :
          {
          "text" : "It is called so because families on vacation, business travellers, those moving to a new city, and anybody else seeking for a hotel can stay for a period while they find lodging alternatives. In most cases, hotel accommodations for extended stay customers are reserved a month, or longer.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
          "name" : "What sort of hotel offers extended stays?",
          "acceptedAnswer" :
          {
          "text" : "UAE has a great Hotel, and Hotel apartment infrastructure. Which constantly is bringing more and more available keys in the market. During summers while the demand slows down with exception of Luxury Hotels, like Atlantis, Four Seasons etc. Almost all the hotels in the lower tier accept the Extended stay and are good on pocket for travellers and guests.",
          "@type" : "Answer"
          },
          "@type" : "Question"
          },{
            "name" : "Why do people choose extended-stay hotel?",
            "acceptedAnswer" :
            {
            "text" : "People opt extended-stay hotel because they have a more home feel than a hotel. The fact that extended stay hotels provide long-term lodging makes them not only more affordable than booking a hotel room, but also far more convenient, with no commitment to sign up lease and setting up the furnishing.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How can a hotel for extended stays feel like home?",
            "acceptedAnswer" :
            {
            "text" : "Making Extended Stay Hotels Feel More Like Home: Fill the refrigerator with wholesome snacks, bring along some familiar items, unpack, establish a daily routine, and so on. Get some fresh air, keep your room tidy, listen to music, and contact us to find extended stay hotels nearby.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Does Dubai's rent include utilities?",
            "acceptedAnswer" :
            {
            "text" : "Rental agreement decides upon who pays the utilities. However, most of the short term (monthly) rentals the bills and utilities are included in the price. So what you pay is exactly your monthly rent. For yearlong lease the utilities are paid by the tenant almost in every contract with few exceptions.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is Dewa's monthly cost?",
            "acceptedAnswer" :
            {
            "text" : "Up to 2000 kWh of usage each month costs AED 0.23 (USD 0.063) 2001-4000 kWh: 0.28 AED (USD 0.076) 4001-6000 kWh: 0.32 AED (USD 0.087) More than 6001 kWh - AED 0.38 (USD 0.10)",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is the DEWA connection charges?",
            "acceptedAnswer" :
            {
            "text" : "Moving into a new property, the tenant has to apply for a new connection which has a non-refundable AED 110 DEWA activation charge (for small meters). The security deposit is AED 2,000 for apartments and AED 4,000 for villas.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In Dubai, is water a paid service?",
            "acceptedAnswer" :
            {
            "text" : "The DEWA bill comprises charges for sewage, housing, water, and electricity. The housing fee from the Dubai Municipality is paid over a twelve-month period and equals 5% of the annual property rent.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How costly is electricity in the UAE?",
            "acceptedAnswer" :
            {
            "text" : "The United Arab Emirates (UAE), with an average cost of 0.08 U.S. dollars per kilowatt-hour of electricity, has the highest electricity price in the Gulf Cooperation Council (GCC). The cost of electricity in Qatar is the lowest in the neighborhood.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which utilities are provided in an apartment?",
            "acceptedAnswer" :
            {
            "text" : "The essential services that your house, apartment, or place of work needs to remain comfortable and functional are known as utilities. Water, sewer, power, gas, garbage, and recycling are examples of common utilities. Utility subscriptions might also include things like cable TV, internet, security, and phone service.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which Dubai areas are the cheapest?",
            "acceptedAnswer" :
            {
            "text" : "Dubai's five most cheapest neighborhoods: 1- International City The area with the lowest rent. 2- Deira A better option for those on a tight budget. 3- Silicon Oasis. 4- Sports City. 5- Jumeirah village circle (JVC)",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In which UAE city is rent the cheapest?",
            "acceptedAnswer" :
            {
            "text" : "According to information from Trends report, Ajman has the lowest rental expenses in the UAE, with rates three times lower than Dubai's median asking price.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Where can I stay the cheapest in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "International City is one. For good reason, International City is at the top of the UAE's list of communities with low rents.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which area of Dubai is the best for lodging?",
            "acceptedAnswer" :
            {
            "text" : "Downtown Dubai is the finest neighbourhood to stay in if you're a first-time visitor or a business traveller visiting Dubai. The Burj Khalifa, the Dubai Mall, and the renowned Dubai Fountain are just a few of the main attractions in Dubai that can be found in the Downtown area.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Where can I get great value for lodging in the UAE?",
            "acceptedAnswer" :
            {
            "text" : "1- Delta Hotels by Marriott Jumeirah Beach is one of the most popular reasonably priced hotels in Jumeirah Beach Residence Hotel. 2- Millennium Place Dubai Marina Inexpensive hotel in Dubai Marina. 3- Ramada by Wyndham JBR. City Seasons. 4- ROVE Hotels",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What are common areas to find singles in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "1- Apartments at Palm Jumeirah. 2- Dubai Downtown Apartments. 3- Apartments in Dubai Hills. 4- Apartments in JBR, Marina and JLT.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which emirate is the richest?",
            "acceptedAnswer" :
            {
            "text" : "Abu Dhabi is the richest emirate in the UAE as a result of its abundant oil resources. It's one of the richest cities in the world, in fact!",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which UAE emirate is the best place to call home? Which Workplace?",
            "acceptedAnswer" :
            {
            "text" : "The UAE's top three emirates: 1- Dubai Given that Dubai is regarded as the nation's business hub, it should not be a surprise that it ranks at the top of this list. 2- Abu Dhabi Although it's still considerably less well-known than Dubai, Abu Dhabi is probably the second most popular option for expats after Ajman. 3- Ajman Ajman is the cheapest place to rent property in the UAE.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is the price of a bedspace in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "The cost of a bedspace can range from AED 400 to AED 2500 per month. The number of people using the room determines the pricing in most cases. A bedspace might cost as little as AED 400 in Deira, Bur Dubai, yet it can cost as much as AED 2500 in Dubai Marina.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What is meant by bedspace?",
            "acceptedAnswer" :
            {
            "text" : "Any floor area, bed, bunk, or sleeping facility utilized or intended to be used as sleeping accommodation for a person while is referred to as a 'bedspace'.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Are rented beds permitted in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "In Dubai, roommates cannot share an apartment unless the landlord gives their consent. Unless it is expressly stated in their Dubai tenancy contract, the tenant is not permitted to sublet the property without the landlord's consent, according to Article 24 of Law No. 26 of 2007.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In Dubai, is rent a monthly rental cheaper than yearly rental?",
            "acceptedAnswer" :
            {
            "text" : "Although it's uncommon, it is possible to discover rental homes in Dubai with monthly payment options. Even though monthly rent payments in Dubai may be convenient, they are also expensive. Long-term costs are more than they would be if you choose a single cheque.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How much should my rent be in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "As a general rule, you shouldn't spend more than 30% of your income on housing. For instance, on a salary of AED 20,000 per month, AED 6,000 or less per month, or AED 72,000 annually, should be set aside for housing. Everyone's financial condition is unique, though.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "What would Dubai's rent rise be in 2022?",
            "acceptedAnswer" :
            {
            "text" : "In the year leading up to June, average rentals rose by 21.7%, with increases of 21.2% and 24.7% for apartments and villas, respectively. The average annual rent for an apartment was Dhs85,294 and for a villa was Dhs255,437 as of June 2022.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "In which month hotels are cheap in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "The least expensive month is JUNE, JULY , AUGUST, with monthly rent as low as 3500 in a Hotel.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How was 2022 in terms of hotel rates?",
            "acceptedAnswer" :
            {
            "text" : "According to the Global Business Travel Forecast released by CWT and the Global Business Travel Association, hotel prices are predicted to rise 13% globally in 2022 and another 10% in 2023.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Is it better to rent a furnished apartment in Dubai, UAE?",
            "acceptedAnswer" :
            {
            "text" : "In the Emirates, furnished apartments often rent for more money than their unfurnished equivalents. However if you consider the total cost of singing up a unfurnished lease and renting a flexible stay it is in most cases a cheapest option to consider.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "How can I afford to live in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "Suggestions for affordable Dubai living: 1- Search for an accessible neighborhood. 2- Legally share an apartment. 3- Use public transport in Dubai, live close to your place of work or children's school, use alternative routes to avoid road tolls, shop at cheap supermarkets, and so on. 4- Purchase locally. 5- Search for free or cheap things to do in Dubai.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Is it legal for unmarried couples to stay together at a hotel in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "Yes, now it is legal for unmarried couples to stay together at a hotel in Dubai, Abu Dhabi, Sharjah, and the rest of the UAE. The government of the UAE announced alterations to the nation's Islamic personal laws. According to this, unmarried couples in the UAE will now be able to cohabitate under the new regulation. Now unmarried couples are free to cohabitate without facing consequences, being investigated, or worrying about being reported.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Can unmarried couples permitted to stay in a hotel room in Abu Dhabi?",
            "acceptedAnswer" :
            {
            "text" : "Yes, unmarried couples permitted to stay in a hotel room in Abu Dhabi and the rest of the UAE. The UAE relaxes Islamic restrictions and permits unmarried couples to live together. The new action is intended to raise the standard of living for citizens of the nation. Article 356 has been completely altered by FDL 15/2020, and the provisions for indecent assault have been eliminated. Following this, unmarried couples are permitted to share a home and cohabit without getting married in UAE.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which months has the lowest cost in Dubai?",
            "acceptedAnswer" :
            {
            "text" : "1- Depends, if you want to enjoy Sun and beach, the summers are good especially August and March. If you are already coming from a hot destination, our advice would be to travel to Dubai in either late November or early December till February.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            },{
            "name" : "Which month is the least expensive to visit Dubai?",
            "acceptedAnswer" :
            {
            "text" : "June-August is often the cheapest month to go to Dubai.",
            "@type" : "Answer"
            },
            "@type" : "Question"
            }     ],
      }}
    />

        {loading && (
          <ContentLoader
            speed={2}
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 300 340"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="-2" y="-2" rx="0" ry="0" width="400" height="225" />
            <rect x="12" y="240" rx="0" ry="0" width="135" height="12" />
            <rect x="12" y="265" rx="0" ry="0" width="215" height="12" />
            <rect x="12" y="300" rx="0" ry="0" width="85" height="12" />
            <rect x="190" y="300" rx="0" ry="0" width="100" height="12" />
          </ContentLoader>
        )}
      </div>

      <WingBlank>
            <Modal
              popup
              visible={guestPopUp}
              onClose={onClose("Guest")}
              animationType="slide-up"
              // afterClose={() => { alert('afterClose'); }}
            >
              <div class="container">
                <div className="row">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                    <div className="row">
                      <div className="col-10">
                        <p>Select Guests</p>
                      </div>
                      <div className="col-2">
                        <span onClick={onClose("Guest")}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex">
                            Number of occupants
                            <div className="counter pl-5">
                              <img
                                id="decrement"
                                className="decrement button-counter"
                                src={Minus}
                                alt=""
                                onClick={guestDecrement}
                              />
                              <span id="tottleCount" className="pl-2 pr-2">
                                {guestNumbers}
                              </span>
                              <img
                                id="increment"
                                className="increment button-counter"
                                src={Plus}
                                alt=""
                                onClick={guestIncrement}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex justify-content-between">
                <div className="form-group mt-2 mb-4">
                  <Button
                    onClick={() => onClose("Guest")()}
                    className="w-100 filter-Reset futuraN700-14"
                  >
                    Reset
                  </Button>
                </div>
                <div className="form-group mt-2 mb-4">
                  <Button
                    onClick={() => {
                      onClose("Guest")();
                      setUserInfo({ ...userInfo, adult: guestNumbers });
                      applyFilters();
                    }}
                    className="w-100 filter-Apply futuraN700-14"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={budgetPopUp}
          onClose={onClose("Budget")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Budget</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Budget")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mt-2 mb-2">
                  <label className="w-100 text-left text-xyz montserratNB-16 mt-2 mb-2">
                    Min price{" "}
                  </label>
                  <input
                    type="text"
                    name=""
                    className="form-control inputBorder"
                    placeholder="10"
                    value={price.min}
                    onChange={(e) => {
                      setPrice({ ...price, min: e.target.value });

                      // change(e, "number", "min_search_price", price.min);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mt-2 mb-2">
                  <label className="w-100 text-left text-xyz montserratNB-16 mt-2 mb-2">
                    Max price{" "}
                  </label>
                  <input
                    type="text"
                    name=""
                    className="form-control inputBorder"
                    placeholder="100"
                    value={price.max}
                    onChange={(e) => {
                      setPrice({ ...price, max: e.target.value });
                      // change(e, "number", "max_search_price", price.max);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Budget")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Budget")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={amenitiesPopUp}
          onClose={onClose("Amenities")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Amenities</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Amenities")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "199px" }}>
                {HotelAmenities.map((items, i) => (
                  <ul class="container_ul" key={i}>
                    {items.children?.map((hasChild, childIndex) => {
                      return (
                        <>
                          <label
                            className="w-100 text-left text-xyz montserratNB-16 "
                            key={childIndex}
                          >
                            {hasChild.display}
                          </label>
                          {hasChild[hasChild.key]?.map(
                            (childKey, childKeyIndex) => {
                              return (
                                <>
                                  <li>
                                    <input
                                      type="checkbox"
                                      id={
                                        childKey.key +
                                        childIndex +
                                        childKeyIndex
                                      }
                                      name={childKey.key}
                                      childof={hasChild.key}
                                      onChange={(e) =>
                                        change(
                                          e,
                                          hasChild.type,
                                          childKey.key,
                                          childKey.value
                                        )
                                      }
                                    />
                                    <div class="check d-flex p-1 filter-Am">
                                      <label
                                        for="s-option"
                                        class="label_active p-1"
                                      >
                                        {childKey.display}{" "}
                                      </label>
                                    </div>
                                  </li>
                                </>
                              );
                            }
                          )}
                        </>
                      );
                    })}
                  </ul>
                ))}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Amenities")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Amenities")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={facilitiesPopUp}
          onClose={onClose("Facilities")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Facilities</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Facilities")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "199px" }}>
                {HotelFacilities.map((items, index) => {
                  return (
                    <>
                      {/* <label htmlFor="">{items.display}</label> */}
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type={
                                            hasChild.type == "checkbox"
                                              ? "checkbox"
                                              : "radio"
                                          }
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Facilities")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Facilities")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={addonPopUp}
          onClose={onClose("Add on")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Add on</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Add on")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "119px" }}>
                {HotelAddOn.map((items, index) => {
                  return (
                    <>
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Add on")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Add on")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={ratingPopUp}
          onClose={onClose("Rating")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Rating</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Rating")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "99px" }}>
                {HotelRating.map((items, index) => {
                  return (
                    <>
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Rating")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Rating")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <WingBlank>
        <Modal
          popup
          visible={sortPopUp}
          onClose={onClose("Sort")}
          animationType="slide-up"
          // afterClose={() => { alert('afterClose'); }}
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>Sort by</p>
                  </div>
                  <div className="col-2">
                    <span onClick={onClose("Sort")}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="get_started_radio" style={{ height: "119px" }}>
                {HotelSortby.map((items, index) => {
                  return (
                    <>
                      <ul class="container_ul">
                        {items.children?.map((hasChild, childIndex) => {
                          return (
                            <>
                              <label
                                className="w-100 text-left text-xyz montserratNB-16 "
                                key={childIndex}
                              >
                                {hasChild.display}
                              </label>
                              {hasChild[hasChild.key]?.map(
                                (childKey, childKeyIndex) => {
                                  return (
                                    <>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id={
                                            childKey.key +
                                            childIndex +
                                            childKeyIndex
                                          }
                                          name={childKey.key}
                                          childof={hasChild.key}
                                          onChange={(e) =>
                                            change(
                                              e,
                                              hasChild.type,
                                              childKey.key,
                                              childKey.value
                                            )
                                          }
                                        />
                                        <div class="check d-flex p-1 filter-Am">
                                          <label
                                            for="s-option"
                                            class="label_active p-1"
                                          >
                                            {childKey.display}{" "}
                                          </label>
                                        </div>
                                      </li>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container d-flex justify-content-between">
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Sort")();
                  reset();
                }}
                className="w-100 filter-Reset futuraN700-14"
              >
                Reset
              </Button>
            </div>
            <div className="form-group mt-2 mb-4">
              <Button
                onClick={() => {
                  onClose("Sort")();
                  applyFilters();
                }}
                className="w-100 filter-Apply futuraN700-14"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <MobileNav />
    </div>
    </>
  );
};

export default HotelApartmentPage;
