import React, { useContext, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button } from "@material-ui/core";
import { AppContext } from "../../AppContext";
import { getUser } from "../../../utils/functions";
import Menu from "../../icons/menu.svg";
import Logo from "../../icons/newLogo.svg";
import app from "../../utils/appConfig";
import ProfileIcon from "../../icons/user2.svg";
import authSideBarData from "./authSideBarData";
import sideBarData from "./sideBarData";
import socials from "../../data/socials";
import "./index.scss";
import "./sideBar.scss";

export default function PageHeader(props) {
  const user = getUser();
  const { userInfo } = useContext(AppContext);

  const [sdBar, setSdBar] = useState(sideBarData);
  const [sidebar, setSidebar] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installable, setInstallable] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  // useEffect(() => {
  //   document.body.style.overflow = sidebar ? "hidden" : "auto";
  // }, [sidebar]);

  useEffect(() => {
    if (user) setSdBar(authSideBarData);
  }, []);

  useEffect(() => {
    if (userInfo.id && userInfo.isLoggedIn == true) {
      axios
        .get(`${app.baseUrl}/api/get-profile?user_id=${userInfo.id}`)
        .then((res) => {
          let data = res.data.data;

          setUserDetail({
            profile: data.fld_profile_pic,
            name: data.fld_name,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [userInfo]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      console.log("INSTALL: Success");
    });
  });

  const handleInstallClick = (e) => {
    setInstallable(false);
    deferredPrompt.prompt();

    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted")
        console.log("User accepted the install prompt");
      else console.log("User dismissed the install prompt");
    });
  };

  return (
    <>
      <div
        className={`header ${props.isCustom && "screen-header"}`}
        style={{ zIndex: "999" }}
      >
        <div className="row align-items-center">
          <div className="col-auto" style={{ flex: "1 1" }}>
            {/* <img className="" onClick={showSidebar} src={Menu} alt="menu" /> */}
            <Link to={props.homeLink} className="navbar-brand">
              <img className="" src={Logo} alt="logo"></img>
            </Link>
          </div>

          {/* <div className="col text-center">
            {props.isHome ? (
              <Link to={props.homeLink} className="navbar-brand">
                <img className="" src={Logo} alt="logo"></img>
              </Link>
            ) : (
              <div className="screen-title">
                {props.title}
              </div>
            )}
          </div> */}

          <div
            className="col-auto d-flex justify-content-end"
            style={{ flex: "1 1" }}
          >
            {/* <Link to="" className="p-2">
              <img className="" src={SearchIcon} alt="search" />
            </Link> */}

            {/* <Link to="/notification" className="p-2">
              <img className="" src={NotificationIcon} alt="notification" />
            </Link> */}

            <Link to="/profile" className="p-2">
              {userInfo.isLoggedIn ? (
                <img
                  style={{
                    height: "35px",
                    width: "35px",
                    objectFit: "contain",
                    borderRadius: "999px",
                  }}
                  src={userDetail.profile || ProfileIcon}
                  alt="profile"
                />
              ) : (
                <img src={ProfileIcon} alt="profile" />
              )}
            </Link>
            <img className="" onClick={showSidebar} src={Menu} alt="menu" />
          </div>
        </div>
      </div>

      {/* Side Menu */}
      {sidebar ? (
        <div onClick={() => setSidebar(false)} className="sidebar-bg">
          &nbsp;
        </div>
      ) : null}

      <div className={sidebar ? "sideBar active" : "sideBar"}>
        <div className="menu-container">
          {sdBar.map((item, i) => (
            <Fragment key={i}>
              <p className="futuraBold14 text-left mb-1 mt-2">{item.name}</p>

              {item.subTypes &&
                item.subTypes.length !== 0 &&
                item.subTypes.map(
                  ({ image, type, name, linkTo, action }, i) => (
                    <>
                      {name === "Log out" ? (
                        <ul key={i} className="nav nav-pills flex-column">
                          <a href="#" className="nav-link" onClick={action}>
                            <li className="nav-item">
                              <img
                                className=""
                                src={image}
                                alt="img"
                                srcset={image}
                              />
                              <span className="pl-3 futuraN5-14">{name}</span>
                            </li>
                          </a>
                        </ul>
                      ) : item.name === "Social" ? (
                        <ul key={i} className="nav nav-pills flex-column">
                          <a
                            href={linkTo}
                            className="nav-link"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <li className="nav-item">
                              <img
                                className=""
                                src={image}
                                alt="img"
                                srcset={image}
                              />
                              <span className="pl-3 futuraN5-14">{name}</span>
                            </li>
                          </a>
                        </ul>
                      ) : name ? (
                        <ul key={i} className="nav nav-pills flex-column">
                          {type && type === "extranet" ? (
                            <a
                              href={linkTo}
                              className="nav-link"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <li className="nav-item">
                                <img
                                  className=""
                                  src={image}
                                  alt="img"
                                  srcset={image}
                                />

                                <span className="pl-3 futuraN5-14">{name}</span>
                              </li>
                            </a>
                          ) : (
                            <Link to={linkTo} className="nav-link">
                              <li className="nav-item">
                                <img
                                  className=""
                                  src={image}
                                  alt="img"
                                  srcset={image}
                                />

                                <span className="pl-3 futuraN5-14">{name}</span>
                              </li>
                            </Link>
                          )}
                        </ul>
                      ) : (
                        <>
                          <div className="text-center futuraBkBtN-14 pt-2">
                            <span className="text-secondary">{`${app.appVersion}`}</span>
                          </div>

                          <div className="text-center d-flex align-items-center justify-content-center py-4">
                            {socials.map(({ title, icon, link }) => (
                              <a
                                className="p-2"
                                href={link}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <img width={25} src={icon} alt={title} />
                              </a>
                            ))}
                          </div>

                          {installable ? (
                            <div className="text-center d-flex align-items-center justify-content-center py-2">
                              <Button
                                className="rd-pwa-install"
                                onClick={handleInstallClick}
                              >
                                Install RoomDaddy
                              </Button>
                            </div>
                          ) : null}

                          <div key={i} className="empty-space">
                            &nbsp;
                          </div>
                        </>
                      )}
                    </>
                  )
                )}
            </Fragment>
          ))}
        </div>
      </div>

      {/* <div className="mt-4 side-new-user__wrap">
        <span className="text-secondary">{`${app.appVersion}`}</span>
      </div> */}
    </>
  );
}
