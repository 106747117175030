import CoLivingIcon from "../icons/coLivingIcon.svg";
import PrivateHomeIcon from "../icons/privateHome.svg";
import SofaIcon from "../icons/sofa.svg";
import HotIcon from "../icons/hot.svg";
import TwoBedsIcon from "../icons/twoBeds.svg";
import ParkingIcon from "../icons/parkingIcon.svg";

const PropertyTypeData = [
  {
    name: "Hot Listing",
    icon: HotIcon,
    linkTo: "/hot-listings",
  },
  {
    name: "Hotel room",
    icon: SofaIcon,
    linkTo: "/hotel-rooms",
  },
  {
    name: "Aparthotel",
    icon: TwoBedsIcon,
    linkTo: "/hotel-apartment",
  },
  {
    name: "Coliving",
    icon: CoLivingIcon,
    linkTo: "/co-living",
  },
  {
    name: "Home",
    icon: PrivateHomeIcon,
    linkTo: "/short-term-rental",
  },
  {
    name: "Parking",
    icon: ParkingIcon,
    linkTo: "/parkings",
  },
];

export default PropertyTypeData;
