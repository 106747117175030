import React from "react";
import { IconButton, TextareaAutosize } from "@material-ui/core";
import ImageIcon from "../../icons/imageHill.svg";
import SendIcon from "../../icons/send.svg";
import "./index.scss";

const ChatForm = ({ input, onSetInput, onSend }) => {
  return (
    <div className="chat-form">
      <IconButton className="chat-form__image">
        <img src={ImageIcon} alt="Upload Images" />
      </IconButton>

      <form className="chat-form__form" onSubmit={onSend}>
        <TextareaAutosize
          autoFocus
          autoComplete="off"
          type="text"
          placeholder="Send a message"
          id="send-message-input"
          value={input}
          onChange={(e) => onSetInput(e.target.value)}
        >
          {input}
        </TextareaAutosize>

        <IconButton type="submit" I className="chat-form__submit">
          <img src={SendIcon} style={{height:24,width:24}} alt="Send Message" />
        </IconButton>
      </form>
    </div>
  );
};

export default ChatForm;
