import React from 'react'
import Footer from '../_newComponents/Footer'
import PageHeader from '../_newComponents/pageHeader'
import CarParkingImg from '../images/carpark.png'
import "./style/parkingBlog.scss"

export default function ParkingBlog() {
    return (
        <>
            <PageHeader homeLink="/" />
            <div className='blog_body'>
                <div className='nHeader'>
                    <div className='nHeader_bg'>
                        <p className='montserratN900-45 text-white'>Parkings</p>
                    </div>
                </div>
                <div className=''>
                    <div className='card-body'>
                        <p className='futuraBkBtN-14 text-lightBlack'>
                            A Parking solution for you who own a vehicle or wish to own one but the struggle of parking space availability stops you. Through our Rent-a-parking you can rent an unused/vacant parking space from someone in your building or nearby area. 
                            On the other hand, people with multiple/vacant parking spaces can earn by renting out their parking to the ones in need. 
                        </p>
                    </div>
                    <div class="mb-4 futuraN700-16">
                        <button class="btn-primary w_kit_a_W text-lightBlack m-2">Rent a parking</button>
                        <button class="btn-primary w_kit_a_W text-lightBlack m-2">List a Parking</button>
                    </div>
                </div>
                <div className='card-body pb-5'>
                    <img className='w-100 borderRadius-10' src={`${CarParkingImg}`} alt='RMD_img'/>
                </div>
                <Footer />
            </div>
        </>
    )
}
