import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import { Tabs } from "antd";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import { getUser } from "../../utils/functions";
import PageHeader from "../_newComponents/pageHeader";
import MobileNav from "../_newComponents/MobileNav";
import CoLivingListing from "../_newComponents/CoLivingListing";
import EntireHomeListing from "../_newComponents/EntireHomeListing";
import ParkingListing from "../_newComponents/ParkingListing";
import "./style/myListingPage.scss";


const { TabPane } = Tabs;

const MyListingPage = () => {
  const { userInfo } = useContext(AppContext);
  const user = getUser();

  const [drafts, setDrafts] = useState([]);
  const [flatShare, setFlateShare] = useState({
    publish: [],
    unpublish: [],
    pending: [],
    book: [],
  });
  const [entirePlace, setEntirePlace] = useState({
    publish: [],
    unpublish: [],
    pending: [],
    book: [],
  });
  const [parking, setParking] = useState({
    publish: [],
    unpublish: [],
    pending: [],
    book: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAll = async () => {
      await fetchEntire();
      await fetchFlatshare();
      await fetchDrafts();
      await fetchParking();

      setIsLoading(false);
    };

    if (user.id) fetchAll();
  }, [userInfo]);

  const fetchDrafts = async () => {
    try {
      const res = await axios.get(
        `${app.baseUrl}/api/get-draft-listings/${user.id}`
      );

      setDrafts(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFlatshare = async () => {
    await axios
      .get(`${app.baseUrl}/api/my-listings/${user.id}`)
      .then((res) => {
        const { data } = res.data;
        const _flatShare = {
          publish: [],
          unpublish: [],
          pending: [],
          book: [],
        };

        data.forEach((rec) => {
          if (rec.is_listing_approved === 0) _flatShare.pending.push(rec);
          else if (rec.is_listing_approved === 1) _flatShare.publish.push(rec);
          else if (rec.is_listing_approved === 2)
            _flatShare.unpublish.push(rec);
          else if (rec.is_listing_approved === 3) _flatShare.book.push(rec);
        });

        setFlateShare({ ..._flatShare });
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const fetchEntire = async () => {
    const data = new FormData();

    data.append("user_id", user.id);
    data.append("draw", 1);
    data.append("start",0);
    data.append("search[value]", '');
    data.append("length", 30);
    await axios
      .post(`${app.homeBaseUrl}/api/homes/datatable`, data)
      .then((res) => {
        const { data } = res.data;
        const _entirePlace = {
          publish: [],
          unpublish: [],
          pending: [], 
          book: [],
        };
     
        data.forEach((rec) => {
          if (rec.admin_status === 0) _entirePlace.pending.push(rec);
          else if (rec.status === 1)
            _entirePlace.publish.push(rec);
          else if (rec.status === 0)
            _entirePlace.unpublish.push(rec);
          else if (rec.admin_status === 1) _entirePlace.book.push(rec);
        });

        setEntirePlace({ ..._entirePlace });
      })
      .catch((err) => console.log(err));
  };

  const fetchParking = async () => {
    await axios
      .get(`${app.baseUrl}/api/get-parking-id/${user.id}`)
      .then((res) => {
        const { data } = res.data;
        const _parking = {
          publish: [],
          unpublish: [],
          pending: [],
          book: [],
        };

        data.forEach((rec) => {
          if (rec.prk_status === 0) _parking.pending.push(rec);
          else if (rec.prk_status === 1) _parking.publish.push(rec);
          else if (rec.prk_status === 2) _parking.unpublish.push(rec);
          else if (rec.prk_status === 3) _parking.book.push(rec);
        });

        setParking({ ..._parking });
      })
      .catch((err) => console.log(err));
  };

  console.log("-->", parking);

  return (
    <div className="my-listing" style={{ overflow: "hidden" }}>
      <PageHeader homeLink="/" />

      <div className="layoutBody booking-page" style={{ padding: "75px 0" }}>
        <div className="container-fluid">
          <div className="row px-3">
            <div className="montserratN900-22">
              <p>My Listings</p>
            </div>
          </div>

          <div className="row">
            <Tabs
              className="futuraN-16 text-gray w-100 h-100 custom-tab"
              style={{ paddingBottom: "80px" }}
              defaultActiveKey="1"
              centered
            >
              <TabPane className="custom-subtab" tab="Flatshare" key="1">
                <Tabs
                  className="futuraN-16 text-gray w-100 h-100"
                  defaultActiveKey="1"
                  centered
                >
                  <TabPane tab="Published" key="1">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {flatShare.publish.map((item, i) => (
                      <CoLivingListing item={item} key={i} />
                    ))}

                    {!isLoading && !flatShare.publish.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no published listing</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Pending" key="2">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {flatShare.pending.map((item, i) => (
                      <CoLivingListing item={item} key={i} />
                    ))}

                    {!isLoading && !flatShare.pending.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no pending listing</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Unpublished" key="3">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {flatShare.unpublish.map((item, i) => (
                      <CoLivingListing item={item} key={i} />
                    ))}

                    {!isLoading && !flatShare.unpublish.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no unpublished listing</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Booked" key="4">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {flatShare.book.map((item, i) => (
                      <CoLivingListing item={item} key={i} />
                    ))}

                    {!isLoading && !flatShare.book.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no booked listing</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                </Tabs>
              </TabPane>
             <TabPane className="custom-subtab" tab="Entire Home" key="3">
                <Tabs
                  className="futuraN-16 text-gray w-100 h-100"
                  defaultActiveKey="1"
                  centered
                >
                  <TabPane tab="Publish" key="1">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {entirePlace.publish.map((item, i) => (
                      <EntireHomeListing item={item} key={i} />
                    ))}

                    {!isLoading && !entirePlace.publish?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no Entire listings</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                  <TabPane tab="Unpublish" key="2">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {entirePlace.unpublish.map((item, i) => (
                      <EntireHomeListing item={item} key={i} />
                    ))}

                    {!isLoading && !entirePlace.publish?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no Entire listings</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                  <TabPane tab="Pending" key="3">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {entirePlace.pending.map((item, i) => (
                      <EntireHomeListing item={item} key={i} />
                    ))}

                    {!isLoading && !entirePlace.publish?.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no Entire listings</h3>

                        <p>
                          Post an ad of your spare room to rent it out and earn
                          money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                
                </Tabs>
              </TabPane>
              <TabPane className="custom-subtab" tab="Parking" key="2">
                <Tabs
                  className="futuraN-16 text-gray w-100 h-100"
                  defaultActiveKey="1"
                  centered
                >
                  <TabPane tab="Published" key="1">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {parking.publish.map((item, i) => (
                      <ParkingListing item={item} key={i} />
                    ))}

                    {!isLoading && !parking.publish.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no published parkings</h3>

                        <p>
                          Post an ad of your parking space to rent it out and
                          earn money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Pending" key="2">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {parking.pending.map((item, i) => (
                      <ParkingListing item={item} key={i} />
                    ))}

                    {!isLoading && !parking.pending.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no pending parkings</h3>

                        <p>
                          Post an ad of your parking space to rent it out and
                          earn money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Unpublished" key="3">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {parking.unpublish.map((item, i) => (
                      <ParkingListing item={item} key={i} />
                    ))}

                    {!isLoading && !parking.unpublish.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no unpublished parkings</h3>

                        <p>
                          Post an ad of your parking space to rent it out and
                          earn money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>

                  <TabPane tab="Booked" key="4">
                    {isLoading ? (
                      <div style={{ padding: 15, paddingTop: 0 }}>
                        <ContentLoader
                          speed={2}
                          style={{ width: "100%", height: "100%" }}
                          viewBox="0 0 300 300"
                          backgroundColor="#f3f3f3"
                          foregroundColor="#ecebeb"
                        >
                          <rect
                            x="0"
                            y="0"
                            rx="10"
                            ry="10"
                            width="300"
                            height="125"
                          />
                        </ContentLoader>
                      </div>
                    ) : null}

                    {parking.book.map((item, i) => (
                      <ParkingListing item={item} key={i} />
                    ))}

                    {!isLoading && !parking.book.length ? (
                      <div className="my-booking__no-booking">
                        <h3>You have no booked parkings</h3>

                        <p>
                          Post an ad of your parking space to rent it out and
                          earn money. <Link to="/post-an-ad">Post Ad</Link>
                        </p>
                      </div>
                    ) : null}
                  </TabPane>
                 
                </Tabs>
              </TabPane>

              
            </Tabs>
          </div>
        </div>
      </div>

      <MobileNav />
    </div>
  );
};

export default MyListingPage;
