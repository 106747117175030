import React, { useState, useEffect, useContext } from "react";
//import OneSignal from "react-onesignal";
import { Checkbox, message } from "antd";
import axios from "axios";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import imageCompression from "browser-image-compression";
import Cross from "../../icons/cross.svg";
import Plus from "../../icons/plus.svg";
import { Button } from "@material-ui/core";

export default function StepFour({ to }) {
  const { userInfo, editRoomDetail } = useContext(AppContext);
 
  const [imgs, setImgs] = useState({ room_pics: [], commonfile: [] });
  const [fourStepInfo, setFourStepInfo] = useState({
    photos_listing_title: "",
    fld_room_description: "",
    fld_notes: "",
    //display_picture: "",
    room_pics: [],
    commonfile: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function sentFormData() {
    // if (!editRoomDetail.room_id)
    //   OneSignal.sendTags({
    //     is_post_ad: false,
    //     post_update: null,
    //   })
    //     .then((res) => console.log("Tagged:", res))
    //     .catch((err) => console.log("Error:", err));

    fourStepInfo.Bedspace_id = userInfo.bedspaceId;
    fourStepInfo.Room_id = userInfo.roomId;
    fourStepInfo.building_id = userInfo.propertyId;

    for (let v in fourStepInfo) {
      if (
        v === "room_pics" &&
        !fourStepInfo[v].length &&
        !fourStepInfo.commonfile.length
      ) {
        message.error("At least one image is required");
        console.log(v, fourStepInfo[v]);
        return;
      } else if (
        v === "commonfile" &&
        !fourStepInfo[v].length &&
        !fourStepInfo.room_pics.length
      ) {
        message.error("At least one image is required");
        console.log(v, fourStepInfo[v]);
        return;
      }

      if( v === "fld_notes") continue;

      if (fourStepInfo[v] === "" || fourStepInfo[v] === 0) {
        message.error("One or more fields are empty");
        console.log("-->", v, fourStepInfo[v]);
        return;
      }
    }

    const data = new FormData();

    for (let i in fourStepInfo) {
      if (i === "commonfile" || i === "room_pics") {
        fourStepInfo[i].map((img) => data.append(i + "[]", img.img));
      } else {
        data.append(i, fourStepInfo[i]);
      }
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${app.baseUrl}/api/${to ? to : "step-four"}`, data, config)
      .then((res) => {
        message.success("Processing complete!");
        window.location = "/thank-you";
      })
      .catch((err) => console.log(err));
  }

  function removeImg(from, name) {
    const remaining = fourStepInfo[from].filter((item) => item.name !== name);
    // const filtered = imgs[from].filter((item) => item.name !== name);

    // setImgs({ ...imgs, [from]: filtered });
    updateForm(from, remaining);
  }

  function updateForm(key, value, push = false) {
    setFourStepInfo((prev) => ({
      ...prev,
      [key]: push ? [...prev[key], value] : value,
    }));
    // setFourStepInfo({ ...fourStepInfo, [key]: value });
  }

  async function uploadFiles(key, e) {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        readFile(file)
      );

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1280,
        useWebWorker: true,
      };

      Promise.all(fileArray)
        .then((res) => {
          res.forEach((img, i) => {
            imageCompression(img.img, options)
              .then(async (compressedFile) => {
                const file = new File([compressedFile], compressedFile.name);
                const imgObj = await readFile(file);

                // console.log(
                //   "------>",
                //   i,
                //   e.target.files,
                //   res,
                //   key,
                //   fourStepInfo[key],
                //   [...fourStepInfo[key], imgObj]
                // );

                updateForm(key, imgObj, true);
                // updateForm(key, [...fourStepInfo[key], imgObj]);
                // setImgs({ ...imgs, [key]: [...imgs[key], imgObj] });
              })
              .catch((e) => console.log(e.message));
          });
        })
        .catch((err) => console.log("Error occured while reading the file..."));

      // updateForm(key, imgs[key]);
    }
  }

  function readFile(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = function () {
        let dataURL = reader.result;
        resolve({ name: file.name, src: dataURL, img: file });
      };

      reader.onerror = function () {
        reject("Something went wrong while reading file...");
      };

      reader.readAsDataURL(file);
    });
  }

  function renderPhotos(of, source) {
    console.log(of, source);
    return source.map((photo, i) => (
      <div key={i} className="col-4">
        <div className="gallery mt-3 mb-3">
          <img
            className="prevImg"
            alt="Upload - RoomDaddy"
            src={photo.src}
            key={photo.name}
          />

          <div
            id="ab"
            className="cross"
            onClick={() => removeImg(of, photo.name)}
          >
            <img src={Cross} alt="cancel_icon" />
          </div>
        </div>
      </div>
    ));
  }

  useEffect(() => {
    if (editRoomDetail.room_id) {
      // debugger;
      setFourStepInfo({
        ...fourStepInfo,
        photos_listing_title: editRoomDetail.photos_listing_title
          ? editRoomDetail.photos_listing_title
          : "",
        fld_room_description: editRoomDetail.fld_room_description
          ? editRoomDetail.fld_room_description
          : "",
          fld_notes: editRoomDetail.fld_notes
          ? editRoomDetail.fld_notes
          : "",
        display_picture: editRoomDetail.display_pic || "room_picture",
        room_pics: editRoomDetail.room_images
          ? editRoomDetail.room_images.map((img) => ({
              name: img.fld_name,
              src: img.path,
            }))
          : [],
        commonfile: editRoomDetail.building_images
          ? editRoomDetail.building_images
          : [],
      });
    }
  }, [editRoomDetail]);

  return (
    <div className="py-3 px-2">
      <div className="container pb-4 px-3">
        <div className="row">
          <div className="col pb-4">
            <div className="">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Give your listing a title* (Max 10 words){" "}
              </label>
              <input
                type="text"
                onChange={(e) =>
                  updateForm("photos_listing_title", e.target.value)
                }
                className="form-control inputBorder"
                value={fourStepInfo.photos_listing_title}
              />
            </div>
            <div className="">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
                Tell us more...* (Max 50 words){" "}
              </label>
              <textarea
                type="text"
                onChange={(e) =>
                  updateForm("fld_room_description", e.target.value)
                }
                className="form-control inputBorder"
                value={fourStepInfo.fld_room_description}
              />
            </div>

            <div className="">
              <label className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
                Add Room Notes (Max 8 chars){" "}
              </label>
              <input
                maxLength={8}
                type="text"
                onChange={(e) =>
                  updateForm("fld_notes", e.target.value)
                }
                className="form-control inputBorder"
                value={fourStepInfo.fld_notes}
              />
            </div>

            <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
              Room Photos*
            </h6>
            <input
              id="file"
              onChange={(e) => uploadFiles("room_pics", e)}
              type="file"
              multiple
              style={{ visibility: "visibel" }}
            />
            <div className="label-holder mt-2">
              <label htmlFor="file" className="label">
                <img src={Plus} alt="add_photos" />
                &nbsp;&nbsp;Add photos
              </label>
            </div>
            <div className="result">
              <div className="row">
                {renderPhotos("room_pics", fourStepInfo.room_pics)}
                {/* {renderPhotos("room_pics", imgs.room_pics)} */}
              </div>
            </div>
            <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              Building Photos
            </h6>
            <input
              id="building"
              type="file"
              onChange={(e) => uploadFiles("commonfile", e)}
              multiple
              style={{ visibility: "visibel" }}
            />
            <div className="label-holder mt-2">
              <label htmlFor="building" className="label">
                <img src={Plus} alt="add_photos" />
                &nbsp;&nbsp;Add photos
              </label>
            </div>
            <div className="result">
              <div className="row">
                {renderPhotos("commonfile", fourStepInfo.commonfile)}
                
              </div>
            </div>
            <div className="form-group floating-form-group active">
              {/* <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
                Choose Display Picture
              </h6>

              <Checkbox
                name="display_picture"
                checked={
                  fourStepInfo.display_picture === "room_picture"
                    ? "checked"
                    : false
                }
                onChange={() => updateForm("display_picture", "room_picture")}
              >
                Room pictures
              </Checkbox>

              <Checkbox
                name="display_picture"
                checked={
                  fourStepInfo.display_picture === "common_picture"
                    ? "checked"
                    : false
                }
                onChange={() => updateForm("display_picture", "common_picture")}
              >
                Common pictures
              </Checkbox> */}

              {/* <div className="custom-control custom-switch mt-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="room_picture"
                  name="display_picture"
                  checked={
                    fourStepInfo.display_picture === "room_picture"
                      ? "checked"
                      : false
                  }
                  onChange={() => updateForm("display_picture", "room_picture")}
                />
                <label className="custom-control-label" for="room_picture">
                  &nbsp;&nbsp;Room pictures
                </label>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="common_picture"
                  name="display_picture"
                  checked={
                    fourStepInfo.display_picture === "common_picture"
                      ? "checked"
                      : false
                  }
                  onChange={() =>
                    updateForm("display_picture", "common_picture")
                  }
                />
                <label className="custom-control-label" for="common_picture">
                  &nbsp;&nbsp;Common pictures
                </label>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </form> */}
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Complete
      </Button>
      {/* <button onClick={sentFormData}>send</button> */}
    </div>
  );
}
