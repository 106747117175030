import React, { useState,useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { DatePicker, message, Select } from "antd";
import { useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { message as msg } from "antd";
import { Button } from "@material-ui/core";
import MobileNav from "../_newComponents/MobileNav";
import app from "../utils/appConfig";
import Back from "../icons/back.svg";
import ManIcon from "../icons/man.svg";
import WomanIcon from "../icons/woman.svg";
import CoupleIcon from "../icons/couple.svg";
import "./style/contactUs.scss";
import "react-phone-input-2/lib/style.css";
import { CACHE__AUTH_USER } from "../data/cacheKeys";
import { setLsValue } from "../../utils/functions";

const HireUsPage = ({navigation}) => {
  const history = useHistory();
  const { Option } = Select;
  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [locationFilter, setLocationFilter] = useState("");
  let timer;
  const [isStatusFalse, setIsStatusFalse] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password] = useState("roomdaddy@123");
  const [phone, setPhone] = useState();
  const [phoneshow, setPhoneShow] = useState();
  const [dial_code, setDialCode] = useState();
  const [nationality, setNationality] = useState();
  const [gender, setGender] = useState();
  const [looking_for, setLookingFor] = useState();
  const [move_in, setMovein] = useState();
  const [budget, setBudget] = useState();
  const [city, setCity] = useState();
  const [location, setLocation] = useState();
  const [length_of_stay, setLengthOfStay] = useState();
  //const [subject, setSubject] = useState('New Hire Us Form');
  const [message, setMessage] = useState();
  const [processing, setProcessing] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/getallcountry`)
      .then((res) => setCountryList(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [locationFilter]);

  function fetchCities() {
    axios
      .get(`${app.baseUrl}/api/cities_list/${229}`)
      .then((res) => {
        // console.log("cities API", res);
        setCitiesList(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function fetchLocations() {
    if (locationFilter !== "") {
      axios
        .get(`${app.baseUrl}/api/location_filter/${locationFilter}`)
        .then((res) => {
          console.log(res);
          setLocationsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }


  function onSearch(key, value) {
    //console.log(`${key} ${value}`);
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (key === "location") {
        setLocationFilter(value);
      }
    }, 400);
  }

  const submit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    let data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("dialCode", dial_code);
    data.append("phone", phone);
    data.append("nationality", nationality);
    data.append("gender", gender);
    data.append("looking_for", looking_for);
    data.append("move_in", move_in);
    data.append("budget", budget);
    data.append("city", city);
    data.append("location", location);
    data.append("length_of_stay", length_of_stay);
    //data.append("subject", subject);
    data.append("message", message);

    const config = {
      headers: {
        "content-type": "hireUs_form",
      },
    };

    await axios
      .post(`${app.baseUrl}/api/send_hire_us_email`, data, config)
      .then((res) => {
        msg.success(
          "Your mail has been sent, our agent will contact you shortly"
        );

        setName("");
        setEmail("");
        setPhone("");
        setNationality("");
        setGender("");
        setLookingFor("");
        setMovein("");
        setBudget("");
        setCity("");
        setLocation("");
        setLengthOfStay("");
        setMessage("");
        setProcessing(false);

        let api = `${app.baseUrl}/api/login/${email}/${password}`;

      axios
      .post(api, { email, password })
      .then((res) => {
        let isEmailVerified = res.data.message;
        let status = res.data.status;

        if (status === false) {
          message.error(isEmailVerified);
          setIsStatusFalse(true);
        }

        if (status === true) 
        {
          let response = res.data.data;

          // if (msg)
          //   msg
          //     //.requestPermission()
          //     .then(() => {
          //       return msg.getToken();
          //     })
          //     .then(async (token) => {
          //       console.log("Updated Token : ", token);
          //       let url = `${app.baseUrl}/api/update_device_id?user_id=${response.id}&device_type=A&device_id=${token}`;

          //       try {
          //         let res = await axios.get(url);
          //         console.log("URL res", res);
          //       } catch (err) {
          //         console.log(err);
          //       }
          //     })
          //     .catch((err) => console.log(err));

          let userData = {
            id: response.id,
            name: response.fld_name,
            email: response.email,
            phone:
            response.fld_number && response.country_code
                ? `+${response.country_code}${response.fld_number}`
                : null,
            role: response.fld_user_type,
            profile: response.fld_profile_pic,
            device_id: response.device_id,
            verification_code: response.verification_code,
            remember_token: response.remember_token,
            sex: response.fld_sex,
          };

          let appState = {
            isLoggedIn: true,
            user: userData,
          };

          setLsValue(CACHE__AUTH_USER, appState);
          setIsLoggedIn(true);
          
          
          //window.location.reload();
          //navigation.navigate('ResetPassPage')
          
        }
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
        
      })
      .catch((err) => {
        console.log(err);

        msg.error("Something went wrong");
        setProcessing(false);
      });

      
  };

  return isLoggedIn ? (
    <Redirect to="/password/set" />
  ) : (
    <div className="contact-us">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "28px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost) history.push("/");
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Hire us</div>
          </div>
        </div>
      </div>

      <div className="contact-us__body">
      <div className='nHeader'>
                <div className='nHeader_bg2'>
                    <p className='montserratN900-45 text-white'></p>
                </div>
            </div>
            <br></br>
        <form onSubmit={submit} className="contact-us__form">
          <label htmlFor="name">
            <h4>Your Name</h4>
          </label>

          <input
            id="name"
            type="text"
            className="rd-custom-input"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />

          <label htmlFor="email">
            <h4>Email</h4>
          </label>

          <input
            id="email"
            type="email"
            className="rd-custom-input"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="number"> 
          <h4>Phone number</h4>
          </label>

            <PhoneInput
            inputProps={{
              name: 'phone',
              required: true,
              // autoFocus: true
            }}
              id="phone"
              country={'ae'}
              value={phoneshow}
              className="rd-custom-input"
              
              onChange={(value, country) => {
                setPhone(value.slice(country.dialCode.length));
                setDialCode(country.dialCode);
              }}
                
            />
            <br></br>

            {/* <label htmlFor="nationality">
            <h4>Nationality</h4>
            </label>

          <input
            id="nationality"
            type="text"
            className="rd-custom-input"
            value={nationality}
            onChange={(e) => setNationality(e.target.value)}
          /> */}

          <label htmlFor="nationality">
            <h4>Nationality</h4>
            </label>

          
            <Select
            style={{ marginTop: "5px",
              marginBottom: "10px",
              border: "double",
              fontSize: "large",
              fontWeight: "600" }}
              showSearch
              className="rd-custom-input"
              placeholder="Select a nationality"
              value={nationality}
              required
              onChange={(e) => setNationality(e)}
            >
              {countryList.map((cty, i) => (
                <Option
                style={{ 
              fontSize: "large",
              fontWeight: "600" }}
               key={i} value={cty.nationality}>
                  {cty.nationality}
                </Option>
              ))}
            </Select>

<div className="profile-edit__extra">
<label htmlFor="gender"> 
          <h4>Gender</h4>
          </label>

        <div className="get_started_radio">
          <ul className="container_ul">
            <li className="mr-3 ml-3">
              <input
                type="radio"
                id="s-option"
                name="gender"
                value="male"
                onChange={(e) => setGender(e.target.value)}
              />

              <div className="check input-radio-custom">
                <img
                  className="radio_active"
                  src={ManIcon}
                  alt="Refinancing_hover"
                />
              </div>

              <label
                htmlFor="s-option"
                className="label_active font-weight-bold"
                style={{ color: "#1c1c1c" }}
              >
                Male
              </label>
            </li>

            <li  className="mr-3 ml-3">
              <input
                type="radio"
                id="f-option"
                name="gender"
                value="female"
                onChange={(e) => setGender(e.target.value)}
                
              />

              <div className="check input-radio-custom">
                <img
                  className="radio_active"
                  src={WomanIcon}
                  alt="Purchasing_hover"
                />
              </div>

              <label
                htmlFor="f-option"
                className="label_active font-weight-bold"
                style={{ color: "#1c1c1c" }}
              >
                Female
              </label>
            </li>

            <li className="mr-3 ml-3">
              <input
                type="radio"
                id="f-option"
                name="gender"
                value="couple"
                onChange={(e) => setGender(e.target.value)}
                
              />

              <div className="check input-radio-custom">
                <img
                  className="radio_active"
                  src={CoupleIcon}
                  alt="Purchasing_hover"
                />
              </div>

              <label
                htmlFor="f-option"
                className="label_active font-weight-bold"
                style={{ color: "#1c1c1c" }}
              >
                Couple
              </label>
            </li>
          </ul>
        </div>
      </div>

     <label htmlFor="looking_for">
            <h4>Looking For</h4>
            </label>

          
            <Select
              // showSearch
              style={{ marginTop: "5px",
              marginBottom: "10px",
              border: "double",
              fontSize: "large",
              fontWeight: "600" }}
              required
              className="rd-custom-input"
              placeholder="What are you looking for?"
              value={looking_for}
              onChange={(e) => setLookingFor(e)}
            >
              <Option 
                style={{ 
                fontSize: "large",
                fontWeight: "600" }}
                value={'Home'}>
                  {'Entire Home'}
                </Option>
                <Option 
                  style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'Room'}>
                  {'Room'}
                </Option>
                <Option 
                style={{ 
                fontSize: "large",
                fontWeight: "600" }}
                value={'Bedspace'}>
                  {'Bedspace'}
                </Option>
                <Option 
                style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'Parking'}>
                  {'Parking'}
                </Option>
              
            </Select>

      <label htmlFor="date">
            <h4>Move-in Date</h4>
            </label>

          <input
            id="movein"
            type="date"
            className="rd-custom-input"
            value={move_in}
            min={new Date().toISOString().split('T')[0]}
            required
            onChange={(e) => setMovein(e.target.value)}
          />

        <label htmlFor="budget">
            <h4>Budget</h4>
            </label>

          <input
            id="budget"
            type="number"
            className="rd-custom-input"
            value={budget}
            required
            onChange={(e) => setBudget(e.target.value)}
          />

        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose your City
          </label>
          
            <Select
              showSearch
              style={{ 
                width: "100%",
                marginTop: "5px",
              marginBottom: "10px",
              border: "double",
              fontSize: "large",
              fontWeight: "600" }}
              className="rd-custom-input"
              placeholder={"Select city"}
              value={city}
              onChange={(value) => setCity(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {citiesList.length !== 0 &&
                citiesList.map((city) => (
                  <Option 
                  style={{ 
                    fontSize: "large",
                    fontWeight: "600" }}
                    value={city.id}>{city.name}</Option>
                ))}
            </Select>
          
        </div>

        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Select Location
          </label>

          {/* <div className="form-control inputBorder"> */}
            <Select
              showSearch
              style={{ 
                width: "100%",
                marginTop: "5px",
              marginBottom: "10px",
              border: "double",
              fontSize: "large",
              fontWeight: "600" }}
              className="rd-custom-input"
              placeholder={"Select location"}
              value={location}
              mode="multiple"
              onChange={(value) => setLocation(value)}
              onSearch={(val) => onSearch("location", val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={null}
            >
              {Object.keys(locationsList).length !== 0 &&
                Object.keys(locationsList).map((building) => (
                  <Option 
                    style={{ 
                    fontSize: "large",
                    fontWeight: "600" }}
                    value={locationsList[building]}>{locationsList[building]}</Option>
                ))}
            </Select>
          {/* </div> */}

          {/* {stepOneInfo.city_id !== "" &&
            Object.keys(locationsList).length === 0 && (
              <> */}
          {/* <label className="futuraBkBtN-14">
            *Not able to find you location{" "}
          </label> */}

          {/* <p
            className="futuraBkBtN-14 text-danger"
            onClick={() => setIsModal("location")}
            type="primary"
            shape="circle"
          >
            + Add new location
          </p> */}
          {/* </>
            )} */}
        </div>

<label htmlFor="length_of_stay">
            <h4>Length of stay</h4>
            </label>

            <Select
            style={{ 
              marginTop: "5px",
            marginBottom: "10px",
            border: "double",
            fontSize: "large",
            fontWeight: "600" }}
              showSearch
              className="rd-custom-input"
              placeholder=""
              value={length_of_stay}
              onChange={(e) => setLengthOfStay(e)}
            >
                <Option 
                style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'1'}>
                  {'1 month'}
                </Option>
                <Option 
                style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'2-5'}>
                  {'2-5 months'}
                </Option>
                <Option 
                style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'6+'}>
                  {'More than 6 months'}
                </Option>
              
            </Select>

          

        
          
          
      

          {/* <label htmlFor="subject">
            <h4>Subject</h4>
          </label>

          <input
            id="subject"
            type="text"
            className="rd-custom-input"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          /> */}

          <label htmlFor="message">
            <h4>Any Additional Information</h4>
          </label>

          <textarea
            id="message"
            type="text"
            className="rd-custom-textarea"
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            value={message}
          >
            
          </textarea>

          <Button
            disabled={processing}
            className="bookN w-100 mt-3"
            type="submit"
          >
            {processing ? "Sending..." : "Send"}
          </Button>
        </form>

        {/* <div className="contact-us__card">
          <div className="contact-us__info">
            <h5>Corporate office</h5>
            <p>JUMEIRAH LAKES TOWER</p>
          </div>

          <div className="contact-us__info">
            <h5>Direct contact</h5>
            <p>
              <strong>Email:</strong> info@roomdaddy.com
            </p>
          </div>

          <div className="contact-us__info">
            <h5>Company department</h5>
            <p>To contact our team, please select one of the following:</p>
          </div>

          <div className="contact-us__links">
            <p>Sales</p>
            <p>Careers</p>
            <p>Disputes</p>
            <p>Help</p>
          </div>
        </div> */}
      </div>
      <MobileNav />
    </div>
  );
};

export default HireUsPage;
