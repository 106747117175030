import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { getLsValue } from "../../../utils/functions";
import { CACHE__AUTH_USER } from "../../data/cacheKeys";

let user = {};

function AuthGuard({ children }) {
  try {
    user = getLsValue(CACHE__AUTH_USER);
  } catch {
    return <Redirect to="/login" />;
  }

  if (user && user.isLoggedIn) {
    return children;
  } else {
    return <Redirect to="/login" />;
  }
}

export default AuthGuard;
