import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import { message, Tooltip } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import PageHeader from "../_newComponents/pageHeader";
import MobileNav from "../_newComponents/MobileNav";
import DotsTop from "../images/dots_login-1.svg";
import DotsBottom from "../images/dots_login-2.svg";
import "./style/signup.scss";
import { setLsValue } from "../../utils/functions";
import { CACHE__AUTH_USER } from "../data/cacheKeys";

const SignupPage = () => {
  const history = useHistory();

  const [isEmpty, setIsEmpty] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isRepeatPasswordMatch, setIsRepeatPasswordMatch] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoggedIn(false);
  }, []);

  const signup = async (e) => {
    e.preventDefault();

    let regExp = {
      letters: /^[A-Za-z\s]+$/,
      numbers: /^[0-9]+$/,
      letterNumber: /^(?=[^\s]*?[0-9])(?=[^\s]*?[a-zA-Z])[a-zA-Z0-9]*$/,
      lettersOrNumbers: /^[0-9\a-z\A-Z]{8,15}$/,
      emailFormat: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };

    if (!name || !email || !password || !repassword) {
      setIsEmpty(true);
      setTimeout(() => {
        setIsEmpty(false);
      }, 5000);

      message.error("One or more fields are empty");

      return false;
    } else if (!name.match(regExp.letters)) {
      setIsNameInvalid(true);
      setTimeout(() => {
        setIsNameInvalid(false);
      }, 5000);

      message.error("Please enter a valid name");

      return false;
    } else if (!email.match(regExp.emailFormat)) {
      setIsEmailInvalid(true);
      setTimeout(() => {
        setIsEmailInvalid(false);
      }, 5000);

      message.error("Please enter a valid email");

      return false;
    } else if (password.length < 8 || password.length > 15) {
      setIsPasswordInvalid(true);
      setTimeout(() => {
        setIsPasswordInvalid(false);
      }, 5000);

      message.error("Password must be of 8 to 15 characters");

      return false;
    } else if (password !== repassword) {
      setIsRepeatPasswordMatch(true);
      setTimeout(() => {
        setIsRepeatPasswordMatch(false);
      }, 5000);

      message.error("Please retype the correct password");

      return false;
    } else {
      setIsLoading(true);

      await axios
        .post(`${app.baseUrl}/api/register`, {
          name,
          password,
          email,
          repassword,
          checkfb: "REGISTER",
        })
        .then((res) => {
          setIsLoading(false);

          const { message: msg } = res.data;
          const { status } = res.data;

          if (msg === "Server Error") message.error("An error occurred!");
          if (!status) message.error(msg);
          if (msg === "User Registered Successfully") {
            let status = res.data.status;

            if (status === true) {
              let userData = {
                id: res.data.id,
                email: res.data.email,
              };
              let appState = {
                isLoggedIn: false,
                user: userData,
              };

              setLsValue(CACHE__AUTH_USER, appState);
              setIsLoggedIn(false);

              history.push("/get_email");
            } else {
              setIsLoggedIn(false);
              message.success(res.data.message);
            }
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="signup">
      <PageHeader homeLink="/" />

      <img src={DotsTop} alt="RoomDaddy" className="signup__dots--top" />

      <div className="signup__body">
        <h1>Register</h1>

        <form className="signup__form" onSubmit={signup}>
          <label className="signup__label" htmlFor="name">
            Name
          </label>

          <input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="signup__input"
            type="text"
          />

          <label className="signup__label" htmlFor="email">
            Email
          </label>

          <input
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup__input"
            type="email"
          />

          <label className="signup__label" htmlFor="password">
            Password
          </label>

          {/* <Tooltip
            title="Must be 8 to 15 digits and combination of numbers and alphabets"
            placement="bottom"
            trigger="click"
          > */}
          <input
            id="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="signup__input"
            type="password"
          />
          {/* </Tooltip> */}

          <label className="signup__label" htmlFor="repassword">
            Retype Password
          </label>

          <input
            id="repassword"
            value={repassword}
            onChange={(e) => setRePassword(e.target.value)}
            className="signup__input"
            type="password"
          />

          <div className="signup__notice">
            By clicking button below you are agree to the{" "}
            <Link to="/terms-and-conditions">Terms and Condition</Link> of
            RoomDaddy
          </div>

          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            className="signup__button"
          >
            {isLoading ? "Signing Up..." : "Sign Up"}
          </Button>
        </form>

        <div className="signup__login-caption">
          <span>Already registered?</span>
          <Link to="/login">Log In</Link>
        </div>
      </div>

      <img src={DotsBottom} alt="RoomDaddy" className="signup__dots--bottom" />

      <MobileNav />
    </div>
  );
};

export default SignupPage;
