import React from 'react'
import Footer from '../_newComponents/Footer'
import PageHeader from '../_newComponents/pageHeader'
import Tiles from '../_newComponents/Tiles'
import { Host_tiles_item } from '../data/tilesData'
import image1 from '../images/Rectangle OurHost.png'

export default function OurHost() {
  return (
    <>
        <PageHeader homeLink="/" />
        <div className='blog_body'>
            <div className='nHeader'>
                <div className='nHeader_bg'>
                    <p className='montserratN900-45 text-white'>Our Hosts</p>
                </div>
            </div>
            <div className='card-body'>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    Open your hearts and doors, to Our ROOMies, and unlock the 'income' opportunities which 'come in' along with them. . Host amazing people, provide them comfortable and quality living space, and manage your rentals seamlessly using our online portal. We will provide full transparency in exchange for Your credibility.  <br/>
                    <br/>
                    If you have a room, an apartment, a house that is vacant so avail of this chance to generate revenue with our support and services on our web portal at <a href="/place-an-ad"><b>Host with Us</b></a>.<br/>
                    <br/>
                    OR <br/>
                    <br/>
                    If you are a Hotel, Hotel Apartment or a Holiday Home company, we offer direct connectivity to your company’s standard Channel Manager, please Talk to Sales. Moreover, we offer a standalone Property Management system, which is specifically designed to manage your Monthly Rentals. Please register at <a href="https://hotel.roomdaddy.com/public/#/"><b>Hotel & Hotel Apartment Extranet</b></a> OR <a href="https://home.roomdaddy.com/public/#/"><b>Holiday Home Extranet</b></a>. <br/>
                    <br/>
                    OR <br/>
                    <br/>
                    If You are a property manager with several properties, we provide you with our custom built ERP called <a href="https://crm.roomdaddy.com"><b>BOLT</b></a> which enables a seamless digital experience for all interactions between the tenants and landlords. <br/>
                    <br/>
                    BOLT offers online bookings, online contracts, online payments,  receipts, services requests, move-in & move-out requests, etc. For landlords, PMS features a booking engine, expenses management system, Account payable, account receivable, inventory management, vacancy management and auto push advert listing functionality to Roomdaddy's website and other leading property portals.<br/> 
                </p>
                <div className='mt-5'>
                    <div className='section pb-5'>
                        <img className='w-100 borderRadius-10' src={`${image1}`} alt='RMD_img'/>
                    </div>
                </div>
            </div>
            <div class="mb-4 futuraN700-16">
                <a href="/about-bolt">
                    <button class="btn-primary w_kit_a_W text-lightBlack m-2">More about BOLT</button>
                </a>
                
            </div>
            <div className='card-body'>
                <p class="futuraN700-20 pt-4">What we offer</p>
                <p className='futuraBkBtN-14 text-lightBlack'>
                    We have you covered and provide a hassle free solution to manage all your day to day operations. We bring to you: 
                </p>
            </div>
            <Tiles items={Host_tiles_item}/>
            <Footer/>
        </div>
    </>
  )
}
