import React, { useState } from 'react'
import { Typography } from 'antd';

export default function Ellipsis(props) {
    const { Paragraph } = Typography;
    const [typoExpand, setTypoExpand] =  useState({
        expand: false,
        counter: 0
    })

    const handleClick = () => setTypoExpand({
        expand: !typoExpand.expand,
        counter: !typoExpand.expand ? typoExpand.counter + 0 : typoExpand.counter + 1
    })

    return (
        <>
            <Paragraph 
                key={typoExpand.counter} 
                className={props.classStyle} 
                ellipsis={{ 
                    rows: props.linesToShow, 
                    expandable: true, 
                    symbol: `${props.showText}`, 
                    onExpand: handleClick 
                }}
            >
                {props.paragraph}
            </Paragraph>

            { typoExpand.expand && <a className={props.classStyle} onClick={handleClick}>{props.hideText}</a> }
        </>
    )
}
