import app from "../../utils/appConfig";
import { logOut } from "../../../utils/functions";
import ProfileIcon from "../../icons/manage.svg";
import ListingIcon from "../../icons/listings.svg";
import BookingIcon from "../../icons/booking.svg";
import SavedIcon from "../../icons/saved.svg";
import WalletIcon from "../../icons/wallet.svg";
import FileIcon from "../../icons/fileIcon.svg";
import WorldIcon from "../../icons/worldIcon.svg";
import EventIcon from "../../icons/eventIcon.svg";
import HeadPhoneIcon from "../../icons/headPhoneIcon.svg";
import PrivacyPolicyIcon from "../../icons/privacyPolicyIcon.svg";
import SettingsIcon from "../../icons/settingsIcon.svg";
import FacebookIcon from "../../icons/logo-facebook.svg";
import InstagramIcon from "../../icons/logo-instagram.svg";
import TwitterIcon from "../../icons/logo-twitter.svg";
import YouTubeIcon from "../../icons/logo-youtube.svg";
import LogOutIcon from "../../icons/logOut.svg";

const authSideBarData = [
  {
    name: "My Account",
    subTypes: [
      {
        image: ProfileIcon,
        name: "Profile",
        linkTo: "/profile",
      },
      {
        image: ListingIcon,
        name: "Listing",
        linkTo: "/my-listings",
      },
      {
        image: BookingIcon,
        name: "Bookings",
        linkTo: "/my_booking",
      },
      // {
      //   image: SavedIcon,
      //   name: "Saved",
      //   linkTo: "/",
      // },
      // {
      //   image: WalletIcon,
      //   name: "Rewards and Wallets",
      //   linkTo: "/",
      // },
    ],
  },
  {
    name: "Listing Management Tools",
    subTypes: [
      {
        image: FileIcon,
        type: "extranet",
        name: "Property management system ",
        linkTo: app.crmBaseUrl,
      },
      {
        image: WorldIcon,
        type: "extranet",
        name: "Extranet (for hotels)",
        linkTo: app.hotelBaseUrl,
      },
    ],
  },
  {
    name: "Help and Support",
    subTypes: [
      {
        image: HeadPhoneIcon,
        name: "Contact Us",
        linkTo: "/contact-us",
      },
      {
        image: FileIcon,
        name: "Hire Us",
        linkTo: "/hire-us",
      },
    ],
  },
  {
    name: "Settings and Legal",
    subTypes: [
      {
        image: PrivacyPolicyIcon,
        name: "Privacy policy",
        linkTo: "/privacy-policy",
      },
      {
        image: WorldIcon,
        name: "General Questions",
        linkTo: "/faqs/general-questions",
      },
      {
        image: PrivacyPolicyIcon,
        name: "FAQs about Listing",
        linkTo: "/faqs/listing",
      },
      {
        image: WorldIcon,
        name: "FAQs about Searching",
        linkTo: "/faqs/home-search",
      },
      // {
      //   image: SettingsIcon,
      //   name: "Settings",
      //   linkTo: "/",
      // },
      {
        image: LogOutIcon,
        name: "Log out",
        action: logOut,
      },
      { name: null },
    ],
  },
];

export default authSideBarData;
