import { ButtonBase } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
// import PropertyData from "./propertyTypeData";
import "./index.scss";

export default function PropertyTypes(props) {
  let data = props.data;

  const handleClick = (e) => {
    let currentClick = e.currentTarget.children[0].children[0].classList;

    if (currentClick.contains("active-item"))
      currentClick.remove("active-item");
    else currentClick.add("active-item");
  };

  return (
    <div className="p-type">
      {data?.map((item, i) => (
        <div key={i} className="p-circle text-center" onClick={handleClick}>
          <Link to={item.linkTo}>
            <ButtonBase
              className="p-circle-60 m-auto"
              style={{ height: 60, width: 60 }}
            >
              <img src={item.icon} width={30} alt="icon" />
            </ButtonBase>

            <p className="futuraNN-12 text-white text-center m-0 pt-2">{item.name}</p>
          </Link>
        </div>
      ))}
    </div>
  );
}
