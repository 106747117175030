import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";
import axios from "axios";
import { Button } from "@material-ui/core";
import { Carousel, WingBlank } from "antd-mobile";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import PropertyTypes from "../_newComponents/propertyType";
import PageHeader from "../_newComponents/pageHeader";
import MyLeaderBoardAd from "../_newComponents/GoogleAds";
import HomePageAd from "../_newComponents/GoogleAds/homepage";
import MobileNav from "../_newComponents/MobileNav";
import data from "../data/propertyTypeData";
import { MetaTags } from "react-meta-tags";
// import PropertyTypeData from "../data/propertyTypeData";
// import BannerImage from "../images/homePage.png";
// import Dots from "../images/dots.png";
import Dots2 from "../images/dots2.png";
// import RoundDots from "../images/roundDots.png";
// import UserIcon from "../icons/user.svg";
// import DoorIcon from "../icons/door.svg";
// import BedIcon from "../icons/bedAm.svg";
// import BathIcon from "../icons/bathAm.svg";
import CoLiving from "../icons/ourGuest.svg";
import OurHost from "../icons/ourHosts.svg";
import ServiceAcc from "../icons/serviceAcc.svg";
import NoImage from "../images/no-image.png";
//import BlogBG from "../images/blogBG.svg";
import "./style/homePage.css";
import moment from "moment";
import { stripTags } from "../../utils/functions";
import { decode } from "html-entities";

import RightIcon from "../icons/right.svg";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HomePage_Accordion, HomePage_Group } from '../data/tilesData';
//import Dots3 from '../icons/dots.svg'
import RightArrow from '../icons/right.svg'

// import BannerImage from "../images/mobileBannerImage.png";


export default function HomePageBody() {
  const {
    //selectedHotel,
    //setSelectedHotel,
    userInfo,
    setUserInfo,
    //editRoomDetail,
  } = useContext(AppContext);
  //const { country, city, building, location, roomType } = useParams();

  const [nearbyHotels, setNearbyHotels] = useState([]);
  //const [nearbyHotelAparts, setNearbyHotelAparts] = useState([]);
  //const [smartListings, setSmartListings] = useState([]);
  //const [hotels, setHotels] = useState([]);
  //const [coLivings, setCoLivings] = useState([]);

  const [price, setPrice] = useState({ min: 0, max: 0 });
  //const [toggleFilter, setToggleFilter] = useState(false);
  //const [toggleMap, setToggleMap] = useState(false);
  const [filters, setFilters] = useState([
    ["hotels.admin_status", 1],
    ["hotels.status", 1],
    ["hotels.hotel_type", "room"],
  ]);

  const [nearbyLoading, setNearbyLoading] = useState(true);
  //const [smartListingLoading, setSmartListingLoading] = useState(true);
  //const [hotelLoading, setHotelLoading] = useState(true);
  const [coLivingLoading, setCoLivingLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  //const [api, setApi] = useState("get-property-list");
  //const [pageNumber, setPageNumber] = useState(1);
  //const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    fetchNearbyHotels();
    //fetchNearbyHotelAparts();
    //fetchSmartListings();
    //fetchHotels();
    //fetchCoLivings();
    //fetchBlogs();
  }, []);

  // const fetchBlogs = () => {
  //   axios
  //     .get(`${app.baseUrl}/blogs/wp-json/wp/v2/posts?per_page=2`)
  //     .then((res) => setBlogs(res.data))
  //     .catch((err) => console.log(err));
  // };

  const searchAround = ({ coords }) => {
    console.log(coords);

    if (window.google) {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = new window.google.maps.LatLng(
        coords.latitude,
        coords.longitude
      );

      geocoder.geocode({ latLng: latlng }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            let city;

            for (let i = 0; i < results[0].address_components.length; i++)
              for (
                let b = 0;
                b < results[0].address_components[i].types.length;
                b++
              )
                if (
                  results[0].address_components[i].types[b] ==
                    "administrative_area_level_1" ||
                  results[0].address_components[i].types[b] ==
                    "administrative_area_level_2"
                ) {
                  city = results[0].address_components[i];
                  break;
                }

            setUserInfo({ ...userInfo, address: city.long_name });
          } else console.log("No results found");
        } else console.log("Geocoder failed due to:", status);
      });
    }
  };

  const getLocation = () => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(searchAround, (err) =>
        console.log(err)
      );
    else console.log("GeoLocation is not supported by your browser.");
  };

  const fetchNearbyHotels = () => {
    getLocation();

    axios
      .get(`${app.hotelBaseUrl}/api/first-three-hotels`, {
        search_latitude: userInfo.lat,
        search_longitude: userInfo.lng,
        check_in: userInfo.checkIn,
        check_out: userInfo.checkOut,
        min_price: price.min,
        max_price: price.max,
        currency: "AED",
        where: filters,
      })
      .then((res) => {
        setNearbyHotels(Object.values(res.data).slice(0, 3));
        setNearbyLoading(false);
      })
      .catch((err) => console.log(err));
  };

  // const fetchNearbyHotelAparts = () => {
  //   getLocation();

  //   axios
  //     .get(`${app.hotelBaseUrl}/api/get_hotels_apartments`, {
  //       search_latitude: userInfo.lat,
  //       search_longitude: userInfo.lng,
  //       check_in: userInfo.checkIn,
  //       check_out: userInfo.checkOut,
  //       min_price: price.min,
  //       max_price: price.max,
  //       currency: "AED",
  //       where: filters,
  //     })
  //     .then((res) => {
  //       setNearbyHotelAparts(Object.values(res.data).slice(0, 3));
  //       setNearbyLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const fetchSmartListings = () => {
  //   axios
  //     .get(`${app.baseUrl}/api/get-smart-listing`)
  //     .then((res) => {
  //       setSmartListings(res.data.data.slice(0, 3));
  //       setSmartListingLoading(false);
  //     })
  //     .catch((e) => console.log(e));
  // };

  // const fetchHotels = () => {
  //   axios
  //     .get(`${app.hotelBaseUrl}/api/first-three-hotels`, {
  //       search_latitude: userInfo.lat,
  //       search_longitude: userInfo.lng,
  //       check_in: userInfo.checkIn,
  //       check_out: userInfo.checkOut,
  //       min_price: price.min,
  //       max_price: price.max,
  //       currency: "AED",
  //       where: filters,
  //     })
  //     .then((res) => {
  //       setHotels(Object.values(res.data).slice(0, 3));
  //       setHotelLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const fetchCoLivings = () => {
  //   let data = new FormData();
  //   for (let i in filters) data.append(i, filters[i]);

  //   let config = {
  //     method: "post",
  //     url: `${app.baseUrl}/api/${
  //       api !== "listing_filter" && (country || city || location || roomType)
  //         ? `flat-share${country !== undefined ? "/" + country : ""}${
  //             city !== undefined ? "/" + city : ""
  //           }${location !== undefined ? "/" + location : ""}${
  //             roomType !== undefined ? "/" + roomType : ""
  //           }`
  //         : api
  //     }?page=${pageNumber}`,
  //     headers: {},
  //     data: data,
  //   };

  //   axios(config)
  //     .then((res) => {
  //       let data = [];
  //       res.data.data.map((item) => {
  //         data.push({
  //           bedspace_sku: item.bedspace_sku,
  //           name: item.name,
  //           profile: item.fld_profile_pic,
  //           lat: Number(item.fld_latitude),
  //           lng: Number(item.fld_longitude),
  //           roomName: item.custom_room_name,
  //           bath: item.bath,
  //           rent: item.rent,
  //           images: item.room_images,
  //           bedtype: item.bedtype,
  //           currency: item.currency,
  //           building: item.building_id,
  //           area: item.Area,
  //           price: item.room_expected_rent,
  //           otherHouseRules: item.other_house_rules,
  //           avalability: item.avalability_date,
  //         });
  //       });

  //       setCoLivings(res.data.data.slice(0, 3));
  //       setCoLivingLoading(false);

  //       if (res.data.data.length !== 0) setLoadMore(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoadMore(false);
  //     });
  // };

  // const getRoomBasePrice = (hotel) => {
  //   if (hotel?.room_rent_type && hotel?.room_rent_type?.room_rent_tax === "yes")
  //     return Math.round(hotel?.hotel_rooms?.room_base_price);

  //   let percent = Number(hotel?.hotel_tax) / 100;
  //   let tax = percent * Math.round(hotel?.hotel_rooms?.room_base_price);

  //   return Math.round(
  //     tax + hotel?.hotel_rooms?.room_base_price + hotel?.hotel_tax_amount
  //   );
  // };

  // const getAptBasePrice = (hotel) => {
  //   if (hotel.room_rent_type && hotel.room_rent_type.room_rent_tax === "yes")
  //     return Math.round(hotel.hotel_room_base_price);

  //   let percent = Number(hotel.hotel_tax) / 100;
  //   let tax = percent * Math.round(hotel.hotel_room_base_price);

  //   return Math.round(
  //     tax + hotel.hotel_room_base_price + hotel.hotel_tax_amount
  //   );
  // };
  
  // const customTabOpenClick = (e) => {
  //   let element = document.querySelectorAll('.customTabsBtn');
  //   let tabs = document.querySelectorAll('.tabContent');
  //   let elementBooking = document.querySelectorAll('.customTabsBtn');
  //   let tabsBooking = document.querySelectorAll('.tabContentBooking');
  //   let currentId = e.target.id;
  //   if( currentId == "HotelRoom" || currentId == "HotelApart" || currentId == "Parking"){
  //     [].forEach.call(element, function(el) {
  //       el.classList.remove("customOpenTab");
  //     });
  //     [].forEach.call(tabs, function(el) {
  //       el.style.display = "none";
  //     });
  //     e.target.classList.add("customOpenTab");
  //     document.querySelector(`.${currentId}`).style.display = "block"
  //   }
  //   if( currentId == "HotelRoomBooking" || currentId == "FlatShareBooking" || currentId == "ParkingBooking"){
  //     [].forEach.call(elementBooking, function(el) {
  //       el.classList.remove("customOpenTabBooking");
  //     });
  //     [].forEach.call(tabsBooking, function(el) {
  //       el.style.display = "none";
  //     });
  //     e.target.classList.add("customOpenTabBooking");
  //     document.querySelector(`.${currentId}`).style.display = "block"
  //   }
  // }

  return (
    <div className="pb-4">

      <MetaTags>
        <title>Monthly Hotels,Apartments,Shared Rooms,Roommate-Find,List,Rent</title>
        <meta name="description" content={"Search and rent out your monthly hotels, rooms, flats, apartments, bedspaces, homes and parkings with Roomdaddy"} />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>
      <PageHeader homeLink="/"/>

      <div className="homeBody">
        <div className="mobileBanner">
          <div className="mobileBannerImage">
            <div className="mobileBannerText">
              <p className="mb-0 montserratN900-45">Monthly</p>
              <div className="animated-text">
                <h1 className="line futuraBold18 text-white text-center">Flatshare</h1>
                <h1 className="line futuraBold18 text-white text-center">Rooms</h1>
                <h1 className="line futuraBold18 text-white text-center">Hotel Rooms</h1>
                <h1 className="line futuraBold18 text-white text-center">Roomshare</h1>
                <h1 className="line futuraBold18 text-white text-center">Holiday Homes </h1>
                <h1 className="line futuraBold18 text-white text-center">Apartments Villas </h1>
                <h1 className="line futuraBold18 text-white text-center">Homeshare</h1>
                <h1 className="line futuraBold18 text-white text-center">Accommodation</h1>
                <h1 className="line futuraBold18 text-white text-center">Bedspace</h1>
              </div>
              {/* <p className="futuraBold18 text-white text-center">Flatshare</p> */}
            </div>
            <div className="propertyTypes px-2 py-1 pb-4 pt-4" style={{ width: "100%", overflowY: "auto" }}>
              <PropertyTypes data={data} />
            </div>
          </div>
        </div>
        <div className="background_colour_lightBlack p-2">
          <p className="futuraNN-12 text-white text-center">GCC's 1st Flexible Housing Community</p>
        </div>
        <div className="background_colour_primary p-2">
        <Link to="/hire-us" >
        <p className="futuraNN-12  text-center">Need Help to Find a Room? Our Specialist will Help to Find!</p>
              </Link>
        </div>
        <div className="mt-3">
          {/* <p className="montserratN800-20 mb-0">Coliving redefined</p> */}

          <Carousel
            className="space-carousel"
            cellSpacing={10}
            slideWidth={0.8}
            dots={false}
          >

            <div className="p-0 mb-2">
              <Link to="/hire-us" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={ServiceAcc} alt="" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">Hire Us</p>
                </div>
              </Link>
            </div>

            <div className="p-0 mb-2">
              <Link to="/our-story" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={OurHost} alt="" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">Our Story </p>
                </div>
              </Link>
            </div>

            <div className="p-0 mb-2">
              <Link to="/our-guest" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={CoLiving} alt="" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">Our Guests</p>
                </div>
              </Link>
            </div>

            <div className="p-0 mb-2">
              <Link to="/our-host" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={OurHost} alt="" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">Our Hosts </p>
                </div>
              </Link>
            </div>

            <div className="p-0 mb-2">
              <Link to="/our-partner-blog" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={OurHost} alt="" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">Our Partners </p>
                </div>
              </Link>
            </div>

            <div className="p-0 mb-2">
              <Link to="/about-bolt" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={OurHost} alt="" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">Bolt - PMS</p>
                </div>
              </Link>
            </div>

            <div className="p-0 mb-2">
              <Link to="/smart-listings" className="comunity blueBackground">
                <div className="comunityImg">
                  <img src={ServiceAcc} alt="" className="seracc" />
                </div>

                <div className="pl-2 comunityText">
                  <p className="futuraNBold-16 comunity-text">
                    Smart Listings
                  </p>
                </div>
              </Link>
            </div>
          </Carousel>
        </div>

        <div className="p-2" style={{ height: "fit-content" }}>
          <div className="blueBackground p-3">
            <img className="b" src={Dots2} alt="" srcset="" />
            <p className="futura-18">Got a Place to Rent Out ?</p>

            <div className="text-center">
              <Link to="/place-an-ad">
                <Button type="button" className="publishBtn futuraBold14">
                  Publish a listing
                </Button>
              </Link>
            </div>
          </div>
        </div>
       
       
        {/* Nearby */}
        <div className="p-2" style={{ height: "fit-content" }}>
          <div className="custom container">
            <div className="row p-2 px-4">
              <div className="col-12 p-0">
                <p className="montserratN900-22 text-center">Trending</p>
              </div>

              <ins className="adsbygoogle"
                style={{display: "block"}}
                data-ad-client="ca-pub-9800566720719955"
                data-ad-slot="6456024999"
                data-ad-format="auto"
                data-full-width-responsive="true">
              </ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
          
            </div>
            {/* <div className="row justify-content-center">
              <div className="customTabs">
                <button id="HotelRoom" className="customTabsBtn futura-12 p-0 customOpenTab" onClick={customTabOpenClick}>Hotel Room</button>
                <button id="HotelApart" className="customTabsBtn futura-12 p-0 " onClick={customTabOpenClick}>Hotel Apartment</button>
                <button id="Parking" className="customTabsBtn futura-12 p-0 " onClick={customTabOpenClick}>Entire Home</button>
              </div>
            </div> */}
            <div id="HRoom" className="HotelRoom tabContent">
              {nearbyLoading ? (
                <div style={{ padding: 10 }}>
                  <ContentLoader
                    speed={2}
                    style={{ width: "100%", height: "100%" }}
                    viewBox="0 0 300 250"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                    <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                    <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                  </ContentLoader>
                </div>
              ) : (
                <div className="row">
                  <Carousel
                    className="space-carousel"
                    frameOverflow="visible"
                    cellSpacing={0}
                    slideWidth="300px"
                    dots={false}
                  >
                    {nearbyHotels.map((hotel, i) => (
                      <div key={i} className="col-12 p-2">
                        <Link to={`/hotel/detail/${hotel.hotel_info.hotel_name.replaceAll(" ", "-")}/${hotel?.id}`}>
                          <div className="card">
                            <div className="card-img">
                              <img
                                className="d-block w-100 carousalImg"
                                src={hotel?.hotel_images?.image || NoImage}
                                alt={hotel?.hotel_images?.[0]?.parent}
                              />
                            </div>

                            <div className="card-body pb-2">
                              <div className="futuraBold18">
                                <p className="card__title">
                                  {hotel?.hotel_info?.hotel_name}
                                </p>
                              </div>

                              <div className="futuraBkBtN-14">
                                <p className="text-gray">
                                  {hotel?.hotel_info?.address_line_two}
                                </p>
                              </div>
                            </div>

                            <div className="futuraN700-14 p-3 d-flex align-items-center justify-content-between">
                              <Button className="bookN book-btn">More Info</Button>

                              {/* <div className="montserratNB-16">
                                <p className="text-black">
                                  AED {getRoomBasePrice(hotel)}
                                </p>
                              </div> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="col-12 p-2 empty">
                      <Link to={`/hotel-rooms`}>
                        <div className="card emptyCard">
                            <div>
                              <p className="futuraN700-16 ">See More</p>
                              <img src={`${RightArrow}`} />
                            </div>
                        </div>
                      </Link>
                    </div>
                  </Carousel>
                </div>
              )}
            </div>
            <HomePageAd />
            {/* <div id="hotelapart" className="HotelApart tabContent" style={{display:"none"}}>
            {nearbyLoading ? (
                <div style={{ padding: 10 }}>
                  <ContentLoader
                    speed={2}
                    style={{ width: "100%", height: "100%" }}
                    viewBox="0 0 300 250"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                    <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                    <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                  </ContentLoader>
                </div>
              ) : (
                <div className="row">
                  <Carousel
                    className="space-carousel"
                    frameOverflow="visible"
                    cellSpacing={0}
                    slideWidth="300px"
                    dots={false}
                  >
                    {nearbyHotelAparts.map((apart, i) => (
                      <div key={i} className="col-12 p-2">
                        <Link to={`/hotel/detail/${apart?.id}`}>
                          <div className="card">
                            <div className="card-img">
                              <img
                                className="d-block w-100 carousalImg"
                                src={apart?.hotel_images?.image || NoImage}
                                alt={apart?.hotel_images?.[0]?.parent}
                              />
                            </div>

                            <div className="card-body pb-2">
                              <div className="futuraBold18">
                                <p className="card__title">
                                  {apart?.hotel_info?.hotel_name}
                                </p>
                              </div>

                              <div className="futuraBkBtN-14">
                                <p className="text-gray">
                                  {apart?.hotel_info?.hotel_pin_location}
                                </p>
                              </div>
                            </div>

                            <div className="futuraN700-14 p-3 d-flex align-items-center justify-content-between">
                              <Button className="bookN book-btn">More Info</Button>

                              
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="col-12 p-2 empty">
                      <Link to={`/hotel-apartment`}>
                        <div className="card emptyCard">
                            <div>
                              <p className="futuraN700-16 ">See More</p>
                              <img src={`${RightArrow}`} />
                            </div>
                        </div>
                      </Link>
                    </div>
                  </Carousel>
                </div>
              )}
            </div>
            <div id="parking" className="Parking tabContent" style={{display:"none"}}>
              <div>Entire Home</div>
            </div>  */}
          </div>
        </div>
        
        {/* My current bookings */}
        {/* <div className="p-2" style={{ height: "fit-content" }}>
          <div className="custom container">
            <div className="row p-2 px-4">
              <div className="col-12 p-0">
                <div className='w-100'>
                  <img src={`${Dots3}`} />
                </div>
                <p className="montserratN900-22 text-center">My current bookings</p>
              </div>

              
            </div>
            <div className="row justify-content-center">
              <div className="customTabs">
                <button id="HotelRoomBooking" className="customTabsBtn futura-12 p-0 customOpenTabBooking" onClick={customTabOpenClick}>Hotel Room</button>
                <button id="FlatShareBooking" className="customTabsBtn futura-12 p-0 " onClick={customTabOpenClick}>Hotel Apartment</button>
                <button id="ParkingBooking" className="customTabsBtn futura-12 p-0 " onClick={customTabOpenClick}>Entire Home</button>
              </div>
            </div>
            <div id="HRoomBooking" className="HotelRoomBooking tabContentBooking">
              {nearbyLoading ? (
                <div style={{ padding: 10 }}>
                  <ContentLoader
                    speed={2}
                    style={{ width: "100%", height: "100%" }}
                    viewBox="0 0 300 250"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                    <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                    <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                  </ContentLoader>
                </div>
              ) : (
                <div className="row">
                  <Carousel
                    className="space-carousel"
                    frameOverflow="visible"
                    cellSpacing={0}
                    slideWidth="300px"
                    dots={false}
                  >
                    {nearbyHotels.map((hotel, i) => (
                      <div key={i} className="col-12 p-2">
                        <Link to={`/hotel/detail/${hotel?.id}`}>
                          <div className="card">
                            <div className="card-img">
                              <img
                                className="d-block w-100 carousalImg"
                                src={hotel?.hotel_images?.[0]?.image || NoImage}
                                alt={hotel?.hotel_images?.[0]?.parent}
                              />
                            </div>

                            <div className="card-body pb-2">
                              <div className="futuraBold18">
                                <p className="card__title">
                                  {hotel?.hotel_info?.hotel_name}
                                </p>
                              </div>

                              <div className="futuraBkBtN-14">
                                <p className="text-gray">
                                  {hotel?.hotel_info?.hotel_pin_location}
                                </p>
                              </div>
                            </div>

                            <div className="futuraN700-14 p-3 d-flex align-items-center justify-content-between">
                              <Button className="bookN book-btn">Book now</Button>

                              <div className="montserratNB-16">
                                <p className="text-black">
                                  AED {getRoomBasePrice(hotel)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="col-12 p-2 empty">
                      <Link to={`/`}>
                        <div className="card emptyCard">
                            <div>
                              <p className="futuraN700-16 ">See More</p>
                              <img src={`${RightArrow}`} />
                            </div>
                        </div>
                      </Link>
                    </div>
                  </Carousel>
                </div>
              )}
            </div>
            <div id="flatshareBooking" className="FlatShareBooking tabContentBooking" style={{display:"none"}}>
              <div>FlatShare Booking</div>
            </div>
            <div id="parkingBooking" className="ParkingBooking tabContentBooking" style={{display:"none"}}>
              <div>Parking Booking</div>
            </div> 
          </div>
        </div> */}
        
        <div className="card-flex mt-3 mb-3">
          {HomePage_Group.map((item, index)=>{
            return(
              <>
                <div key={index} className="card-flex-item">
                  <img className="card-flex-item-img" src={`${item.icon}`}/>
                  <p className="futuraBkBtN-14 text-center">{item.name}</p>
                </div>
              </>
            )
          })}
        </div>
        {/* Smart Listing */}
        {/* <div className="p-2" style={{ height: "fit-content" }}>
          <div className="custom container">
            <div className="row p-2 px-4">
              <div className="col-12 p-0">
                <p className="montserratN900-22">Featured Listings</p>
              </div>
            </div>

            {smartListingLoading ? (
              <div style={{ padding: 10 }}>
                <ContentLoader
                  speed={2}
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 300 250"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                  <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                  <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                </ContentLoader>
              </div>
            ) : (
              <div className="row">
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={20}
                  slideWidth="350px"
                  dots={false}
                >
                  {smartListings.map((smartListing, i) => (
                    <div key={i} className="col-12" style={{ height: "375px" }}>
                      <Link
                        to={`/flatshare/list/${smartListing?.bedspace_sku}`}
                      >
                        <div className="card">
                          <div className="card-img">
                            <img
                              className="d-block w-100 carousalImg"
                              src={smartListing?.room_images[0] || NoImage}
                              alt={smartListing?.custom_room_name}
                            />
                          </div>

                          <div className="card-body pb-2">
                            <div className="futuraBold18">
                              <p className="card__title">
                                {smartListing?.custom_room_name}
                              </p>
                            </div>

                            <div className="futuraBkBtN-14">
                              <p className="text-gray">{smartListing?.Area}</p>
                            </div>
                          </div>

                          <div className="futuraN700-14 p-3 d-flex align-items-center justify-content-between">
                            <Button className="bookN book-btn">Book now</Button>

                            <div className="montserratNB-16">
                              <p className="text-black">
                                {smartListing?.currency} {smartListing?.rent}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </div> */}

        {/* Hotel */}
        {/* <div className="p-2" style={{ height: "fit-content" }}>
          <div className="custom container">
            <div className="row p-2 px-4">
              <div className="col-6 p-0">
                <p className="montserratN900-22">Hotels</p>
              </div>
              <div className="col-6 p-0">
                <img className="roundDots" src={RoundDots} alt="" />
              </div>
            </div>

            {hotelLoading ? (
              <div style={{ padding: 10 }}>
                <ContentLoader
                  speed={2}
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 300 250"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                  <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                  <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                </ContentLoader>
              </div>
            ) : (
              <div className="row">
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={20}
                  slideWidth="350px"
                  dots={false}
                >
                  {hotels.map((hotel, i) => (
                    <div key={i} className="col-12">
                      <Link to={`/hotel/detail/${hotel?.id}`}>
                        <div className="card">
                          <div className="card-img">
                            <img
                              className="d-block w-100 carousalImg"
                              src={hotel?.hotel_images?.[0]?.image || NoImage}
                              alt={hotel?.hotel_images?.[0]?.parent}
                            />
                          </div>

                          <div className="card-body pb-2">
                            <div className="futuraBold18">
                              <p className="card__title">
                                {hotel?.hotel_info?.hotel_name}
                              </p>
                            </div>

                            <div className="futuraBkBtN-14">
                              <p className="text-gray">
                                {hotel?.hotel_info?.hotel_pin_location}
                              </p>
                            </div>
                          </div>

                          <div className="futuraN700-14 p-3 d-flex align-items-center justify-content-between">
                            <Button className="bookN book-btn">Book now</Button>

                            <div className="montserratNB-16">
                              <p className="text-black">
                                AED {getRoomBasePrice(hotel)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </div> */}

        {/* Co-Living */}
        {/* <div className="p-2" style={{ height: "fit-content" }}>
          <div className="custom container">
            <div className="row p-2 px-4">
              <div className="col-12 p-0">
                <p className="montserratN900-22">Co-Livings</p>
              </div>
            </div>

            {coLivingLoading ? (
              <div style={{ padding: 10 }}>
                <ContentLoader
                  speed={2}
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 300 250"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                  <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                  <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                </ContentLoader>
              </div>
            ) : (
              <div className="row">
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={20}
                  slideWidth="350px"
                  dots={false}
                >
                  {coLivings.map((coLiving, i) => (
                    <div key={i} className="col-12" style={{ height: "375px" }}>
                      <Link to={`/flatshare/list/${coLiving?.bedspace_sku}`}>
                        <div className="card">
                          <div className="card-img">
                            <img
                              className="d-block w-100 carousalImg"
                              src={coLiving?.room_images[0] || NoImage}
                              alt={coLiving?.custom_room_name}
                            />
                          </div>

                          <div className="card-body pb-2">
                            <div className="futuraBold18">
                              <p className="card__title">
                                {coLiving?.custom_room_name}
                              </p>
                            </div>

                            <div className="futuraBkBtN-14">
                              <p className="text-gray">{coLiving?.Area}</p>
                            </div>
                          </div>

                          <div className="futuraN700-14 p-3 d-flex align-items-center justify-content-between">
                            <Button className="bookN book-btn">Book now</Button>

                            <div className="montserratNB-16">
                              <p className="text-black">
                                {coLiving?.currency} {coLiving?.rent}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </div> */}
        {/* Accordion */}
        <br></br>
        <br></br>
        <div className="w-100 border-top">
          {HomePage_Accordion.map((item, index)=>{
            return(
              <>
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='futuraN700-20'>{item.tabName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='home_story_card'>
                    <div className='homePage_acordion borderRadius-15 badge_colour_primary blur'>
                      <div className='futuraN700-16 text-lightBlack'>
                        <p className='mb-0'>{item.name}</p>
                        <p className='futuraBkBtN-14'>{item.title}</p>
                        <Link className='futuraBkBtN-14' to={`${item.link}`}>
                          Read All <span><img src={RightIcon}></img></span>
                        </Link>
                      </div>
                    </div>
                    <img className='w-100 borderRadius-10' src={`${item.img}`} alt='RMD_img'/>
                  </div>
                </AccordionDetails>
              </Accordion> 
              </>
            )
          })}
        </div>
        <div
          className="p-2 pt-4 pb-4"
          style={{
            height: "fit-content",
            // background: `url(${BlogBG})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="custom container blogs-section">
            <div className="row p-2 px-4">
              <div className="col-12 p-0">
                <p className="montserratN900-22 text-center">Blogs and updates</p>
              </div>
            </div>

            {/* {coLivingLoading ? (
              <div style={{ padding: 10 }}>
                <ContentLoader
                  speed={2}
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 300 250"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="10" ry="10" width="285" height="190" />
                  <rect x="2" y="208" rx="5" ry="5" width="200" height="12" />
                  <rect x="2" y="233" rx="5" ry="5" width="285" height="12" />
                </ContentLoader>
              </div>
            ) : (
              <div className="row">
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={0}
                  slideWidth="330px"
                  style={{ height: "500px" }}
                  dots={false}
                >
                  {blogs.map((blog, i) => (
                    <div key={i} className="col-12" style={{ height: "380px" }}>
                      <Link
                        to={`/blogs/${blog?.slug}`}
                        style={{ color: "#1C2C4A !important" }}
                      >
                        <div className="card">
                          <div className="card-img">
                            <img
                              className="d-block w-100 carousalImg"
                              src={
                                blog?.better_featured_image?.source_url ||
                                NoImage
                              }
                              alt={
                                blog?.better_featured_image?.alt_text
                                  ?.split("-")
                                  ?.join(" ") + " - RoomDaddy"
                              }
                            />
                          </div>

                          <div className="card-body pb-2">
                            <p
                              style={{ fontFamily: "futuraBkBt" }}
                              className="mb-1"
                            >
                              {moment(
                                blog?.date?.split("T")[0],
                                "YYYY-MM-DD"
                              ).format("DD MMM YYYY")}
                            </p>

                            <div className="futuraBold18 mb-2">
                              <p>{(blog?.title?.rendered).substr(0,45)+'...'}</p>
                            </div>

                            <div className="futuraBkBtN-14">
                              <p className="text-gray pb-2">
                                {decode(stripTags(blog?.excerpt?.rendered))}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </div>
            )} */}

            <div className="text-center py-5 pb-4">
              <Link
                to="/blogs"
                // className="text-black bookN"
                 
                
              >
                <img
            src="https://roomdaddy.com/public/frontend/imgs/blogs.png"
            alt="Blogs"
            style={{ width: "-webkit-fill-available" }}
          />
               
              </Link>
            </div>
          </div>
        </div>
      </div>

      <MobileNav />
    </div>
  );
}
