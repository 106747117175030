import React, { useContext, useEffect, useRef } from "react";
import { AutoComplete } from "antd";
import { Button } from "@material-ui/core";
import { AppContext } from "../../AppContext";
import SearchIcon from "../../icons/search.svg";
import RightIcon from "../../icons/right-arrow.svg";
import "./index.scss";

let autoComplete;

const SearchListing = ({
  handleFocus,
  noPadding,
  search,
  isSearch,
  isButton,
}) => {
  const { userInfo, setUserInfo } = useContext(AppContext);
  const autoCompleteRef = useRef(null);

  const handlePlaceSelect = async () => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;

    setUserInfo({
      ...userInfo,
      address: query,
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
    });
  };

  const initAutocomplete = (autoCompleteRef) => {
    if (window.isMap) {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: ["ae", "qa"] },
        }
      );

      autoComplete.setFields([
        "address_components",
        "geometry",
        "formatted_address",
      ]);

      autoComplete.addListener("place_changed", handlePlaceSelect);
    }
  };

  useEffect(() => {
    initAutocomplete(autoCompleteRef);
  }, []);

  return (
    <div className={`search-listing ${noPadding && "p-0"}`}>
      <form
        className="search-listing__container mr-2"
        onSubmit={(e) => {
          e.preventDefault();
          search && search();
        }}
      >
        <img src={SearchIcon} alt="Search" />

        <input
          type="text"
          ref={autoCompleteRef}
          onFocus={handleFocus}
          placeholder={isSearch ? "Enter a location" : "Find a place to stay"}
          // value={userInfo.address}
          // onChange={(e) =>
          //   setUserInfo({ ...userInfo, address: e.target.value })
          // }
        />

        {/* <AutoComplete
          style={{
            background: "inherit",
            borderRadius: "999px",
            boxShadow: "none",
          }}
          className="form-control search"
          placeholder="Find a place to stay"
          ref={autoCompleteRef}
          defaultValue={value}
          onFocus={handleFocus}
          // onChange={(e) =>
          //   setUserInfo({ ...userInfo, address: e.target.value })
          // }
          onSelect={handlePlaceSelect}
          onSearch={(e) => {
            console.log(e);

            search(e);
            setValue(e);
          }}
        /> */}

        {/* {search && (
          <button type="submit" className="search-listing__action">
            <img src={RightIcon} alt="Right Arrow - RoomDaddy" />
          </button>
        )} */}
      </form>

      {isButton && (
        <div>
          <Button
            onClick={search}
            className="bookN-s"
            style={{ fontWeight: "bold" }}
          >
            Search
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchListing;
