import React, { Component  } from 'react'

class HomePageAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>
<ins class="adsbygoogle"
     style={{display:"inline-block",width:"350px",height:"250px"}}
     data-ad-client="ca-pub-9800566720719955"
     data-ad-slot="1949486545"></ins>
        </div>)
    }
}


export default HomePageAd