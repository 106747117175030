const app = {
  // crmBaseUrl: "https://crm.roomdaddy.ae",
  // hotelBaseUrl: "https://hotel.roomdaddy.ae/public",
  // baseUrl: "https://stage.roomdaddy.ae",

  // hotelBaseUrl: "http://127.0.0.1:2020",
  // baseUrl: "https://localhost/rd-public-new-design",

  baseUrl: "https://roomdaddy.co.uk",
  //aeUrl: "https://roomdaddy.ae",
  blogUrl: "https://blogs.roomdaddy.com",
  publicUrl: "https://roomdaddy.com/public_html",
  hotelBaseUrl: "https://hotel.roomdaddy.com/public",
  homeBaseUrl: "https://home.roomdaddy.com/public",
  crmBaseUrl: "https://crm.roomdaddy.com",

  appVersion: "v3.19.4",
};

export default app;
