// tile_items_icons
import clarity_process from '../icons/clarity_process-on-vm-line.svg'
import bx_bx_support from '../icons/bx_bx-support.svg'
import gg_play_list_search from '../icons/gg_play-list-search.svg'
import ic_round_cleaning_services from '../icons/ic_round-cleaning-services.svg'
import bx_bx_user_check from '../icons/bx_bx-user-check.svg'
import bx_bx_mail_send from '../icons/bx_bx-mail-send.svg'
import bx_bx_user_plus from '../icons/bx_bx-user-plus.svg'
import clarity_list_outline_badged from '../icons/clarity_list-outline-badged.svg'
import fluent_payment_16_regular from '../icons/fluent_payment-16-regular.svg'
import carbon_review from '../icons/carbon_review.svg'
import subway_sharing_1 from '../icons/subway_sharing-1.svg'
import ic_outline_interests from '../icons/ic_outline-interests.svg'

// ourPartner_cards_images
import Maintenance from '../images/Maintenance.png'
import Car_Lift from '../images/Car_Lift.png'
import Washerman from '../images/Washerman.png'
import Party_organizer from '../images/Party_organizer.png'
import Car_Rentals from '../images/Car_Rentals.png'
import Photographer from '../images/Photographer.png'
import Food_Delivery from '../images/Food_Delivery.png'
import Cleaning_Service from '../images/Cleaning_Service.png'
import image13 from '../images/image 13.png'
import image14 from '../images/image 14.png'
import image15 from '../images/image 15.png'

// Our_Story
import Reliability from '../icons/Reliability.svg'
import ReliabilityImage from '../images/Reliability.png'
import Convenience from '../icons/Convenience.svg'
import ConvenienceImage from '../images/Convenience.png'
import Community from '../icons/community.svg'
import CommunityImage from '../images/Community.png'
import Evolution from '../icons/Evolution.svg'
import EvolutionImage from '../images/Evolution.png'

// HomePage_Group
import Verified from '../icons/verified.svg'
import bookings from '../icons/bookingpayment.svg'
import portal from '../icons/portal.svg'
import CommunityIcon from '../icons/community.svg'
import OnePlace from '../icons/allinone.svg'
import Reviews from '../icons/reviews.svg'

const Tenants_tile_items = [
    {
        'name': 'Seamless booking process',
        'icon': clarity_process,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Free to contact ',
        'icon': bx_bx_support,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Diverse range of acomodations',
        'icon': gg_play_list_search,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Service requests and tracking ',
        'icon': ic_round_cleaning_services,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Verified Hosts',
        'icon': bx_bx_user_check,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Send Check-Out Notice ',
        'icon': bx_bx_mail_send,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Online Bookings and Contracts',
        'icon': bx_bx_user_plus,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Payment & Booking History ',
        'icon': clarity_list_outline_badged ,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Online Payments and receipts ',
        'icon': fluent_payment_16_regular,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Ratings and Reviews ',
        'icon': carbon_review,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Access to social network',
        'icon': subway_sharing_1,
        'badgeIcon': true,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Housemates interests match',
        'icon': ic_outline_interests,
        'badgeIcon': true,
        'badge': 'Coming soon!'
    },
];

const Host_tiles_item = [
    {
        'name': 'Seamless booking process',
        'icon': clarity_process,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Get messages, calls and emails',
        'icon': bx_bx_support,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Free listing',
        'icon': gg_play_list_search,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Service requests and tracking',
        'icon': ic_round_cleaning_services,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Smart listing verification logo',
        'icon': bx_bx_user_check,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Recieve Check-Out Notice',
        'icon': bx_bx_mail_send,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Online Bookings and Contracts',
        'icon': bx_bx_user_plus,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Send Vacancy Notice',
        'icon': clarity_list_outline_badged ,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Online Payments and receipts',
        'icon': fluent_payment_16_regular,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Respond to Ratings & Reviews',
        'icon': carbon_review,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Access to social network',
        'icon': subway_sharing_1,
        'badgeIcon': true,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Automated Ad Publishing /Unpublishing',
        'icon': ic_outline_interests,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
];

const About_tiles_item = [
    {
        'name': 'Seamless booking process',
        'icon': clarity_process,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Get messages, calls and emails',
        'icon': bx_bx_support,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Free listing',
        'icon': gg_play_list_search,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Service requests and tracking',
        'icon': ic_round_cleaning_services,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Smart listing verification logo',
        'icon': bx_bx_user_check,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Recieve Check-Out Notice',
        'icon': bx_bx_mail_send,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Online Bookings and Contracts',
        'icon': bx_bx_user_plus,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Send Vacancy Notice',
        'icon': clarity_list_outline_badged ,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Online Payments and receipts',
        'icon': fluent_payment_16_regular,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Respond to Ratings & Reviews',
        'icon': carbon_review,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Access to social network',
        'icon': subway_sharing_1,
        'badgeIcon': true,
        'badge': 'Coming soon!'
    },
    {
        'name': 'Automated Ad Publishing /Unpublishing',
        'icon': ic_outline_interests,
        'badgeIcon': false,
        'badge': 'Coming soon!'
    },
];

const Our_partner_card = [
    {
        'name': 'Maintenance',
        'image': Maintenance
    },
    {
        'name': 'Car Lift ',
        'image': Car_Lift 
    },
    {
        'name': 'Washerman',
        'image': Washerman
    },
    {
        'name': 'Party Organizer',
        'image': Party_organizer
    },
    {
        'name': 'Car Rentals',
        'image': Car_Rentals
    },
    {
        'name': 'Photographer',
        'image': Photographer
    },
    {
        'name': 'Food Delivery',
        'image': Food_Delivery
    },
    {
        'name': 'Cleaning Service ',
        'image': Cleaning_Service 
    },
];
const Our_partner = [
    {
        'image': image13,
        'content': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat a pretium purus morbi in mauris, justo ut.'
    },
    {
        'image': image14,
        'content': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat a pretium purus morbi in mauris, justo ut.'
    },
    {
        'image': image15,
        'content': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat a pretium purus morbi in mauris, justo ut.'
    },
];

const Our_Story = [
    {
        icon: Reliability,
        name: 'Reliability',
        title: 'Responsible to arrange the BEST for you ',
        img: ReliabilityImage,
    },
    {
        icon: Convenience,
        name: 'Convenience',
        title: 'A seamless system with flexible options',
        img: ConvenienceImage,
    },
    // {
    //     icon: Community,
    //     name: 'Community',
    //     title: 'Responsible to arrange the BEST for you ',
    //     img: CommunityImage,
    // },
    {
        icon: Evolution,
        name: 'Evolution',
        title: 'We evolve with your choices and needs ',
        img: EvolutionImage,
    },
];

const HomePage_Accordion = [
    {
        tabName: 'Coliving Redefined',
        name: 'Coliving Redefined',
        title: 'Co-living is a modern concept of communal living in which people live in private rooms at a furnished accommodation with shared common areas like, dining room, living room, kitchen, etc. The accommodation includes all basics and some advance amenities required to make the home functional.',
        link: '/home',
        img: EvolutionImage,
    },
    {
        tabName: 'Our Story',
        name: 'About us',
        title: 'When WE left our family and home to move to a new country for better opportunities, nothing felt better, WE experienced alienation and loneliness. WE struggled to build a new life, to make new friends (even acquaintances), to manage expenses, to find quality accommodations that matched our lifestyle. Life felt very mechanical and joyless then.',
        link: '/our-story',
        img: EvolutionImage,
    },
];

const HomePage_Group  = [
    {
        icon: Verified,
        name: "Verified Hosts",
    },
    {
        icon: bookings,
        name: "Online bookings & Payments",
    },
    {
        icon: portal,
        name: "Fully integrated service portal",
    },
    {
        icon: CommunityIcon,
        name: "Great Community",
    },
    {
        icon: OnePlace,
        name: "All in one place",
    },
    {
        icon: Reviews,
        name: "Reviews & Rating",
    },
];

export { 
    Tenants_tile_items, 
    Host_tiles_item,
    About_tiles_item,
    Our_partner_card,
    Our_partner,
    Our_Story,
    HomePage_Accordion,
    HomePage_Group
};
