import vapid from "./vapid.json";

export default function swDev() {
  // navigator.serviceWorker.getRegistrations().then((regs) => {
  //   for (let ref of regs) ref.unregister();
  // });

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i)
      outputArray[i] = rawData.charCodeAt(i);

    return outputArray;
  }

  function determineAppServerKey() {
    return urlBase64ToUint8Array(vapid.publicKey);
  }

  let swUrl = `${process.env.PUBLIC_URL}/sw.js`;

  if ("serviceWorker" in navigator)
    navigator.serviceWorker
      .register(swUrl)
      .then(async (res) => {
        console.log(res);
        const subscription = await res.pushManager.getSubscription();

        res.update();

        return await res.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: determineAppServerKey(),
        });
      })
      .catch((err) => console.error(err));
}
