import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { Button, IconButton } from "@material-ui/core";
import app from "../utils/appConfig";
import { getDateTime, getUser } from "../../utils/functions";
import pusher from "../../utils/pusher";
import ChatMessage from "../_newComponents/ChatMessage";
import ChatForm from "../_newComponents/ChatForm";
import BackIcon from "../icons/back.svg";
import EllipsisIcon from "../icons/ellipsis.svg";
import "./style/chatScreen.scss";

const ChatUi = () => {
  const user = getUser();
  const history = useHistory();

  const { the_chatroom } = useParams();
  const [bed, rec, sen, chatroom_id] = the_chatroom.split("-");

  if (!user || !bed || !rec || !sen || !chatroom_id) history.push("/");

  const [messages, setMessages] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [room, setRoom] = useState({});
  const [input, setInput] = useState(``);
  const [image, setImage] = useState("");
  const [scroller,setScroller]= useState(false)

  const receiver =
    user?.id == messages?.receiver_id
      ? messages?.sender_id
      : messages?.receiver_id;
// useEffect(()=>{
//   if(messages){
// setScroller(true)
//   }
// },[messages])
  useEffect(() => {
    const getMessages = async () => {
      await axios
        .get(`${app.baseUrl}/api/fetchMessages/${bed}/${rec}/${sen}`)
        .then(async (res) => {
          if (res.data.chatroom.id != chatroom_id) history.push("/");

          const receiver =
            user?.id == res.data.receiver_id
              ? res.data.sender_id
              : res.data.receiver_id;

          const sender =
            user?.id == res.data.sender_id
              ? res.data.sender_id
              : res.data.receiver_id;

          setMessages(res.data);
          setRoom(res.data.bedspace[0]);

          if (receiver != user?.id)
            await axios
              .post(`${app.baseUrl}/api/change-message-status`, {
                bedspace_id: bed,
                receiver_id: receiver,
                sender_id: sender,
                chatroom_id: chatroom_id,
              })
              .then((res) => {
                // console.log(res)
              })
              .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    };

    const sendMessageChannel = pusher.subscribe("rd-channel");
    sendMessageChannel.bind("rd-event", (data) => {
      getMessages();
    });

    const deleteMessageChannel = pusher.subscribe("delete-message-channel");
    deleteMessageChannel.bind("App\\Events\\DeleteMessageEvent", (data) => {
      getMessages();
    });

    getMessages();

    return () => {
      sendMessageChannel.unbind_all();
      deleteMessageChannel.unbind_all();
    };
  }, []);

  useEffect(() => {
    const updateSize = () => {
      var objDiv = document.getElementById("scroll-to-bottom-view");
      objDiv.scrollIntoView({ behavior: "smooth" });
    };

    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const ScrollToBottom = () => {
    const ref = useRef();
    useEffect(() => ref.current.scrollIntoView({ behavior: "smooth" }),[messages]);
    return <div id="scroll-to-bottom-view" ref={ref} />;
  };

  const sendMessage = (e) => {
    e.preventDefault();

    document.getElementById("send-message-input").focus();
    document.getElementById("send-message-input").innerText = "";

    if (!input) return;

    const stateMessages = { ...messages };
    stateMessages?.messages?.push({
      text: input,
      is_read: 0,
      sender_id: messages?.sender_id,
      receiver_id: messages?.receiver_id,
      profile_pc: messages?.sender_img,
      fld_name: user?.name,
    });

    const postData = async (
      message,
      image,
      bedspace_id,
      receiver_id,
      sender_id
    ) => {
      await axios({
        method: "post",
        url: `${app.baseUrl}/api/sendMessage`,
        data: {
          message,
          image,
          bedspace_id,
          receiver_id,
          sender_id,
        },
      })
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => console.log(err));
    };

    postData(input, image, room?.id, receiver, user.id);
    setInput("");
  };

  const deleteMessage = async (id) => {
    await axios
      .post(`${app.baseUrl}/api/deleteMessage/${"sender"}/${id}/${user?.id}`)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="chat-screen">
      <ChatForm input={input} onSetInput={setInput} onSend={sendMessage} />

      <div className="chat-screen__messages">
        {messages?.messages &&
          messages.messages.map((message, i) => (
            <ChatMessage
              key={i}
              id={message._id}
              message={message.text}
              sender={message.sender_id}
              receiver={message.receiver_id}
              is_read={message.is_read}
              image={message.image}
              avatar={message.profile_pc}
              name={message.fld_name}
              created_at={getDateTime(message.created_at)}
              onDelete={deleteMessage}
            />
          ))}

        <ScrollToBottom />
      </div>

      <div className="chat-screen__listing-header">
        <div
          className="header screen-header"
          style={{ zIndex: "999", padding: "20px" }}
        >
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                className=""
                src={BackIcon}
                alt="back_Arrow"
                onClick={() => {
                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost)
                    history.push("/message");
                  else history.goBack();
                }}
              />
            </div>

            <div className="col">
              <div className="futuraN700-17">
                {messages?.sender?.id == user?.id
                  ? messages?.receiver_details?.fld_name
                  : messages?.sender?.fld_name}
              </div>
            </div>

            <div className="pr-3">
              <IconButton size="small" onClick={() => setIsShow(!isShow)}>
                <img width={15} src={EllipsisIcon} alt="Ellipsis RoomDaddy" />
              </IconButton>
            </div>
          </div>
        </div>

        <div
          className={`chat-screen__listing-info card row ${
            isShow && "chat-screen__listing-info--show"
          }`}
        >
          <div
            className="col-4 listing-info__img pl-0"
            style={{
              backgroundImage: `url("${room?.path}")`,
            }}
          >
            <img src={room?.path} alt={room?.fld_name} />
          </div>

          <div className="col-8 listing-info__info pr-0">
            <h3>{room?.fld_name}</h3>
            <p>{room?.addr}</p>

            <div>
              <p>
                <span>AED {room?.rent}</span> / month
              </p>

              {/* <Link to={`/flatshare/list/${room?.id}`}> */}
              <Button className="bookN-s">View</Button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatUi;
