import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Carousel, Collapse, DatePicker, message } from "antd";
import { WingBlank } from "antd-mobile";
import { JsonLd } from "react-schemaorg";

import app from "../utils/appConfig";
import { AppContext } from "../AppContext";

import NoImage from "../images/no-image.png";
import UploadProfile from "../images/uploadProfile.jpg";
import Plus from "../images/plus.png";
import Minus from "../images/minus.png";
import StarActiveIcon from "../icons/star_active.svg";
import StarInactiveIcon from "../icons/star_inactive.svg";

import PhoneCall from "../icons/phone-call.svg";
import Email from "../icons/email.svg";
import Back from "../icons/back.svg";
import Next from "../icons/next.svg";
import RatingStar from "../icons/rating.svg";
import PinMark from "../icons/pinMarker.svg";
import UserIcon from "../icons/user.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
import BathIcon from "../icons/bathAm.svg";
import User from "../icons/guest2Icon.svg";
import Cross from "../icons/cross.svg";
import Tick from "../icons/tick.svg";

import { getUser, separateNumbers } from "../../utils/functions";
import { MetaTags } from "react-meta-tags";
import PageHeader from "../_newComponents/pageHeader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import GoogleMap from "../_newComponents/GoogleMap";
import "./style/hotelDetail.css";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import ImageGrid from "../_newComponents/ImageGrid";
import Modal from "../_newComponents/Modal";
import LeafletMap from "../_newComponents/LeafletMap";
import RdLoader from "../_newComponents/RdLoader";
import ContentLoader from "react-content-loader";

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const dateFormat = "YYYY-MM-DD";

export default function HotelDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const user = getUser();

  const history = useHistory();
  const { id } = useParams();
  const { userInfo, setUserInfo } = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [isLeadCalled, setIsLeadCalled] = useState(false);
  const [ip, setIp] = useState();
  const [hotelIndex, setHotelIndex] = useState(-1);
  const [calculating, setCalculating] = useState(false);
  const [hotel, setHotel] = useState({});
  const [reviews, setReviews] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [guestNumbers, setGuestNumbers] = useState(1);
  const [guestPopUp, setGuestPopUp] = useState(false);
  const [dates, setDates] = useState([
    moment(userInfo.checkIn, dateFormat),
    moment(userInfo.checkOut, dateFormat),
  ]);
  const [hackValue, setHackValue] = useState([
    moment(userInfo.checkIn, dateFormat),
    moment(userInfo.checkOut, dateFormat),
  ]);
  const [value, setValue] = useState();
  const [images, setImages] = useState([]);
  const [position, setPosition] = useState([25.2048, 55.2708]);
  const [contactPopUp, setContactPopUp] = useState(false);
  const [phonePopUp, setPhonePopUp] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [chatPopUp, setChatPopUp] = useState(false);
  const [contact, setContact] = useState({
    name: user?.name,
    email: user?.email,
    number: user?.phone,
    message:
      "I saw your ad on Roomdaddy. When is it available for viewing? Thanks.",
  });

  const handleClose = () => setIsModalVisible(false);
  const handleShow = () => setIsModalVisible(true);

  const startDate = (date, dateStr) => {
    setUserInfo({
      ...userInfo,
      checkIn: dateStr,
    });
  };

  const endDate = (date, dateStr) => {
    setUserInfo({
      ...userInfo,
      checkIn: userInfo.checkIn,
      checkOut: dateStr,
      diffInDays: date.diff(userInfo.checkIn, "days"),
    });
  };

  const guestIncrement = () => {
    if (guestNumbers == 6) return;
    else setGuestNumbers((prev) => prev + 1);
  };

  const guestDecrement = () => {
    if (guestNumbers == 1) return;
    else setGuestNumbers((prev) => prev - 1);
  };

  const onOpen = (key) => (e) => {
    e.preventDefault();
    if (key === "Guest") setGuestPopUp(true);
  };

  const onClose = (key) => (e) => {
    if (key === "Guest") setGuestPopUp(false);
  };

  const disabledDate = (current) => {
    let d = new Date();
    return current && current < d.setDate(d.getDate() - 1);
  };

  // const disabledDate = (current) => {
  //   if (!dates || dates.length === 0) return false;

  //   const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
  //   const tooEarly = dates[1] && dates[1].diff(current, "days") > 7;
  //   return tooEarly || tooLate;
  // };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else setHackValue(undefined);
  };

  const collectLead = (type) => {
    const data = new FormData();

    data.append("user_id", user?.id);
    data.append("hotel_id", id);
    data.append("fld_system_ip", ip);
    data.append("lead_type", type);
    data.append("access_type", "A");

    axios
      .post(`${app.hotelBaseUrl}/api/save_phone_lead`, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // axios
    //   .get("http://geoplugin.net/json.gp")
    //   .then((res) => setIp(res.data.geoplugin_request))
    //   .catch((err) => console.log(err));

    axios
      .get(`${app.baseUrl}/api/get_ip`)
      .then((res) => setIp(res.data.ip))
      .catch((err) => console.log(err));

    fetchData();
    // fetchSmartListing();

    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (
      // user?.id &&
      hotel?.id &&
      ip &&
      !isLeadCalled
    ) {
      collectLead("v");
      setIsLeadCalled(true);
    }
  }, [
    hotel?.id,
    ip,
    user?.id,
    isLeadCalled,
  ]);

  const fetchData = () => {
    axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_hotel_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        setHotel(res.data[0]);
        userInfo.policyId = res.data[0].policies.id;

        const imgArr = [];
        res.data[0].hotel_images.forEach((image) => imgArr.push(image.image));

        setImages(imgArr);

        if (res.data[0].hotel_latitude && res.data[0].hotel_longitude)
          setPosition([
            res.data[0].hotel_latitude,
            res.data[0].hotel_longitude,
          ]);

        setLoading(false);
        setCalculating(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  };

  const fetchReviews = () => {
    axios
      .get(`${app.hotelBaseUrl}/api/get_hotel_reviews/${id}`)
      .then((res) => setReviews(res.data))
      .catch((err) => {
        message.error("An error occurred while fetching reviews");
        console.log(err);
      });
  };

  const getRoomPrice = (index) => {
    if (hotel.hotel_active_rooms) {
      let tax = 0;
      if (
        // hotel["hotel_active_rooms"][index]["room_rent_type"][
        //   "room_rent_tax"
        // ] === "no"

        hotel.hotel_active_rooms[index].room_rent_type &&
        hotel.hotel_active_rooms[index].room_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(hotel["hotel_tax"]) / 100) *
          hotel["hotel_active_rooms"][index]["room_base_price"];
        tax =
          tax +
          hotel["hotel_active_rooms"][index]["room_base_price"] +
          hotel["hotel_tax_amount"];
      } else {
        tax = hotel["hotel_active_rooms"][index]["room_base_price"];
      }

      return Math.ceil(tax);
    }
  };

  const getMinPrice = (rooms) => {
    const prices = [];

    for (let i = 0; i < rooms?.length; i++) {
      const price = getRoomPrice(i);
      if (price) prices.push(price);
    }

    return Math.min(...prices);
  };

  const getAvg = (arr) => {
    if (arr.length)
      return (arr?.reduce((a, b) => a + b) / arr?.length).toFixed(1);
  };

  const disabledCheckInDate = (current) =>
    current && current < moment().subtract(1, "d");

  const disabledCheckOutDate = (current) => {
    const checkIn = dates[0];
    const isAfter = checkIn?.isAfter(moment());
    const diffInDays = isAfter ? checkIn?.diff(moment(), "days") : 0;

    return current && current < moment().add(30 + diffInDays, "days");
  };

  const sendEmail = () => {
    const data = new FormData();

    for (let key in contact) {
      if (key !== "number" && !contact[key]) return;
      data.append(key, contact[key]);
    }

    data.append("owner_mail_id", 'info@roomdaddy.com');
    data.append("owner_name", hotel?.hotel_name);
    data.append("own_property_description", hotel?.hotel_desc.substr(0,100));
    // data.append("room_name", coLiving?.room_title);
    data.append("link", window.location.href);

    axios
      .post(`${app.baseUrl}/api/reply-form`, data)
      .then((res) => {
        message.success("Your email has been sent.");
        onClose("Email")();
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
    fetchReviews();
  }, [id, userInfo]);

  return (
    <div
      style={
        isModalVisible
          ? { height: "100%", overflow: "hidden" }
          : { height: "100%" }
      }
    >

      <MetaTags>
        <title>{hotel?.hotel_name}</title>
        <meta name="description" content={hotel?.hotel_desc || "A heaven of luxury & superior living set a usual concept of creating an awesome life system around"} />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>

      <PageHeader isHome homeLink="/" />

            <JsonLd

      item={{
        "@context":"http://schema.org",
        "@type":"Hotel",
        "name": (hotel?.hotel_name),
        "description": (hotel?.hotel_desc),
        "url": window.location.href,
        "hasMap" : "https://www.google.com/maps/place/"+(position),
        "priceRange" : "Prices for upcoming dates start at AED "+ (getMinPrice(hotel?.hotel_active_rooms) != "Infinity" ? (separateNumbers(getMinPrice(hotel?.hotel_active_rooms))) : (5000)) +" per month (We Price Match)",
        "aggregateRating":{"@type":"AggregateRating","ratingValue": hotel?.hotel_rating+".0","reviewCount":"2628"},
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "AE",
          "addressLocality": hotel?.address_line_two,
          "addressRegion": "Dubai",
          "streetAddress": hotel?.hotel_pin_location},
        
        "image": images[0],
        
      }}
      />


      {loading ? (
        <RdLoader />
      ) : (
        <div style={{ paddingTop: "70px" }}>
          <ImageGrid
            images={images}
            title={hotel?.hotel_name}
            share={window.location.href}
            type="R"
          />

          <div className="container-fluid">
            {/* <div className="row">
              <div className="imgGallery grid-container">
                <div className="backIcon">
                  <img
                    onClick={() => history.goBack()}
                    src={Back}
                    alt=""
                    srcset=""
                  />
                </div>

                {hotel?.hotel_images?.map((item, index) => {
                  if (index <= 2)
                    return (
                      <div key={index} className={`grid-item-${index}`}>
                        <img
                          className="grid-img"
                          src={item.image}
                          alt={`${hotel?.hotel_name} - RoomDaddy`}
                        />
                      </div>
                    );
                })}

                {!hotel?.hotel_images?.length && (
                  <div className="grid-item-full">
                    <img
                      className="grid-img"
                      src={NoImage}
                      alt="Empty - RoomDaddy"
                    />
                  </div>
                )}

                {hotel?.hotel_images?.length && (
                  <div className="blurIcon futura-12" onClick={handleShow}>
                    <span>+{hotel?.hotel_images?.length}</span>
                  </div>
                )}
              </div>
            </div>

            <div
              className="filter-backdrop"
              style={{
                display: isModalVisible ? "block" : "none",
                zIndex: isModalVisible ? 999 : -1,
              }}
            >
              <button className="btn text-white" onClick={handleClose}>
                &#10005;
              </button>

              <div className="mt-6 h-100">
                <Carousel autoplay={false} infinite>
                  {hotel?.hotel_images?.map((item, index) => (
                    <div key={index} className="frame">
                      <img
                        className="d-block w-100"
                        alt=""
                        src={item.image}
                        style={{ width: "100%", verticalAlign: "top" }}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div> */}

            <div className="row backgroudLightBlue p-3 d-flex flex-column">
              <div className="montserratN900-22 mb-2">
                <h1
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: 22,
                  lineHeight: "145.9%",
                  color: "#1c2c4a",
                }}
              >{hotel?.hotel_name}</h1>
              </div>

              <div className="pb-2">
                <div className="futura-14">
                  <span className="hotel-listing__star">
                    {[...Array(hotel?.hotel_rating)].map((n) => (
                      <img key={n} src={StarActiveIcon} alt="Star" />
                    ))}

                    {/* {[...Array(5 - hotel?.hotel_rating)].map((n) => (
                      <img key={n} src={StarInactiveIcon} alt="Star" />
                    ))} */}
                  </span>

                  {/* <img src={RatingStar} alt="" srcset="" />

                  <span className="p-1">
                    {hotel?.hotel_rating?.toFixed(1)}
                  </span>

                  <span className="p-1 futuraBkBtN-14">
                    <u>All reviews</u>
                  </span> */}
                </div>
              </div>

              <div className="futuraBkBtN-14">
                <p>{hotel?.address_line_two}</p>
              </div>
            </div>
           
            <div className="row mt-4">
              <div className="col-6 hotel-date-pickers">
                {/* <RangePicker
                  size="small"
                  format="DD-MM-YYYY"
                  defaultValue={[
                    moment(userInfo.checkIn, "YYYY-MM-DD"),
                    moment(userInfo.checkOut, "YYYY-MM-DD"),
                  ]}
                  // value={hackValue || value}
                  disabledDate={disabledDate}
                  onOpenChange={onOpenChange}
                  onChange={(m, str) => {
                    startDate(m, str[0]);
                    endDate(m, str[1]);
                  }}
                /> */}

                <DatePicker
                  className="mb-2"
                  format={dateFormat}
                  disabledDate={disabledCheckInDate}
                  value={dates[0] || moment(userInfo.checkIn, dateFormat)}
                  onChange={(date, dateStr) => {
                    setCalculating(true);

                    setUserInfo({
                      ...userInfo,
                      checkIn: dateStr,
                      checkOut: moment(dateStr, dateFormat).add(30, "days").format(dateFormat),
                    });

                    setDates([
                      date,
                      moment(dateStr, dateFormat).add(30, "days"),
                    ]);
                  }}
                />

                <DatePicker
                  format={dateFormat}
                  disabledDate={disabledCheckOutDate}
                  value={dates[1] || moment(userInfo.checkOut, dateFormat)}
                  onChange={(date, dateStr) => {
                    setCalculating(true);

                    setUserInfo({
                      ...userInfo,
                      checkOut: dateStr,
                      diffInDays:
                        dates[0] && date ? date.diff(dates[0], "days") : 30,
                    });

                    setDates([moment(userInfo.checkIn, dateFormat), date]);
                  }}
                />

                {/* <div className="guest mt-3" onClick={onOpen("Guest")}>
                  <img src={User} alt="" srcset="" />
                  <span className="pl-3">{userInfo.adult} guest</span>
                </div> */}
              </div>

              <div className="col-6">
                <div className="text-right">
                  <p className="futuraBkBtN-14 m-0">
                    {hotel?.hotel_active_rooms?.length} room type(s) available
                  </p>

                  <p className="montserratNB-16 m-0 py-1">
                    {calculating ? (
                      <ContentLoader
                        speed={2}
                        width={90}
                        height={19}
                        viewBox="0 0 90 19"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="0"
                          ry="0"
                          width="90"
                          height="19"
                        />
                      </ContentLoader>
                    ) : getMinPrice(hotel?.hotel_active_rooms) != "Infinity" ? (
                      hotel?.hotel_currency +
                      " " +
                      separateNumbers(getMinPrice(hotel?.hotel_active_rooms))
                    ) : (
                      <span onClick={() => setPhonePopUp(true)}>
                        Inquire Now
                      </span>
                    )}
                  </p>

                  <div className="montserratNB-16">
                    <Link
                      to={`/hotel/detail/${id}/rooms`}
                      className="p-1 montserratN400-16 VRooms"
                    >
                      <u>See rooms</u>
                    </Link>

                    <img src={Next} className="ml-1" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <ResponsiveAd />
            <div className="mt-4">
              <div className="card">
                <div className="card-body">
                  <div className="montserratN900-22 pl-3">
                    <p className="">Property Details</p>
                  </div>
                  <div className="futuraBkBtN-14 pl-3">
                    <p className="py-2">
                      {hotel.hotel_desc ||
                        "A heaven of luxury & superior living set a usual concept of creating an awesome life system around"}
                    </p>
                  </div>
                  <div className="futuraBkBtN-14 d-flex">
                    <div className="m-2">
                      <img src={PinMark} alt="" />
                    </div>
                    <div className="m-2">
                      <p className="mb-0">{hotel?.hotel_pin_location}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="montserratN900-22 pl-3">
                <p className="">Hotel Features</p>
              </div>

              <p className="futuraN700-17 pl-3">Most popular facilities</p>

              <ul className="hotel-feature-list p-0">
                {hotel?.services?.facilities_array?.map((item, i) => (
                  <li key={i} className="p-1">
                    <span
                      style={{
                        textTransform: "capitalize",
                        fontFamily: "futuraBkBt",
                      }}
                    >
                      <img src={Tick} alt="" className="mr-2" />
                      {item.split("_").join(" ").split("-").join(" ")}
                    </span>
                  </li>
                ))}
              </ul>

              {/* <ul className="hotel-feature-list p-0">
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Restaurant
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Hot tub / Jacuzzi
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Bar
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  24/7 Front Desk
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Sauna Spa
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Fitness Centre
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Garden
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Terrace
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Airport Shuttle
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Spa &amp; Welness Centre
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Room Service
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Air Conditioning
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Water Park
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Electric Vehicle Charging Station
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Swimming Pool
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Beach
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Smoking Rooms
                </li>
                <li>
                  <span>
                    <img src={Tick} alt="" />
                  </span>
                  Family Rooms
                </li>
              </ul>*/}

              {/* <div className="mt-4">
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  expandIconPosition={"right"}
                >
                  <Panel
                    className="futuraN700-17"
                    header="Media and technology"
                    key="1"
                  >
                    <p className="futuraBkBtN-14 pl-3">
                      A dog is a type of domesticated animal. Known for its
                      loyalty and faithfulness, it can be found as a welcome guest
                      in many households across the world.
                    </p>
                  </Panel>
                  <Panel
                    className="futuraN700-17"
                    header="Food and drink"
                    key="2"
                  >
                    <p className="futuraBkBtN-14 pl-3">
                      A dog is a type of domesticated animal. Known for its
                      loyalty and faithfulness, it can be found as a welcome guest
                      in many households across the world.
                    </p>
                  </Panel>
                  <Panel
                    className="futuraN700-17"
                    header="Services and extra"
                    key="3"
                  >
                    <p className="futuraBkBtN-14 pl-3">
                      A dog is a type of domesticated animal. Known for its
                      loyalty and faithfulness, it can be found as a welcome guest
                      in many households across the world.
                    </p>
                  </Panel>
                  <Panel
                    className="futuraN700-17"
                    header="Outdor and view"
                    key="4"
                  >
                    <p className="futuraBkBtN-14 pl-3">
                      A dog is a type of domesticated animal. Known for its
                      loyalty and faithfulness, it can be found as a welcome guest
                      in many households across the world.
                    </p>
                  </Panel>
                  <Panel
                    className="futuraN700-17"
                    header="Entertainment and family services"
                    key="5"
                  >
                    <p className="futuraBkBtN-14 pl-3">
                      A dog is a type of domesticated animal. Known for its
                      loyalty and faithfulness, it can be found as a welcome guest
                      in many households across the world.
                    </p>
                  </Panel>
                  <Panel className="futuraN700-17" header="Polices" key="6">
                    <div className="futuraN700-14 pl-3">
                      <div className="futuraBkBtN-14">
                        <p className="mb-0">Check-in: After 12:00 PM</p>
                        <p className="mb-0">Rental contract</p>
                        <p className="mb-0">Maintenance service</p>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div> */}
            </div>

            <div className="hotel-discount mt-4">
              <div className="montserratN900-22 pl-3">
                <p>Applicable Discount</p>
              </div>

              <div className="px-3 pt-2">
                <TableContainer style={{ boxShadow: "none !important" }}>
                  <Table className="discount-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Restaurant/Bar</TableCell>
                        <TableCell>Discount(%)</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {hotel?.discounts?.map((elem, i) => (
                        <TableRow key={i}>
                          <TableCell>{elem.restaurant_name}</TableCell>
                          <TableCell>{elem.restaurant_type}</TableCell>
                          <TableCell>{elem.discount_applicable}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* {hotel?.discounts?.map((elem, i) => (
                  <div key={i} className="my-2">
                    <p className="mb-1">
                      <strong>Name:</strong> {elem.restaurant_name}
                    </p>

                    <p className="mb-1">
                      <strong>Type:</strong> {elem.restaurant_type}
                    </p>

                    <p className="mb-1">
                      <strong>Discount:</strong> {elem.discount_applicable}
                    </p>
                  </div>
                ))} */}
              </div>
            </div>

            <div className="mt-5 float-left w-100">
              <div className="montserratN900-22 pl-3">
                <p className="">Reviews</p>
              </div>
              <WingBlank>
                <Carousel
                  className="space-carousel"
                  frameOverflow="visible"
                  cellSpacing={10}
                  slideWidth={0.9}
                  infinite
                  dots={false}
                >
                  {reviews?.map((item, i) => (
                    <div className="card" key={i}>
                      <div className="card-body">
                        {/* <div className="row">
                          <div className="col-3">
                            <div className="icon icon-50">
                              <figure
                                className="background"
                                style={{ backgroundImage: `url(${UploadProfile})` }}
                              >
                                <img
                                  src={UploadProfile}
                                  alt=""
                                  style={{ display: "none" }}
                                />
                              </figure>
                            </div>
                          </div>
                          <div className="col-9">
                            <p className="futuraN700-18 mb-0">
                              Mary, <span>32</span>
                            </p>
                          </div>
                        </div> */}

                        <div className="futuraBkBtN-14 p-3">
                          <p>{item.review}</p>
                        </div>
                      </div>
                    </div>
                  ))}

                  {!reviews?.length && (
                    <div className="card">
                      <div className="card-body">
                        <div className="futuraBkBtN-14 p-3">
                          <p>No reviews found for this listing</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Carousel>
              </WingBlank>

              {/* <div className="pt-2 futuraN700-14">
                <Button type="button" className="bookN">
                  Show all reviews
                </Button>
              </div> */}
            </div>

            {/* map */}
            <div
              className="map-box-new mt-5 float-left w-100"
              style={{ paddingBottom: "100px" }}
            >
              <div className="montserratN900-22 pl-3 mb-1">
                <p className="">Location</p>
              </div>

              <div className="futuraBkBtN-14 pl-3 mb-3">
                <p>{hotel.hotel_pin_location}</p>
              </div>

              <LeafletMap position={position} />
            </div>
          </div>
          {/* <WingBlank>
            <Modal
              popup
              visible={guestPopUp}
              onClose={onClose("Guest")}
              animationType="slide-up"
              // afterClose={() => { alert('afterClose'); }}
            >
              <div className="container">
                <div className="row">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                    <div className="row">
                      <div className="col-10">
                        <p>Select Guests</p>
                      </div>
                      <div className="col-2">
                        <span onClick={onClose("Guest")}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex futuraBkBtN-14 pl-3">
                            Number of occupants
                            <div className="counter pl-3">
                              <img
                                id="decrement"
                                className="decrement button-counter"
                                src={Minus}
                                alt=""
                                onClick={guestDecrement}
                              />
                              <span id="tottleCount" className="pl-2 pr-2">
                                {guestNumbers}
                              </span>
                              <img
                                id="increment"
                                className="increment button-counter"
                                src={Plus}
                                alt=""
                                onClick={guestIncrement}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex justify-content-between">
                <div className="form-group mt-2 mb-4">
                  <Button className="w-100 filter-Reset futuraN700-14">
                    Reset
                  </Button>
                </div>
                <div className="form-group mt-2 mb-4">
                  <Button className="w-100 filter-Apply futuraN700-14">
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </WingBlank> */}

          <Modal visible={contactPopUp}>
            <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
              <div className="row">
                <div className="col-10 pl-5">
                  <p>Contact</p>
                </div>

                <div className="col-2 pr-4">
                  <span onClick={() => setContactPopUp(false)}>
                    <img src={Cross} alt="Close - RoomDaddy" />
                  </span>
                </div>
              </div>
            </div>

            <div className="p-4" style={{ fontFamily: "futuraBkBt" }}>
              <div className="row">
                <div className="col-3 pr-0 py-1">Name</div>
                <div className="col-8">
                  <strong>{hotel?.contact_name}</strong>
                </div>
              </div>

              <div className="row">
                <div className="col-3 pr-0 py-1">Email</div>
                <div className="col-8">
                  <strong>{hotel?.contact_email}</strong>
                </div>
              </div>

              <div className="row">
                <div className="col-3 pr-0 py-1">Phone No.</div>
                <div className="col-8">
                  {/* <strong>{hotel?.alternate_phone_number}</strong> */}
                  <strong>{hotel?.phone_number}</strong>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            popup
            visible={emailPopUp}
            onClose={() => setEmailPopUp(false)}
            animationType="slide-up"
          >
            <div class="container">
              <div className="">
                <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                  <div className="row">
                    <div className="col-10 text-left">
                      <p>Email</p>
                    </div>

                    <div className="col-2">
                      <span onClick={() => setEmailPopUp(false)}>
                        <img src={Cross} alt="" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your name </label>

                  <input
                    type="name"
                    name="userName"
                    className="rd-custom-input"
                    placeholder="Name"
                    value={contact.name}
                    onChange={(e) =>
                      setContact({ ...contact, name: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your email </label>

                  <input
                    type="email"
                    name="userEmail"
                    className="rd-custom-input"
                    placeholder="username@email.com"
                    value={contact.email}
                    onChange={(e) =>
                      setContact({ ...contact, email: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2">
                  <label className="futura-14 text-xyz my-2">Your phone </label>

                  <input
                    type="phone"
                    name="userPhone"
                    className="rd-custom-input"
                    placeholder="+971 xxx xxx xxx"
                    maxLength={32}
                    value={contact.number}
                    onChange={(e) =>
                      setContact({ ...contact, number: e.target.value })
                    }
                  />
                </div>

                <div className="form-group my-2 mb-3">
                  <label className="futura-14 text-xyz my-2">
                    Your message{" "}
                  </label>

                  <textarea
                    className="rd-custom-textarea"
                    rows="3"
                    cols="48"
                    onChange={(e) =>
                      setContact({ ...contact, message: e.target.value })
                    }
                  >
                    {contact.message}
                  </textarea>
                </div>

                <div className="form-group mt-2 mb-4">
                  <Button
                    className="w-100 bookN futuraN700-14 EmailMsgHotel"
                    onClick={() => {
                      sendEmail();
                      collectLead("e");
                    }}
                  >
                    Send Email
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal visible={phonePopUp}>
            <div className="p-4 text-center">
              <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
                Would you like to call the host?
              </p>

              <div className="d-flex justify-content-center align-items-center">
                <Button
                  className="bookN-s mr-3 CallHotelClick"
                  onClick={() => setPhonePopUp(false)}
                >
                  {/* <a href={`tel:${hotel?.alternate_phone_number}`}>Yes</a> */}
                  <a href={`tel:${hotel?.phone_number}`}
                  onClick={() => collectLead("p")}
                  >Yes</a>
                  {/* <a href={`whatsapp://send?phone=${hotel?.phone_number}&text=I'm interested to know about ${hotel?.hotel_name}`} 
                  class="bla"
                  onClick={() => collectLead("p")}
                  >Yes</a> */}
                </Button>

                <Button
                  className="bookN-s"
                  onClick={() => setPhonePopUp(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </Modal>

          <div className="bottom-navigation-wrap py-3" style={{ zIndex: 9999 }}>
            <div className="pl-2 pr-1 w-100">
              {/* <a href={`tel:${hotel?.alternate_phone_number}`}></a> */}
              <a href="#">
                <Button
                  className="bookN-s futuraN700-14 CallHotel"
                  style={{ width: "100%" }}
                  onClick={() => setPhonePopUp(true)}
                >
                  <img src={PhoneCall} alt="" />
                  <span className="p-1">WhatsApp</span>
                </Button>
              </a>
            </div>

            <div className="pr-2 pl-1 w-100">
              <a href="#">
                <Button
                  className="bookN-s futuraN700-14 EmailHotel"
                  style={{ width: "100%" }}
                  onClick={() => setEmailPopUp(true)}
                >
                  <img src={Email} alt="" />
                  <span className="p-1">Email</span>
                </Button>
              </a>
            </div>

            {/* <div className="futuraN700-14">
              <Link
                id="hotel-room-mob-view-rooms"
                to={`/hotel/detail/${id}/rooms`}
              >
                <Button className="bookN w-100 VRooms">View rooms</Button>
              </Link>

              <Button
                className="bookN w-100"
                onClick={() => setContactPopUp(true)}
              >
                Contact
              </Button>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
