import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import axios from "axios";
import { Checkbox, DatePicker, message, Row, TimePicker } from "antd";
import { Button } from "@material-ui/core";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import { getUser } from "../../utils/functions";
import { sr } from "../data/serviceRequestData";
import ServiceRequest from "../images/serviceRequest.svg";
import BackIcon from "../icons/back.svg";
import CrossIcon from "../icons/cross_dark.svg";
import PlusIcon from "../icons/plus.svg";
import "./style/newServiceRequest.scss";
import { WingBlank } from "antd-mobile";
import Modal from "../_newComponents/Modal";
import moment from "moment";

const dateFormat = "YYYY-MM-DD";

const NewServiceRequest = () => {
  const user = getUser();

  const history = useHistory();
  const { booking_id } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [time, setTime] = useState("");
  const [request, setRequest] = useState({});
  const [booking, setBooking] = useState({});
  const [type, setType] = useState(null);
  const [desc, setDesc] = useState(null);
  const [images, setImages] = useState([]);
  const [presence, setPresence] = useState(false);
  const [preferTime, setPreferTime] = useState(null);
  const [preferDate, setPreferDate] = useState(null);

  useEffect(() => {
    axios
      .get(`${app.baseUrl}/api/my-bookings-details/${booking_id}`)
      .then((res) => {
        if (res.data.data[0].id != user.id) history.push("/my_booking");
        setBooking(res.data.data[0]);
      })
      .catch((err) => console.log(err));
  }, [booking_id]);

  function updateForm(value) {
    setImages(value);
  }

  function removeImg(name) {
    let remaining = images.filter((item) => item.name != name);
    updateForm(remaining);
  }

  function uploadFiles(e) {
    if (e.target.files) {
      const fileArray = Array.from(e.target.files).map((file) =>
        readFile(file)
      );
      Promise.all(fileArray)
        .then((res) => {
          console.log(res);
          updateForm(res);
        })
        .catch((err) => console.log("error occured while reading file"));
    }
  }

  function readFile(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        let dataURL = reader.result;
        resolve({ name: file.name, src: dataURL, img: file });
      };
      reader.onerror = function () {
        reject("something went wrong while reading file");
      };
      reader.readAsDataURL(file);
    });
  }

  function renderPhotos() {
    return images.map((img, i) => (
      <div kye={i} className="col-4">
        <div className="gallery mt-3 mb-3">
          <div
            id="ab"
            className="cross custom-cross"
            onClick={() => removeImg(img.name)}
          >
            <img
              src={CrossIcon}
              alt="cancel_icon"
              style={{ marginRight: "-1px", opacity: "1 !important" }}
            />
          </div>

          <img className="prevImg" src={img.src} key={img.name} alt="upload" />
        </div>
      </div>
    ));
  }

  const submitData = () => {
    if (presence && (!preferTime || !preferDate)) {
      message.warning("Please select time and date");
      return;
    }

    if (!type) {
      message.warning("Please select a type of service.");
      return;
    }

    const data = new FormData();

    data.append("fld_tenant_id", user.id);
    data.append("fld_booking_id", booking_id);
    data.append("description", desc);
    data.append("fld_complaint_type", type);
    data.append("fld_presence_needed", presence);
    data.append("fld_prefer_time", preferTime);
    data.append("fld_prefer_date", preferDate);

    if (images.length)
      for (let i = 0; i < images.length; i++) {
        if (i > 2) break;
        data.append(`image${i + 1}`, images[i].img);
      }

    const config = {
      header: { "content-type": "multipart/form-data" },
    };

    axios
      .post(`${app.baseUrl}/api/add_complaint`, data, config)
      .then((res) => {
        setRequest(res.data.complaint);
        setShowModal(true);
        setTime(moment().format("h:mm A"));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="new-sr">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={BackIcon}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push(`/my_booking/co-living/${booking_id}`);
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Service Request</div>
          </div>
        </div>
      </div>

      <div className="new-sr__body">
        <div className="new-sr__img-container">
          <img src={ServiceRequest} alt="New Service Request" />
        </div>

        <div className="new-sr__checkboxes">
          <Row>
            {sr.map(({ icon, title, value }, i) => (
              <div key={i} className={i % 2 === 0 ? "sr-col-60" : "sr-col-40"}>
                <Checkbox
                  checked={type === value ? "checked" : false}
                  value={value}
                  onChange={() => {
                    if (type === value) return;
                    setType(value);
                  }}
                  className="sr-checkbox"
                >
                  <img src={icon} alt={title} />
                  <span>{title}</span>
                </Checkbox>
              </div>
            ))}
          </Row>
        </div>

        <div className="new-sr__presence">
          <p>
            <Checkbox
              checked={presence}
              value={presence}
              onChange={() => setPresence(!presence)}
            >
              My presence is required
            </Checkbox>
          </p>

          {presence ? (
            <div className="new-sr__presence-active">
              <div className="new-item">
                <h3>Preferred Date</h3>

                <DatePicker
                  defaultValue={preferDate}
                  onChange={(_, str) => setPreferDate(str)}
                />
              </div>

              <div className="new-item">
                <h3>Preferred Time</h3>
                <TimePicker onChange={(_, str) => setPreferTime(str)} />
              </div>
            </div>
          ) : null}
        </div>

        <div className="new-sr__inputs">
          <div className="description">
            <label htmlFor="description">
              <h3>Description</h3>
            </label>

            <textarea
              id="description"
              onChange={(e) => setDesc(e.target.value)}
            >
              {desc}
            </textarea>
          </div>

          <div className="new-sr__images">
            <h3 className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
              Upload images (max 3)
            </h3>

            <input
              id="file"
              onChange={(e) => uploadFiles(e)}
              type="file"
              multiple
              style={{ visibility: "visible" }}
            />

            <div className="label-holder mt-2">
              <label htmlFor="file" className="upload-file-btn">
                <img src={PlusIcon} alt="add_photos" />
                <span>Add photos</span>
              </label>
            </div>

            <div className="result bg-white">
              <div className="row">{renderPhotos()}</div>
            </div>
          </div>
        </div>

        <div className="new-sr__submit">
          <Button className="bookN" onClick={submitData}>
            Submit
          </Button>
        </div>
      </div>

      <WingBlank>
        <Modal
          popup
          visible={showModal}
          onClose={() => setShowModal(false)}
          animationType="slide-up"
        >
          <div className="d-flex align-items-center justify-content-between p-3">
            <div style={{ opacity: 0 }}>&nbsp;&nbsp;</div>

            <h5
              className="mb-0"
              style={{ color: "#1c1c1c", fontFamily: "futuraMdBt" }}
            >
              Confirmation
            </h5>

            <div>
              <img
                src={CrossIcon}
                alt="Close"
                onClick={() => {
                  setShowModal(false);

                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost)
                    history.push(`/my_booking/co-living/${booking_id}`);
                  else history.goBack();
                }}
              />
            </div>
          </div>

          <div
            style={{
              fontFamily: "futuraBkBt",
              color: "#1C1C1C",
              fontSize: "16px",
            }}
            className="container text-center px-4 pt-3 pb-5"
          >
            <p>
              Service successfully requested at <strong>{time}</strong>
              <br />
              Request number <strong>{request?.fld_id}</strong>
            </p>
          </div>
        </Modal>
      </WingBlank>
    </div>
  );
};

export default NewServiceRequest;
