import React from "react";
import { Button } from "@material-ui/core";
import CrossIcon from "../../icons/cross_dark.svg";
import "./index.scss";

const ConfirmPopup = ({
  title,
  onConfirm,
  onCancel,
  isGeneral,
  isModal,
  isVerify,
  children,
}) => {
  return (
    <div className="rd-confirm">
      <div
        className={`rd-confirm__container ${
          isModal && "rd-confirm__container--width"
        }`}
      >
        <div className="rd-confirm__title">
          <p>&nbsp;</p>
          <h3>{title}</h3>

          <div className="close">
            <img onClick={onCancel} src={CrossIcon} alt="Cancel" />
          </div>
        </div>

        <div className="rd-confirm__body">
          <p>{children}</p>
        </div>

        {isGeneral ? (
          <div className="rd-confirm__footer">
            <Button
              variant="outlined"
              className="btn-normal"
              onClick={onCancel}
            >
              Ok
            </Button>
          </div>
        ) : isVerify ? (
          <div className="rd-confirm__footer">
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="rd-confirm__footer">
            <Button
              variant="outlined"
              id="popup-cancel-btn"
              className="btn-cancel"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              id="popup-confirm-btn"
              className="btn-confirm"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmPopup;
