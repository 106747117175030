import React, { useEffect } from "react";
import "./index.scss";

const Modal = ({ visible, children }) => {
  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "unset";
  }, [visible]);

  return (
    <div className={`rd-modal ${visible && "rd-modal--active"}`}>
      <div
        className={`rd-modal__container ${
          visible && "rd-modal__container--active"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
