import React, { useState, useEffect, useContext } from "react";
//import OneSignal from "react-onesignal";
import { Select, Input, message } from "antd";
import { WingBlank } from "antd-mobile";
import Modal from "../../_newComponents/Modal";
// import SearchLocationInput from "../../components/GoogleAutoComplete";
import { AppContext } from "../../AppContext";
import app from "../../utils/appConfig";
import axios from "axios";
import Cross from "../../icons/cross.svg";
import { Button } from "@material-ui/core";
import Checkbox from "antd/lib/checkbox/Checkbox";
import SearchListing from "../../_newComponents/SearchListing";
import { getUser } from "../../../utils/functions";

const { Option } = Select;
let timer;
let contryId;

const uaeCitiesLatLng = [
  {
    id: 48973,
    name: "Abu Dhabi",
    latlong: {
      lat: 24.466667,
      lng: 54.366669,
    },
  },
  {
    id: 48974,
    name: "Ajman",
    latlong: {
      lat: 25.4052,
      lng: 55.5136,
    },
  },
  {
    id: 48974,
    name: "Al Khaimah",
    latlong: {
      lat: 25.6741,
      lng: 55.9804,
    },
  },
  {
    id: 48981,
    name: "ash-Shariqah",
    latlong: {
      lat: 25.3462,
      lng: 55.4211,
    },
  },
  {
    id: 48975,
    name: "Dubai",
    latlong: {
      lat: 25.2048,
      lng: 55.2708,
    },
  },
  {
    id: 48980,
    name: "Fujeirah ",
    latlong: {
      lat: 25.1288,
      lng: 56.3265,
    },
  },
  {
    id: 48976,
    name: "Ras Al-Khaimah",
    latlong: {
      lat: 25.800694,
      lng: 55.9762,
    },
  },
  {
    id: 48977,
    name: "Sharjah",
    latlong: {
      lat: 25.348766,
      lng: 55.405403,
    },
  },
  {
    id: 48979,
    name: "Umm al Qaywayn",
    latlong: {
      lat: 25.5325,
      lng: 55.5492,
    },
  },
  {
    id: 33093,
    name: "Doha",
    latlong: {
      lat: 25.2854,
      lng: 51.531,
    },
  },
];
export default function StepOne({ nextStep, to }) {
  const user = getUser();
  const { userInfo, setUserInfo, editRoomDetail } = useContext(AppContext);

  // const [isCoords, setIsCoords] = useState(false);
  // const [color, setColor] = useState("white");
  // const [coordsMsg, setCoordsMsg] = useState("");

  const [loc, setLoc] = useState(userInfo.address);

  const [citiesList, setCitiesList] = useState([]);
  const [buildingsList, setBuildingsList] = useState({});
  const [locationsList, setLocationsList] = useState([]);
  const [locationFilter, setLocationFilter] = useState("");
  const [buildingFilter, setBuildingFilter] = useState("");
  const [isModal, setIsModal] = useState("");
  const [newBuilding, setNewBuilding] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [stepOneInfo, setStepOneInfo] = useState({
    country_id: "",
    city_id: "",
    property_type: "",
    fld_building: "",
    fld_area: "",
    fld_address: "",
    fld_latitude: userInfo.lat,
    fld_longitude: userInfo.lng,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const getLocation = () => {
  //   setIsCoords(true);

  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       ({ coords }) =>
  //         setStepOneInfo({
  //           ...stepOneInfo,
  //           fld_latitude: coords.latitude,
  //           fld_longitude: coords.longitude,
  //         }),
  //       (err) => console.log(err)
  //     );

  //     console.log(stepOneInfo.fld_latitude, stepOneInfo.fld_longitude);

  //     setColor("#1DAC97");
  //     setCoordsMsg("Coordinates are set ✓");
  //   } else {
  //     setColor("#E04025");
  //     setCoordsMsg("Unable to set your coordinates ✗");

  //     console.log("GeoLocation is not supported by your browser.");
  //   }
  // };

  async function sentFormData() {
    // if (!editRoomDetail.room_id)
    //   OneSignal.sendTags({
    //     is_post_ad: true,
    //     post_update: Math.floor(Date.now() / 1000),
    //   })
    //     .then((res) => console.log("Tagged:", res))
    //     .catch((err) => console.log("Error:", err));

    let { address, lat, lng } = userInfo;

    stepOneInfo.fld_address = address;
    stepOneInfo.fld_latitude = lat;
    stepOneInfo.fld_longitude = lng;

    for (let v in stepOneInfo) {
      if (
        stepOneInfo.property_type !== "villa" &&
        v === "fld_building" &&
        !stepOneInfo[v]
      ) {
        console.log(v, stepOneInfo[v]);
        //message.error("One or more fields are empty building");
        //return;
      } else if (v !== "fld_building" && !stepOneInfo[v]) {
        console.log(v, stepOneInfo[v]);
        message.error("One or more fields are empty");
        return;
      }
    }

    let data = new FormData();
    for (let i in stepOneInfo) data.append(i, stepOneInfo[i]);
    data.append("fld_tanent", user.id);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    await axios
      .post(`${app.baseUrl}/api/${to ? to : "step-one"}`, data, config)
      .then((res) => {
        message.success("Step one completed");
        setUserInfo({ ...userInfo, propertyId: res.data.id });
        nextStep(1);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    fetchBuildings();
  }, [buildingFilter]);

  useEffect(() => {
    fetchLocations();
  }, [locationFilter]);

  // useEffect(() => {
  //   console.log("target", stepOneInfo);
  // }, [stepOneInfo]);

  useEffect(() => {
    if (editRoomDetail.room_id) {
      setStepOneInfo({
        fld_building_id: editRoomDetail.fld_building_id,
        property_type: editRoomDetail.property_type
          ? editRoomDetail.property_type
          : "",
        city_id: editRoomDetail.city_id ? editRoomDetail.city_id : "",
        country_id: editRoomDetail.country_id ? editRoomDetail.country_id : 229,
        fld_building: editRoomDetail.property_id
          ? editRoomDetail.property_id
          : 0,
        fld_area: editRoomDetail.area_id ? editRoomDetail.area_id : 0,
        fld_address: editRoomDetail.fld_address
          ? editRoomDetail.fld_address
          : "",
        fld_tanent: editRoomDetail.fld_tanent ? editRoomDetail.fld_tanent : 191,
        fld_latitude: editRoomDetail.fld_latitude
          ? editRoomDetail.fld_latitude
          : 0,
        fld_longitude: editRoomDetail.fld_longitude
          ? editRoomDetail.fld_longitude
          : 0,
      });
    }
  }, [editRoomDetail]);

  function fetchCities() {
    axios
      .get(`${app.baseUrl}/api/cities_list/${229}`)
      .then((res) => {
        // console.log("cities API", res);
        setCitiesList(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function fetchBuildings() {
    if (buildingFilter !== "") {
      axios
        .get(`${app.baseUrl}/api/building_filter/${buildingFilter}`)
        .then((res) => {
          console.log(res);
          setBuildingsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }

  function fetchLocations() {
    if (locationFilter !== "") {
      axios
        .get(`${app.baseUrl}/api/location_filter/${locationFilter}`)
        .then((res) => {
          console.log(res);
          setLocationsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }

  function addNewBuilding(key) {
    if (newBuilding !== "") {
      axios
        .post(`${app.baseUrl}/api/add_building`, {
          country_id: stepOneInfo.country_id,
          city_id: stepOneInfo.city_id,
          name: newBuilding,
        })
        .then((res) => {
          console.log(res);
          setBuildingsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }

  function addNewLocation(key) {
    if (newLocation !== "") {
      axios
        .post(`${app.baseUrl}/api/add_location`, {
          country_id: stepOneInfo.country_id,
          city_id: stepOneInfo.city_id,
          name: newLocation,
        })
        .then((res) => {
          console.log(res);
          setLocationsList(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }

  function updateFirstForm(property, value) {
    if (property == "city_id") {
      uaeCitiesLatLng.map((item, index) => {
        if (item.id == value) {
          setUserInfo({
            ...userInfo,
            lat: item.latlong.lat,
            lng: item.latlong.lng,
          });
        }
      });
    }
    console.log(`selected form ${value}`);
    setStepOneInfo({ ...stepOneInfo, [property]: value });
    if (editRoomDetail.room_id) {
      editRoomDetail[property] = value;
    }
  }

  function onSearch(key, value) {
    //console.log(`${key} ${value}`);
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (key === "building") {
        setBuildingFilter(value);
      } else if (key === "location") {
        setLocationFilter(value);
      }
    }, 400);
  }

  function searchLoc(e) {
    console.log("-->", e);
  }

  /**
   * // Qatar country id 178
    // Doha city id 33093
   */

  return (
    <div className="py-3 px-2">
      <div className="container pb-4 px-3">
        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose your Country*
          </label>

          <div className="form-control inputBorder">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"United Arab Emirates"}
              value={stepOneInfo.country_id || editRoomDetail.country_id}
              onChange={(value) => updateFirstForm("country_id", value)}
            >
              <Option key="1" value={229}>
                United Arab Emirates
              </Option>

              <Option key="2" value={178}>
                Qatar
              </Option>
            </Select>
          </div>
        </div>

        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose your City*
          </label>
          <div className="form-control inputBorder">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Select city"}
              value={
                stepOneInfo.city_id
                  ? stepOneInfo.city_id
                  : editRoomDetail.city_name
              }
              onChange={(value) => updateFirstForm("city_id", value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {stepOneInfo.country_id === 229 &&
                citiesList.length !== 0 &&
                citiesList.map((city) => (
                  <Option value={city.id}>{city.name}</Option>
                ))}

              {stepOneInfo.country_id === 178 && (
                <Option value={33093}>Doha</Option>
              )}
            </Select>
          </div>
        </div>

        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Choose property type?*
          </label>
          <div className="mr-3"></div>
          {/* <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={
                stepOneInfo.property_type === "villa" ? "checked" : false
              }
              name="optradio"
              className="custom-control-input"
              id="villa"
              onChange={() => updateFirstForm("property_type", "villa")}
            />
            <label
              className="custom-control-label futuraBkBtN-14 pl-5"
              for="villa"
            >
              Villa
            </label>
          </div> */}

          <Checkbox
            checked={stepOneInfo.property_type === "villa" ? "checked" : false}
            name="optradio"
            onChange={() => updateFirstForm("property_type", "villa")}
          >
            Villa
          </Checkbox>

          <Checkbox
            checked={
              stepOneInfo.property_type === "apartment" ? "checked" : false
            }
            name="optradio"
            onChange={() => updateFirstForm("property_type", "apartment")}
          >
            Apartment
          </Checkbox>

          {/* <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={
                stepOneInfo.property_type === "apartment" ? "checked" : false
              }
              name="optradio"
              className="custom-control-input"
              id="apartment"
              onChange={() => updateFirstForm("property_type", "apartment")}
            />
            <label
              className="custom-control-label futuraBkBtN-14 pl-5"
              for="apartment"
            >
              Apartment
            </label>
          </div> */}
        </div>

        {stepOneInfo.property_type === "apartment" && (
          <div className="form-group">
            <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              Select Building
            </label>
            <div
              className="form-control inputBorder"
              style={{ marginBottom: 8 }}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={"Select building"}
                value={
                  editRoomDetail.fld_building
                    ? editRoomDetail.fld_building
                    : stepOneInfo.property_id
                }
                onChange={(value) => updateFirstForm("property_id", value)}
                onSearch={(val) => onSearch("building", val)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={null}
              >
                {Object.keys(buildingsList).length !== 0 &&
                  Object.keys(buildingsList).map((building) => (
                    <Option value={building}>{buildingsList[building]}</Option>
                  ))}
              </Select>
            </div>

            {/* {stepOneInfo.city_id !== "" &&
              Object.keys(buildingsList).length === 0 && (
                <> */}
            {/* <label className="futuraBkBtN-14">
              *Not able to find you building{" "}
            </label> */}

            <p
              className="futuraBkBtN-14 text-danger"
              onClick={() => setIsModal("building")}
              type="primary"
              shape="circle"
            >
              + Add new building
            </p>
            {/* </>
              )} */}
          </div>
        )}

        <div className="form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Select Location*
          </label>

          <div className="form-control inputBorder">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder={"Select location"}
              value={
                editRoomDetail.fld_area
                  ? editRoomDetail.fld_area
                  : stepOneInfo.fld_area
              }
              onChange={(value) => updateFirstForm("fld_area", value)}
              onSearch={(val) => onSearch("location", val)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              notFoundContent={null}
            >
              {Object.keys(locationsList).length !== 0 &&
                Object.keys(locationsList).map((building) => (
                  <Option value={building}>{locationsList[building]}</Option>
                ))}
            </Select>
          </div>

          {/* {stepOneInfo.city_id !== "" &&
            Object.keys(locationsList).length === 0 && (
              <> */}
          {/* <label className="futuraBkBtN-14">
            *Not able to find you location{" "}
          </label> */}

          <p
            className="futuraBkBtN-14 text-danger"
            onClick={() => setIsModal("location")}
            type="primary"
            shape="circle"
          >
            + Add new location
          </p>
          {/* </>
            )} */}
        </div>

        <div className="form-group mx-0">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Pin your location
          </label>

          <SearchListing
            value={loc}
            setValue={setLoc}
            search={searchLoc}
            noPadding
          />
        </div>
      </div>

      <WingBlank>
        <Modal
          popup
          visible={isModal}
          onClose={() => setIsModal(false)}
          animationType="slide-up"
        >
          <div class="container">
            <div className="row">
              <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                <div className="row">
                  <div className="col-10">
                    <p>
                      {"Add New " +
                        (isModal === "building" ? "Building" : "Location")}
                    </p>
                  </div>
                  <div className="col-2">
                    <span onClick={() => setIsModal(false)}>
                      <img src={Cross} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  className="rd-custom-input"
                  placeholder={"Add new " + isModal}
                  value={isModal === "building" ? newBuilding : newLocation}
                  onChange={(e) => {
                    if (isModal === "building") setNewBuilding(e.target.value);
                    else setNewLocation(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="form-group mt-2 mb-4">
              <Button
                className="w-100 filter-Apply futuraN700-14"
                onClick={() => {
                  setIsModal(false);
                  if (isModal === "building") addNewBuilding(isModal);
                  else addNewLocation(isModal);
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </Modal>
      </WingBlank>

      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Continue
      </Button>
    </div>
  );
}
