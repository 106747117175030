import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
//import OneSignal from "react-onesignal";
import { Carousel, WingBlank } from "antd-mobile";
import { message, Select } from "antd";
import axios from "axios";
import { Button, Tooltip } from "@material-ui/core";
import app from "../utils/appConfig";
import { AppContext } from "../AppContext";
import NoImage from "../images/no-image.png";
import Back from "../icons/back.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
// import SmokeIcon from "../icons/smoking.svg";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import "./style/hotelRoomDetail.scss";
import { CACHE__H_ROOMS_CART } from "../data/cacheKeys";
import {
  getLsValue,
  removeLsValue,
  separateNumbers,
  setLsValue,
} from "../../utils/functions";
import RdLoader from "../_newComponents/RdLoader";
import ImageGrid from "../_newComponents/ImageGrid";
import Modal from "../_newComponents/Modal";

export default function HotelRoomDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Option } = Select;

  const history = useHistory();
  const { id, room_id } = useParams();
  const {
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [hotel, setHotel] = useState({});
  const [room, setRoom] = useState([]);
  const [beds, setBeds] = useState(0);
  const [bedType, setBedType] = useState(0);
  const [gallery, setGallery] = useState([]);
  const [showAddon, setShowAddon] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [state, setState] = useState({
    numberOfRooms: numOfRooms,
    breakfast: false,
    numberOfBreakfast: 0,
    internet: false,
    parking: false,
    pets: false,
  });

  const [phonePopUp, setPhonePopUp] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const selectAddon = () => setShowAddon(true);
  const handleBack = () => setShowAddon(false);

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };

  function updateState(name, value) {
    setState({ ...state, [name]: value });
  }

  function getRoomPrice(index) {
    if (hotel.hotel_active_rooms) {
      let tax = 0;

      if (
        // hotel["hotel_active_rooms"][index]["room_rent_type"][
        //   "room_rent_tax"
        // ] === "no"

        hotel.hotel_active_rooms[index].room_rent_type &&
        hotel.hotel_active_rooms[index].room_rent_type.room_rent_tax === "no"
      ) {
        tax =
          (parseInt(hotel["hotel_tax"]) / 100) *
          hotel["hotel_active_rooms"][index]["room_base_price"];
        tax =
          tax +
          hotel["hotel_active_rooms"][index]["room_base_price"] +
          hotel["hotel_tax_amount"];
      } else {
        tax = hotel["hotel_active_rooms"][index]["room_base_price"];
      }

      return Math.ceil(tax);
    }
  }

  function updatePrice() {
    if (hotel.hotel_active_rooms) {
      let total = getRoomPrice(activeModalIndex);
      total = total * state.numberOfRooms;

      if (state.breakfast) {
        let bfastPrice = Number(hotel.services.optional_breakfast_price);
        total = total + bfastPrice * state.numberOfBreakfast;
      }

      setTotalPrice(total);
      return total;
    }
  }

  function clearRoom(id) {
    let temp = selectedRooms;
    delete temp[id];

    setSelectedRooms(temp);
    setState({
      numberOfRooms: 1,
      breakfast: false,
      numberOfBreakfast: 0,
      internet: false,
      parking: false,
      pets: false,
    });

    if (Object.keys(temp).length) {
      const cart = {
        selectedRooms: temp,
        userInfo,
        state,
      };

      setLsValue(CACHE__H_ROOMS_CART, cart);
    } else {
      removeLsValue(CACHE__H_ROOMS_CART);
    }
  }

  function getTotalPrice() {
    let price = 0;

    Object.keys(selectedRooms).map((elem) => {
      price += selectedRooms[elem].totalAmmount;
    });

    return price;
  }

  function reserveRoom(index) {
    const sR = hotel.hotel_active_rooms[index];
    const newData = {
      ...selectedRooms,
      [sR.id]: {
        ...state,
        hotelName: hotel.hotel_name,
        hotelId: sR.parent_id,
        roomId: sR.id,
        roomName: sR.room_name,
        roomType: sR.room_type,
        roomSize: sR.room_size,
        checkIn: userInfo.checkIn,
        checkOut: userInfo.checkOut,
        currency: hotel.hotel_currency,
        // totalAmmount: updatePrice(),
        totalAmmount: getRoomPrice(index) * state.numberOfRooms,
      },
    };

    setActiveModalIndex(index);
    setSelectedRooms(newData);

    // const cart = {
    //   selectedRooms: newData,
    //   userInfo,
    //   state,
    // };

    // setLsValue(CACHE__H_ROOMS_CART, cart);

    // OneSignal.sendTags({
    //   is_cart: true,
    //   cart_update: Math.floor(Date.now() / 1000),
    //   // hotel_id: sR.parent_id,
    //   hotel_name: hotel?.hotel_name,
    //   hotel_image: hotel?.hotel_images?.[0]?.image,
    //   // rooms: Object.keys(newData).length,
    // })
    //   .then((res) => console.log("Cart Tagged:", res))
    //   .catch((err) => console.log(err));

    getTotalPrice();
  }

  useEffect(() => {
    const cart = getLsValue(CACHE__H_ROOMS_CART);

    if (cart) {
      setSelectedRooms(cart.selectedRooms);
      setUserInfo(cart.userInfo);
      setState(cart.state);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_hotel_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        const _room = res.data[0].hotel_active_rooms.filter(
          (data) => data.id == room_id
        )?.[0];

        const _images = _room.room_images.map((item) => item.image);

        const index = res.data[0].hotel_active_rooms.findIndex(
          (d) => d.id == room_id
        );

        setHotel(res.data[0]);
        setRoom(_room);
        setImages(_images);
        setActiveModalIndex(index);
        setLoading(false);
      })
      .catch((err) => {
        message.error("An error occurred");
        console.log(err);
      });
  }, []);

  function getOptions(e) {
    let elem = [];
    for (let i = 1; i <= parseInt(e); i++)
      elem.push(
        <Option value={i} key={i}>
          {i} {i === 1 ? "room" : "rooms"}
        </Option>
      );

    return elem;
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="header screen-header pt-3 pb-3" style={{ zIndex: "999" }}>
        <div style={{ display: `${showAddon ? "none" : "block"}` }}>
          <div className="row" /* align-items-center justify-content-center */>
            <div className="col-auto">
              <img
                className=""
                src={Back}
                alt="back_Arrow"
                onClick={() => {
                  const prevHost = window.document.referrer
                    ? new URL(window.document.referrer).hostname
                    : null;
                  const currHost = window.location.hostname;

                  if (!prevHost || prevHost !== currHost)
                    history.push(`/hotel/detail/${id}/rooms`);
                  else history.goBack();
                }}
              />
            </div>

            <div className="col-auto">
              <div className="futuraN700-17">Room detail</div>
            </div>
          </div>
        </div>

        <div style={{ display: `${showAddon ? "block" : "none"}` }}>
          <div className="row align-items-center">
            <div className="col-auto">
              <img
                className=""
                src={Back}
                alt="back_Arrow"
                onClick={handleBack}
              />
            </div>

            <div className="col">
              <div className="futuraN700-17">Choose addons</div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <RdLoader />
      ) : (
        <>
          <div
            className="layoutBody room-detail"
            style={{
              display: `${showAddon ? "none" : "block"}`,
            }}
          >
            <ImageGrid
              images={images}
              title={
                room?.room_custom_name &&
                room?.room_custom_name !== "null" &&
                room?.room_custom_name !== "0"
                  ? room?.room_custom_name
                  : room?.room_name
              }
              share={window.location.href}
              type="R"
            />

            {/* <WingBlank>
              <Carousel autoplay={false}>
                {room?.room_images?.map((item, i) => (
                  <img
                    key={i}
                    className="d-block w-100"
                    src={item.image}
                    style={{ width: "100%", verticalAlign: "top" }}
                    alt="RoomDaddy"
                  />
                ))}

                {!room?.room_images?.length && (
                  <img
                    className="d-block w-100"
                    src={NoImage}
                    style={{ width: "100%", verticalAlign: "top" }}
                    alt="RoomDaddy"
                  />
                )}
              </Carousel>
            </WingBlank> */}

            <div className="container-fluid p-0">
              <div className="container">
                <div className="border-bottom pt-3 pb-3">
                  <div className="montserratN900-22">
                    <p className="m-0">
                      {room?.room_custom_name &&
                      room?.room_custom_name !== "null" &&
                      room?.room_custom_name !== "0"
                        ? room?.room_custom_name
                        : room?.room_name}
                    </p>
                  </div>

                  <div className="futuraBkBtN-14">
                    <p className="m-0">
                      Size: {room?.room_size} ft<sup>2</sup>
                    </p>
                  </div>
                </div>

                <div className="border-bottom pt-3 pb-3">
                  <div className="row justify-content-between">
                    <div className="col-7">
                      {hotel?.policies?.live_booking === "yes" && (
                        <div className="row align-items-center justify-content-between">
                          {selectedRooms[room?.id] ? (
                            <>
                              <div className="col-5">
                                <div className="futuraN700-14">
                                  <Button
                                    className="bookDanger w-100"
                                    onClick={() => clearRoom(room?.id)}
                                  >
                                    ✗
                                  </Button>
                                </div>
                              </div>

                              <div className="col-7 pl-0 pr-0">
                                <Select
                                  className="selector-ant w-100"
                                  defaultValue={
                                    selectedRooms[room?.id].numberOfRooms
                                  }
                                  onChange={(value) => {
                                    updateState("numberOfRooms", value);
                                    setSelectedRooms({
                                      ...selectedRooms,
                                      [room?.id]: {
                                        ...selectedRooms[room?.id],
                                        numberOfRooms: value,
                                      },
                                    });
                                  }}
                                  disabled
                                >
                                  {getOptions(
                                    room?.number_rooms_this_type || 0
                                  )}
                                </Select>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-5 ">
                                <div className="futuraN700-14">
                                  <Button
                                    className="bookN-s futuraN700-14 w-100"
                                    onClick={() => {
                                      reserveRoom(activeModalIndex);

                                      setNumOfRooms(1);
                                      setState({
                                        numberOfRooms: 1,
                                        breakfast: false,
                                        numberOfBreakfast: 0,
                                        internet: false,
                                        parking: false,
                                        pets: false,
                                      });
                                    }}
                                  >
                                    ✓
                                  </Button>
                                </div>
                              </div>

                              <div className="col-7 pl-0 pr-0">
                                <Select
                                  className="selector-ant w-100"
                                  style={{ width: 100 }}
                                  placeholder="Select room"
                                  optionFilterProp="children"
                                  defaultValue={
                                    selectedRooms[room?.id]?.numberOfRooms ||
                                    state.numberOfRooms
                                  }
                                  onChange={(value) =>
                                    updateState("numberOfRooms", value)
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {getOptions(
                                    room?.number_rooms_this_type || 0
                                  )}
                                </Select>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {/* <div>
                        <Button
                          variant="contained"
                          className="bookN-s futuraN700-14"
                          onClick={selectAddon}
                        >
                          Choose addons
                        </Button>
                      </div> */}
                    </div>

                    <div className="col-auto">
                      <div class="text-right">
                        <p class="futuraBkBtN-14 m-0">
                          Price for {userInfo.diffInDays} night
                        </p>

                        <p class="montserratNB-16 m-0">
                          {/* AED {separateNumbers(getRoomPrice(activeModalIndex))} */}

                          {getRoomPrice(activeModalIndex) ? (
                            hotel?.hotel_currency +
                            " " +
                            separateNumbers(getRoomPrice(activeModalIndex))
                          ) : (
                            <span onClick={() => setPhonePopUp(true)}>
                              Inquire Now
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal visible={phonePopUp}>
                <div className="p-4 text-center">
                  <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
                    Would you like to call the host?
                  </p>

                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      className="bookN-s mr-3"
                      onClick={() => setPhonePopUp(false)}
                    >
                      <a href={`tel:${hotel?.alternate_phone_number}`}>Yes</a>
                    </Button>

                    <Button
                      className="bookN-s"
                      onClick={() => setPhonePopUp(false)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </Modal>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="border-bottom pt-3 pb-3">
                      <ul class="futuraBkBtN-14 p-0">
                        {/* <li>
                          <img src={DoorIcon} alt="" />
                          <span
                            className="ml-2"
                            style={{ textTransform: "capitalize" }}
                          >
                            {room?.room_type} room
                          </span>
                        </li> */}

                        <li>
                          <img src={DoorIcon} alt="" />

                          <span
                            className="ml-2"
                            style={{ textTransform: "capitalize" }}
                          >
                            {room?.smoking_option === "both"
                              ? "Smoking & Non-smoking"
                              : room?.smoking_option}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ResponsiveAd />

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="border-bottom pt-3 pb-3">
                      <p className="futuraN700-17 m-0">Bed preference</p>

                      <ul class="futuraBkBtN-14 p-0">
                        {room?.room_beds?.map((bed, i) => (
                          <li key={i}>
                            <img src={BedIcon} alt="Bed Icon" />

                            <span className="ml-2">
                              {bed.kind_of_beds_number} {bed.kind_of_beds}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="container">
            <div
              className="border-bottom pt-3 pb-3"
              // style={{ padding: "0 15px" }}
            >
              <div className="futuraBkBtN-14 mb-2">
                <p>
                  Only {hotel?.hotel_active_rooms?.length} rooms left on
                  Roomdaddy.com
                </p>
              </div>
            </div>
          </div> */}

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="pt-3 pb-3">
                      <p className="futuraN700-17">Facilities</p>

                      <ul class="container_ul futuraBkBtN-14 p-0 pb-5">
                        {room?.room_amenities?.map((ame, i) => (
                          <li key={i}>
                            <span
                              className="items"
                              style={{ textTransform: "capitalize" }}
                            >
                              {ame.split("_").join(" ")}
                            </span>
                          </li>
                        ))}

                        {/* <li>
                      <span className="items">Terracce</span>
                    </li>
                    <li>
                      <span className="items">Hair dryer</span>
                    </li>
                    <li>
                      <span className="items">Oven</span>
                    </li>
                    <li>
                      <span className="items">Linen</span>
                    </li>
                    <li>
                      <span className="items">Bed</span>
                    </li>
                    <li>
                      <span className="items">Balcony</span>
                    </li>
                    <li>
                      <span className="items">Hair dryer</span>
                    </li>
                    <li>
                      <span className="items">Oven</span>
                    </li>
                    <li>
                      <span className="items">Linen</span>
                    </li>
                    <li>
                      <span className="items">Bed</span>
                    </li>
                    <li>
                      <span className="items">Balcony</span>
                    </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {hotel?.policies?.live_booking === "yes" && (
                <div
                  className="bottom-navigation d-flex align-items-center justify-content-between"
                  style={{
                    background: "rgba(255, 255, 255, 0.9)",
                    backdropFilter: "blur(10px) saturate(180%)",
                    zIndex: 999,
                  }}
                >
                  <div className="futuraBkBtN-14 w-50">
                    {getTotalPrice() ? (
                      <>
                        <span className="text-muted">Total price</span>
                        <h5 style={{ fontFamily: "futuraMdBt" }}>
                          AED {separateNumbers(getTotalPrice())}
                        </h5>
                      </>
                    ) : (
                      <span className="text-muted">Select a room first</span>
                    )}
                  </div>

                  <div className="futuraN700-14">
                    <Tooltip
                      onClose={handleTooltipClose}
                      open={open}
                      title={
                        selectedRooms &&
                        Object.keys(selectedRooms).length === 0 &&
                        selectedRooms.constructor === Object
                          ? "You need to select a room first"
                          : "Go to checkout"
                      }
                    >
                      <Button
                        size="large"
                        type="primary"
                        className="bookN ReserveHroom"
                        id="hotel-room-mob-reserve"
                        onClick={() => {
                          handleTooltipOpen();
                          history.push(
                            selectedRooms &&
                              Object.keys(selectedRooms).length === 0 &&
                              selectedRooms.constructor === Object
                              ? "#"
                              : hotel.services.breakfast === "optional" ||
                                hotel.services.internet === "paid" ||
                                hotel.services.parking === "paid" ||
                                hotel.policies.allow_pets === "yes"
                              ? `/hotel/detail/${id}/addons`
                              : "/checkout"
                          );
                        }}
                      >
                        Reserve
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <div
            className="layoutBody"
            style={{ display: `${showAddon ? "block" : "none"}` }}
          >
            <div className="container-fluid">
              <div className="card">
                <div className="card-body">
                  <div className="">
                    <ul className="futuraBkBtN-14 p-0">
                      <li>
                        <div className="counter">
                          <input
                            id="breakFast"
                            className=""
                            type="checkbox"
                            name=""
                          />
                          <label for="breakFast" className="pl-5 mr-2">
                            Breakfast at AED 500{" "}
                          </label>
                          <Select
                            style={{ width: 70 }}
                            className="pl-5"
                            placeholder="0"
                            optionFilterProp="children"
                            onChange={onChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                          </Select>
                        </div>
                      </li>

                      <li>
                        <input id="pets" className="" type="checkbox" name="" />
                        <label for="pets" className="pl-5">
                          {" "}
                          Pets can stay for AED
                        </label>
                      </li>

                      <li>
                        <input
                          id="fullBoard"
                          className=""
                          type="checkbox"
                          name=""
                        />
                        <label for="fullBoard" className="pl-5">
                          Full board
                        </label>
                      </li>

                      <li>
                        <input
                          id="halfBoard"
                          className=""
                          type="checkbox"
                          name=""
                        />
                        <label for="halfBoard" className="pl-5">
                          Half board
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className="futuraN700-14">
                    <button className="bookN w-100">Confirm</button>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4">
                <div className="futuraN5-14">
                  <span className="text-darkBlue">Skip to checkout</span>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
