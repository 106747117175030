import React, { Component  } from 'react'

class ResponsiveAd extends Component {

    componentDidMount() {
     (window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div>
<ins className="adsbygoogle"
     style={{display:"block"}}
     data-ad-client="ca-pub-9800566720719955"
     data-ad-slot="6456024999"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
        </div>)
    }
}

export default ResponsiveAd