import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import http from "./service/http";
import { BASE_API_URL } from "./config";
import { getUser } from "./utils/functions";
import { msg } from "./firebase";
import swDev from "./swDev";
import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.css";
import "./app/style/css/style.css";
import "antd/dist/antd.css";
import "./index.css";

/* Backup from package.json
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  }
*/

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (msg) {
  console.log("Supported browser.");
  const user = getUser();

  msg
    .requestPermission()
    .then(() => msg.getToken())
    .then(async (token) => {
      console.log("Updated Token : ", token);
      let url = user
        ? `${BASE_API_URL}/update_device_id?user_id=${user.id}&device_type=A&device_id=${token}`
        : `${BASE_API_URL}/update_device_id?user_id=${0}&device_type=A&device_id=${token}`;

      try {
        const response = await http.get(url);
      } catch (err) {
        return console.log(err);
      }
    })
    .catch((err) => console.log(err));

  msg.onMessage((payload) => console.log("foreground >>>", payload));
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.register();

swDev();
