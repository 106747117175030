import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
// import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { AppContext } from "../../AppContext";
import { Card, Steps } from "antd";
import StepOne from "../PostnAddPages/parkingStepOne";
import StepTwo from "../PostnAddPages/parkingStepTwo";
import StepThree from "../PostnAddPages/parkingStepThree";

import Back from "../../icons/back.svg";
import Cross from "../../icons/cross.svg";
import LocationIcon from "../../icons/pinLocation.svg";
import GoogleMap from "../../_newComponents/GoogleMap";
import "./parkingRent.scss";

const { Step } = Steps;
const mapStyleTheme = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export default function ParkingRentPlace() {
  const history = useHistory();
  const { userInfo, setUserInfo } = useContext(AppContext);
  const [current, setCurrent] = useState(0);
  const steps = [
    <StepOne nextStep={setCurrent} />,
    <StepTwo nextStep={setCurrent} />,
    <StepThree nextStep={setCurrent} />,
  ];
  function updateCords(e) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      },
      (results, status) => {
        let result = results[0];
        if (result) {
          let adrs = result.formatted_address;
          let location = result.geometry.location;
          setUserInfo({
            ...userInfo,
            address: adrs,
            lat: location.lat(),
            lng: location.lng(),
          });
        }
      }
    );
  }

  return (
    <div className="place-an-ad">
      <div className="header screen-header pt-3 pb-3" style={{ zIndex: "999" }}>
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                history.goBack();
              }}
            />
          </div>
          <div className="col">
            <div className="futuraN700-17">Parking</div>
          </div>
          <div className="col-auto">
            {current > 0 && (
              <img
                className=""
                src={Cross}
                alt="cencel_Arrow"
                onClick={() => setCurrent(current - 1)}
              />
            )}
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "55px" }}>
        {/* <div className="map-container-post-ad"> */}
        <GoogleMap lat={userInfo.lat} lng={userInfo.lng} />
        {/* </div> */}

        {/* <LoadScript googleMapsApiKey="AIzaSyClJsuYdZbul3gxV80hbyF9CtOHBGPfXSw">
          <GoogleMap
            id="map"
            options={{
              styles: mapStyleTheme,
            }}
            mapContainerStyle={{ height: 300, marginTop: 57 }}
            zoom={13}
            center={{ lat: userInfo.lat, lng: userInfo.lng }}
          >
            <Marker
              icon={{ url: LocationIcon }}
              position={{ lat: userInfo.lat, lng: userInfo.lng }}
              draggable={true}
              onDragEnd={(e) => updateCords(e)}
            />
          </GoogleMap>
        </LoadScript> */}

        <div className="steps">
          <Steps current={current}>
            {steps.map((item, idx) => (
              <Step key={"step" + idx} />
            ))}
          </Steps>

          <div className="steps-content">
            <Card style={{ display: `${current === 0 ? "block" : "none"}` }}>
              {steps[0]}
            </Card>

            <Card style={{ display: `${current === 1 ? "block" : "none"}` }}>
              {steps[1]}
            </Card>

            <Card style={{ display: `${current === 2 ? "block" : "none"}` }}>
              {steps[2]}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
