import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import http from '../../../service/http';
import { getUser } from '../../../utils/functions';
import chat from '../../img/chat.png';

// import userProfile from '../../img/user-1.jpg'

export default function ChatBot({ message, owner_id, bedspace_id, img, name, button, textareaRow, number }) {
    const sender_id = getUser()?.id;
    const history = useHistory();

    const [show, setShow] = useState(true);
    const [input, setInput] = useState(message);

    const handleClick = () => setShow(!show);

    const sendMessage = (e) => {
        e.preventDefault();

        if (owner_id == sender_id)
            return;

        http.post('first-message', {
            sender_id,
            owner_id,
            bedspace_id,
            message
        })
            .then(res => console.log(res))
            .catch(err => console.log(err));

        setInput('');
        history.push('/message');
    };

    return (
        <div className="chatbox-wrapper">
            <div className="chat-popup chat-bounce" onClick={handleClick} style={{ display: 'block' }}>
                <img src={chat} style={{ height: '25px', width: '27px', margin: '14px' }} alt="Chat Icon" />
            </div>

            <div className="agent-chat-form v1" style={{ display: (show ? " none " : " block ") }}>
                <div className="container">
                    <div className="row">
                        <div className="container mt-4 text-center">
                            <div className="icon icon-100 position-relative">
                                <figure className="background" style={{ backgroundImage: `url(${img})`, }}>
                                    <img src={img} alt="" style={{ display: 'none' }} />
                                </figure>
                            </div>
                        </div>

                        <div className="col-md-8 pl-0">
                            <br />

                            <ul className="text-secondary small lists">
                                <li>
                                    Name: <span class="listing-address-span-m ">{name}</span>
                                </li>

                                {/* <li>contact : <span class="listing-address-span-m">{number}</span></li> */}
                            </ul>

                            <span className="chat-close">
                                <i className="lnr lnr-cross"></i>
                            </span>
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-md-12">
                            <form onSubmit={sendMessage}>
                                <input
                                    type="hidden"
                                    name="_token"
                                    value="tJaKy9t3MXM1ctDK1CRZncd43iTfbzwNQplW8NBs"
                                />

                                <div className="chat-group mt-1">
                                    <textarea
                                        className="form-control chat-msg"
                                        name="message"
                                        rows={textareaRow}
                                        placeholder="Description"
                                        onChange={(e) => setInput(e.target.value)}
                                    >
                                        {message}
                                    </textarea>
                                </div>

                                <div className="tools">
                                    <div className="chat-button mt-3">
                                        <button
                                            className="btn btn-info btn-block btn-lg"
                                            aria-hidden="true"
                                            type="submit"
                                        >
                                            {button}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
