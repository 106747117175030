import { CACHE__AUTH_USER } from "../app/data/cacheKeys";

export function getUser() {
  return getLsValue(CACHE__AUTH_USER)?.user;
}

export function logOut() {
  removeLsValue(CACHE__AUTH_USER);
  window.location.reload();
}

export function separateNumbers(num) {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}

export function getLsValue(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function setLsValue(key, value) {
  return localStorage.setItem(key, JSON.stringify(value));
}

export function removeLsValue(key) {
  return localStorage.removeItem(key);
}

export function getDateTime(datetime) {
  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  };

  if (!Date.parse(datetime)) return formatAMPM(new Date());

  const fullDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dt = new Date(datetime);
  const date = dt.getDate();
  const month = months[dt.getMonth()];
  const diffDays = new Date().getDate() - date;
  const diffMonths = new Date().getMonth() - dt.getMonth();
  const diffYears = new Date().getFullYear() - dt.getFullYear();

  // const timeDiff = datetime - Date.now();

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0)
    return formatAMPM(dt);
  else if (diffYears === 0 && diffDays === 1) return "Yesterday";
  else if (diffYears === 0 && diffDays === -1) return "Tomorrow";
  else if (diffYears === 0 && diffDays < -1 && diffDays > -7)
    return fullDays[dt.getDay()];
  else if (diffYears >= 1)
    return month + " " + date + ", " + new Date(datetime).getFullYear();
  else return month + " " + date;
}

// export function getDateTime(datetime) {
//   if (!Date.parse(datetime)) return "Getting date...";

//   const monthNames = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];

//   const d = new Date(datetime);
//   const date = d.getDate();
//   const year = d.getFullYear();
//   const month = monthNames[d.getMonth()];

//   const formatAMPM = (date) => {
//     let hours = date.getHours();
//     let minutes = date.getMinutes();
//     let ampm = hours >= 12 ? "PM" : "AM";

//     hours = hours % 12;
//     hours = hours ? hours : 12;
//     minutes = minutes < 10 ? "0" + minutes : minutes;

//     const strTime = `${hours}:${minutes} ${ampm}`;
//     return strTime;
//   };

//   const time = formatAMPM(d);
//   const date_view = `${time}, ${date} ${month} ${year}`;

//   return date_view;
// }

export function convertToSpecialChar(str) {
  return (
    str &&
    str
      .replace(/&/g, "&amp;")
      .replace(/>/g, "&gt;")
      .replace(/</g, "&lt;")
      .replace(/"/g, "&quot;")
      .replace(/\n/g, "&#13; &#10;")
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      .replace(/\s/g, "&nbsp;")
  );
}

export function convertToString(str) {
  return str
    .replace(/&amp;/, "&")
    .replace(/&gt;/, ">")
    .replace(/&lt;/, "<")
    .replace(/&quot;/, '"')
    .replace(/&#13;&#10;/, "\n")
    .replace(/&nbsp;&nbsp;&nbsp;&nbsp;/, "\t")
    .replace(/&nbsp;/, " ");
}

export function stripTags(str) {
  return str && str.replace(/(<([^>]+)>)/gi, "");
}
