import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { InputNumber, Space, DatePicker, message } from "antd";
import app from "../../utils/appConfig";
import moment from "moment";
import axios from "axios";
import { Button } from "@material-ui/core";

export default function StepThree({ nextStep, to }) {
  let localData = JSON.parse(localStorage.getItem("_RMDParkingAd"));
  let parkingOb;
  if (localData !== null) {
    let parkingAddData = localData;
    parkingOb = {
      user_id: parkingAddData.user_id,
      country_id: parkingAddData.country_id,
      city: parkingAddData.city,
      city_id: parkingAddData.city_id,
      build_id: parkingAddData.build_id,
      location: parkingAddData.location,
      search_park_street: parkingAddData.search_park_street,
      search_longitude: parkingAddData.search_longitude,
      search_latitude: parkingAddData.search_latitude,
      title: parkingAddData.title,
      parking_bay_value: parkingAddData.parking_bay_value,
      parking_type: parkingAddData.parking_type,
    };
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { userInfo, editRoomDetail } = useContext(AppContext);
  const [thirdStepInfo, setThirdStepInfo] = useState({
    user_id: parkingOb?.user_id,
    country_id: parkingOb?.country_id,
    city: parkingOb?.city,
    city_id: parkingOb?.city_id,
    build_id: parkingOb?.build_id,
    location: parkingOb?.location,
    search_park_street: parkingOb?.search_park_street,
    search_longitude: parkingOb?.search_longitude,
    search_latitude: parkingOb?.search_latitude,
    title: parkingOb?.title,
    parking_bay_value: parkingOb?.parking_bay_value,
    parking_type: parkingOb?.parking_type,
    availability_start: userInfo.checkIn,
    availability_end: null,
    monthly_price: "",
    initial_deposit: "",
    parking_description: "",
  });

  function updateThirdForm(property, value) {
    setThirdStepInfo({ ...thirdStepInfo, [property]: value });
  }
  function sentFormData() {
    for (let v in thirdStepInfo) {
      console.log(v, thirdStepInfo[v]);

      if (v === "availability_end") continue;
      else if (Array.isArray(v) && !v.length) return;
      else if (!thirdStepInfo[v]) return;
    }

    let data = new FormData();
    for (let i in thirdStepInfo) {
      data.append(i, thirdStepInfo[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(`${app.baseUrl}/api/parking-${to ? to : "step-one"}`, data, config)
      .then((res) => {
        message.success("Processing complete!");
        localStorage.clear();
      })
      .catch(
        (err) => console.log(err),
        message.error("Please fill the values")
      );
  }
  return (
    <div className="px-2 py-3">
      <div className="container pb-4 px-3">
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Monthly Rent{" "}
          </label>
          <br></br>
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => updateThirdForm("monthly_price", value)}
          />
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Initial Deposit{" "}
          </label>
          <br></br>
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => updateThirdForm("initial_deposit", value)}
          />
        </div>
        <div className="form-group floating-form-group">
          <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
            Availability{" "}
          </label>
          <div className="calender">
            <Space direction="vertical">
              <div className="d-flex align-items-center">
                from&nbsp;&nbsp;
                <DatePicker
                  placeholder={"Start date"}
                  style={{ width: "100%" }}
                  defaultValue={moment(
                    thirdStepInfo.availability_start,
                    "YYYY/MM/DD"
                  )}
                  disabledDate={(current) =>
                    current &&
                    current <
                      moment(thirdStepInfo.availability_start, "YYYY/MM/DD")
                  }
                  onChange={(e, str) =>
                    setThirdStepInfo({
                      ...thirdStepInfo,
                      availability_start: str,
                    })
                  }
                />
              </div>
              <div className="d-flex align-items-center">
                To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <DatePicker
                  placeholder={"End date"}
                  style={{ width: "100%" }}
                  defaultValue={
                    thirdStepInfo.availability_end &&
                    moment(thirdStepInfo.availability_end, "YYYY/MM/DD")
                  }
                  disabledDate={(current) =>
                    current &&
                    current <
                      moment(thirdStepInfo.availability_start, "YYYY/MM/DD")
                  }
                  onChange={(e, str) =>
                    setThirdStepInfo({
                      ...thirdStepInfo,
                      availability_end: str,
                    })
                  }
                />
              </div>
            </Space>
          </div>
          <div className="form-group">
            <label className="w-100 text-left text-xyz futuraNBold-16 mt-2 mb-2">
              Description{" "}
            </label>
            <textarea
              onChange={(e) =>
                updateThirdForm("parking_description", e.target.value)
              }
              className="form-control inputBorder"
              value={thirdStepInfo.parking_description}
              name="message"
              rows="3"
              placeholder="About parking spot"
            ></textarea>
          </div>
        </div>
      </div>
      <Button
        className="bookN futuraN700-14 w-100"
        type="button"
        onClick={sentFormData}
      >
        Complete
      </Button>
    </div>
  );
}
