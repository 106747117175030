import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import GoogleOneTapLogin from "react-google-one-tap-login";
import ReactGA from "react-ga";
import axios from "axios";
import app from "./utils/appConfig";
import { CACHE__AUTH_USER } from "./data/cacheKeys";
import { setLsValue } from "./../utils/functions";
// //import OneSignal from "react-onesignal";

/**
 * New Pages
 */
import "./_newPages/style/custom.scss"
import HomePageBody from "./_newPages/HomePageBody";
import BlogsPage from "./_newPages/BlogsPage";
import Blog from "./_newPages/Blog";
import ProfilePage from "./_newPages/ProfilePage";
import ProfileEdit from "./_newPages/ProfileEdit";
import ChangePassword from "./_newPages/ChangePassword";
import LoginPage from "./_newPages/LoginPage";
import SignupPage from "./_newPages/SignupPage";
import VerifyEmail from "./_newPages/VerifyEmail";
import VerifyEmailSend from "./_newPages/VerifyEmailSend";
import ForgetPassPage from "./_newPages/ForgetPassPage";
import ResetPassPage from "./_newPages/ResetPassPage";
import SetPassPage from "./_newPages/SetPassPage";
import ChatPage from "./_newPages/ChatPage";
import ChatScreen from "./_newPages/ChatScreen";
import NotificationPage from "./_newPages/NotificationPage";
import HotLeadPage from "./_newPages/HotLeadPage";
import HotListingPage from "./_newPages/HotListingPage";
import HotListingDetail from "./_newPages/HotListingDetail";
import CoLivingPage from "./_newPages/CoLivingPage";
import CoLivingDetail from "./_newPages/CoLivingDetail";
import ParkingPage from "./_newPages/ParkingPage";
import HotelListingPage from "./_newPages/HotelListingPage";
import HotelDetail from "./_newPages/HotelDetail";
import HotelDetailRooms from "./_newPages/HotelDetailRooms";
import HotelRoomDetail from "./_newPages/HotelRoomDetail";
import HotelApartmentPage from "./_newPages/HotelApartmentPage";
import HotelApartmentDetail from "./_newPages/HotelApartmentDetail";
import HotelApartmentDetailRoom from "./_newPages/HotelApartmentDetailRoom";
import HotelApartmentRoomDetail from "./_newPages/HotelApartmentRoomDetail";
import EntireHomePage from "./_newPages/EntireHomePage";
import EntireHomeDetail from "./_newPages/EntireHomeDetail";
import AddOnsPage from "./_newPages/AddOnsPage";
import CheckoutPage from "./_newPages/CheckoutPage";
import PostnAdd from "./_newPages/PostnAdd";
import ColivingAdd from "./_newPages/RentPlace/colivingRent";
import ParkingAddPage from "./_newPages/RentPlace/parkingRent";
import ThankYouPage from "./_newPages/ThankYouPage";
import MyBooking from "./_newPages/MyBooking";
import HotelBookingPage from "./_newPages/HotelBookingPage";
import CoLivingBookingPage from "./_newPages/CoLivingBookingPage";
import NewServiceRequest from "./_newPages/NewServiceRequest";
import ServiceRequests from "./_newPages/ServiceRequests";
import ServiceRequestDetail from "./_newPages/ServiceRequestDetail";
import PaymentHistory from "./_newPages/PaymentHistory";
import MyListingPage from "./_newPages/MyListingPage";
import ContactUsPage from "./_newPages/ContactUsPage";
import HireUsPage from "./_newPages/HireUsPage";
import ParkingBlog from "./_newPages/ParkingBlog";
import OurPartnerBlog from "./_newPages/OurPartner";
import AboutBolt from "./_newPages/AboutBolt";
import OurHost from "./_newPages/OurHost";
import OurTenants  from "./_newPages/OurTenants";
import OurStory from "./_newPages/OurStory";
import SmartListings from "./_newPages/SmartListings";
import VisaPage from "./_newPages//VisaForm";
// import LandingPage from "./_newPages/LandingPage";

/**
 * Old Pages
 */
import ParkingDetails from "./pages/ParkingDetails/parkingDetails";
import ParkingBook from "./pages/ParkingPages/parkingBook";
import EntireAdd from "./pages/EntirePlacePages";
import Payment from "./components/otherBookingCard/payment";
import Notice from "./components/otherBookingCard/notice";
import TermsAndConditions from "./pages/Routes/termsCondition";
import PrivacyPolicy from "./pages/Routes/privacyPolicy";
import FAQListing from "./pages/Routes/about-listings.jsx";
import FAQHomeSearch from "./pages/Routes/home-search-faq.jsx";
import FAQGQ from "./pages/Routes/general-questions";

/**
 * Components, Functions, and Styles
 */
import { AppContextProvider } from "./AppContext";
import RouteChangeTracker from "../googleAnalytics";
import { getUser } from "../utils/functions";
import AuthGuard from "./components/AuthGuard";
import "../app/style/AppStyle.scss";
import EntireHomeDetailRooms from "./_newPages/EntireHomeDetailRooms";
import EntireHomeRoomDetail from "./_newPages/EntireHomeRoomDetail";
import CheckoutHome from "./_newPages/CheckoutHome";

const RenderAuthRoute = (path, Component) => (
  <Route
    exact
    path={path}
    render={(props) => (
      <AuthGuard>
        <Component {...props} />
      </AuthGuard>
    )}
  />
);

const responseGoogle = (res) => {
  let userID = res.sub;

  if (userID) {
    let data = new FormData();

    data.append("name", res.given_name);
    data.append("password", "roomdaddy@123");
    data.append("email", res.email);
    data.append("profile", res.picture);
    //data.append("login_type", res.tokenObj.idpId);

    axios
      .post(`${app.baseUrl}/api/social_login`, data)
      .then((resp) => {
        let socialLoginStatus = resp.data.status;

        if (socialLoginStatus === true) {
          let data = resp.data;
          let userData = {
            id: data.data.id,
            name: data.data.fld_name,
            email: data.data.email,
            profile: data.data.fld_profile_pic,
            //login_type: data.data.login_type,
          };

          let appState = {
            isLoggedIn: true,
            user: userData,
          };

          setLsValue(CACHE__AUTH_USER, appState);
          //setIsLoggedIn(true);

          window.location.reload();
        } else {
          //let isEmail = resp.data.message;

         // message.error(isEmail);
          console.log("error");
        }
      })
      .catch((err) => console.log(err));
  }
};

function App() {
  const userData = getUser();

  // const googleClick = () => {
  //   const elem = document.getElementsByClassName(
  //     "google-button-googleLoginonetap"
  //   )[0];
  //   elem.click();
  // };

  const TRACKING_ID = "UA-69105690-2";
  ReactGA.initialize(TRACKING_ID);

  if (userData) {
    ReactGA.event({
      category: "User",
      action: userData.id,
    });

    ReactGA.exception({
      description: "An error ocurred",
      fatal: true,
    });
  }

  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "5156733e-3173-4fa3-b888-08cb0a79f5ef",
  //   });
  // }, []);

  return (
    <AppContextProvider>
      {!userData ? (
        <GoogleOneTapLogin
        className="google-button-googleLoginonetap"
        onError={(error) => console.log(error)}
        onSuccess={responseGoogle}
          googleAccountConfigs={{
            client_id:
              "970577563957-ejfegfnms8a1re2knr3imnnln97hjfhf.apps.googleusercontent.com",
          }}
        />
      ) : null}

      <Router>
        <div className="App">
          <Switch>
            {/* New Routes */}

            <Route path="/" exact component={HomePageBody} />

            <Route path="/blogs/:slug" component={Blog} />
            <Route path="/blogs" component={BlogsPage} />

            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} />

            <Route path="/password/reset/:token" component={ResetPassPage} />
            <Route path="/password/set" component={SetPassPage} />

            <Route path="/password/reset" component={ForgetPassPage} />
            <Route path="/get_email" component={VerifyEmail} />
            <Route path="/send_email" component={VerifyEmailSend} />


            <Route path="/hot-listings" component={HotListingPage} />
            <Route path="/flatshare/hotlist/:id" component={HotListingDetail} />

            <Route path="/hot-leads" component={HotLeadPage} />

            <Route path="/co-living" component={CoLivingPage} />
            <Route
              path="/flat-share/:country?/:city?/:location?/:roomType?"
              component={CoLivingPage}
            />
            <Route
              path="/flat-share/:country?/:city?/:location?"
              component={CoLivingPage}
            />
            <Route
              path="/flat-share/:country?/:city?/:roomType?"
              component={CoLivingPage}
            />
            <Route path="/flatshare/list/:id" component={CoLivingDetail} />

            <Route path="/parking/:id/:title" component={ParkingDetails} />
            <Route path="/parkings" component={ParkingPage} />

            <Route exact path="/hotel-rooms" component={HotelListingPage} />
            <Route exact path="/hotel-rooms/:country?/:city?" component={HotelListingPage} />
            <Route exact path="/hotel-rooms/:country?/:city?/:location?" component={HotelListingPage} />
            <Route path="/hotel/detail/:id/addons" component={AddOnsPage} />
            <Route
              path="/hotel/detail/:id/rooms/:room_id"
              component={HotelRoomDetail}
            />
            <Route
              path="/hotel/detail/:id/rooms"
              component={HotelDetailRooms}
            />
            <Route path="/hotel/detail/:title/:id" component={HotelDetail} />

            <Route exact path="/checkout" component={CheckoutPage} />
            {/* {RenderAuthRoute("/checkout", CheckoutPage)} */}

            <Route
              path="/hotel-apartment/detail/:id/rooms/:roomId"
              component={HotelApartmentRoomDetail}
            />

            <Route
              path="/hotel-apartment/detail/:id/rooms"
              component={HotelApartmentDetailRoom}
            />

            <Route
              path="/hotel-apartment/detail/:title/:id"
              component={HotelApartmentDetail}
            />

            <Route
              exact
              path="/hotel-apartment"
              component={HotelApartmentPage}
            />
            <Route exact path="/hotel-apartment/:country?/:city?" component={HotelApartmentPage} />
            <Route exact path="/hotel-apartment/:country?/:city?/:location?" component={HotelApartmentPage} />                                                                             

            <Route exact path="/checkout/home" component={CheckoutHome} />

            <Route
              path="/short-term-rental/detail/:id/rooms/:roomId"
              component={EntireHomeRoomDetail}
            />

            <Route
              path="/short-term-rental/detail/:id/rooms"
              component={EntireHomeDetailRooms}
            />

            <Route
              path="/short-term-rental/detail/:title/:id"
              component={EntireHomeDetail}
            />

            <Route exact path="/short-term-rental" component={EntireHomePage} />
            <Route exact path="/short-term-rental/:country?/:city?/:location?" component={EntireHomePage} />
            <Route exact path="/short-term-rental/:country?/:city?/:location?/:type?" component={EntireHomePage} />

            <Route path="/hire-us" component={HireUsPage} />
            <Route path="/visa-form" component={VisaPage} />

            {RenderAuthRoute("/contact-us", ContactUsPage)}

            {RenderAuthRoute("/my_booking", MyBooking)}

            {RenderAuthRoute("/my_booking/hotel/:booking_id", HotelBookingPage)}
            {RenderAuthRoute(
              "/my_booking/co-living/:booking_id",
              CoLivingBookingPage
            )}
            {RenderAuthRoute(
              "/my_booking/co-living/:booking_id/service-requests/new",
              NewServiceRequest
            )}

            {/* "/my_booking/co-living/:booking_id/service-requests/:id", */}
            {RenderAuthRoute(
              "/my_booking/co-living/service-requests/:id",
              ServiceRequestDetail
            )}
            {RenderAuthRoute(
              "/my_booking/co-living/:booking_id/service-requests",
              ServiceRequests
            )}
            {RenderAuthRoute(
              "/my_booking/co-living/:booking_id/payment-history",
              PaymentHistory
            )}

            {RenderAuthRoute("/message/:the_chatroom", ChatScreen)}
            {RenderAuthRoute("/message", ChatPage)}

            {/* {RenderAuthRoute("/my-listings/co-living/:id", ColivingAdd)} */}
            {RenderAuthRoute("/my-listings", MyListingPage)}

            {RenderAuthRoute("/place-an-ad", PostnAdd)}
            {RenderAuthRoute("/room_rent", ColivingAdd)}
            {RenderAuthRoute("/entire-accomodation", EntireAdd)}
            {RenderAuthRoute("/parking/add", ParkingAddPage)}

            {RenderAuthRoute("/notification", NotificationPage)}

            {RenderAuthRoute("/profile", ProfilePage)}
            {RenderAuthRoute("/profile/edit", ProfileEdit)}

            {RenderAuthRoute("/change-password", ChangePassword)}

            {RenderAuthRoute("/thank-you", ThankYouPage)}

            

            {RenderAuthRoute("/room_rent/entire-accomodation", EntireAdd)}
            {RenderAuthRoute("/payment-history", Payment)}
            {RenderAuthRoute("/notice/:id", Notice)}
            {RenderAuthRoute("/parking/book", ParkingBook)}

            {/* static blogs */}

            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/faqs/general-questions" component={FAQGQ} />
            <Route path="/faqs/listing" component={FAQListing} />
            <Route path="/faqs/home-search" component={FAQHomeSearch} />
            <Route path="/our-story" component={OurStory} />
            <Route path="/our-guest" component={OurTenants} />
            <Route path="/our-host" component={OurHost} />
            <Route path="/about-bolt" component={AboutBolt} />
            <Route path="/smart-listings" component={SmartListings} />
            <Route path="/our-partner-blog" component={OurPartnerBlog} />
            <Route path="/parking-blog" component={ParkingBlog} />
            

            <Route path="/*" render={() => <Redirect to="/" />} />
          </Switch>
          <RouteChangeTracker />
        </div>
      </Router>
    </AppContextProvider>
  );
}

export default App;
