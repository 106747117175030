import React, { useState,useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { DatePicker, message, Select } from "antd";
import { useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { message as msg } from "antd";
import imageCompression from "browser-image-compression";
import { Button } from "@material-ui/core";
import MobileNav from "../_newComponents/MobileNav";
import app from "../utils/appConfig";
import Back from "../icons/back.svg";
import Plus from "../icons/plus.svg";
// import ManIcon from "../icons/man.svg";
// import WomanIcon from "../icons/woman.svg";
// import CoupleIcon from "../icons/couple.svg";
import "./style/contactUs.scss";
import "react-phone-input-2/lib/style.css";
import { CACHE__AUTH_USER } from "../data/cacheKeys";
import { setLsValue } from "../../utils/functions";

const VisaPage = ({navigation}) => {
  const history = useHistory();
  const { Option } = Select;
  const [processing, setProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [visaType, setVisaType] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [passportSizePhoto, setPassportSizePhoto] = useState(null);

  const handleVisaTypeChange = (e) => {
    setVisaType(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handlePassportPhotoChange = (e) => {
    setPassportPhoto(e.target.files[0]);
  };

  const handlePassportSizePhotoChange = (e) => {
    setPassportSizePhoto(e.target.files[0]);
  };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission
//     // ...
//   };

const previewPassportPhoto = passportPhoto && (
    <img src={URL.createObjectURL(passportPhoto)} alt="Passport" />
  );

  const previewPassportSizePhoto = passportSizePhoto && (
    <img src={URL.createObjectURL(passportSizePhoto)} alt="Passport Size" />
  );

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const isFormValid = () => {
    switch (currentStep) {
      case 1:
        return visaType !== "";
      case 2:
        return email !== "" && phone !== "";
      case 3:
        return passportPhoto !== null;
      case 4:
        return passportSizePhoto !== null;
      default:
        return false;
    }
  };

  


  const submit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    let data = new FormData();

    data.append("email", email);
    data.append("phone", phone);
    data.append("visa_type", visaType);
    data.append("passport", passportPhoto);
    data.append("photo", passportSizePhoto);

    const config = {
      headers: {
        "content-type": "visa_form",
      },
    };

    await axios
      .post(`${app.baseUrl}/api/send_visa_form_email`, data, config)
      .then((res) => {
        msg.success(
          "Your mail has been sent, our agent will contact you shortly"
        );

        setEmail("");
        setPhone("");
        setProcessing(false);

        
      })
      .catch((err) => {
        console.log(err);

        msg.error("Something went wrong");
        setProcessing(false);
      });

      
  };

  return (
    <div className="contact-us">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "28px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={Back}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost) history.push("/");
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">Fill this form to apply for Visa</div>
          </div>
        </div>
      </div>

      <div className="contact-us__body">
      <div className='nHeader'>
                <div className='nHeader_bg2'>
                    <p className='montserratN900-45 text-white'></p>
                </div>
            </div>
            <br></br>
        <form onSubmit={submit} className="contact-us__form">
        {currentStep === 1 && (
            <div>
            <label>
              Visa Type:
              <select className="rd-custom-input" value={visaType} onChange={handleVisaTypeChange}>
                <option value="">Select a visa type</option>
                <option value="tourist">Tourist</option>
                <option value="business">Business</option>
                <option value="student">Student</option>
              </select>
            </label>
            <button className="bookN w-100 mt-3" disabled={!isFormValid()} onClick={handleNextStep}>
              Next
            </button>
          </div>

)}
{currentStep === 2 && (
        <div>
          <label htmlFor="email">
            <h4>Email</h4>
          </label>
            <input id="email" className="rd-custom-input" type="email" value={email} onChange={handleEmailChange} />
          
            <label htmlFor="number"> 
          <h4>Phone number</h4>
          </label>
            <input id="phone" className="rd-custom-input" type="tel" value={phone} onChange={handlePhoneChange} />
          
          <button className="bookN w-100 mt-3" disabled={!isFormValid()} onClick={handleNextStep}>
            Next
          </button>
          <button className="bookN w-100 mt-3" onClick={handlePrevStep}>Back</button>
        </div>
      )}
          {/* <label htmlFor="email">
            <h4>Email</h4>
          </label>

          <input
            id="email"
            type="email"
            className="rd-custom-input"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />

          <label htmlFor="number"> 
          <h4>Phone number</h4>
          </label>

            <PhoneInput
            inputProps={{
              name: 'phone',
              required: true,
              // autoFocus: true
            }}
              id="phone"
              country={'ae'}
              value={phoneshow}
              className="rd-custom-input"
              
              onChange={(value, country) => {
                setPhone(value.slice(country.dialCode.length));
                setDialCode(country.dialCode);
              }}
                
            /> */}
            <br></br>
            {currentStep === 3 && (
        <div>
          <label>
            Passport Photo:
            <input
              type="file"
              accept="image/*"
              onChange={handlePassportPhotoChange}
            />
          </label>
          {previewPassportPhoto}
          <button className="bookN w-100 mt-3" disabled={!isFormValid()} onClick={handleNextStep}>
            Next
          </button>
          <button className="bookN w-100 mt-3" onClick={handlePrevStep}>Back</button>
        </div>
      )}
            {/* <h6 className="w-100 text-left text-xyz futuraNBold-16 mt-3 mb-2">
              Room Photos*
            </h6>
            <input
              id="file"
              onChange={(e) => uploadFiles("photo", e)}
              type="file"
              multiple
              style={{ visibility: "visibel" }}
            />
            <div className="label-holder mt-2">
              <label htmlFor="file" className="label">
                <img src={Plus} alt="add_photos" />
                &nbsp;&nbsp;Add photos
              </label>
            </div>
            <div className="result">
              <div className="row">
                {renderPhotos("photo", photo)}
               
              </div>
            </div> */}
            

            {currentStep === 4 && (
        <div>
          <label>
            Passport Size Photo:
            <input
              type="file"
              accept="image/*"
              onChange={handlePassportSizePhotoChange}
            />
          </label>
          {previewPassportSizePhoto}
          <button type="submit" className="bookN w-100 mt-3" disabled={!isFormValid()} onClick={handleNextStep}>
            Submit
          </button>
          <button className="bookN w-100 mt-3" onClick={handlePrevStep}>Back</button>
        </div>
      )}

     {/* <label htmlFor="looking_for">
            <h4>Visa Type</h4>
            </label>

          
            <Select
              // showSearch
              style={{ marginTop: "5px",
              marginBottom: "10px",
              border: "double",
              fontSize: "large",
              fontWeight: "600" }}
              required
              className="rd-custom-input"
              placeholder="What are you looking for?"
              value={visa_type}
              onChange={(e) => setVisaType(e)}
            >
              <Option 
                style={{ 
                fontSize: "large",
                fontWeight: "600" }}
                value={'Home'}>
                  {'Entire Home'}
                </Option>
                <Option 
                  style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'Room'}>
                  {'Room'}
                </Option>
                <Option 
                style={{ 
                fontSize: "large",
                fontWeight: "600" }}
                value={'Bedspace'}>
                  {'Bedspace'}
                </Option>
                <Option 
                style={{ 
                  fontSize: "large",
                  fontWeight: "600" }}
                  value={'Parking'}>
                  {'Parking'}
                </Option>
              
            </Select> */}

      
        

          <Button
            disabled={processing}
            className="bookN w-100 mt-3"
            type="submit"
          >
            {processing ? "Sending..." : "Send"}
          </Button>
        </form>

        {/* <div className="contact-us__card">
          <div className="contact-us__info">
            <h5>Corporate office</h5>
            <p>JUMEIRAH LAKES TOWER</p>
          </div>

          <div className="contact-us__info">
            <h5>Direct contact</h5>
            <p>
              <strong>Email:</strong> info@roomdaddy.com
            </p>
          </div>

          <div className="contact-us__info">
            <h5>Company department</h5>
            <p>To contact our team, please select one of the following:</p>
          </div>

          <div className="contact-us__links">
            <p>Sales</p>
            <p>Careers</p>
            <p>Disputes</p>
            <p>Help</p>
          </div>
        </div> */}
      </div>
      <MobileNav />
    </div>
  );
};

export default VisaPage;
