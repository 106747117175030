import React, { useContext } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { AppContext } from "../../AppContext";
import LocationIcon from "../../icons/pinLocation.svg";

const mapStyleTheme = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

function MyComponent({ lat, lng }) {
  const { userInfo, setUserInfo } = useContext(AppContext);

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   // googleMapsApiKey: "AIzaSyClJsuYdZbul3gxV80hbyF9CtOHBGPfXSw",
  //   googleMapsApiKey: "AIzaSyBoY4Yn60USF1fDNIm65QVpRBowNeBBgbA",
  // });

  const containerStyle = {
    height: "250px",
  };

  function updateCoords(e) {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        location: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      },
      (results, status) => {
        let result = results[0];
        if (result) {
          let adrs = result.formatted_address;
          let location = result.geometry.location;
          setUserInfo({
            ...userInfo,
            address: adrs,
            lat: location.lat(),
            lng: location.lng(),
          });
        }
      }
    );
  }

  const isLoaded = window.isMap;

  return isLoaded ? (
    <GoogleMap
      options={{
        styles: mapStyleTheme,
      }}
      mapContainerStyle={containerStyle}
      center={{ lat: lat || 25.2048, lng: lng || 55.2708 }}
      zoom={13}
    >
      <Marker
        position={{ lat: lat || 25.2048, lng: lng || 55.2708 }}
        icon={{ url: LocationIcon }}
        onDragEnd={updateCoords}
        draggable
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MyComponent);
