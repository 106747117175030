import React, { forwardRef } from "react";
import { Avatar } from "@material-ui/core";
import { Delete, DoneAll } from "@material-ui/icons";
import { getUser } from "../../../utils/functions";
import "./index.scss";

const ChatMessage = forwardRef(
  (
    {
      id,
      message,
      sender,
      receiver,
      image,
      is_read,
      created_at,
      onDelete,
      avatar,
      name,
    },
    ref
  ) => {
    const user = getUser();

    return (
      <div
        ref={ref}
        className={`message${user.id == sender ? " message--sender" : " message--receiver"}`}
      >
        <div className="message__avatar">
          <Avatar src={avatar} alt={name} />
        </div>

        {user.id != sender&&user.id == receiver &&
          <div className="message__message">
          <div className="message__info">
            <span className="message__sender">{name}&nbsp;</span>
            <span>{created_at.split(",")[0]}</span>
          </div>
          <p>{message}</p>
        </div>}
        {user.id == sender&&user.id != receiver &&
          <div className="message__message">
          <div className="message__info">
            <span>{created_at.split(",")[0]}&nbsp;</span>
            <span className="message__sender">{name}</span>
          </div>
          <p style={{textAlign:'right'}}>{message}</p>
          
        </div>}
      </div>
    );
  }
);

export default ChatMessage;
