// import firebase from 'firebase'

// const config ={
//     apiKey: "AIzaSyDwRpGb70Jxsfh3DAXuWpwR-sPmBa1dmkg",
//     authDomain: "my-app-c2fc0.firebaseapp.com",
//     projectId: "my-app-c2fc0",
//     databaseURL: "https://my-app-c2fc0-default-rtdb.firebaseio.com",
//     storageBucket: "my-app-c2fc0.appspot.com",
//     messagingSenderId: "181200283917",
//     appId: "1:181200283917:web:87589560501393748f6844"
// }
// firebase.initializeApp(config) 

// export default firebase


import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBQkkw1JVDRPdZveP9y7-icVt3XmCEz9tY",
    authDomain: "roomdaddy-c7512.firebaseapp.com",
    projectId: "roomdaddy-c7512",
    storageBucket: "roomdaddy-c7512.appspot.com",
    messagingSenderId: "1066330451356",
    appId: "1:1066330451356:web:4d330500b775e4e7e4ac59"
});

export const msg = firebase.messaging.isSupported() ? firebaseApp.messaging() : null;

export default firebase;
