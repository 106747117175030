import React from 'react'
import Footer from '../_newComponents/Footer'
import PageHeader from '../_newComponents/pageHeader'
import image1 from '../images/smartListings.png'
import Hosts from '../images/smartHosts.png'
import Tenants from '../images/smartTenets.png'
import Dots from '../icons/dots.svg'

export default function SmartListings() {
  return (
    <>
        <PageHeader homeLink="/" />
        <div className='blog_body'>
            <div className='nHeader'>
                <div className='nHeader_bg'>
                    <p className='montserratN900-45 text-white w-90'>Smart Listings</p>
                </div>
            </div>
            <div className='card-body'>
                <p className='futuraN700-20 '>
                    Smart Listing are listings that are added by our 'Smart Hosts'.  
                </p>
                <div className='mt-5'>
                    <div className='section pb-5'>
                        <img className='w-100 borderRadius-10' src={`${image1}`} alt='RMD_img'/>
                    </div>
                </div>
                <div className='w-100'>
                    <img src={`${Dots}`} />
                </div>
            </div>
            <div className='card-body'>
                <div className=' pb-2'>
                    <div className='custom_card_img-text'>
                        <img className='w-100 borderRadius-10' src={`${Hosts}`} alt='RMD_img'/>
                        <div className='custom_card_text_listings'>
                            <p className='futuraNBold-30 w-90 text-white mb-0'>Who are our 'Smart hosts'?</p>
                            <ul className='futuraBkBtN-14 text-white pl-3'>
                                <li>Online Portal Hosts who use our portal to;</li>
                                <li>Confirm Online bookings </li>
                                <li>Accept Online payments  </li>
                                <li>Cater Online Service requests</li>
                                <li>Have Ratings and R</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className=' pb-2'>
                    <div className='custom_card_img-text'>
                        <img className='w-100 borderRadius-10' src={`${Tenants}`} alt='RMD_img'/>
                        <div className='custom_card_text_listings'>
                            <p className='futuraNBold-30 w-90 text-white mb-0'>Who are our 'Smart tenants'?</p>
                            <ul className='futuraBkBtN-14 text-white pl-3'>
                                <li>Book a smart listing and Become our 'Smart Tenant' NOW!</li>
                                <li>Online service requests & tracking</li>
                                <li>Online check-out Notice</li>
                                <li>Dashboard with easy to use features </li>
                                <li>Payment History & records</li>
                                <li>Booking History & records</li>
                                <li>Access to social network (Coming Soon!)</li>
                                <li> Housemates interests visibility (Coming Soon!)</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>
  )
}
