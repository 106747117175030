import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
//import OneSignal from "react-onesignal";
import { JsonLd } from "react-schemaorg";
import UploadProfile from "../images/uploadProfile.jpg";
import BannerImage from "../images/mobileBannerImage.png";

import UserIcon from "../icons/user.svg";
import DoorIcon from "../icons/door.svg";
import BedIcon from "../icons/bedAm.svg";
import BathIcon from "../icons/bathAm.svg";
import RatingStar from "../icons/rating.svg";
import ListerBadge from "../icons/listerBadge.svg";
import Internet from "../icons/internet.svg";
import Men from "../icons/man.svg";
import Women from "../icons/woman.svg";
import Back from "../icons/back.svg";
import Bus from "../icons/bus.svg";
import PinMark from "../icons/pinMarker.svg";
import Calender from "../icons/calender.svg";
import Relax from "../icons/relax.svg";
import Elevator from "../icons/elevator.svg";
import Laundry from "../icons/laundry.svg";
import Balcony from "../icons/balcony.svg";
import Wheelchair from "../icons/wheelchair.svg";
import Terrace from "../icons/terrace.svg";
import DishWasher from "../icons/dishwasher.svg";
import Parking from "../icons/parkingAm.svg";
import Fitness from "../icons/fitness.svg";
import Pool from "../icons/pool.svg";
import Landscape from "../icons/landscape.svg";
import TableTennis from "../icons/table-tennis.svg";
import Tennis from "../icons/tennis.svg";
import Squash from "../icons/tennis.svg";
import TennisCourt from "../icons/tennis-court.svg";
import Lake from "../icons/lake.svg";
import Park from "../icons/park.svg";
import Pets from "../icons/pets.svg";
import Smoking from "../icons/smoking.svg";
import Couple from "../icons/couple.svg";
import Toys from "../icons/toys.svg";
import Mars from "../icons/mars.svg";
import Femenine from "../icons/femenine.svg";
import Mix from "../icons/mixG.svg";
import Bath from "../icons/bath.svg";
import NaturalLights from "../icons/naturalLights.svg";
import Size from "../icons/size.svg";
import Tv from "../icons/tv.svg";
import Window from "../icons/window.svg";
import Fridge from "../icons/fridge.svg";
import CommonRoom from "../icons/relax.svg";
import Kitchen from "../icons/kitchen.svg";
import Cross from "../icons/cross.svg";
import PhoneCall from "../icons/phone-call.svg";
import Comment from "../icons/comment.svg";
import Email from "../icons/email.svg";
import Payment from "../icons/payment.svg";
import ProfileIcon from "../icons/profileIcon.svg";

import PropertyTypes from "../_newComponents/propertyType";
import PopularAmen from "../data/popularAmen.js";
import PageHeader from "../_newComponents/pageHeader";
import ResponsiveAd from "../_newComponents/GoogleAds/responsive";
import { Carousel, message } from "antd";
import { WingBlank } from "antd-mobile";
import Modal from "../_newComponents/Modal";
import axios from "axios";
import { getUser } from "../../utils/functions";
import app from "../utils/appConfig";
import GoogleMap from "../_newComponents/GoogleMap";
import moment from "moment";
import "./style/coLivingDetail.css";
import { MetaTags } from "react-meta-tags";
import ImageGrid from "../_newComponents/ImageGrid";
import LeafletMap from "../_newComponents/LeafletMap";
import { Button } from "@material-ui/core";

export default function HotListingDetail() {
  const user = getUser();
  const { id } = useParams();

  const handleClose = () => setIsModalVisible(false);
  const handleShow = () => setIsModalVisible(true);

  const [isLeadCalled, setIsLeadCalled] = useState(false);
  const [ip, setIp] = useState();
  const [coLiving, setCoLiving] = useState({});
  const [images, setImages] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewsModal, setReviewsModal] = useState(false);
  const [smartListings, setSmartListings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phonePopUp, setPhonePopUp] = useState(false);
  const [whatsappPopUp, setWhatsAppPopUp] = useState(false);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [chatPopUp, setChatPopUp] = useState(false);
  const [contactForm, setContectForm] = useState({
    name: user?.name,
    email: user?.email,
    number: user?.phone,
    message_data:
      "I saw your ad on Roomdaddy. When is it available for viewing? Thanks.",
  });
  const [position, setPosition] = useState([25.2048, 55.2708]);

  const onOpen = (key) => (e) => {
    e.preventDefault();

    if (key === "Email") setEmailPopUp(true);
    if (key === "Chat") setChatPopUp(true);
  };

  const onClose = (key) => (e) => {
    if (key === "Email") setEmailPopUp(false);
    if (key === "Chat") setChatPopUp(false);
  };

  function updateForm(key, value) {
    setContectForm({ ...contactForm, [key]: value });
  }

  useEffect(() => {
    // axios
    //   .get("http://geoplugin.net/json.gp")
    //   .then((res) => setIp(res.data.geoplugin_request))
    //   .catch((err) => console.log(err));

    axios
      .get(`${app.baseUrl}/api/get_ip`)
      .then((res) => setIp(res.data.ip))
      .catch((err) => console.log(err));

    fetchCoLiving();
    // fetchSmartListing();

    window.scrollTo(0, 0);
  }, [id]);

  // useEffect(() => {
  //   if (
  //     coLiving &&
  //     Object.keys(coLiving).length &&
  //     Object.getPrototypeOf(coLiving) === Object.prototype
  //   )
  //     OneSignal.sendTags({
  //       last_flatshare: coLiving.room_title,
  //       last_flatshare_url: window.location.href,
  //     })
  //       .then((res) => console.log("Tagged:", res))
  //       .catch((err) => console.log(err));
  // }, [coLiving]);

  const fetchCoLiving = async () => {
    await axios
      .get(`${app.baseUrl}/api/get-hotproperty-detail/${id}`)
      .then((res) => {
        const data = res.data.data[0];
        const isAfter = moment(data.avalability_date).isAfter(
          moment().format("YYYY-MM-DD"),
          "day"
        );

        data.date = isAfter
          ? moment(data.avalability_date).format("D MMMM YYYY")
          : null;

        setImages([...data.building_room_image, ...data.room_images]);
        setCoLiving(data);
        setPosition([data.fld_latitude, data.fld_longitude]);
        fetchReviews(data);
      })
      .catch((err) => console.log(err));
  };

  const fetchReviews = (data) => {
    axios
      .get(`${app.baseUrl}/api/get-bedspace-reviews/${data.bedspace_sku}`)
      .then((res) => setReviews(res.data))
      .catch((err) => console.log(err));
  };

  // const fetchSmartListing = () => {
  //   axios
  //     .get(`${app.baseUrl}/api/get-smart-listing`)
  //     .then((res) => setSmartListings(res.data.data))
  //     .catch((e) => console.log(e));
  // };

  const sender_id = user?.id;
  const history = useHistory();

  const [show, setShow] = useState(true);
  const [input, setInput] = useState(
    "I saw your ad on Roomdaddy. When is it available for viewing? Thanks."
  );

  const sendMessage = () => {
    if (coLiving.Owner_id == sender_id) return;

    axios
      .post(`${app.baseUrl}/api/first-message`, {
        sender_id,
        owner_id: coLiving.Owner_id,
        bedspace_id: coLiving.bedspace_id,
        message: input,
        own_property_description: coLiving?.room_title,
        room_name: coLiving?.room_name,
        link: window.location.href,
      })
      .then((res) => {
        message.success("Your message has been sent.");
        setInput("");
        history.push("/message");
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });
  };

  const sendEmail = () => {
    const data = new FormData();

    for (let key in contactForm) {
      if (key !== "number" && !contactForm[key]) return;
      data.append(key, contactForm[key]);
    }

    data.append("owner_mail_id", coLiving?.Email);
    data.append("owner_name", coLiving?.name);
    data.append("own_property_description", coLiving?.room_title);
    // data.append("room_name", coLiving?.room_title);
    data.append("link", coLiving?.property_share_url);

    axios
      .post(`${app.baseUrl}/api/reply-form`, data)
      .then((res) => {
        message.success("Your email has been sent.");
        onClose("Email")();
      })
      .catch((err) => {
        message.error("An error occurred! Please try again.");
        console.log(err);
      });
  };

  const getAvg = (arr) => {
    if (arr.length)
      return (arr?.reduce((a, b) => a + b) / arr?.length).toFixed(1);
  };

  const collectLead = (type) => {
    const data = new FormData();

    data.append("user_id", user?.id);
    data.append("room_id", coLiving?.room_id);
    data.append("bedspace_id", coLiving?.bedspace_id);
    data.append("building_id", coLiving?.building_id);
    data.append("fld_system_ip", ip);
    data.append("lead_type", type);

    axios
      .post("/save-lead", data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (
      // user?.id &&
      coLiving?.room_id &&
      coLiving?.bedspace_id &&
      coLiving?.building_id &&
      ip &&
      !isLeadCalled
    ) {
      collectLead("v");
      setIsLeadCalled(true);
    }
  }, [
    coLiving?.bedspace_id,
    coLiving?.building_id,
    coLiving?.room_id,
    ip,
    user?.id,
    isLeadCalled,
  ]);

  return (
    <div>
      <MetaTags>
        <title>{coLiving?.room_title}</title>
        <meta name="description" content={coLiving?.fld_room_description} />
      </MetaTags>

      <PageHeader isHome homeLink="/" />
      
      <JsonLd

      item={{
        "@context":"http://schema.org",
        "@type":"Accommodation",
        "name": (coLiving?.room_title),
        "description": (coLiving?.fld_room_description),
        "hasMap" : "https://www.google.com/maps/place/"+(position),
       
        
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "AE",
          "addressLocality": coLiving.Area,
          "addressRegion": "Dubai",
          "streetAddress": coLiving.fld_address},
          "leaseLength": {
            "@type": "QuantitativeValue",
            "value": 1,
            "unitText": "months"},
          "image": images[0],
          "":{"@type":"Product","name":(coLiving.fld_type == 'B' ? 'Bedspace for rent' : 'Room for rent'),
          "url": window.location.href,
          "offers":{
            "@type":"Offer",
            "price":coLiving.room_expected_rent,
            "priceCurrency":coLiving.currency,
            "description": coLiving?.fld_room_description
          }},
      }}
      />



      <div className="layoutBody co-living-detail">
        <ImageGrid
          images={images}
          title={coLiving?.room_title}
          share={window.location.href}
          type="C"
        />

        <div className="container-fluid">
          {/* <div className="row">
            <div className="imgGallery grid-container">
              <div className="backIcon">
                <img
                  onClick={() => {
                    history.goBack();
                  }}
                  src={Back}
                  alt=""
                  srcset=""
                />
              </div>

              {images?.length <= 2 &&
                images?.map((item, index) => (
                  <div key={index} className={`grid-item-${index ? "n" : 0}`}>
                    <img
                      className="grid-img"
                      src={item}
                      alt={coLiving?.room_title}
                    />
                  </div>
                ))}

              {images?.length >= 3 &&
                images?.map((item, index) => {
                  if (index <= 2)
                    return (
                      <div key={index} className={`grid-item-${index}`}>
                        <img
                          className="grid-img"
                          src={item}
                          alt={coLiving?.room_title}
                        />
                      </div>
                    );
                })}

              <div className="blurIcon futura-12" onClick={handleShow}>
                <span>+{images?.length}</span>
              </div>
            </div>
          </div>

          <div
            class="filter-backdrop"
            style={{ display: isModalVisible ? "block" : "none" }}
          >
            <button className="btn text-white" onClick={handleClose}>
              &#10005;
            </button>

            <div className="mt-2">
              <Carousel autoplay={false} infinite>
                {images?.map((item, index) => (
                  <div key={index} className="frame">
                    <img
                      className="d-block w-100"
                      src={item}
                      alt="RoomDaddy"
                      style={{ width: "100%", verticalAlign: "top" }}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div> */}

          <div className="row backgroudLightBlue p-0 d-flex flex-column p-4">
            <div className="montserratN900-22">
              <h1
                style={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: 900,
                  fontSize: 22,
                  lineHeight: "145.9%",
                  color: "#1c2c4a",
                }}
              >
                {coLiving.room_title}
              </h1>
            </div>
            <div className="py-2">
              <div className="futura-14">
                <img src={RatingStar} alt="" srcset="" />
                <span className="p-1">{reviews.length && getAvg(reviews)}</span>
                <span className="p-1 futuraBkBtN-14">
                  <u>All reviews</u>
                </span>
              </div>
            </div>
            <div className="futuraBkBtN-14 d-flex align-items-center justify-content-between">
              <p className="m-0">
                {coLiving.Area}, {coLiving.city}
              </p>

              <p
                className="montserratNB-18 m-0"
                style={{ whiteSpace: "nowrap" }}
              >
                {coLiving.currency} {coLiving.rent}{" "}
                <span className="montserratNB-14">/ month</span>
              </p>
            </div>
            {/* <div className="">
              <div className="d-flex align-items-baseline p-0">
                <div className="col-6 pl-0">
                </div>
                  <p
                    className="montserratNB-18 mb-0 mt-2"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {coLiving.currency} {coLiving.rent}{" "}
                    <span className="montserratNB-14">/ month</span>
                  </p>
                <div className="col-6 pr-0 d-flex justify-content-end text-right ml-auto">
                  <button
                    type="button"
                    className="bookN futuraN700-14 px-4 py-2"
                    style={{ borderRadius: "99px" }}
                  >
                    Book now
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <WingBlank>
            <Modal
              popup
              visible={emailPopUp}
              onClose={onClose("Email")}
              animationType="slide-up"
              // afterClose={() => { alert('afterClose'); }}
            >
              <div class="container">
                <div className="">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 text-center w-100">
                    <div className="row">
                      <div className="col-10 text-left">
                        <p>Email</p>
                      </div>

                      <div className="col-2">
                        <span onClick={onClose("Email")}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group my-2">
                    <label className="futura-14 text-xyz my-2">
                      Your name{" "}
                    </label>

                    <input
                      type="name"
                      name="userName"
                      className="rd-custom-input"
                      placeholder="Name"
                      value={contactForm.name}
                      onChange={(e) => updateForm("name", e.target.value)}
                    />
                  </div>

                  <div className="form-group my-2">
                    <label className="futura-14 text-xyz my-2">
                      Your email{" "}
                    </label>

                    <input
                      type="email"
                      name="userEmail"
                      className="rd-custom-input"
                      placeholder="username@email.com"
                      value={contactForm.email}
                      onChange={(e) => updateForm("email", e.target.value)}
                    />
                  </div>

                  <div className="form-group my-2">
                    <label className="futura-14 text-xyz my-2">
                      Your phone{" "}
                    </label>

                    <input
                      type="phone"
                      name="userPhone"
                      className="rd-custom-input"
                      placeholder="+971 xxx xxx xxx"
                      maxLength={32}
                      value={contactForm.number}
                      onChange={(e) => updateForm("number", e.target.value)}
                    />
                  </div>

                  <div className="form-group my-2 mb-3">
                    <label className="futura-14 text-xyz my-2">
                      Your message{" "}
                    </label>

                    <textarea
                      className="rd-custom-textarea"
                      rows="3"
                      cols="48"
                      // value=
                      onChange={(e) => updateForm("message", e.target.value)}
                    >
                      {contactForm.message_data}
                    </textarea>
                  </div>

                  <div className="form-group mt-2 mb-4">
                    <Button
                      className="w-100 bookN futuraN700-14 EmailMsg"
                      onClick={() => {
                        sendEmail();
                        collectLead("e");
                      }}
                    >
                      Send Email
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </WingBlank>

          <WingBlank>
            <Modal
              popup
              visible={chatPopUp}
              onClose={onClose("Chat")}
              animationType="slide-up"
              // afterClose={() => { alert('afterClose'); }}
            >
              <div class="px-4 py-2 w-100">
                <div className="pl-3 w-100">
                  <div className="montserratNB-16 text-xyz mt-4 mb-3 w-100">
                    <div className="row w-100">
                      <div className="col-10">
                        <p>Chat</p>
                      </div>

                      <div className="col-2 pr-0 text-right">
                        <span onClick={onClose("Chat")}>
                          <img src={Cross} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group my-2 futura-14">
                    <label className="futura-14 text-xyz my-2">
                      Your message{" "}
                    </label>

                    <textarea
                      className="rd-custom-textarea"
                      style={{ fontFamily: "futuraBkbt" }}
                      rows="3"
                      cols="46"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                    ></textarea>
                  </div>

                  {/* <div className="form-group my-2">
                    <label className="futura-14 text-xyz my-2">
                      Your name{" "}
                    </label>
                    <input
                      type="name"
                      name="userName"
                      className="form-control inputBorder"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group my-2">
                    <label className="futura-14 text-xyz my-2">
                      Your email{" "}
                    </label>
                    <input
                      type="email"
                      name="userEmail"
                      className="form-control inputBorder"
                      placeholder="username@gmail.com"
                    />
                  </div>
                  <div className="form-group my-2">
                    <label className="futura-14 text-xyz my-2">
                      Your phone{" "}
                    </label>
                    <input
                      type="phone"
                      name="userPhone"
                      className="form-control inputBorder"
                      placeholder="+971 xxx xxx xxx"
                    />
                  </div> */}

                  <div className="form-group mt-2 mb-4 w-100">
                    <Button
                      className="w-100 bookN futuraN700-14 ChatMsg"
                      onClick={() => {
                        if (user) {
                          sendMessage();
                          collectLead("c");
                        } else {
                          history.push("/login");
                        }

                        onClose("Chat")();
                      }}
                    >
                      Send message
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </WingBlank>

          <div className="container-fluid" style={{ padding: "5px 0" }}>
            <div className="d-flex align-items-center">
              <div className="p-3" style={{ overflow: "hidden" }}>
                {/* <figure
                    className="background"
                    // style={{
                    //   backgroundImage: `url(${UploadProfile})`,
                    //   height: "70px",
                    //   objectFit: "contain",
                    // }}
                  > */}
                {coLiving.fld_profile_pic ? (
                  <img
                    src={coLiving.fld_profile_pic}
                    alt=""
                    style={{
                      height: "40px",
                      objectFit: "contain",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <img
                    src={ProfileIcon}
                    alt=""
                    style={{
                      height: "40px",
                      objectFit: "contain",
                      borderRadius: "50%",
                    }}
                  />
                )}
                {/* </figure> */}
              </div>

              <p className="futuraN700-18 mb-0 pl-2">{coLiving.name}</p>

              {/* <p className="futuraBkBtN-14 d-flex align-items-center">
                  Lister score N/A
                  <span
                    className="ml-2 cardItemsList p-1 m-1 futuraBkBtN-14 d-flex align-items-center justify-content-center"
                    style={{ borderRadius: "99px" }}
                  >
                    <img
                      className="p-1 pr-2"
                      src={ListerBadge}
                      alt=""
                      srcset=""
                    />
                    <span>5</span>
                  </span>
                </p> */}
            </div>
          </div>

          <div className="mt-1">
            <div className="row backgroudLightBlueAmen p-3">
              {/* <div className="border-bottom pt-4 pb-3">
                <p className="futuraN700-18">Popular</p>
                <PropertyTypes data={PopularAmen} />
              </div> */}
              <div className="border-bottom pt-1 pb-3 w-100">
                <div>
                  <p className="futuraN700-18 mb-1">Flatmates</p>
                  <div className="d-flex mb-2">
                    <div className="m-2">
                      <img src={Women} alt="" srcset="" />
                      <span className="futuraBkBtN-14 p-2">
                        {coLiving.room_females || coLiving.room_mates_female}{" "}
                        female(s)
                      </span>
                    </div>
                    <div className="m-2">
                      <img src={Men} alt="" srcset="" />
                      <span className="futuraBkBtN-14 p-2">
                        {coLiving.room_males || coLiving.room_mates_male}{" "}
                        male(s)
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <p className="futuraMdBtNB-14 mb-1">Prefered flatmate</p>
                  <span className="futuraBkBtN-14">
                    Can accomodate:{" "}
                    {(coLiving.gender === "M/F" ||
                      coLiving.gender === "on" ||
                      !coLiving.gender) &&
                      "Male or Female"}
                    {coLiving.gender === "M" && "Male"}
                    {coLiving.gender === "F" && "Female"}
                  </span>
                  <br />
                  {/* <span className="futuraBkBtN-14">18 to 40+ years old</span> */}
                </div>

                <div className="mt-3">
                  <p className="futuraMdBtNB-14 mb-1">Room Capacity</p>
                  <span className="futuraBkBtN-14">
                    Up to {coLiving.occupancy} person(s)
                  </span>
                </div>
              </div>

              <div className="border-bottom pt-4 pb-3 w-100 border-bottom-0">
                <p className="futuraN700-18">Other</p>
                <div className="">
                  <div className="m-2">
                    <img src={Calender} alt="" srcset="" />
                    <span className="futuraBkBtN-14 p-2">
                      Available from {coLiving.avalability_date}
                    </span>
                  </div>
                  {/* <div className="m-2">
                    <img src={Bus} alt="" srcset="" />
                    <span className="futuraBkBtN-14 p-2">
                      10-15 minutes walking
                    </span>
                  </div> */}
                  <div className="m-2">
                    <img src={Payment} alt="" srcset="" />
                    <span className="futuraBkBtN-14 p-2">
                      Deposit <span>{coLiving.room_deposit} AED</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ResponsiveAd />
          <div className="mt-4">
            <div className="card">
              <div className="card-body">
                <div className="montserratN900-22 pl-2">
                  <p className="">Property Details</p>
                </div>

                <div className="futuraBkBtN-14 pl-2 py-2">
                  <p>{coLiving.fld_room_description}</p>
                </div>

                <div class="futuraBkBtN-14 d-flex">
                  <div class="m-2">
                    <img src={PinMark} alt="" />
                  </div>
                  <div class="m-2">
                    <p class="mb-0">{coLiving.fld_address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 border-bottom d-flex flex-column">
            <div className="montserratN900-22 pl-3">
              <p className="">Apartment Features</p>
            </div>
            {/* Room */}
            <div className="futuraN700-14 pl-3 pb-2">
              <p className="mb-2">Room Amenities</p>
              <div className="futuraBkBtN-14 amenities">
                {coLiving.natural_light_amenities !== 0 && (
                  <div className="p-2 amenDiv">
                    <img src={NaturalLights} alt="" />
                    <span className="p-1">Natural light</span>
                  </div>
                )}

                <div className="p-2 amenDiv">
                  <img src={Bath} alt="" />
                  <span className="p-1">{coLiving.bath}</span>
                </div>

                {coLiving.window_amenities !== 0 && (
                  <div className="p-2 amenDiv">
                    <img src={Window} alt="" />
                    <span className="p-1">Window</span>
                  </div>
                )}

                {coLiving.fridge_amenities !== 0 && (
                  <div className="p-2 amenDiv">
                    <img src={Fridge} alt="" />
                    <span className="p-1">Fridge</span>
                  </div>
                )}

                {coLiving.tv_amenities !== 0 && (
                  <div className="p-2 amenDiv">
                    <img src={Tv} alt="" />
                    <span className="p-1">Tv</span>
                  </div>
                )}

                {/* <div className="p-2 amenDiv">
                  <img src={Size} alt="" />
                  <span className="p-1">Room size</span>
                </div> */}
              </div>
            </div>

            {/* Home */}
            {(coLiving.wifi_amenities !== 0 ||
              coLiving.parking_amenities !== 0 ||
              coLiving.balcony_amenities != 0 ||
              coLiving.elevator_amenities !== 0 ||
              coLiving.private_balcony_amenities !== 0 ||
              coLiving.wheelcheer_amenities !== 0 ||
              coLiving.terrace_amenities !== 0 ||
              coLiving.washing_amenities !== 0 ||
              coLiving.living_room_amenities !== 0 ||
              coLiving.dishwasher_amenities !== 0) && (
              <div className="float-left">
                <div className="futuraN700-14 pl-3 pb-2">
                  <p className="mb-2">House Amenities</p>

                  <div className="futuraBkBtN-14 amenities">
                    {coLiving.wifi_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Internet} alt="" />
                        <span className="p-1">Free wi-fi</span>
                      </div>
                    )}

                    {coLiving.parking_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Parking} alt="" />
                        <span className="p-1">Parking</span>
                      </div>
                    )}

                    {coLiving.elevator_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Elevator} alt="" />
                        <span className="p-1">Elevator</span>
                      </div>
                    )}

                    {coLiving.washing_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Laundry} alt="" />
                        <span className="p-1">Washing Machine</span>
                      </div>
                    )}

                    {coLiving.balcony_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Balcony} alt="" />
                        <span className="p-1">Common Balcony</span>
                      </div>
                    )}

                    {coLiving.dishwasher_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={DishWasher} alt="" />
                        <span className="p-1">Dish washer</span>
                      </div>
                    )}

                    {coLiving.wheelcheer_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Wheelchair} alt="" />
                        <span className="p-1">Wheelchair friendly</span>
                      </div>
                    )}

                    {coLiving.terrace_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Terrace} alt="" />
                        <span className="p-1">Terrace</span>
                      </div>
                    )}

                    {coLiving.private_balcony_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Balcony} alt="" />
                        <span className="p-1">Private Balcony</span>
                      </div>
                    )}

                    {coLiving.living_room_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={CommonRoom} alt="" />
                        <span className="p-1">Living Room</span>
                      </div>
                    )}

                    

                   
                  </div>
                </div>
              </div>
            )}

            {/* Outdoor */}
            {(coLiving.gym !== 0 ||
              coLiving.pool_amenities !== 0 ||
              coLiving.garden_amenities !== 0 ||
              coLiving.squash !== 0 ||
              coLiving.table_tennis !== 0 ||
              coLiving.lawn_tennis !== 0 ||
              coLiving.parking_amenities !== 0) && (
              <div className="float-left">
                <div className="futuraN700-14 pl-3 pb-2">
                  <p className="mb-2">Outdoor Amenities</p>
                  <div className="futuraBkBtN-14 amenities">
                    {coLiving.gym !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Fitness} alt="" />
                        <span className="p-1">Gym</span>
                      </div>
                    )}

                    {coLiving.pool_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Pool} alt="" />
                        <span className="p-1">Pool</span>
                      </div>
                    )}

                    {coLiving.garden_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Landscape} alt="" />
                        <span className="p-1">Garden</span>
                      </div>
                    )}

                    {coLiving.squash !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Squash} alt="" />
                        <span className="p-1">Squash</span>
                      </div>
                    )}

                    {coLiving.table_tennis !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={TableTennis} alt="" />
                        <span className="p-1">Table tennis</span>
                      </div>
                    )}

                    {coLiving.lawn_tennis !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={TennisCourt} alt="" />
                        <span className="p-1">Lawn tennis</span>
                      </div>
                    )}

                    {/* <div className="p-2 amenDiv">
                      <img src={Lake} alt="" />
                      <span className="p-1">Lake</span>
                    </div> */}

                    {/* {coLiving.parking_amenities !== 0 && (
                      <div className="p-2 amenDiv">
                        <img src={Park} alt="" />
                        <span className="p-1">Park</span>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            )}

            {/* Rules */}
            <div className="float-left">
              <div className="futuraN700-14 pl-3 pb-2">
                <p className="mb-2">House rules</p>
                <div className="futuraBkBtN-14 amenities">
                  <div className="p-2 amenDiv">
                    <img src={Smoking} alt="" />
                    <span className="p-1">
                      {!coLiving.house_rules_smoker
                        ? "Non smoking"
                        : "Smoker friendly"}
                    </span>
                  </div>

                  <div className="p-2 amenDiv">
                    <img src={Pets} alt="" />
                    <span className="p-1">
                      {!coLiving.house_rules_pet ? "No pets" : "Pets friendly"}
                    </span>
                  </div>

                  <div className="p-2 amenDiv">
                    <img src={Couple} alt="" />
                    <span className="p-1">
                      {!coLiving.house_rules_couple
                        ? "No couples"
                        : "Couples accepted"}
                    </span>
                  </div>

                  <div className="p-2 amenDiv">
                    <img src={Toys} alt="" />
                    <span className="p-1">
                      {!coLiving.house_rules_children
                        ? "No children"
                        : "Children allowed"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Gender */}
            <div className="float-left">
              <div className="futuraN700-14 pl-3 pb-2">
                <p className="mb-2">Home gender rules</p>
                <div className="futuraBkBtN-14 amenities">
                  {coLiving.gender === "M" && (
                    <div className="p-2 amenDiv">
                      <img src={Mars} alt="" />
                      <span className="p-1">Strictly male</span>
                    </div>
                  )}

                  {coLiving.gender === "F" && (
                    <div className="p-2 amenDiv">
                      <img src={Femenine} alt="" />
                      <span className="p-1">Strictly female</span>
                    </div>
                  )}

                  {(coLiving.gender === "M/F" ||
                    coLiving.gender === "on" ||
                    !coLiving.gender) && (
                    <div className="p-2 amenDiv">
                      <img src={Mix} alt="" />
                      <span className="p-1">Mix gender</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Things to know  */}
            <div className="float-left w-100">
              <div className="futuraN700-14 pl-3 pb-2">
                <p className="mb-2">Things to know</p>
                <div className="futuraBkBtN-14">
                  {/* <p className="mb-0">Check-in: After 12:00 PM</p> */}
                  <p className="mb-0">{coLiving.bills_included}</p>
                  <p className="mb-0">Bed Type: {coLiving.roombedtype}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 float-left w-100">
            <div className="montserratN900-22 pl-3">
              <p className="">Reviews</p>
            </div>

            <WingBlank>
              <Carousel
                className="space-carousel"
                frameOverflow="visible"
                cellSpacing={10}
                slideWidth={0.9}
                // autoplay
                infinite
                dots={false}
              >
                {reviews.map((review, i) => (
                  <div key={i} className="card">
                    <div className="card-body">
                      {/* <div className="row">
                        <div className="col-3">
                          <div className="icon icon-50">
                            <figure
                              className="background"
                              style={{
                                backgroundImage: `url(${UploadProfile})`,
                              }}
                            >
                              <img
                                src={UploadProfile}
                                alt=""
                                style={{ display: "none" }}
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="col-9">
                          <p className="futuraN700-18 mb-0">
                            Mary, <span>32</span>
                          </p>
                        </div>
                      </div> */}

                      <div className="futuraBkBtN-14 p-3">
                        <p>{review.fld_comments}</p>
                      </div>
                    </div>
                  </div>
                ))}

                {!reviews.length && (
                  <div className="card">
                    <div className="card-body">
                      <div className="futuraBkBtN-14 p-2">
                        No reviews found.
                      </div>
                    </div>
                  </div>
                )}
              </Carousel>
            </WingBlank>

            {reviews.length ? (
              <div className="pt-2 futuraN700-14">
                <button
                  type="button"
                  className="bookN"
                  onClick={() => setReviewsModal(true)}
                  style={{ borderRadius: "99px" }}
                >
                  Show all reviews
                </button>
              </div>
            ) : null}
          </div>

          <div
            className="map-box-new mt-5 float-left w-100"
            style={{ paddingBottom: 85 }}
          >
            <div className="montserratN900-22 pl-3">
              <p className="">Location</p>
            </div>

            <div className="futuraBkBtN-14 pl-3 pb-3">
              <p>{coLiving.fld_address}</p>
            </div>

            <LeafletMap position={position} />

            {/* <GoogleMap
              lat={coLiving.fld_latitude}
              lng={coLiving.fld_longitude}
            /> */}
          </div>

          {/* smart listing */}
          {/* <div className="mt-5 float-left pb60 w-100">
            <div className="montserratN900-22 pl-3">
              <p className="">Smart Listings</p>
            </div>
            <WingBlank>
              <Carousel
                className="space-carousel"
                frameOverflow="visible"
                cellSpacing={10}
                slideWidth={1}
                // autoplay
                infinite
                dots={false}
              >
                {smartListings.map((smartListing, i) => (
                  <div key={i} className="col-12 w-100">
                    <div className="card">
                      <div className="card-head"></div>
                      <div className="card-bod">
                        {/* <Carousel>
                        {/* {smartListing?.room_images.map((img, i) => (
                        <img
                          key={i}
                          className="d-block w-100 carousalImg"
                          src={BannerImage}
                          // src={img}
                          alt={smartListing?.custom_room_name}
                        />
                        {/* ))}
                        {/* </Carousel>
                      </div>
                      <Link to={`/flatshare/list/${smartListing?.bedspace_id}`}>
                        <div className="pt-3">
                          <div className="futuraBold18 pl-3">
                            <p
                              className="pb-2"
                              style={{
                                width: "225px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {smartListing?.custom_room_name}
                            </p>
                          </div>
                          <div className="montserratNB-16 pl-3">
                            <p className="text-black pb-2">
                              {smartListing?.currency} {smartListing?.rent}
                            </p>
                          </div>
                          <div className="futuraBkBtN-14 pl-3">
                            <p className="text-gray pb-2">
                              {smartListing?.Area}
                            </p>
                          </div>
                        </div>
                        <div className="pl-3">
                          <ul className="cardItemUl d-flex">
                            <li className="cardItemsList">
                              <img src={UserIcon} alt="" />
                              <span className="p-2 text-black">5</span>
                            </li>
                            <li className="cardItemsList">
                              <img src={DoorIcon} alt="" />
                              <span className="p-2 text-black">1</span>
                            </li>
                            <li className="cardItemsList">
                              <img src={BedIcon} alt="" />
                              <span className="p-2 text-black">2</span>
                            </li>
                            <li className="cardItemsList">
                              <img src={BathIcon} alt="" />
                              <span className="p-2 text-black">1</span>
                            </li>
                          </ul>
                        </div>
                        <div className="futuraN700-14 pl-3 py-3">
                          <Link
                            to={`/flatshare/list/${smartListing?.custom_room_name}`}
                            className="bookN"
                          >
                            Book now
                          </Link>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </Carousel>
            </WingBlank>
          </div> */}
        </div>
      </div>

      <Modal visible={phonePopUp}>
        <div className="p-4 text-center">
          <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
            Would you like to call the host?
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="bookN-s mr-3 CallButton"
              onClick={() => setPhonePopUp(false)}
            >
              <a
                href={`tel:${coLiving?.contact}`}
                onClick={() => collectLead("p")}
              >
                Yes
              </a>
            </Button>

            <Button className="bookN-s" onClick={() => setPhonePopUp(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>

      <Modal visible={whatsappPopUp}>
        <div className="p-4 text-center">
          <p style={{ fontSize: 18, fontFamily: "futuraBkBt" }}>
          Would you like to contact the host on WhatsApp?
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <Button
              className="bookN-s mr-3 CallButton"
              onClick={() => setWhatsAppPopUp(false)}
            >
             <a href={`whatsapp://send?phone=${coLiving?.contact}&text=I'm interested to know about ${coLiving?.room_title}, ${coLiving?.property_share_url}`} 
                  class="bla"
                  onClick={() => collectLead("p")}
                  >Yes</a>
            </Button>

            <Button className="bookN-s" onClick={() => setWhatsAppPopUp(false)}>
              No
            </Button>
          </div>
        </div>
      </Modal>

      <div className="bottom-navigation-wrap py-3">
        

        {/* {coLiving.Owner_id === 8760 ?  */}
      {/* ( */}
      <div className="pr-1">
              <button
                id="co-living-mob-call"
                className="bootomBtn futuraN700-14 MobCall"
                onClick={() => setPhonePopUp(true)}
              >
                <a
                  href={`tel:${coLiving.contact}`}
                  onClick={() => collectLead("p")}
                >
                <img src={PhoneCall} alt="" />
                <span className="p-1">WhatsApp</span>
                </a>
              </button>
            </div>
            {/* ) : 
             (
             <div className="pr-1">
             <button
              id="co-living-mob-call"
              className="bootomBtn futuraN700-14 MobCall"
              onClick={() => setPhonePopUp(true)}
            >
              
              <img src={PhoneCall} alt="" />
              <span className="p-1 CallButton">Call</span>
              
            </button>
          </div>) */}

        

        <div className="pr-1">
          <button
            id="co-living-mob-chat"
            className="bootomBtn futuraN700-14 MobChat"
            onClick={onOpen("Chat")}
          >
            <img src={Comment} alt="" />
            <span className="p-1">Chat</span>
          </button>
        </div>

        <div className="">
          <button
            id="co-living-mob-email"
            className="bootomBtn futuraN700-14 MobEmail"
            onClick={onOpen("Email")}
          >
            <img src={Email} alt="" />
            <span className="p-1">Email</span>
          </button>
        </div>
      </div>

      <Modal visible={reviewsModal}>
        <div class="w-100">
          <div className="px-4 montserratNB-16 text-xyz pt-2 mb-2 mt-3 w-100">
            <div className="row w-100">
              <div className="col-10">
                <p>Reviews</p>
              </div>

              <div className="col-2 pr-0 text-right">
                <span onClick={() => setReviewsModal(false)}>
                  <img src={Cross} alt="" />
                </span>
              </div>
            </div>
          </div>

          <div
            className="px-4 pb-2 pt-3 w-100"
            style={{ maxHeight: 500, overflowY: "auto" }}
          >
            {reviews.map((review, i) => (
              <div key={i} className="card mb-3">
                <div className="card-body">
                  <div className="futuraBkBtN-14 p-3">
                    <p>{review.fld_comments}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
