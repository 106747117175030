import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import Spinner from '../../pages/Spinner/normal';
import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom'
//import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'

const mapStyles = {
  width: '100%',
  height: '100%'
};

const room_name = {
  display: "block",
  whiteSpace: "nowrap",
  width: "18em",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      mapItems: props.mapItems,
      selected: {},
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false
    }
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      selected: props,
      showingInfoWindow: true
    });
    console.log(this.state.selectedPlace)
  }

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  displayMarkers = () => {
    return this.state.mapItems.map((item, index) => {
      return (
        <Marker
          custom_room_name = {item.custom_room_name}
          bath = {item.bath}
          rent = {item.rent}
          room_images = {item.room_images}
          bedtype = {item.bedtype}
          currency = {item.currency}
          building_id = {item.building_id}
          onClick={this.onMarkerClick}
          position={{ lat: Number(item.latitude), lng: Number(item.longitude)}}
        />
        //</MarkerClusterer>
      )
    })
  }

  render() {
    if (!this.props.loaded) return <Spinner />;

    return (
      <Map
        google={this.props.google}
        zoom={13}
        style={mapStyles}
        onClick={this.onMapClicked}
        initialCenter={{ lat: Number(this.state.mapItems[0].latitude), lng: Number(this.state.mapItems[0].longitude)}}
      >
      
      {this.displayMarkers()}

        <InfoWindow
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}
        >
              <div className="card product-card-large mb-3"> 
                  <div className="card-body p-0">
                      <div className="product-image">
                        { this.state.selectedPlace.room_images && (
                          <Carousel controls={false} slide={false} interval={4000}>
                              {this.state.selectedPlace.room_images.map((image, index) => (
                                  <Carousel.Item className="" >
                                      <img
                                      className="d-block w-100 carousalImg"
                                      src= { image } 
                                      alt={ this.state.selectedPlace.custom_room_name }
                                      /> 
                                  </Carousel.Item>
                              ))}
                          </Carousel>
                        )}
                      </div>
                  </div>
                  <div to="/someLink" className="card-footer">
                      <div className="">
                        <div className="">
                          <span className="text-dark" style={room_name} >{this.state.selectedPlace.custom_room_name} </span>
                        </div>
                      </div>
                      <div className="">
                          <div className="d-flex">
                              { this.state.selectedPlace.bath && (<span className="small text-secondary">{ this.state.selectedPlace.bath } &nbsp;&nbsp;| &nbsp; &nbsp;</span>)}
                              { this.state.selectedPlace.bedtype && (<span className="small text-secondary">{ this.state.selectedPlace.bedtype } &nbsp;&nbsp; </span>)}
                          </div>
                          <div className="">
                              <p className="small text-secondary">
                                  <b>{this.state.selectedPlace.currency} &nbsp; { this.state.selectedPlace.rent }</b>
                                  <span className="small text-secondary"> / Month</span>
                              </p> 
                          </div>
                      </div>
                  </div>
              </div>
        </InfoWindow>
      </Map>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyCQ5ROxtS28P2G6656XDszU84H16lAfJ98",
  version: "3.38"
})(MapContainer);
