import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from "../../AppContext";
import Header from "../pageHeader/index"
import { DatePicker, message } from 'antd';
import moment from "moment";
import axios from 'axios'
import { useParams } from "react-router-dom";
import app from './../../utils/appConfig';

export default function Notice() {
    let user = JSON.parse(localStorage.getItem("_RMDUser"))
    // console.log("local", user.user.id)
    const { id } = useParams();
    const { userInfo } = useContext(AppContext);
    const[moveOutNotice, setMoveOutNotice] = useState({
        booking_id: id,
        fld_tenent_id: user.user.id,
        date: userInfo.checkIn,
        reason: '',
    })
    function updateForm(key, value) {
        setMoveOutNotice({...moveOutNotice, [key]: value});
    }
    useEffect(() => {
        console.log(moveOutNotice);
    }, [moveOutNotice]);

    function sendNoticeData (){
        let data = new FormData();
        for(let i in moveOutNotice ) {
            data.append(i, moveOutNotice[i]);
        }
        const config = {
            headers: {
                'content-type': 'addNotice_form'
            }
        };
        axios.post(`${app.baseUrl}/api/add_notice`, data, config).then((res)=>{
            console.log('Notice', res)
            message.success('Submit successfully!');
            // window.location = "/booking"
        }).catch((err)=>{
            console.log(err) 
            message.error('Somting went wrong!');
        })
    }
    return (
        <>
            <Header
                arrowLink={"/booking"}
                heading={"Move out notice"}
            />
            <main className="flex-shrink-0">
                <div className="container">
                    <div className="card mb-3">
                        <div className="card-body">
                            <h6>Notice Request</h6>
                            <p class="text-secondary ">
                                For
                                <span class="text-dark">&nbsp;&nbsp;Booking No {moveOutNotice.booking_id}</span>
                            </p>
                            <DatePicker  
                                style={{ width: 250 }}
                                defaultValue={moment(moveOutNotice.date, 'YYYY/MM/DD')}
                                onChange={(e, str)=> updateForm("date", str)} 
                            />
                            <div className="mt-4 mb-4">
                                <textarea 
                                    className="form-control chat-msg"
                                    onChange={ e => updateForm("reason", e.target.value) }
                                    name="message" rows="3" placeholder="Reason to Move Out:..."
                                ></textarea>
                            </div>
                            <button type="button" className="btn btn-block btn-info btn-lg"
                                onClick={sendNoticeData}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </main>  
        </>
    )
}
