import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { Checkbox, Select } from "antd";
import { AppContext } from "../AppContext";
import app from "../utils/appConfig";
import BackIcon from "../icons/back.svg";
import "./style/addOns.scss";
import { ButtonBase as Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CACHE__H_ROOMS_CART } from "../data/cacheKeys";
import { getLsValue, separateNumbers, setLsValue } from "../../utils/functions";

const AddOnsPage = () => {
  const history = useHistory();
  const { id, startTime, endTime, interval, currency } = useParams();
  const {
    selectedRooms,
    setSelectedRooms,
    userInfo,
    setUserInfo,
    numOfRooms,
    setNumOfRooms,
    addons,
    setAddons,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [room, setRoom] = useState({});
  const [startDate, setStartDate] = useState(startTime);
  const [endDate, setEndDate] = useState(endTime);
  const [hotelDetail, setHotelDetail] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [active, setActive] = useState(false);
  const [state, setState] = useState({
    breakfast: false,
    breakfast_price: 0,
    numberOfBreakfast: 1,
    internet: false,
    internet_price: 0,
    parking: false,
    parking_price: 0,
    pets: false,
    pets_price: 0,
    full_board: false,
    full_board_price: 0,
    half_board: false,
    half_board_price: 0,
    numberOfPersons: 1,
  });

  // useEffect(() => {
  // if (Object.getPrototypeOf(selectedRooms) === Object.prototype) {
  //   if (Object.values(selectedRooms).length)
  //     setHotelId(Object.values(selectedRooms)[0].hotelId);
  //   else history.push("/");
  // } else {
  //   if (selectedRooms.length) setHotelId(selectedRooms[0].hotelId);
  //   else history.push("/");
  // }
  // if (
  //   (Object.getPrototypeOf(selectedRooms) === Object.prototype &&
  //     !Object.values(selectedRooms).length) ||
  //   !selectedRooms.length
  // )
  //   history.push("/");
  // const cart = JSON.parse(localStorage.getItem("_RD_Cart"));
  // if (cart) {
  //   setSelectedRooms(cart.selectedRooms);
  //   setUserInfo(cart.userInfo);
  //   setState(cart.state);
  //   if (
  //     cart.selectedRooms &&
  //     Object.keys(cart.selectedRooms).length === 0 &&
  //     Object.getPrototypeOf(cart.selectedRooms) === Object.prototype
  //   )
  //     history.push(`/hotel/detail/${id}/rooms`);
  // }
  // }, []);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await axios
      .get(
        `${app.hotelBaseUrl}/api/get_client_hotel_data/${id}/${userInfo.checkIn}/${userInfo.checkOut}/${userInfo.diffInDays}/AED`
      )
      .then((res) => {
        setHotelDetail(res.data[0]);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  function updateState(name, value) {
    if (name === "numberOfRooms") setNumOfRooms(value);
    else
      setState({
        ...state,
        [name]: value,
        internet_price: hotelDetail?.services?.internet_price,
        parking_price:
          hotelDetail?.services?.parking_price &&
          parseInt(hotelDetail?.services?.parking_price),
        pets_price: hotelDetail?.services?.charges_pets_price,
        breakfast_price:
          hotelDetail?.services?.optional_breakfast_price *
          state.numberOfBreakfast,
        full_board_price:
          hotelDetail?.services?.fullboard_price * state.numberOfPersons,
        half_board_price:
          hotelDetail?.services?.halfboard_price * state.numberOfPersons,
      });
  }

  function getBreakfastOptions(e) {
    let elem = [];
    for (let index = 1; index <= parseInt(e); index++)
      elem.push(
        <Select.Option key={index} value={index}>
          {index}
        </Select.Option>
      );
    return elem;
  }

  function getNumberOfPersons(e) {
    let elem = [];
    for (let index = 1; index <= parseInt(e); index++)
      elem.push(
        <Select.Option key={index} value={index}>
          {index}
        </Select.Option>
      );
    return elem;
  }

  const confirmAddOns = () => {
    setAddons(state);

    // const cart = getLsValue(CACHE__H_ROOMS_CART);
    // cart.state = state;

    // setLsValue(CACHE__H_ROOMS_CART, cart);

    history.push("/checkout");
  };

  const skipToCheckout = () => {
    setAddons({
      breakfast: false,
      breakfast_price: 0,
      numberOfBreakfast: 1,
      internet: false,
      internet_price: 0,
      parking: false,
      parking_price: 0,
      pets: false,
      pets_price: 0,
      full_board: false,
      full_board_price: 0,
      half_board: false,
      half_board_price: 0,
    });

    history.push("/checkout");
  };

  return (
    <div className="add-ons">
      <div
        className="header screen-header"
        style={{ zIndex: "999", padding: "20px" }}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <img
              className=""
              src={BackIcon}
              alt="back_Arrow"
              onClick={() => {
                const prevHost = window.document.referrer
                  ? new URL(window.document.referrer).hostname
                  : null;
                const currHost = window.location.hostname;

                if (!prevHost || prevHost !== currHost)
                  history.push(`/hotel/detail/${id}/rooms`);
                else history.goBack();
              }}
            />
          </div>

          <div className="col">
            <div className="futuraN700-17">AddOns</div>
          </div>
        </div>
      </div>

      <div className="container-fluid" style={{ padding: "85px 15px" }}>
        <div className="card">
          <div className="card-body">
            {loading ? (
              <p className="px-2 mb-0">Loading...</p>
            ) : (
              <>
                {hotelDetail?.services?.fullboard === "free" ||
                hotelDetail?.services?.halfboard === "free" ||
                hotelDetail?.services?.breakfast === "free" ||
                hotelDetail?.services?.internet === "free" ||
                hotelDetail?.services?.parking === "free" ||
                (hotelDetail?.policies?.allow_pets === "yes" &&
                  hotelDetail?.policies?.charges_pets === null) ? (
                  <ul
                    className="futuraBkBtN-14 p-2 pl-4 pb-3"
                    style={{ listStyle: "inherit" }}
                  >
                    {hotelDetail?.services?.fullboard === "free" && (
                      <li>Free Fullboard</li>
                    )}

                    {hotelDetail?.services?.halfboard === "free" && (
                      <li>Free Halfboard</li>
                    )}

                    {hotelDetail?.services?.breakfast === "free" && (
                      <li>Free Breakfast</li>
                    )}

                    {hotelDetail?.services?.internet === "free" && (
                      <li>Free Internet</li>
                    )}

                    {hotelDetail?.services?.parking === "free" && (
                      <li>Free Parking</li>
                    )}

                    {hotelDetail?.policies?.allow_pets === "yes" &&
                      hotelDetail?.policies?.charges_pets === null && (
                        <li>Pets Allowed for Free</li>
                      )}
                  </ul>
                ) : null}

                <ul className="futuraBkBtN-14 p-0">
                  {hotelDetail?.services?.breakfast === "optional" && (
                    <li className="pb-2 pl-2">
                      <div className="counter">
                        <Checkbox
                          onChange={(e) =>
                            updateState("breakfast", e.target.checked)
                          }
                        >
                          Breakfast at {hotelDetail?.hotel_currency}{" "}
                          {separateNumbers(
                            hotelDetail?.services?.optional_breakfast_price
                          )}
                        </Checkbox>

                        <Select
                          style={{ width: "65px" }}
                          size="small"
                          className="pl-2"
                          placeholder="0"
                          disabled={!state.breakfast}
                          value={state.numberOfBreakfast}
                          defaultValue={state.numberOfBreakfast}
                          optionFilterProp="children"
                          onChange={(value) =>
                            updateState("numberOfBreakfast", value)
                          }
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {getBreakfastOptions(2)}
                        </Select>
                      </div>
                    </li>
                  )}

                  {hotelDetail?.services?.internet === "optional" && (
                    <li className="pb-2 pl-2">
                      <Checkbox
                        onChange={(e) =>
                          updateState("internet", e.target.checked)
                        }
                      >
                        Internet at {hotelDetail?.hotel_currency}{" "}
                        {separateNumbers(hotelDetail?.services?.internet_price)}
                      </Checkbox>
                    </li>
                  )}

                  {hotelDetail?.services?.parking === "optional" && (
                    <li className="pb-2 pl-2">
                      <Checkbox
                        onChange={(e) =>
                          updateState("parking", e.target.checked)
                        }
                      >
                        Parking at {hotelDetail?.hotel_currency}{" "}
                        {separateNumbers(hotelDetail?.services?.parking_price)}
                      </Checkbox>
                    </li>
                  )}

                  {hotelDetail?.policies?.allow_pets === "yes" &&
                    hotelDetail?.policies?.charges_pets ===
                      "Charges may apply" && (
                      <li className="pb-2 pl-2">
                        <Checkbox
                          onChange={(e) =>
                            updateState("pets", e.target.checked)
                          }
                        >
                          Pets can stay for {hotelDetail?.hotel_currency}{" "}
                          {separateNumbers(
                            hotelDetail?.policies?.charges_pets_price
                          )}
                        </Checkbox>
                      </li>
                    )}

                  {hotelDetail?.services?.fullboard === "optional" &&
                    hotelDetail?.services?.fullboard_price &&
                    hotelDetail?.services?.fullboard_price !== "undefined" && (
                      <li className="pb-2 pl-2">
                        <div className="counter">
                          <Checkbox
                            onChange={(e) =>
                              updateState("full_board", e.target.checked)
                            }
                          >
                            Full board for {hotelDetail?.hotel_currency}{" "}
                            {separateNumbers(
                              hotelDetail?.services?.fullboard_price
                            )}
                          </Checkbox>

                          <Select
                            style={{ width: "65px" }}
                            size="small"
                            className="pl-2"
                            placeholder="0"
                            disabled={!state.full_board}
                            value={state.numberOfPersons}
                            defaultValue={state.numberOfPersons}
                            optionFilterProp="children"
                            onChange={(value) =>
                              updateState("numberOfPersons", value)
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {getNumberOfPersons(2)}
                          </Select>
                        </div>
                      </li>
                    )}

                  {hotelDetail?.services?.halfboard === "optional" &&
                    hotelDetail?.services?.halfboard_price &&
                    hotelDetail?.services?.halfboard_price !== "undefined" && (
                      <li className="pb-2 pl-2">
                        <div className="counter">
                          <Checkbox
                            onChange={(e) =>
                              updateState("half_board", e.target.checked)
                            }
                          >
                            Half board for {hotelDetail?.hotel_currency}{" "}
                            {separateNumbers(
                              hotelDetail?.services?.halfboard_price
                            )}
                          </Checkbox>

                          <Select
                            style={{ width: "65px" }}
                            size="small"
                            className="pl-2"
                            placeholder="0"
                            disabled={!state.half_board}
                            value={state.numberOfPersons}
                            defaultValue={state.numberOfPersons}
                            optionFilterProp="children"
                            onChange={(value) =>
                              updateState("numberOfPersons", value)
                            }
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {getNumberOfPersons(2)}
                          </Select>
                        </div>
                      </li>
                    )}
                </ul>
              </>
            )}

            <div className="futuraN700-14 py-2 pt-1">
              <Button
                disabled={loading}
                className="bookN w-100"
                onClick={confirmAddOns}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>

        <div className="text-center mt-3">
          <div className="futuraN5-14">
            <Button
              block
              type="link"
              className="text-darkBlue mt-2 py-2 px-3"
              onClick={skipToCheckout}
            >
              Skip to checkout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOnsPage;
