import { LocationDisabled, MyLocation } from "@material-ui/icons";
import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from '../../AppContext';

let autoComplete, map, infoWindow;

function SearchLocationInput({ onAutoLocation }) {
    const { userInfo, setUserInfo, editRoomDetail } = useContext(AppContext);
    // const [query, setQuery] = useState("Dubai - United Arab Emirates");
    const autoCompleteRef = useRef(null);

    function initAutocomplete(autoCompleteRef) {
        // map = new window.google.maps.Map(document.getElementById("map"), {
        //     center: { lat: -34.397, lng: 150.644 },
        //     zoom: 6,
        // });
        // infoWindow = new window.google.maps.InfoWindow();
        // const locationButton = document.createElement("button");
        // locationButton.textContent = "Current Location";
        // locationButton.classList.add("custom-map-control-button");
        // map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
        //   locationButton
        // );
        // locationButton.addEventListener("click", () => {
        //     // Try HTML5 geolocation.
        //     if (navigator.geolocation) {
        //         navigator.geolocation.getCurrentPosition(
        //             (position) => {
        //                 // debugger;
        //                 const pos = {
        //                     lat: position.coords.latitude,
        //                     lng: position.coords.longitude,
        //                 };
        //                 infoWindow.setPosition(pos);
        //                 infoWindow.setContent("Location found.");
        //                 infoWindow.open(map);
        //                 map.setCenter(pos);
        //             }, () => {
        //                 handleLocationError(true, infoWindow, map.getCenter());
        //             }
        //         );
        //     } else {
        //         // Browser doesn't support Geolocation
        //         handleLocationError(false, infoWindow, map.getCenter());
        //     }
        // });

        if (window.google) {
            autoComplete = new window.google.maps.places.Autocomplete(
                autoCompleteRef.current,
                {
                    types: ['geocode'],
                    componentRestrictions: { country: "ae" }
                }
            );
            autoComplete.setFields(["address_components", "geometry", "formatted_address"]);
            autoComplete.addListener("place_changed", () => handlePlaceSelect());
        }

        // function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        //     infoWindow.setPosition(pos);
        //     infoWindow.setContent(
        //       browserHasGeolocation
        //         ? "Error: The Geolocation service failed."
        //         : "Error: Your browser doesn't support geolocation."
        //     );
        //     infoWindow.open(map);
        // }
    }

    async function handlePlaceSelect() {
        // debugger;
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        // updateQuery(query);
        setUserInfo({
            ...userInfo,
            "address": query,
            "lat": addressObject.geometry.location.lat(),
            "lng": addressObject.geometry.location.lng()
        });
    }

    function getLocation() {
        if (navigator.geolocation)
            navigator.geolocation.getCurrentPosition(searchAround, (err) => console.log(err));
        else
            console.log("GeoLocation is not supported by your browser.");
    }

    function searchAround({ coords }) {
        console.log(coords);

        if (window.google) {
            const latlng = new window.google.maps.LatLng(coords.latitude, coords.longitude);
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    console.log(">>>", results);

                    if (results[1]) {
                        let city;
                        console.log("formatted address ->", results[0].formatted_address);

                        for (let i = 0; i < results[0].address_components.length; i++)
                            for (let b = 0; b < results[0].address_components[i].types.length; b++)
                                if (
                                    results[0].address_components[i].types[b] == "administrative_area_level_1" ||
                                    results[0].address_components[i].types[b] == "administrative_area_level_2"
                                ) {
                                    city = results[0].address_components[i];
                                    break;
                                }

                        console.log("City Name ->", city.short_name + " " + city.long_name);
                        setUserInfo({ ...userInfo, "address": city.long_name });

                        onAutoLocation();
                    } else
                        console.log("No results found");
                } else
                    console.log("Geocoder failed due to:", status);
            });
        }
    }

    useEffect(() => {
        initAutocomplete(autoCompleteRef);
    }, []);

    return (
        <div className="search-location-input">
            <input
                ref={autoCompleteRef}
                onChange={e => setUserInfo({ ...userInfo, "address": e.target.value })}
                placeholder={editRoomDetail.city ? editRoomDetail.fld_address : "Enter a City"}
                value={userInfo.address}
                className="form-control search"
                style={{ marginBottom: "5px" }}
            />

            {
                navigator.geolocation ? (
                    <a
                        className="link_button d-inline-flex align-items-center"
                        onClick={getLocation}
                        style={{ padding: "5px 10px" }}
                    >
                        <MyLocation style={{ fontSize: "16px" }} />
                        <span className="ml-1">Search Nearby</span>
                    </a>
                ) : (
                    <a
                        className="text-muted d-inline-flex align-items-center"
                        style={{ padding: "5px 10px" }}
                    >
                        <LocationDisabled style={{ fontSize: "16px" }} />
                        <span className="ml-1">Search Nearby</span>
                    </a>
                )
            }
        </div>
    );
}

export default SearchLocationInput;
